import { call, put, takeLatest } from 'redux-saga/effects'

import { withServerStructure } from 'src/utils'
import { showSuccessToast } from 'src/store/ducks/success'
import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  Server,
  AxiosServerResponse,
  STUDENT_TRAINING_CONFIG_ROUTES,
} from 'src/services/Server'

import {
  STUDENT_TRAINING_CONFIG_TYPES as STCT,
  RequestEditTrainingConfigAction,
} from './types'

type DataToEdit = Partial<
  Omit<
    Models.StudentTrainingConfig,
    'treinonivel_id' | 'configtreinoalu_dtinclusao'
  >
>

type TrainingDaysObject = {
  configtreinoalu_segunda?: 'S' | 'N'
  configtreinoalu_terca?: 'S' | 'N'
  configtreinoalu_quarta?: 'S' | 'N'
  configtreinoalu_quinta?: 'S' | 'N'
  configtreinoalu_sexta?: 'S' | 'N'
  configtreinoalu_sabado?: 'S' | 'N'
  configtreinoalu_domingo?: 'S' | 'N'
}

export function* requestEditTrainingConfigWatcher() {
  yield takeLatest(
    STCT.REQUEST_EDIT_TRAINING_CONFIG,
    handleEditStudentTrainingConfig,
  )
}

export function* handleEditStudentTrainingConfig(
  action: RequestEditTrainingConfigAction,
) {
  try {
    yield put(addLoading(STCT.REQUEST_EDIT_TRAINING_CONFIG))
    const {
      studentGroupId,
      trainingConfigId,
      testType,
      cardiacProtocol,
      testDuration,
      trainingDaysStartingFromMonday,
    } = action.payload

    const weekDaysArray = [
      'configtreinoalu_segunda',
      'configtreinoalu_terca',
      'configtreinoalu_quarta',
      'configtreinoalu_quinta',
      'configtreinoalu_sexta',
      'configtreinoalu_sabado',
      'configtreinoalu_domingo',
    ]

    const objectWithWeekDaysAsAttributes: TrainingDaysObject = {}

    weekDaysArray.forEach((weekDay, index) => {
      const isDaySelected = trainingDaysStartingFromMonday[index]

      objectWithWeekDaysAsAttributes[
        weekDay as keyof TrainingDaysObject
      ] = isDaySelected ? 'S' : 'N'
    })

    const dataToEdit: DataToEdit = {
      ...objectWithWeekDaysAsAttributes,
      grupoaluno_id: studentGroupId,
      configtreinoaluno_id: trainingConfigId,
      configtreinoalu_protocolocardiaco: cardiacProtocol,
      configtreinoalu_tipoteste: testType,
      configtreinoalu_tempodeteste: testDuration,
      configtreinoalu_situacao: 'AT',
    }

    const {
      data,
    }: AxiosServerResponse<Models.StudentTrainingConfig> = yield call(
      // Usa PUT para salvar outra configuração e deixar a atual inativa
      // Como para o front-end é uma edição não mudei no nome da saga, action
      // e nem do type
      Server.put,
      STUDENT_TRAINING_CONFIG_ROUTES.CONFIG_TREINO_ALUNO,
      withServerStructure(dataToEdit),
    )

    if (data.isSuccessful) {
      yield put(showSuccessToast({ messageCode: 'editStudentTrainingConfig' }))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(STCT.REQUEST_EDIT_TRAINING_CONFIG))
  }
}
