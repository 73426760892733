import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FiFileText } from 'react-icons/fi'

import { DefaultError, DefaultLabel, SnowInput } from 'src/components'

import { Container, NameInput } from './styles'

export interface TrainingNameInputProps {
  isShowingError: boolean
  defaultTrainingName?: string
}

export const TrainingNameInput = forwardRef<
  HTMLInputElement,
  TrainingNameInputProps
>((props, ref) => {
  const { isShowingError, defaultTrainingName } = props

  const { t } = useTranslation(['TrainingManagement', 'Glossary', 'Error'])

  return (
    <Container>
      <NameInput
        iconComponent={<FiFileText size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="trainingLevel">
            <span className="required">{t('Glossary:required')}</span>
            <span>{t('trainingNameLabel')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowInput
            ref={ref}
            id="trainingName"
            name="trainingName"
            type="text"
            placeholder={t('trainingNamePh')}
            defaultValue={defaultTrainingName}
          />
        }
        errorComponent={
          <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
        }
        showError={isShowingError}
      />
    </Container>
  )
})

TrainingNameInput.displayName = 'TrainingNameInput'
