import { Reducer } from 'redux'

import { StudentState, STUDENT_TYPES } from './types'

export const initialState: StudentState = {
  students: [],
  studentsOutsideGroup: [],
}

const reducer: Reducer<StudentState> = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case STUDENT_TYPES.SET_STUDENT_LIST: {
      return {
        ...state,
        [payload.listName]: payload.students,
      }
    }

    default:
      return state
  }
}

export default reducer
