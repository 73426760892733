import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Info } from 'luxon'
import {
  FiCheck,
  FiCheckCircle,
  FiClock,
  FiFile,
  FiHeart,
  FiSettings,
} from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import { INPUT_MASKS, STUDENT_TEST_TYPES } from 'src/config'
import {
  DefaultError,
  DefaultLabel,
  FullHeightSpinnerContainer,
  FullScreenSpinner,
  ReactSelectOption,
  SnowInputMask,
  SnowSelect,
  Spinner,
  SpinnerWithText,
  WhiteSpinner,
} from 'src/components'
import {
  useCardiacProtocols,
  useIsLoading,
  useSetBrowserTabTitle,
  useStudentTestTypes,
  useTypedSelector,
} from 'src/hooks'
import {
  requestEditTrainingConfig,
  requestGetTrainingConfig,
  setTrainingConfigData,
  STUDENT_TRAINING_CONFIG_TYPES,
} from 'src/store/ducks/studentTrainingConfig'

import {
  Container,
  StyledPageTitle,
  Form,
  SaveButton,
  StudentInput,
  WeekDayContainer,
  WeekDaysLabel,
  WeekDaysError,
  WeekDays,
  WeekDayCheckbox,
} from './styles'

type StudentTrainingConfigParams = {
  id: string
}

type TestTypeSelectOption = ReactSelectOption<Models.Common.TestTypesUnion>
type CardiacProtocolSelectOption = ReactSelectOption<Models.Common.CardiacProtocolsUnion>

const StudentTrainingConfig: React.FC = () => {
  useSetBrowserTabTitle('studentTrainingConfig')

  const { id } = useParams<StudentTrainingConfigParams>()

  const { t } = useTranslation(['StudentTrainingConfig', 'Glossary', 'Error'])
  const { studentTestTypesArray } = useStudentTestTypes()
  const { cardiacProtocolsArray } = useCardiacProtocols()
  const dispatch = useDispatch()

  const testDurationRef = useRef<HTMLInputElement | null>(null)
  const [trainingDays, setTrainingDays] = useState<boolean[]>([])
  const [wasSaveButtonClicked, setWasSaveButtonClicked] = useState(false)

  const [testType, setTestType] = useState<TestTypeSelectOption | undefined>()

  const [cardiacProtocol, setCardiacProtocol] = useState<
    CardiacProtocolSelectOption | undefined
  >()

  const hasAtLeastOneDaySelected = useMemo(
    () => trainingDays.some((isSelected) => isSelected),
    [trainingDays],
  )

  const isLoading = useIsLoading(
    STUDENT_TRAINING_CONFIG_TYPES.REQUEST_GET_TRAINING_CONFIG,
  )

  const isSaving = useIsLoading(
    STUDENT_TRAINING_CONFIG_TYPES.REQUEST_EDIT_TRAINING_CONFIG,
  )

  const trainingConfig = useTypedSelector(
    ({ StudentTrainingConfig }) => StudentTrainingConfig.trainingConfig,
  )

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setWasSaveButtonClicked(true)
    if (testType && hasAtLeastOneDaySelected) {
      const testDuration = testDurationRef.current?.value.trim()

      dispatch(
        requestEditTrainingConfig({
          studentGroupId: Number(id),
          testType: testType.value,
          cardiacProtocol: cardiacProtocol?.value,
          trainingDaysStartingFromMonday: trainingDays,
          trainingConfigId: trainingConfig?.configtreinoaluno_id || 0,
          testDuration,
        }),
      )
    }
  }

  const handleCheckDay = (index: number, check: boolean) => {
    setTrainingDays((currentArray) => {
      const arrayClone = [...currentArray]
      arrayClone[index] = check
      return arrayClone
    })
  }

  const handleClearTestDuration = () => {
    if (testDurationRef.current) {
      testDurationRef.current.value = ''
      testDurationRef.current.defaultValue = ''
    }
  }

  const handleClearCardiacProtocol = () => {
    setCardiacProtocol(undefined)
  }

  const handleCheckTestTypeAndClearData = (
    testTypeToCheck: Models.Common.TestTypesUnion,
  ) => {
    if (testTypeToCheck === STUDENT_TEST_TYPES.CARDIAC_TEST) {
      handleClearCardiacProtocol()
    } else if (testTypeToCheck === STUDENT_TEST_TYPES.RUN_TEST) {
      handleClearTestDuration()
    }
  }

  const handleChangeTestType = (option: any) => {
    const selectedOption = option as TestTypeSelectOption
    handleCheckTestTypeAndClearData(selectedOption.value)
    setTestType(selectedOption)
  }

  const handleChangeCardiacProtocol = (option: any) => {
    const selectedOption = option as CardiacProtocolSelectOption
    setCardiacProtocol(selectedOption)
  }

  const getTestTypeOption = useCallback(
    (
      testTypeId?: Models.Common.TestTypesUnion,
    ): TestTypeSelectOption | undefined => {
      if (!testTypeId) return

      const testTypeFound = studentTestTypesArray.find(
        (testType) => testType.type === testTypeId,
      )

      if (!testTypeFound) return

      return {
        label: testTypeFound.text,
        value: testTypeFound.type,
      }
    },
    [studentTestTypesArray],
  )

  const getCardiacProtocolOption = useCallback(
    (
      cardiacProtocolId?: Models.Common.CardiacProtocolsUnion,
    ): CardiacProtocolSelectOption | undefined => {
      if (!cardiacProtocolId) return

      const cardiacProtocolFound = cardiacProtocolsArray.find(
        (protocol) => protocol.cardiacProtocol === cardiacProtocolId,
      )

      if (!cardiacProtocolFound) return

      return {
        label: cardiacProtocolFound.text,
        value: cardiacProtocolFound.cardiacProtocol,
      }
    },
    [cardiacProtocolsArray],
  )

  useEffect(() => {
    if (trainingConfig) {
      const {
        configtreinoalu_segunda,
        configtreinoalu_terca,
        configtreinoalu_quarta,
        configtreinoalu_quinta,
        configtreinoalu_sexta,
        configtreinoalu_sabado,
        configtreinoalu_domingo,
        configtreinoalu_tipoteste,
        configtreinoalu_protocolocardiaco,
      } = trainingConfig

      const currentTrainingDays = [
        configtreinoalu_segunda,
        configtreinoalu_terca,
        configtreinoalu_quarta,
        configtreinoalu_quinta,
        configtreinoalu_sexta,
        configtreinoalu_sabado,
        configtreinoalu_domingo,
      ]

      setTrainingDays(currentTrainingDays.map((value) => value === 'S'))
      setTestType(getTestTypeOption(configtreinoalu_tipoteste))

      setCardiacProtocol(
        getCardiacProtocolOption(configtreinoalu_protocolocardiaco),
      )
    }
  }, [getCardiacProtocolOption, getTestTypeOption, trainingConfig])

  useLayoutEffect(() => {
    dispatch(requestGetTrainingConfig(Number(id)))
    return () => {
      dispatch(setTrainingConfigData(undefined))
    }
  }, [dispatch, id])

  return isLoading ? (
    <FullHeightSpinnerContainer>
      <SpinnerWithText hasSpinnerOnTheLeft>
        <Spinner />
        <span>{t('loadingText')}</span>
      </SpinnerWithText>
    </FullHeightSpinnerContainer>
  ) : (
    <Container>
      {isSaving && (
        <FullScreenSpinner>
          <SpinnerWithText hasSpinnerOnTheLeft>
            <WhiteSpinner />
            <span>{t('savingConfigText')}</span>
          </SpinnerWithText>
        </FullScreenSpinner>
      )}

      <StyledPageTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<FiSettings />}
      />

      <Form onSubmit={handleSubmit} noValidate>
        <WeekDayContainer>
          <WeekDaysLabel>
            <span className="required">{t('Glossary:required')}</span>
            <span>{t('weekDaysLabel')}</span>
          </WeekDaysLabel>

          <WeekDays>
            {Info.weekdays('long').map((weekday, index) => {
              const isChecked = trainingDays[index]

              const handleCheckThisDay = () => {
                handleCheckDay(index, !isChecked)
              }

              return (
                <WeekDayCheckbox
                  key={weekday}
                  checked={isChecked}
                  checkMarkIcon={<FiCheck />}
                  onChange={handleCheckThisDay}
                >
                  <span className="label">{weekday}</span>
                </WeekDayCheckbox>
              )
            })}
          </WeekDays>

          {wasSaveButtonClicked && !hasAtLeastOneDaySelected && (
            <WeekDaysError>{t('noWeekDaySelected')}</WeekDaysError>
          )}
        </WeekDayContainer>

        <StudentInput
          iconComponent={<FiFile size="3.2rem" />}
          labelComponent={
            <DefaultLabel>
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('testTypeLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowSelect
              id="testType"
              name="testType"
              value={testType}
              placeholder={t('testTypePh')}
              onChange={handleChangeTestType}
              noOptionsMessage={() => t('noTestTypes')}
              loadingMessage={() => t('Glossary:loading')}
              options={studentTestTypesArray.map((testType) => ({
                label: testType.text,
                value: testType.type,
              }))}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:noOptionSelectedError')}</DefaultError>
          }
          showError={wasSaveButtonClicked && !testType}
        />

        {testType?.value === STUDENT_TEST_TYPES.RUN_TEST && (
          <StudentInput
            iconComponent={<FiClock size="3.2rem" />}
            labelComponent={
              <DefaultLabel htmlFor="testDuration">
                <span>{t('testDurationLabel')}</span>
                <span className="label-hint">{t('testDurationHint')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowInputMask
                inputRef={(ref) => (testDurationRef.current = ref)}
                maskChar={null}
                mask={INPUT_MASKS.HHMMSS_TIME}
                alwaysShowMask={false}
                type="text"
                id="testDuration"
                name="testDuration"
                placeholder={t('testDurationPh')}
                defaultValue={
                  testType?.value === STUDENT_TEST_TYPES.RUN_TEST
                    ? trainingConfig?.configtreinoalu_tempodeteste
                    : undefined
                }
              />
            }
          />
        )}

        {testType?.value === STUDENT_TEST_TYPES.CARDIAC_TEST && (
          <StudentInput
            iconComponent={<FiHeart size="3.2rem" />}
            labelComponent={
              <DefaultLabel>
                <span>{t('cardiacProtocolLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowSelect
                id="cardiacProtocol"
                name="cardiacProtocol"
                placeholder={t('cardiacProtocolPh')}
                onChange={handleChangeCardiacProtocol}
                noOptionsMessage={() => t('noCardiacProtocols')}
                loadingMessage={() => t('Glossary:loading')}
                value={cardiacProtocol}
                options={cardiacProtocolsArray.map((cardiacProtocol) => ({
                  label: cardiacProtocol.text,
                  value: cardiacProtocol.cardiacProtocol,
                }))}
              />
            }
          />
        )}

        <SaveButton type="submit">
          <span>{t('saveButton')}</span>
          <FiCheckCircle />
        </SaveButton>
      </Form>
    </Container>
  )
}

export default StudentTrainingConfig
