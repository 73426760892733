import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiEdit, FiTrash, FiZap } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import {
  ConfirmModal,
  DangerRoundIconButton,
  ListItemText,
  ListItemTitle,
  ListItemSubTitle,
  Spinner,
  SpinnerWithText,
} from 'src/components'
import { useIsLoading, useTypedSelector } from 'src/hooks'
import { ACTIVITY_TYPES, requestDeleteActivity } from 'src/store/ducks/activity'

import { List, ListEmpty, ActivityItem } from './styles'

interface ActivityListProps {
  nameRef: React.MutableRefObject<HTMLInputElement | null>
  editingId: number
  setEditingId: (editingId: number) => void
  setValues: (values: { name?: string; video: string }) => void
  handleFinishEditing: () => void
}

const ActivityList: React.FC<ActivityListProps> = (props) => {
  const {
    editingId,
    setEditingId,
    nameRef,
    setValues,
    handleFinishEditing,
  } = props

  const { t } = useTranslation(['ActivityConfig', 'Glossary'])
  const dispatch = useDispatch()

  const [
    activityToDelete,
    setActivityToDelete,
  ] = useState<Models.Activity | null>(null)

  const isLoading = useIsLoading(ACTIVITY_TYPES.REQUEST_FETCH)
  const activities = useTypedSelector(({ Activity }) => Activity.activities)

  const handleEdit = (activity: Models.Activity) => {
    const {
      atividade_id,
      atividade_descricao,
      atividade_linkvideopadrao,
    } = activity

    if (editingId === atividade_id) {
      handleFinishEditing()
    } else {
      setEditingId(atividade_id)
      setValues({ name: atividade_descricao, video: atividade_linkvideopadrao })
      if (nameRef.current) nameRef.current.focus()
    }
  }

  const handleDelete = (activity: Models.Activity) => {
    const { atividade_id } = activity
    if (editingId === atividade_id) handleFinishEditing()
    dispatch(requestDeleteActivity(atividade_id))
  }

  const handleShowDeleteConfirmationModal = (activity: Models.Activity) => {
    setActivityToDelete(activity)
  }

  const handleCloseDeleteConfirmationModal = () => {
    setActivityToDelete(null)
  }

  const handleConfirmDeletion = () => {
    if (activityToDelete) handleDelete(activityToDelete)
  }

  return (
    <>
      {isLoading ? (
        <SpinnerWithText hasSpinnerOnTheLeft>
          <Spinner />
          <span>{t('loadingText')}</span>
        </SpinnerWithText>
      ) : (
        <>
          <ConfirmModal
            confirmButtonType="danger"
            confirmButtonText={t('Glossary:delete')}
            isShowing={!!activityToDelete}
            onConfirm={handleConfirmDeletion}
            title={t('deleteConfirmationTitle')}
            iconComponent={<FiTrash size="3.2rem" />}
            handleCloseModal={handleCloseDeleteConfirmationModal}
            message={t('deleteConfirmationMessage', {
              name: activityToDelete?.atividade_descricao,
            })}
          />

          <List>
            {activities.map((activity) => {
              const {
                atividade_id,
                atividade_descricao,
                atividade_situacao,
                atividade_linkvideopadrao,
              } = activity

              const isEditingThisItem = editingId === atividade_id
              const isActive = atividade_situacao === 'S'

              const handleEditItem = () => {
                handleEdit(activity)
              }

              const handleDeleteItem = (
                e: React.MouseEvent<HTMLDivElement, MouseEvent>,
              ) => {
                e.stopPropagation()
                handleShowDeleteConfirmationModal(activity)
              }

              return (
                <ActivityItem
                  key={atividade_id}
                  onClick={handleEditItem}
                  isEditing={isEditingThisItem}
                  leftComponent={
                    isEditingThisItem ? (
                      <FiEdit size="2.4rem" />
                    ) : (
                      <FiZap size="2.4rem" />
                    )
                  }
                  rightComponent={
                    <DangerRoundIconButton
                      className="deleteActivity"
                      onClick={handleDeleteItem}
                    >
                      <FiTrash />
                    </DangerRoundIconButton>
                  }
                >
                  <ListItemTitle>{atividade_descricao}</ListItemTitle>
                  <ListItemSubTitle>
                    {atividade_linkvideopadrao}
                  </ListItemSubTitle>
                  <ListItemText>
                    {t(isActive ? 'Glossary:active' : 'Glossary:inactive', {
                      context: 'female',
                    })}
                  </ListItemText>
                </ActivityItem>
              )
            })}
          </List>
        </>
      )}

      {!isLoading && !activities.length && (
        <ListEmpty iconComponent={<FiZap />} message={t('emptyListMessage')} />
      )}
    </>
  )
}

export default ActivityList
