import { call, put, takeLatest } from 'redux-saga/effects'

import { AxiosServerResponse, BLOCK_ROUTES, Server } from 'src/services/Server'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import { withServerStructure } from 'src/utils'

import { addInBlockList } from './actions'
import { RequestAddBlockAction, BLOCK_TYPES } from './types'

export function* requestAddBlockWatcher() {
  yield takeLatest(BLOCK_TYPES.REQUEST_ADD, handleAddBlock)
}

export function* handleAddBlock(action: RequestAddBlockAction) {
  try {
    yield put(addLoading(BLOCK_TYPES.REQUEST_ADD))
    const { block, successCallback } = action.payload

    const { data }: AxiosServerResponse<Models.Block> = yield call(
      Server.put,
      BLOCK_ROUTES.BLOCO,
      withServerStructure(block),
    )

    if (data.isSuccessful) {
      const block = data.getFirstData()
      if (successCallback && block) successCallback(block)
      if (block) yield put(addInBlockList(block))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(BLOCK_TYPES.REQUEST_ADD))
  }
}
