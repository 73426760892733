import { call, put, takeLatest } from 'redux-saga/effects'

import { withServerStructure } from 'src/utils'
import { LOCAL_STORAGE_KEYS } from 'src/config'
import { showSuccessToast } from 'src/store/ducks/success'
import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, Server, USER_ROUTES } from 'src/services/Server'

import { setAuthenticated } from './actions'
import { AUTH_TYPES, RequestCreateAccountAction } from './types'

type DataToSave = Pick<
  Models.User,
  | 'usuario_nome'
  | 'usuario_email'
  | 'usuario_senha'
  | 'usuario_aluno'
  | 'usuario_professor'
>

export function* requestCreateAccountWatcher() {
  yield takeLatest(AUTH_TYPES.REQUEST_CREATE_ACCOUNT, handleCreateAccount)
}

export function* handleCreateAccount(action: RequestCreateAccountAction) {
  try {
    yield put(addLoading(AUTH_TYPES.REQUEST_CREATE_ACCOUNT))
    const { name, email, password, isTeacher, successCallback } = action.payload

    const dataToSave: DataToSave = {
      usuario_nome: name,
      usuario_email: email,
      usuario_senha: password,
      usuario_aluno: isTeacher ? 'N' : 'S',
      usuario_professor: isTeacher ? 'S' : 'N',
    }

    const { data }: AxiosServerResponse<Models.User> = yield call(
      Server.put,
      USER_ROUTES.USUARIO,
      withServerStructure(dataToSave),
    )

    if (data.isSuccessful) {
      const userData = data.getFirstData()

      if (userData) {
        yield call(
          [localStorage, localStorage.setItem],
          LOCAL_STORAGE_KEYS.USER_DATA,
          JSON.stringify(userData),
        )

        yield put(setAuthenticated(userData))
        if (successCallback) successCallback()
        yield put(showSuccessToast({ messageCode: 'createAccount' }))
      } else {
        throw new Error()
      }
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(AUTH_TYPES.REQUEST_CREATE_ACCOUNT))
  }
}
