import { Reducer } from 'redux'

import { UserState, USER_TYPES } from './types'

export const initialState: UserState = {
  userList: [],
}

const reducer: Reducer<UserState> = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_TYPES.SET_USER_DATA: {
      return {
        ...state,
        userData: payload.userData,
      }
    }

    case USER_TYPES.SET_USER_LIST: {
      return {
        ...state,
        userList: payload.userList,
      }
    }

    default:
      return state
  }
}

export default reducer
