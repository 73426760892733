import React from 'react'

import {
  Container,
  Header,
  HeaderTexts,
  Title,
  Subtitle,
  Status,
  Body,
} from './styles'

export interface AnamneseItemProps {
  style?: React.CSSProperties
  className?: string
  onClick?: () => void
  headerRightComponent?: React.ReactNode
  headerLeftComponent?: React.ReactNode
  subtitle?: string
  title: string
  isActive?: boolean
  statusText?: string
  children?: any
}

export const AnamneseItem: React.FC<AnamneseItemProps> = (props) => {
  const {
    title,
    children,
    className,
    subtitle,
    style,
    onClick,
    headerLeftComponent,
    headerRightComponent,
    isActive = false,
    statusText,
  } = props

  return (
    <Container style={style} className={className} onClick={onClick}>
      <Header>
        {headerLeftComponent}

        <HeaderTexts
          hasLeftComponent={!!headerLeftComponent}
          hasRightComponent={!!headerRightComponent}
        >
          <Title>{title}</Title>
          {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
          {!!statusText && <Status isActive={isActive}>{statusText}</Status>}
        </HeaderTexts>

        {headerRightComponent}
      </Header>

      <Body>{children}</Body>
    </Container>
  )
}

