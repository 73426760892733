import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { StudentTrainingDetailsParams } from '../types'

import { Container, EditButton } from './styles'

const Actions: React.FC = () => {
  const { id } = useParams<StudentTrainingDetailsParams>()
  const { t } = useTranslation('StudentTrainingDetails')
  const history = useHistory()

  const handleGoToEditPage = () => {
    history.push(`/edit-student-training/${id || 0}`)
  }

  return (
    <Container>
      <EditButton onClick={handleGoToEditPage}>
        <span>{t('actions.edit')}</span>
        <FiEdit />
      </EditButton>
    </Container>
  )
}

export default Actions
