import React from 'react'
import { FiMail, FiSmartphone, FiUser } from 'react-icons/fi'

import { Container, Content, Title, Text } from './styles'

export interface UserItemProps {
  className?: string
  style?: React.CSSProperties
  name?: string
  email?: string
  phone?: string
  isTeacher?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  leftSideComponent?: React.ReactNode
  rightSideComponent?: React.ReactNode
  contentHeaderComponent?: React.ReactNode
  contentFooterComponent?: React.ReactNode
}

export const UserItem: React.FC<UserItemProps> = (props) => {
  const {
    style,
    className,
    name,
    email,
    phone,
    onClick,
    isTeacher,
    leftSideComponent,
    rightSideComponent,
    contentHeaderComponent,
    contentFooterComponent,
  } = props

  return (
    <Container
      style={style}
      className={className}
      onClick={onClick}
      isTeacher={isTeacher}
    >
      {leftSideComponent}

      <Content
        hasLeftSideComponent={!!leftSideComponent}
        hasRightSideComponent={!!rightSideComponent}
      >
        {contentHeaderComponent}

        {!!name && (
          <Title>
            <FiUser />
            <span>{name}</span>
          </Title>
        )}

        {!!email && (
          <Text>
            <FiMail />
            <span>{email}</span>
          </Text>
        )}

        {!!phone && (
          <Text>
            <FiSmartphone />
            <span>{phone}</span>
          </Text>
        )}

        {contentFooterComponent}
      </Content>

      {rightSideComponent}
    </Container>
  )
}
