import React from 'react'
import { DateTime } from 'luxon'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useTypedSelector } from 'src/hooks'

import { Container, Training } from './styles'

const CondensedList: React.FC = () => {
  const { t } = useTranslation(['StudentTrainings', 'Training'])
  const history = useHistory()

  const trainings = useTypedSelector(
    ({ StudentTraining }) => StudentTraining.trainings,
  )

  const getFormattedDate = (date: string): string => {
    if (!date) return ''
    return DateTime.fromSQL(date).toFormat('DDD')
  }

  return (
    <Container>
      {trainings.map((item) => {
        const {
          treinoaluno_id,
          treino_descricao,
          treinoaluno_data,
          treinonivel_descricao,
        } = item

        const handleGoToStudentTrainingDetails = () => {
          history.push(`/student-training/${treinoaluno_id}`)
        }

        return (
          <Training
            key={treinoaluno_id}
            trainingName={treino_descricao}
            trainingLevelName={treinonivel_descricao}
            onClick={handleGoToStudentTrainingDetails}
            trainingNumber={t('Training:trainingNumber', {
              number: treinoaluno_id,
            })}
            trainingDate={t('Training:trainingDate', {
              date: getFormattedDate(treinoaluno_data),
            })}
          />
        )
      })}
    </Container>
  )
}

export default CondensedList
