import React, { useLayoutEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FiCheckCircle, FiEdit, FiFileText } from 'react-icons/fi'

import {
  DefaultError,
  DefaultLabel,
  FullHeightSpinnerContainer,
  SnowInput,
  Spinner,
  SpinnerWithText,
} from 'src/components'
import {
  useIsLoading,
  useSetBrowserTabTitle,
  useTypedSelector,
} from 'src/hooks'
import {
  requestEditRunningGroup,
  requestGetRunningGroup,
  RUNNING_GROUP_TYPES,
  setRunningGroupData,
} from 'src/store/ducks/runningGroup'

import RunningGroupImage from './RunningGroupImage'
import {
  Container,
  EditRunningGroupTitle,
  Form,
  FormInput,
  SaveButton,
} from './styles'

interface EditRunningGroupParams {
  id?: string
}

const EditRunningGroup: React.FC<EditRunningGroupParams> = () => {
  useSetBrowserTabTitle('editRunningGroup')

  const { t } = useTranslation(['EditRunningGroup', 'Glossary', 'Error'])
  const { id } = useParams<EditRunningGroupParams>()
  const dispatch = useDispatch()
  const history = useHistory()

  const imageRef = useRef<HTMLInputElement | null>(null)
  const nameRef = useRef<HTMLInputElement | null>(null)

  const [imageFile, setImageFile] = useState<File | null>(null)

  const [errors, setErrors] = useState({
    nameEmpty: false,
  })

  const groupData = useTypedSelector(
    ({ RunningGroup }) => RunningGroup.runningGroupData,
  )

  const isLoadingGroupData = useIsLoading(
    RUNNING_GROUP_TYPES.REQUEST_GET_RUNNING_GROUP,
  )

  const getValues = () => {
    const name = nameRef.current?.value || ''
    return { name }
  }

  const handleValidate = (): boolean => {
    const { name } = getValues()

    const errorState = {
      nameEmpty: !name.trim(),
    }

    setErrors(errorState)
    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleEditGroup = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleValidate()) {
      const { name } = getValues()
      dispatch(
        requestEditRunningGroup({
          name,
          id: Number(id),
          image: imageFile,
          successCallback() {
            history.push(`/running-group/${id}`)
          },
        }),
      )
    }
  }

  useLayoutEffect(() => {
    dispatch(requestGetRunningGroup(Number(id || 0)))

    return () => {
      dispatch(setRunningGroupData(undefined))
    }
  }, [dispatch, id])

  return isLoadingGroupData ? (
    <FullHeightSpinnerContainer>
      <SpinnerWithText hasSpinnerOnTheLeft>
        <Spinner />
        <span>{t('loadingText')}</span>
      </SpinnerWithText>
    </FullHeightSpinnerContainer>
  ) : (
    <Container>
      <EditRunningGroupTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<FiEdit />}
      />

      <Form onSubmit={handleEditGroup} noValidate>
        <RunningGroupImage
          imageRef={imageRef}
          imageFile={imageFile}
          setImageFile={setImageFile}
          groupData={groupData}
        />

        <FormInput
          iconComponent={<FiFileText size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="name">
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('RunningGroup:name')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="name"
              name="name"
              ref={nameRef}
              type="text"
              autoCapitalize="words"
              placeholder={t('RunningGroup:namePh')}
              defaultValue={groupData?.grupo_nome}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.nameEmpty}
        />

        <SaveButton type="submit">
          <span>{t('saveButton')}</span>
          <FiCheckCircle />
        </SaveButton>
      </Form>
    </Container>
  )
}

export default EditRunningGroup
