import styled from 'styled-components'

export const TrainingSheetContainer = styled.div`
  overflow: hidden;

  border-radius: 1rem;
  border: 1px solid ${(props) => props.theme.border};

  font-size: 1rem;

  & > * {
    border-bottom: 1px solid ${(props) => props.theme.border};
  }

  & > :last-child {
    border: none;
  }
`
