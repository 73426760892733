export enum OBSERVABLE_TYPES {
  ADD_OBSERVER = '@OBSERVABLE/ADD_OBSERVER',
  REMOVE_OBSERVER = '@OBSERVABLE/REMOVE_OBSERVER',
}

export interface ObservableState {
  MainPageContentScrollEndReached: { [key: string]: () => void }
}

export type ObservableTopics = keyof ObservableState

export interface AddObserverAction {
  type: OBSERVABLE_TYPES.ADD_OBSERVER
  payload: {
    topic: ObservableTopics
    observerId: string
    callback: () => void
  }
}

export interface RemoveObserverAction {
  type: OBSERVABLE_TYPES.REMOVE_OBSERVER
  payload: {
    topic: ObservableTopics
    observerId: string
  }
}
