import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import StateManager from 'react-select'

import { ReactSelectOption } from 'src/components'
import { useTypedSelector } from 'src/hooks'
import { showError } from 'src/store/ducks/error'
import { requestEditStudentTraining } from 'src/store/ducks/studentTraining'

import {
  EditingActivityIndexes,
  EditStudentTrainingBlock,
  EditStudentTrainingActivity,
} from '../types'

export default () => {
  const { t } = useTranslation('TrainingManagement')
  const dispatch = useDispatch()

  const trainingLevelRef = useRef<StateManager<ReactSelectOption> | null>(null)
  const trainingNameRef = useRef<HTMLInputElement | null>(null)

  const [blocks, setBlocks] = useState<EditStudentTrainingBlock[]>([])
  const [editingBlockIndex, setEditingBlockIndex] = useState(-1)

  const [errors, setErrors] = useState({
    isTrainingLevelNotSelected: false,
    isTrainingNameEmpty: false,
  })

  const [
    editingActivityIndexes,
    setEditingActivityIndexes,
  ] = useState<EditingActivityIndexes>({
    activityIndex: -1,
    blockIndex: -1,
  })

  const StudentTrainingData = useTypedSelector(
    ({ StudentTraining }) => StudentTraining.trainingData,
  )

  const handleCancelEditingBlock = () => {
    setEditingBlockIndex(-1)
  }

  const handleCancelEditingActivity = () => {
    setEditingActivityIndexes({
      activityIndex: -1,
      blockIndex: -1,
    })
  }

  const handleAddTrainingBlock = (block: EditStudentTrainingBlock) => {
    setBlocks((currentBlocksArray) => [...currentBlocksArray, block])
  }

  const handleRemoveTrainingBlock = (index: number) => {
    const { activityIndex, blockIndex } = editingActivityIndexes

    if (editingBlockIndex === index) {
      handleCancelEditingBlock()
    } else if (index < editingBlockIndex) {
      setEditingBlockIndex(editingBlockIndex - 1)
    }

    if (editingBlockIndex === blockIndex) {
      handleCancelEditingActivity()
    } else if (index < blockIndex) {
      setEditingActivityIndexes({
        blockIndex: blockIndex - 1,
        activityIndex,
      })
    }

    const blocksClone = [...blocks]
    blocksClone.splice(index, 1)
    setBlocks(blocksClone)
  }

  const handleAddActivityInBlock = (
    activity: EditStudentTrainingActivity,
    targetBlockIndex: number,
  ) => {
    const blocksClone = [...blocks]
    blocksClone[targetBlockIndex].atividades.push(activity)
    setBlocks(blocksClone)
  }

  const handleRemoveActivityFromBlock = (
    blockIndex: number,
    activityIndex: number,
  ) => {
    if (
      editingActivityIndexes.blockIndex === blockIndex &&
      editingActivityIndexes.activityIndex === activityIndex
    ) {
      handleCancelEditingActivity()
    }

    const blocksClone = [...blocks]
    const block = blocksClone[blockIndex]

    const activitiesClone = [...block.atividades]
    activitiesClone.splice(activityIndex, 1)
    blocksClone[blockIndex].atividades = activitiesClone

    setBlocks(blocksClone)
  }

  const handleMoveActivityInBlock = (
    blockIndex: number,
    activityIndex: number,
    direction: 'down' | 'up',
  ) => {
    const isUp = direction === 'up'
    const destinationIndex = isUp ? activityIndex - 1 : activityIndex + 1

    const blocksClone = [...blocks]
    const block = blocksClone[blockIndex]

    const activitiesClone = [...block.atividades]
    const [removed] = activitiesClone.splice(activityIndex, 1)
    activitiesClone.splice(destinationIndex, 0, removed)

    blocksClone[blockIndex].atividades = activitiesClone

    if (
      editingActivityIndexes.blockIndex !== -1 &&
      editingActivityIndexes.activityIndex !== -1
    ) {
      setEditingActivityIndexes({
        activityIndex: destinationIndex,
        blockIndex: editingActivityIndexes.blockIndex,
      })
    }

    setBlocks(blocksClone)
  }

  const handleEditBlock = (block: EditStudentTrainingBlock) => {
    handleCancelEditingBlock()

    const blocksClone = [...blocks]

    const blockToEdit = { ...block }
    blockToEdit.atividades = blocksClone[editingBlockIndex].atividades
    blocksClone[editingBlockIndex] = blockToEdit

    setBlocks(blocksClone)
  }

  const handleEditActivity = (
    activity: EditStudentTrainingActivity,
    targetBlockIndex: number,
  ) => {
    const { blockIndex, activityIndex } = editingActivityIndexes

    if (targetBlockIndex === blockIndex) {
      const blocksClone = [...blocks]
      const block = blocksClone[blockIndex]

      const activitiesClone = [...block.atividades]
      activitiesClone[activityIndex] = activity
      blocksClone[blockIndex].atividades = activitiesClone

      setBlocks(blocksClone)
    } else {
      handleRemoveActivityFromBlock(blockIndex, activityIndex)
      handleAddActivityInBlock(activity, targetBlockIndex)
    }

    handleCancelEditingActivity()
  }

  const getValues = () => {
    const selectedTrainingLevel = trainingLevelRef.current?.state.value as
      | ReactSelectOption
      | undefined

    const trainingLevelId = selectedTrainingLevel?.value || ''
    const trainingName = trainingNameRef.current?.value.trim() || ''

    return {
      trainingLevelId,
      trainingName,
    }
  }

  const handleValidation = (): boolean => {
    const { trainingLevelId, trainingName } = getValues()

    const errorState: typeof errors = {
      isTrainingLevelNotSelected: !trainingLevelId,
      isTrainingNameEmpty: !trainingName,
    }

    const haveNoErrors = Object.values(errorState).every(
      (hasError) => !hasError,
    )

    if (haveNoErrors && !blocks.length) {
      dispatch(showError([t('noBlocksErrorMessage')]))
    }

    setErrors(errorState)
    return !!blocks.length && haveNoErrors
  }

  const handleSaveTrainingSheet = () => {
    if (handleValidation() && StudentTrainingData) {
      const { trainingLevelId, trainingName } = getValues()

      const {
        treino_id,
        treino_situacao,
        configtreinoaluno_id,
        treinoaluno_data,
        treinoaluno_id,
      } = StudentTrainingData

      dispatch(
        requestEditStudentTraining({
          blocos: blocks,
          treino_descricao: trainingName,
          treinonivel_id: Number(trainingLevelId || 0),
          treino_situacao: treino_situacao || 'AT',
          treino_id: Number(treino_id || 0),

          configtreinoaluno_id: Number(configtreinoaluno_id || 0),
          treinoaluno_data,
          treinoaluno_id,
        }),
      )
    }
  }

  return {
    trainingLevelRef,
    trainingNameRef,

    blocks,
    setBlocks,
    editingBlockIndex,
    setEditingBlockIndex,
    editingActivityIndexes,
    setEditingActivityIndexes,
    errors,
    setErrors,

    handleCancelEditingBlock,
    handleCancelEditingActivity,
    handleAddTrainingBlock,
    handleRemoveTrainingBlock,
    handleAddActivityInBlock,
    handleRemoveActivityFromBlock,
    handleMoveActivityInBlock,
    handleSaveTrainingSheet,
    handleEditBlock,
    handleEditActivity,
  }
}
