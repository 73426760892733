export enum ACTIVITY_TYPES {
  REQUEST_FETCH = '@ACTIVITY/REQUEST_FETCH',
  REQUEST_EDIT = '@ACTIVITY/REQUEST_EDIT',
  REQUEST_ADD = '@ACTIVITY/REQUEST_ADD',
  REQUEST_DELETE = '@ACTIVITY/REQUEST_DELETE',

  SET_LIST = '@ACTIVITY/SET_LIST',
  EDIT_IN_LIST = '@ACTIVITY/EDIT_IN_LIST',
  ADD_IN_LIST = '@ACTIVITY/ADD_IN_LIST',
  REMOVE_FROM_LIST = '@ACTIVITY/REMOVE_FROM_LIST',
}

export interface ActivityState {
  activities: Models.Activity[]
}

export interface RequestFetchActivitiesAction {
  type: ACTIVITY_TYPES.REQUEST_FETCH
}

export interface RequestEditActivityAction {
  type: ACTIVITY_TYPES.REQUEST_EDIT
  payload: {
    id: number
    name: string
    video: string
    situation: 'S' | 'N'
  }
}

export interface RequestAddActivityAction {
  type: ACTIVITY_TYPES.REQUEST_ADD
  payload: {
    name: string
    video: string
    successCallback?: (activity: Models.Activity) => void
  }
}

export interface RequestDeleteActivityAction {
  type: ACTIVITY_TYPES.REQUEST_DELETE
  payload: {
    id: number
  }
}

export interface SetActivityListAction {
  type: ACTIVITY_TYPES.SET_LIST
  payload: {
    activities: Models.Activity[]
  }
}

export interface EditActivityInListAction {
  type: ACTIVITY_TYPES.EDIT_IN_LIST
  payload: Models.Activity
}

export interface AddInActivityListAction {
  type: ACTIVITY_TYPES.ADD_IN_LIST
  payload: Models.Activity
}

export interface RemoveFromActivityListAction {
  type: ACTIVITY_TYPES.REMOVE_FROM_LIST
  payload: {
    id: number
  }
}
