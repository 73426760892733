import React from 'react'
import { FiCalendar, FiFileText } from 'react-icons/fi'
import { BsClipboardData, BsFileEarmarkRuled } from 'react-icons/bs'

import { Container, DataContainer, ActionsContainer, Data } from './styles'

export interface TrainingHeaderProps {
  className?: string
  style?: React.CSSProperties
  onClick?: (e: React.MouseEvent) => void
  actionsComponent?: React.ReactChild
  trainingName?: string
  trainingLevelName?: string
  trainingNumber?: string
  trainingDate?: string
}

export const TrainingHeader: React.FC<TrainingHeaderProps> = (props) => {
  const {
    className,
    style,
    actionsComponent,
    trainingName,
    trainingLevelName,
    trainingNumber,
    trainingDate,
  } = props

  return (
    <Container className={className} style={style}>
      <DataContainer>
        {!!trainingName && (
          <Data isTitle>
            <FiFileText />
            <span>{trainingName}</span>
          </Data>
        )}

        {!!trainingLevelName && (
          <Data>
            <BsClipboardData />
            <span>{trainingLevelName}</span>
          </Data>
        )}

        {!!trainingNumber && (
          <Data>
            <BsFileEarmarkRuled />
            <span>{trainingNumber}</span>
          </Data>
        )}

        {!!trainingDate && (
          <Data>
            <FiCalendar />
            <span>{trainingDate}</span>
          </Data>
        )}
      </DataContainer>

      {!!actionsComponent && (
        <ActionsContainer>{actionsComponent}</ActionsContainer>
      )}
    </Container>
  )
}
