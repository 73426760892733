import { call, put, takeLatest } from 'redux-saga/effects'

import { showSuccessToast } from 'src/store/ducks/success'
import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  AxiosServerResponse,
  GROUP_STUDENT_ROUTES,
  GROUP_TEACHER_ROUTES,
  Server,
} from 'src/services/Server'
import { withServerStructure } from 'src/utils'

import { GROUP_MEMBER_TYPES, RequestAddGroupMemberAction } from './types'

type DataToSave = {
  usuario_id: number
  grupo_id: number
}

export function* requestAddGroupMemberWatcher() {
  yield takeLatest(GROUP_MEMBER_TYPES.REQUEST_ADD_MEMBER, handleAddGroupMember)
}

export function* handleAddGroupMember(action: RequestAddGroupMemberAction) {
  try {
    yield put(addLoading(GROUP_MEMBER_TYPES.REQUEST_ADD_MEMBER))
    const { groupId, userId, isStudent, successCallback } = action.payload

    const dataToSave: DataToSave = {
      grupo_id: groupId,
      usuario_id: userId,
    }

    const { data }: AxiosServerResponse = yield call(
      Server.put,
      isStudent
        ? GROUP_STUDENT_ROUTES.GRUPO_ALUNO
        : GROUP_TEACHER_ROUTES.GRUPO_PROFESSOR,
      withServerStructure(dataToSave),
    )

    if (data.isSuccessful) {
      if (successCallback) successCallback()
      yield put(showSuccessToast({ messageCode: 'addGroupMember' }))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(GROUP_MEMBER_TYPES.REQUEST_ADD_MEMBER))
  }
}
