import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Spinner } from 'src/components'
import { TRAINING_SHEET_TYPES } from 'src/store/ducks/trainingSheet'
import { InfinitePagination, useIsLoading, useTypedSelector } from 'src/hooks'

import {
  List,
  Training,
  BottomSpinnerContainer,
  TopSpinnerContainer,
} from './styles'

interface TrainingListProps {
  pagination: InfinitePagination
}

const TrainingList: React.FC<TrainingListProps> = ({ pagination }) => {
  const { t } = useTranslation(['TrainingConfig', 'Training'])
  const history = useHistory()

  const isLoading = useIsLoading(TRAINING_SHEET_TYPES.REQUEST_FETCH)

  const trainingSheets = useTypedSelector(
    ({ TrainingSheet }) => TrainingSheet.trainingSheets,
  )

  return (
    <>
      {isLoading && pagination.pageNumber === 0 && (
        <TopSpinnerContainer hasSpinnerOnTheLeft>
          <Spinner />
          <span>{t('loadingText')}</span>
        </TopSpinnerContainer>
      )}

      <List>
        {trainingSheets.map((trainingSheet) => {
          const {
            treino_id,
            treinonivel_descricao,
            treino_sequencia,
            treino_situacao,
            treino_descricao,
          } = trainingSheet

          const isActive = treino_situacao === 'AT'

          const handleGoToTrainingSheetDetails = () => {
            history.push(`/training-sheet/${treino_id}`)
          }

          return (
            <Training
              key={treino_id}
              isActive={isActive}
              onClick={handleGoToTrainingSheetDetails}
              trainingName={treino_descricao}
              trainingLevelName={treinonivel_descricao}
              statusText={t(
                isActive ? 'Training:statusActive' : 'Training:statusInactive',
              )}
              trainingSequence={t('Training:trainingSequence', {
                number: treino_sequencia,
              })}
            />
          )
        })}
      </List>

      {isLoading && pagination.pageNumber > 0 && (
        <BottomSpinnerContainer hasSpinnerOnTheLeft>
          <Spinner />
          <span>{t('loadingMoreText')}</span>
        </BottomSpinnerContainer>
      )}
    </>
  )
}

export default TrainingList
