import React from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedSelector } from 'src/hooks'

import {
  Container,
  UserAvatar,
  UserName,
  UserTypes,
  TeacherTypeBadge,
  StudentTypeBadge,
  AdminTypeBadge,
} from './styles'

const MainDataSection: React.FC = () => {
  const { t } = useTranslation(['UserPublicProfile', 'Glossary'])
  const userData = useTypedSelector(({ User }) => User.userData)

  return (
    <Container>
      <UserAvatar src={undefined} alt={userData?.usuario_nome} />
      <UserName>{userData?.usuario_nome}</UserName>

      <UserTypes>
        {userData?.usuario_professor === 'S' && (
          <TeacherTypeBadge>{t('Glossary:teacher')}</TeacherTypeBadge>
        )}

        {userData?.usuario_aluno === 'S' && (
          <StudentTypeBadge>{t('Glossary:student')}</StudentTypeBadge>
        )}

        {userData?.usuario_administrador === 'S' && (
          <AdminTypeBadge>{t('Glossary:admin')}</AdminTypeBadge>
        )}
      </UserTypes>
    </Container>
  )
}

export default MainDataSection
