import React from 'react'
import { Settings } from 'luxon'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { ErrorBoundary } from 'react-error-boundary'

import { LIGHT_THEME, GlobalStyles } from 'src/styles'
import { AppRoutes } from 'src/routes'
import { Store } from 'src/store'

import GlobalSuccessToast from './GlobalSuccessToast'
import GlobalErrorModal from './GlobalErrorModal'
import ErrorFallback from './ErrorFallback'

import 'src/locales'
Settings.defaultLocale = 'pt-BR'

const App: React.FC = () => {
  return (
    <ThemeProvider theme={LIGHT_THEME}>
      <GlobalStyles />

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Provider store={Store}>
          <AppRoutes />
          <GlobalSuccessToast />
          <GlobalErrorModal />
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
