import styled from 'styled-components'

import { LabeledEdgeContainer } from 'src/components'
import { NoWrapEllipsis } from 'src/styles'

type ContainerProps = {
  isAdmin: boolean
  isTeacher: boolean
  isStudent: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  // Cor do label da Section
  // Ela herda esta cor
  color: ${(props) => {
    if (props.isAdmin) return props.theme.admin
    else if (props.isTeacher) return props.theme.teacher
    else return props.theme.student
  }};
`

export const Section = styled(LabeledEdgeContainer)`
  flex: 1 32%;
  min-width: 30rem;

  margin: 0 0.8rem 2.4rem 0.8rem;
`

export const UserData = styled.div`
  margin-bottom: 1.6rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  div:nth-child(1) {
    font-weight: 900;
  }

  div:nth-child(2) {
    ${NoWrapEllipsis};
  }
`
