import styled, { css } from 'styled-components'

type ContainerProps = {
  isEditing: boolean
}

type HeaderTitleProps = {
  hasRightComponent: boolean
  hasLeftComponent: boolean
}

export const Container = styled.div<ContainerProps>`
  ${(props) => {
    if (props.isEditing) {
      return css`
        border: 3px solid ${props.theme.danger} !important;
        border-radius: 1rem;
        overflow: hidden;

        transition: margin 0.1s ease-in;

        margin: 0.8em;
      `
    }
  }}
`

export const Header = styled.div`
  background: ${(props) => props.theme.snowDark};

  border-bottom: 1px solid ${(props) => props.theme.border};

  display: flex;
  align-items: center;

  padding: 0.8em 2.4em;
`

export const HeaderTitle = styled.div<HeaderTitleProps>`
  color: ${(props) => props.theme.primaryText};
  font-size: 2em;
  font-weight: 900;
  text-align: center;

  flex: 1;

  margin-left: ${(props) => (props.hasLeftComponent ? 2.4 : 0)}rem;
  margin-right: ${(props) => (props.hasRightComponent ? 2.4 : 0)}rem;
`

export const Body = styled.div`
  display: flex;
  align-items: stretch;
`

export const Repeat = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 3em;
  min-width: 4em;

  background: ${(props) => props.theme.snow};

  color: ${(props) => props.theme.danger};
  font-weight: 900;
  font-size: 1.6em;

  border-right: 1px solid ${(props) => props.theme.border};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;

  & > * {
    border-bottom: 1px solid ${(props) => props.theme.border};
  }

  & > :last-child {
    border: none;
  }
`

export const EditingText = styled.div`
  background: ${(props) => props.theme.danger};

  color: white;
  font-size: 1.2rem;
  font-weight: bold;

  padding: 0.3em 1em;
`
