import styled from 'styled-components'

import { Input } from 'src/components'

export const Container = styled.div`
  max-width: 60rem;

  margin: 0 auto 1.6rem auto;
`

export const TrainingLevelInput = styled(Input)``
