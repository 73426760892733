import React from 'react'

import { Portal } from '../Portal'
import { WhiteSpinner } from '../Spinners'

import { Container } from './styles'

export interface FullScreenSpinnerProps {
  className?: string
  style?: React.CSSProperties
  children?: any
}

export const FullScreenSpinner: React.FC<FullScreenSpinnerProps> = (props) => {
  const { children, className, style } = props

  return (
    <Portal id="full-screen-spinner">
      <Container className={className} style={style}>
        {children || <WhiteSpinner />}
      </Container>
    </Portal>
  )
}

