import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiActivity, FiEdit, FiTrash } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import {
  ConfirmModal,
  DangerRoundIconButton,
  ListItemText,
  ListItemTitle,
  Spinner,
  SpinnerWithText,
} from 'src/components'
import { useIsLoading, useTypedSelector } from 'src/hooks'
import {
  requestDeleteTrainingZone,
  TRAINING_ZONE_TYPES,
} from 'src/store/ducks/trainingZone'

import { List, ListEmpty, TrainingZoneItem } from './styles'

interface TrainingZoneListProps {
  nameRef: React.MutableRefObject<HTMLInputElement | null>
  editingId: number
  setEditingId: (editingId: number) => void
  setValues: (values: {
    name?: string
    minBpm?: string
    maxBpm?: string
  }) => void
  handleFinishEditing: () => void
}

const TrainingZoneList: React.FC<TrainingZoneListProps> = (props) => {
  const {
    nameRef,
    editingId,
    setEditingId,
    setValues,
    handleFinishEditing,
  } = props

  const dispatch = useDispatch()
  const { t } = useTranslation([
    'TrainingZoneConfig',
    'TrainingZone',
    'Glossary',
  ])

  const isLoading = useIsLoading(TRAINING_ZONE_TYPES.REQUEST_FETCH)

  const trainingZones = useTypedSelector(
    ({ TrainingZone }) => TrainingZone.trainingZones,
  )

  const [
    trainingZoneToDelete,
    setTrainingZoneToDelete,
  ] = useState<Models.TrainingZone | null>(null)

  const handleEdit = (trainingZone: Models.TrainingZone) => {
    const {
      zonatrein_id,
      zonatrein_descricao,
      zonatrein_bpmminimo,
      zonatrein_bpmmaximo,
    } = trainingZone

    if (editingId === zonatrein_id) {
      handleFinishEditing()
    } else {
      setEditingId(zonatrein_id)

      setValues({
        name: zonatrein_descricao,
        minBpm: String(zonatrein_bpmminimo),
        maxBpm: String(zonatrein_bpmmaximo),
      })

      if (nameRef.current) nameRef.current.focus()
    }
  }

  const handleDelete = (trainingZone: Models.TrainingZone) => {
    const { zonatrein_id } = trainingZone
    if (editingId === zonatrein_id) handleFinishEditing()
    dispatch(requestDeleteTrainingZone(zonatrein_id))
  }

  const handleShowDeleteConfirmationModal = (
    trainingZone: Models.TrainingZone,
  ) => {
    setTrainingZoneToDelete(trainingZone)
  }

  const handleCloseDeleteConfirmationModal = () => {
    setTrainingZoneToDelete(null)
  }

  const handleConfirmDeletion = () => {
    if (trainingZoneToDelete) handleDelete(trainingZoneToDelete)
  }

  return (
    <>
      {isLoading ? (
        <SpinnerWithText hasSpinnerOnTheLeft>
          <Spinner />
          <span>{t('loadingText')}</span>
        </SpinnerWithText>
      ) : (
        <>
          <ConfirmModal
            confirmButtonType="danger"
            confirmButtonText={t('Glossary:delete')}
            isShowing={!!trainingZoneToDelete}
            onConfirm={handleConfirmDeletion}
            title={t('deleteConfirmationTitle')}
            iconComponent={<FiTrash size="3.2rem" />}
            handleCloseModal={handleCloseDeleteConfirmationModal}
            message={t('deleteConfirmationMessage', {
              name: trainingZoneToDelete?.zonatrein_descricao,
            })}
          />

          <List>
            {trainingZones.map((trainingZone) => {
              const {
                zonatrein_id,
                zonatrein_descricao,
                zonatrein_bpmminimo,
                zonatrein_bpmmaximo,
              } = trainingZone

              const isEditingThisItem = editingId === zonatrein_id

              const handleEditItem = () => {
                handleEdit(trainingZone)
              }

              const handleDeleteItem = (
                e: React.MouseEvent<HTMLDivElement, MouseEvent>,
              ) => {
                e.stopPropagation()
                handleShowDeleteConfirmationModal(trainingZone)
              }

              return (
                <TrainingZoneItem
                  key={zonatrein_id}
                  onClick={handleEditItem}
                  isEditing={isEditingThisItem}
                  leftComponent={
                    isEditingThisItem ? (
                      <FiEdit size="2.4rem" />
                    ) : (
                      <FiActivity size="2.4rem" />
                    )
                  }
                  rightComponent={
                    <DangerRoundIconButton
                      className="deleteTrainingZone"
                      onClick={handleDeleteItem}
                    >
                      <FiTrash />
                    </DangerRoundIconButton>
                  }
                >
                  <ListItemTitle>{zonatrein_descricao}</ListItemTitle>

                  <ListItemText>
                    {t('TrainingZone:minBpmValue', {
                      bpm: zonatrein_bpmminimo,
                    })}
                  </ListItemText>

                  <ListItemText>
                    {t('TrainingZone:maxBpmValue', {
                      bpm: zonatrein_bpmmaximo,
                    })}
                  </ListItemText>
                </TrainingZoneItem>
              )
            })}
          </List>
        </>
      )}

      {!isLoading && !trainingZones.length && (
        <ListEmpty
          iconComponent={<FiActivity />}
          message={t('emptyListMessage')}
        />
      )}
    </>
  )
}

export default TrainingZoneList
