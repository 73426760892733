import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiBox, FiEdit, FiTrash } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import {
  ConfirmModal,
  DangerRoundIconButton,
  ListItemText,
  ListItemTitle,
  Spinner,
  SpinnerWithText,
} from 'src/components'
import { useIsLoading, useTypedSelector } from 'src/hooks'
import { requestDeleteBlock, BLOCK_TYPES } from 'src/store/ducks/block'

import { List, ListEmpty, TrainingBlockItem } from './styles'

interface TrainingBlockListProps {
  nameRef: React.MutableRefObject<HTMLInputElement | null>
  editingId: number
  setEditingId: (editingId: number) => void
  setValues: (values: { name?: string }) => void
  handleFinishEditing: () => void
}

const TrainingBlockList: React.FC<TrainingBlockListProps> = (props) => {
  const {
    nameRef,
    editingId,
    setEditingId,
    setValues,
    handleFinishEditing,
  } = props

  const dispatch = useDispatch()
  const { t } = useTranslation([
    'TrainingBlockConfig',
    'TrainingBlock',
    'Glossary',
  ])

  const isLoading = useIsLoading(BLOCK_TYPES.REQUEST_FETCH)
  const blocks = useTypedSelector(({ Block }) => Block.blocks)

  const [blockToDelete, setBlockToDelete] = useState<Models.Block | null>(null)

  const handleEdit = (block: Models.Block) => {
    const { bloco_id, bloco_descricao } = block

    if (editingId === bloco_id) {
      handleFinishEditing()
    } else {
      setEditingId(bloco_id)

      setValues({
        name: bloco_descricao,
      })

      if (nameRef.current) nameRef.current.focus()
    }
  }

  const handleDelete = (block: Models.Block) => {
    const { bloco_id } = block
    if (editingId === bloco_id) handleFinishEditing()
    dispatch(requestDeleteBlock(bloco_id))
  }

  const handleShowDeleteConfirmationModal = (block: Models.Block) => {
    setBlockToDelete(block)
  }

  const handleCloseDeleteConfirmationModal = () => {
    setBlockToDelete(null)
  }

  const handleConfirmDeletion = () => {
    if (blockToDelete) handleDelete(blockToDelete)
  }

  return (
    <>
      {isLoading ? (
        <SpinnerWithText hasSpinnerOnTheLeft>
          <Spinner />
          <span>{t('loadingText')}</span>
        </SpinnerWithText>
      ) : (
        <>
          <ConfirmModal
            confirmButtonType="danger"
            confirmButtonText={t('Glossary:delete')}
            isShowing={!!blockToDelete}
            onConfirm={handleConfirmDeletion}
            title={t('deleteConfirmationTitle')}
            iconComponent={<FiTrash size="3.2rem" />}
            handleCloseModal={handleCloseDeleteConfirmationModal}
            message={t('deleteConfirmationMessage', {
              name: blockToDelete?.bloco_descricao,
            })}
          />

          <List>
            {blocks.map((block) => {
              const { bloco_id, bloco_descricao, bloco_situacao } = block
              const isEditingThisItem = editingId === bloco_id
              const isActive = bloco_situacao === 'S'

              const handleEditItem = () => {
                handleEdit(block)
              }

              const handleDeleteItem = (
                e: React.MouseEvent<HTMLDivElement, MouseEvent>,
              ) => {
                e.stopPropagation()
                handleShowDeleteConfirmationModal(block)
              }

              return (
                <TrainingBlockItem
                  key={bloco_id}
                  onClick={handleEditItem}
                  isEditing={isEditingThisItem}
                  leftComponent={
                    isEditingThisItem ? (
                      <FiEdit size="2.4rem" />
                    ) : (
                      <FiBox size="2.4rem" />
                    )
                  }
                  rightComponent={
                    <DangerRoundIconButton
                      className="deleteTrainingBlock"
                      onClick={handleDeleteItem}
                    >
                      <FiTrash />
                    </DangerRoundIconButton>
                  }
                >
                  <ListItemTitle>{bloco_descricao}</ListItemTitle>
                  <ListItemText>
                    {t(isActive ? 'Glossary:active' : 'Glossary:inactive', {
                      context: 'male',
                    })}
                  </ListItemText>
                </TrainingBlockItem>
              )
            })}
          </List>
        </>
      )}

      {!isLoading && !blocks.length && (
        <ListEmpty iconComponent={<FiBox />} message={t('emptyListMessage')} />
      )}
    </>
  )
}

export default TrainingBlockList
