import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  TRAINING_LEVEL_ROUTES,
  Server,
} from 'src/services/Server'
import { withServerStructure } from 'src/utils'

import { RequestEditTrainingLevelAction, TRAINING_LEVEL_TYPES } from './types'
import { editTrainingLevelInList } from './actions'

export function* requestEditTrainingLevelWatcher() {
  yield takeLatest(TRAINING_LEVEL_TYPES.REQUEST_EDIT, handleEditTrainingLevel)
}

export function* handleEditTrainingLevel(
  action: RequestEditTrainingLevelAction,
) {
  try {
    yield put(addLoading(TRAINING_LEVEL_TYPES.REQUEST_EDIT))
    const { id, name, sequence, type } = action.payload

    const dataToAdd: Partial<Models.TrainingLevel> = {
      treinonivel_id: id,
      treinonivel_descricao: name,
      treinonivel_sequencia: sequence,
      treinonivel_tipo: type,
    }

    const { data }: AxiosServerResponse<Models.TrainingLevel> = yield call(
      Server.post,
      TRAINING_LEVEL_ROUTES.TREINO_NIVEL,
      withServerStructure(dataToAdd),
    )

    if (data.isSuccessful) {
      const trainingLevel = data.getFirstData()
      if (trainingLevel) yield put(editTrainingLevelInList(trainingLevel))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(TRAINING_LEVEL_TYPES.REQUEST_EDIT))
  }
}
