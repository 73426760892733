export interface AppTheme {
  id: string
  background: string
  snow: string
  snowDark: string
  primaryText: string
  secondaryText: string
  contrastPrimaryText: string
  contrastSecondaryText: string
  accent: string
  accentDark: string
  danger: string
  dangerDark: string
  warning: string
  success: string
  successDark: string
  border: string
  placeholder: string
  disabled: string
  hover: string
  contrastHover: string
  teacherLight: string
  teacher: string
  teacherDark: string
  studentLight: string
  student: string
  studentDark: string
  adminLight: string
  admin: string
  adminDark: string
  drawer: string
  drawerDark: string
  scrollBarThumb: string
  scrollBarTrack: string
}

export const LIGHT_THEME: AppTheme = {
  id: 'light',
  background: '#ffffff',
  snow: '#f2f2f2',
  snowDark: '#e7e7e7',
  primaryText: '#464646',
  secondaryText: '#737373',
  contrastPrimaryText: '#e6e6e6',
  contrastSecondaryText: '#c3c3c3',
  accent: '#D7DF36',
  accentDark: '#d3dd22',
  danger: '#ff6666',
  dangerDark: '#ff4d4d',
  warning: '#ff8000',
  success: '#33cc33',
  successDark: '#2eb82e',
  border: '#d9d9d9',
  placeholder: 'rgba(0, 0, 0, 0.7)',
  disabled: '#cccccc',
  hover: 'rgba(0, 0, 0, 0.1)',
  contrastHover: 'rgba(255, 255, 255, 0.1)',
  teacherLight: '#ffa64d',
  teacher: '#ff9933',
  teacherDark: '#ff8c1a',
  studentLight: '#4da6ff',
  student: '#3399ff',
  studentDark: '#1a8cff',
  adminLight: '#a64dff',
  admin: '#9933ff',
  adminDark: '#8c1aff',
  drawer: '#333333',
  drawerDark: '#262626',
  scrollBarThumb: '#737373',
  scrollBarTrack: '#434343',
}
