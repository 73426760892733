import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FiEdit, FiEye, FiPlay } from 'react-icons/fi'

import { STUDENT_TEST_TYPES } from 'src/config'
import { useRebuildTooltips, useTypedSelector, useUnits } from 'src/hooks'
import {
  DefaultTooltip,
  IframeVideo,
  RoundIconButton,
  TrainingHeader,
} from 'src/components'

import {
  Container,
  TrainingContainer,
  TrainingSheet,
  Block,
  Activity,
  StyledModal,
} from './styles'

const FullList: React.FC = () => {
  useRebuildTooltips()

  const { t } = useTranslation(['StudentTrainings', 'Training', 'VideoModal'])
  const { handleFormatValueWithUnit } = useUnits()
  const history = useHistory()

  const [isShowingVideo, setIsShowingVideo] = useState<{
    describe: string
    link: string
  } | null>()

  const handleHideVideoModal = () => {
    setIsShowingVideo(null)
  }

  const fullTrainings = useTypedSelector(
    ({ StudentTraining }) => StudentTraining.fullTrainings,
  )

  const getFormattedDate = (date: string): string => {
    if (!date) return ''
    return DateTime.fromSQL(date).toFormat('DDD')
  }

  return (
    <Container>
      <DefaultTooltip id="student-trainings-full-list" />

      {fullTrainings.map((trainings) => {
        const {
          treinoaluno_id,
          treino_descricao,
          treinoaluno_data,
          treinonivel_descricao,
          configtreinoalu_tipoteste,
          blocos,
        } = trainings

        const testType = configtreinoalu_tipoteste
        const canShowBpm = testType === STUDENT_TEST_TYPES.CARDIAC_TEST

        const handleOpenDetailsPage = () => {
          history.push(`/student-training/${treinoaluno_id}`)
        }

        const handleOpenEditTrainingPage = () => {
          history.push(`/edit-student-training/${treinoaluno_id}`)
        }

        return (
          <TrainingContainer key={treinoaluno_id}>
            <TrainingHeader
              trainingName={treino_descricao}
              trainingDate={getFormattedDate(treinoaluno_data)}
              trainingNumber={t('Training:trainingNumber', {
                number: treinoaluno_id,
              })}
              trainingLevelName={
                treinonivel_descricao
                  ? t('Training:trainingLevelName', {
                      name: treinonivel_descricao,
                    })
                  : ''
              }
              actionsComponent={
                <>
                  <RoundIconButton
                    onClick={handleOpenDetailsPage}
                    data-tip={t('seeTrainingActionTooltip')}
                    data-for="student-trainings-full-list"
                  >
                    <FiEye />
                  </RoundIconButton>

                  <RoundIconButton
                    onClick={handleOpenEditTrainingPage}
                    data-tip={t('editTrainingActionTooltip')}
                    data-for="student-trainings-full-list"
                  >
                    <FiEdit />
                  </RoundIconButton>
                </>
              }
            />

            <TrainingSheet>
              {blocos.map((block) => {
                const {
                  treinoblocoaluno_id,
                  treinoblocoalu_repeticao,
                  bloco_descricao,
                  atividades,
                } = block

                return (
                  <Block
                    key={treinoblocoaluno_id}
                    name={bloco_descricao}
                    repeat={treinoblocoalu_repeticao}
                  >
                    {atividades.map((activity) => {
                      const {
                        trebloativaluno_id,
                        trebloativaluno_repeticao,
                        trebloativaluno_tempo,
                        trebloativaluno_unidade,
                        atividade_descricao,
                        zonatrein_bpmminimo,
                        zonatrein_bpmmaximo,
                        valor_convertido,
                        atividade_linkvideopadrao,
                      } = activity

                      return (
                        <>
                          <StyledModal
                            isShowing={!!isShowingVideo}
                            handleHideModal={handleHideVideoModal}
                            title={t('VideoModal:title')}
                            iconComponent={<FiPlay size="3.2rem" />}
                          >
                            <IframeVideo
                              tittle={isShowingVideo?.describe}
                              url={isShowingVideo?.link}
                            />
                          </StyledModal>

                          <Activity
                            key={trebloativaluno_id}
                            name={atividade_descricao}
                            repeat={trebloativaluno_repeticao}
                            value={handleFormatValueWithUnit(
                              trebloativaluno_unidade,
                              trebloativaluno_tempo,
                            )}
                            url={atividade_linkvideopadrao}
                            minBpm={
                              canShowBpm ? zonatrein_bpmminimo : undefined
                            }
                            maxBpm={
                              canShowBpm ? zonatrein_bpmmaximo : undefined
                            }
                            pace={
                              valor_convertido
                                ? Number(valor_convertido).toFixed(2)
                                : undefined
                            }
                            isCalculatedBpm
                            onClick={() =>
                              setIsShowingVideo({
                                describe: atividade_descricao,
                                link: atividade_linkvideopadrao,
                              })
                            }
                          />
                        </>
                      )
                    })}
                  </Block>
                )
              })}
            </TrainingSheet>
          </TrainingContainer>
        )
      })}
    </Container>
  )
}

export default FullList
