import React, { forwardRef } from 'react'
import { FiCalendar } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import { DefaultLabel, SnowInputMask } from 'src/components'
import { INPUT_MASKS } from 'src/config'

import { EditProfileInput } from './styles'

interface UserBirthDateInputProps {
  defaultBirthDate?: string
}

const UserBirthDateInput = forwardRef<
  HTMLInputElement,
  UserBirthDateInputProps
>((props, ref) => {
  const { defaultBirthDate } = props

  const { t } = useTranslation('EditUserProfile')

  return (
    <EditProfileInput
      iconComponent={<FiCalendar size="3.2rem" />}
      labelComponent={
        <DefaultLabel htmlFor="birthDate">
          <span>{t('birthDateLabel')}</span>
        </DefaultLabel>
      }
      inputComponent={
        <SnowInputMask
          inputRef={ref}
          id="birthDate"
          mask={INPUT_MASKS.DDMMYYYY_DATE}
          maskChar={null}
          alwaysShowMask={false}
          type="text"
          placeholder={t('birthDatePh')}
          defaultValue={defaultBirthDate}
        />
      }
    />
  )
})

UserBirthDateInput.displayName = 'UserBirthDateInput'

export default UserBirthDateInput
