import styled from 'styled-components'

import {
  SimpleModal,
  TrainingActivity,
  TrainingBlock,
  TrainingSheetContainer,
} from 'src/components'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const TrainingContainer = styled.div`
  flex: 1 45%;
  min-width: 30rem;

  margin: 0.8rem;
`

export const StyledModal = styled(SimpleModal)`
  background: rgba(0, 0, 0, 0.1);
`

export const TrainingSheet = styled(TrainingSheetContainer)`
  font-size: 0.8rem;

  border-radius: 0 0 1rem 1rem;
`

export const Block = styled(TrainingBlock)``

export const Activity = styled(TrainingActivity)``
