import styled from 'styled-components'

import {
  EmptyMessage,
  PageContainer,
  PageTitle,
  UserItem,
  SnowInput,
  RoundIconButton,
} from 'src/components'

export const Container = styled(PageContainer)``

export const MyStudentsTitle = styled(PageTitle)`
  margin-bottom: 4.8rem;
`

export const FilterContainer = styled.div`
  margin: 0 auto 4.8rem auto;

  max-width: 60rem;
`

export const FilterInputContainer = styled.div`
  display: flex;
  align-items: center;
`

export const SearchInput = styled(SnowInput)`
  flex: 1;
  margin-right: 0.8rem;
`

export const ClearSearchInputButton = styled(RoundIconButton)``

export const StudentList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Student = styled(UserItem)`
  flex: 1 40%;
  min-width: 40rem;
  margin: 0.8rem;
`

export const NoStudentsMessage = styled(EmptyMessage)`
  margin: auto;
`
