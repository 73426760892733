import React, { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FiZap } from 'react-icons/fi'

import { useSetBrowserTabTitle } from 'src/hooks'
import {
  requestFetchActivities,
  setActivityList,
} from 'src/store/ducks/activity'

import ActivityForm from './ActivityForm'
import ActivityList from './ActivityList'
import { Container, ActivityConfigTitle, Divider } from './styles'

const ActivityConfig: React.FC = () => {
  useSetBrowserTabTitle('activityConfig')

  const { t } = useTranslation(['ActivityConfig'])
  const dispatch = useDispatch()

  const nameRef = useRef<HTMLInputElement | null>(null)
  const videoRef = useRef<HTMLInputElement | null>(null)
  const [editingId, setEditingId] = useState(0)

  const setValues = ({ name = '', video = '' }) => {
    if (nameRef.current) nameRef.current.value = name
    if (videoRef.current) videoRef.current.value = video
  }

  const handleFinishEditing = () => {
    setEditingId(0)
    setValues({})
  }

  useLayoutEffect(() => {
    dispatch(requestFetchActivities())
    return () => {
      dispatch(setActivityList([]))
    }
  }, [dispatch])

  return (
    <Container>
      <ActivityConfigTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<FiZap />}
      />

      <ActivityForm
        editingId={editingId}
        nameRef={nameRef}
        videoRef={videoRef}
        setValues={setValues}
        handleFinishEditing={handleFinishEditing}
      />

      <Divider />

      <ActivityList
        editingId={editingId}
        setEditingId={setEditingId}
        nameRef={nameRef}
        setValues={setValues}
        handleFinishEditing={handleFinishEditing}
      />
    </Container>
  )
}

export default ActivityConfig
