import {
  STUDENT_TYPES,
  SetStudentListAction,
  RequestFetchStudentsAction,
  RequestRegisterStudentAction,
  RequestFetchStudentsOutsideGroupAction,
} from './types'

export const requestFetchStudents = (
  teacherId: number,
  search?: string,
): RequestFetchStudentsAction => ({
  type: STUDENT_TYPES.REQUEST_FETCH_STUDENTS,
  payload: {
    teacherId,
    search,
  },
})

export const setStudentsList = (
  students: Models.User[],
  listName?: SetStudentListAction['payload']['listName'],
): SetStudentListAction => ({
  type: STUDENT_TYPES.SET_STUDENT_LIST,
  payload: {
    students,
    listName: listName || 'students',
  },
})

export const requestRegisterStudent = (
  data: RequestRegisterStudentAction['payload'],
): RequestRegisterStudentAction => ({
  type: STUDENT_TYPES.REQUEST_REGISTER_STUDENT,
  payload: data,
})

export const requestFetchStudentsOutsideGroup = (
  groupId: number,
  search?: string,
): RequestFetchStudentsOutsideGroupAction => ({
  type: STUDENT_TYPES.REQUEST_FETCH_STUDENTS_OUTSIDE_GROUP,
  payload: {
    groupId,
    search,
  },
})
