import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsFileEarmarkRuled, BsFileCheck } from 'react-icons/bs'

import { useTypedSelector } from 'src/hooks'

import {
  Container,
  SeeStudentTrainings,
  SeeStudentAnamneseAnswers,
} from './styles'

const ActionsSection: React.FC = () => {
  const { t } = useTranslation('UserPublicProfile')
  const history = useHistory()

  const userData = useTypedSelector(({ User }) => User.userData)

  const handleOpenStudentTrainingsPage = () => {
    const userId = userData?.usuario_id || 0
    history.push(`/student-trainings/${userId}`)
  }

  const handleSeeStudentAnamneseAnswers = () => {
    const userId = userData?.usuario_id || 0
    history.push(`/anamnese-answers/${userId}`)
  }

  return (
    <Container>
      <SeeStudentTrainings onClick={handleOpenStudentTrainingsPage}>
        <span>{t('seeStudentTrainings')}</span>
        <BsFileEarmarkRuled />
      </SeeStudentTrainings>

      <SeeStudentAnamneseAnswers onClick={handleSeeStudentAnamneseAnswers}>
        <span>{t('seeStudentAnamneseAnswers')}</span>
        <BsFileCheck />
      </SeeStudentAnamneseAnswers>
    </Container>
  )
}

export default ActionsSection
