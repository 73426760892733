import React, { useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FiHeart, FiPlusCircle } from 'react-icons/fi'

import {
  requestGetAnamneseQuiz,
  setAnamneseQuiz,
} from 'src/store/ducks/anamnese'
import { useModalToggleFunctions, useSetBrowserTabTitle } from 'src/hooks'

import { EditingQuiz } from './types'
import AnamneseForm from './AnamneseForm'
import AnamneseList from './AnamneseList'
import {
  Container,
  AnamneseConfigTitle,
  CreateAnamneseButton,
  Divider,
} from './styles'

const AnamneseConfig: React.FC = () => {
  useSetBrowserTabTitle('anamneseConfig')

  const { t } = useTranslation('AnamneseConfig')
  const dispatch = useDispatch()

  const [
    isBuildingQuiz,
    handleBuildNewQuiz,
    handleNotBuildingQuiz,
  ] = useModalToggleFunctions()

  const [editingQuiz, setEditingQuiz] = useState<EditingQuiz>(null)

  const handleCancelEditingQuiz = () => {
    setEditingQuiz(null)
  }

  const handleEditQuiz = (quiz: EditingQuiz) => {
    setEditingQuiz(quiz)
    handleBuildNewQuiz()
  }

  useLayoutEffect(() => {
    dispatch(requestGetAnamneseQuiz())
    return () => {
      dispatch(setAnamneseQuiz(undefined))
    }
  }, [dispatch])

  return (
    <Container>
      <AnamneseConfigTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<FiHeart />}
      />

      {isBuildingQuiz ? (
        <AnamneseForm
          isEditing={!!editingQuiz}
          editingQuiz={editingQuiz}
          handleNotBuildingQuiz={handleNotBuildingQuiz}
          handleCancelEditingQuiz={handleCancelEditingQuiz}
        />
      ) : (
        <CreateAnamneseButton onClick={handleBuildNewQuiz}>
          <span>{t('createAnamneseButton')}</span>
          <FiPlusCircle />
        </CreateAnamneseButton>
      )}

      <Divider />

      <AnamneseList
        isEditing={!!editingQuiz}
        handleEditQuiz={handleEditQuiz}
        handleNotBuildingQuiz={handleNotBuildingQuiz}
      />
    </Container>
  )
}

export default AnamneseConfig
