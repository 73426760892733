import styled, { css } from 'styled-components'

const PartCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  min-height: 2em;
  min-width: 3.625em;

  font-size: 1.6em;

  padding: 0 0.5em;
`

export const Container = styled.div`
  display: flex;
  align-items: center;

  height: 100%;

  background: ${(props) => props.theme.background};
`

export const ActivityName = styled.div`
  flex: 1;

  padding: 0 1em;

  font-size: 1.6em;
`

export const Repeat = styled.div`
  ${PartCss}

  color: ${(props) => props.theme.student};
  font-weight: bold;

  border-right: 1px solid ${(props) => props.theme.border};
`

export const Value = styled.div`
  ${PartCss};

  min-width: 7em;
  width: 7em;

  color: ${(props) => props.theme.success};
  font-weight: bold;
  font-size: 1.4em;

  border-left: 1px solid ${(props) => props.theme.border};

  svg {
    font-size: 1.9rem;
    color: ${(props) => props.theme.studentLight};
  }

  svg:hover {
    color: ${(props) => props.theme.studentDark};
    cursor: pointer;
  }
`

export const BpmValue = styled.div`
  ${PartCss};

  min-width: 7em;
  width: 7em;

  color: ${(props) => props.theme.teacher};
  font-weight: bold;
  font-size: 1.4em;
  line-height: 1em;

  border-left: 1px solid ${(props) => props.theme.border};

  > svg {
    font-size: 0.8em;
  }
`
