import styled from 'styled-components'

import {
  AnamneseAnswerableQuestion,
  EmptyMessage,
  PageContainer,
  PageTitle,
  SuccessButton,
  CheckBox,
} from 'src/components'

export const Container = styled(PageContainer)``

export const AnswerAnamneseTitle = styled(PageTitle)`
  margin-bottom: 2.4rem;
  max-width: 100rem;
`

export const WarningText = styled.div`
  padding: 1.6rem;
  margin: 0 0.8rem 2.4rem 0.8rem;

  background: ${(props) => props.theme.snow};

  border: 1px solid ${(props) => props.theme.border};
  border-radius: 1rem;
`

export const EditingText = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 2.4rem;
  padding: 0 0.8rem;

  color: ${(props) => props.theme.warning};
  font-weight: 900;
  font-size: 1.8rem;

  span {
    margin-left: 1.6rem;
  }
`

export const QuestionsList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-bottom: 1.6rem;
`

export const AnswerableQuestion = styled(AnamneseAnswerableQuestion)`
  flex: 1 40%;
  min-width: 30rem;
  margin: 0 0.8rem 1.6rem 0.8rem;
`

export const EmptyList = styled(EmptyMessage)`
  margin: auto;
`

export const TermTitle = styled.div`
  font-weight: bold;

  padding: 0 0.8rem;
  margin-bottom: 0.8rem;
`

export const TermText = styled(WarningText)`
  margin-bottom: 1.6rem;

  overflow-y: auto;
  max-height: 15rem;
`

export const TermCheckbox = styled(CheckBox)`
  margin: 0 0.8rem 2.4rem 0.8rem;
`

export const SaveAnswersButton = styled(SuccessButton)`
  margin: auto;

  min-width: 30rem;
  max-width: 60rem;
`
