import useTypedSelector from './useTypedSelector'

export default () => {
  const userData = useTypedSelector(({ Auth }) => Auth.userData)

  return {
    isStudent: userData?.usuario_aluno === 'S',
    isTeacher: userData?.usuario_professor === 'S',
    isAdmin: userData?.usuario_administrador === 'S',
  }
}
