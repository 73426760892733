import React from 'react'
import { FiEdit2, FiHeart, FiX } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

import { ANAMNESE_TYPES } from 'src/store/ducks/anamnese'
import { Spinner, DefaultTooltip, SpinnerWithText } from 'src/components'
import { useIsLoading, useRebuildTooltips, useTypedSelector } from 'src/hooks'

import { EditingQuiz } from '../types'

import {
  Container,
  Anamnese,
  Question,
  ListEmpty,
  EditIconButton,
} from './styles'

interface AnamneseListProps {
  isEditing: boolean
  handleEditQuiz: (quiz: EditingQuiz) => void
  handleNotBuildingQuiz: () => void
}

const AnamneseList: React.FC<AnamneseListProps> = ({
  isEditing,
  handleEditQuiz,
  handleNotBuildingQuiz,
}) => {
  useRebuildTooltips()

  const { t } = useTranslation(['AnamneseConfig', 'Anamnese'])
  const isLoading = useIsLoading(ANAMNESE_TYPES.REQUEST_GET)
  const quiz = useTypedSelector(({ Anamnese }) => Anamnese.quiz)

  const handleFormatDate = (date: string | undefined) => {
    if (!date) return date
    return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy')
  }

  const handleEditThisQuiz = () => {
    if (isEditing) {
      handleEditQuiz(null)
      handleNotBuildingQuiz()
    } else if (quiz) {
      handleEditQuiz(quiz)
    }
  }

  if (isLoading) {
    return (
      <Container>
        <SpinnerWithText hasSpinnerOnTheLeft>
          <Spinner />
          <span>{t('loadingText')}</span>
        </SpinnerWithText>
      </Container>
    )
  }

  if (!quiz) {
    return (
      <ListEmpty
        iconComponent={<FiHeart />}
        message={t('noActiveQuizMessage')}
      />
    )
  }

  return (
    <Container>
      <DefaultTooltip />

      <Anamnese
        isEditing={isEditing}
        headerLeftComponent={<FiHeart size="2.4rem" />}
        statusText={t('Glossary:active', { context: 'male' })}
        title={t('Anamnese:quizName', { id: quiz.anaminese_id })}
        subtitle={t('Anamnese:createdAt', {
          date: handleFormatDate(quiz.anaminese_dtinclusao),
          interpolation: {
            escapeValue: false,
          },
        })}
        headerRightComponent={
          <EditIconButton
            isEditing={isEditing}
            onClick={handleEditThisQuiz}
            data-tip={t(
              isEditing
                ? 'editQuizButtonTooltipWhenEditing'
                : 'editQuizButtonTooltip',
            )}
          >
            {isEditing ? <FiX /> : <FiEdit2 />}
          </EditIconButton>
        }
        isActive
      >
        {quiz.questions.map((question) => {
          const {
            anamipergunta_id,
            anamipergunta_descricao,
            anamipergunta_sequencia,
          } = question

          return (
            <Question key={`${quiz.anaminese_id}_${anamipergunta_id}`}>
              <span>{anamipergunta_sequencia}</span>
              <span>{anamipergunta_descricao}</span>
            </Question>
          )
        })}
      </Anamnese>
    </Container>
  )
}

export default AnamneseList
