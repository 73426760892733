import styled from 'styled-components'

import { SpinnerWithText, TrainingItem } from 'src/components'

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Training = styled(TrainingItem)`
  flex: 1 40%;
  min-width: 30rem;

  margin: 0.8rem;
`

export const BottomSpinnerContainer = styled(SpinnerWithText)`
  margin: 0.8rem 0.8rem 1.6rem 0.8rem;
`

export const TopSpinnerContainer = styled(SpinnerWithText)`
  margin: 0.8rem 0.8rem 1.6rem 0.8rem;
`
