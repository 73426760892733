import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  FiCheckCircle,
  FiHelpCircle,
  FiPlusCircle,
  FiTrash,
  FiXCircle,
} from 'react-icons/fi'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'

import { useIsLoading } from 'src/hooks'
import {
  DefaultError,
  DefaultLabel,
  SnowInput,
  WhiteSpinner,
} from 'src/components'
import {
  ANAMNESE_TYPES,
  requestAddAnamneseQuiz,
  requestUpdateAnamneseQuiz,
} from 'src/store/ducks/anamnese'

import { EditingQuiz } from '../types'

import {
  Container,
  Form,
  Questions,
  SaveAnamneseButton,
  AnamneseInput,
  AddQuestionButton,
  Question,
  DiscardQuiz,
  RemoveQuestionButton,
  DragAndDropHint,
} from './styles'

interface AnamneseFormProps {
  isEditing: boolean
  editingQuiz: EditingQuiz
  handleNotBuildingQuiz: () => void
  handleCancelEditingQuiz: () => void
}

const AnamneseForm: React.FC<AnamneseFormProps> = ({
  isEditing,
  editingQuiz,
  handleNotBuildingQuiz,
  handleCancelEditingQuiz,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation([
    'AnamneseConfig',
    'Error',
    'Anamnese',
    'Glossary',
  ])

  const questionRef = useRef<HTMLInputElement | null>(null)
  const [questions, setQuestions] = useState<string[]>([])
  const [errors, setErrors] = useState({
    isQuestionEmpty: false,
  })

  const isSavingOrUpdating = useIsLoading(
    ANAMNESE_TYPES.REQUEST_ADD,
    ANAMNESE_TYPES.REQUEST_UPDATE,
  )

  const handleValidateQuestion = (question: string): boolean => {
    setErrors({ isQuestionEmpty: !question.trim() })
    return !!question.trim()
  }

  const handleAddQuestion = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const question = questionRef.current?.value || ''
    if (handleValidateQuestion(question)) {
      setQuestions((questionList) => [...questionList, question])
      if (questionRef.current) {
        questionRef.current.value = ''
        questionRef.current.focus()
      }
    }
  }

  const handleRemoveQuestion = (index: number) => {
    const questionsClone = [...questions]
    questionsClone.splice(index, 1)
    setQuestions(questionsClone)
  }

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const startIndex = result.source.index
    const endIndex = result.destination.index
    const reorderedQuestions = [...questions]
    const [removed] = reorderedQuestions.splice(startIndex, 1)
    reorderedQuestions.splice(endIndex, 0, removed)
    setQuestions(reorderedQuestions)
  }

  const handleDiscardQuiz = () => {
    if (isEditing) handleCancelEditingQuiz()
    handleNotBuildingQuiz()
  }

  const handleSaveQuiz = () => {
    if (questions.length) {
      dispatch(requestAddAnamneseQuiz(questions, handleDiscardQuiz))
    }
  }

  const handleEditQuiz = () => {
    if (questions.length) {
      dispatch(requestUpdateAnamneseQuiz(questions, handleDiscardQuiz))
    }
  }

  useEffect(() => {
    if (isEditing) {
      const questionsToEdit = editingQuiz?.questions.map(
        ({ anamipergunta_descricao }) => anamipergunta_descricao,
      )

      setQuestions(questionsToEdit || [])
    }
  }, [editingQuiz?.questions, isEditing])

  return (
    <Container>
      <DiscardQuiz onClick={handleDiscardQuiz}>
        <span>{t(isEditing ? 'discardChanges' : 'discardQuiz')}</span>
        <FiXCircle />
      </DiscardQuiz>

      <Form onSubmit={handleAddQuestion} noValidate>
        <AnamneseInput
          iconComponent={<FiHelpCircle size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="question">
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('question')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="question"
              name="question"
              ref={questionRef}
              type="text"
              disabled={isSavingOrUpdating}
              autoCapitalize="words"
              placeholder={t('questionPh')}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isQuestionEmpty}
        />

        <AddQuestionButton type="submit" disabled={isSavingOrUpdating}>
          <span>{t('addQuestion')}</span>
          <FiPlusCircle />
        </AddQuestionButton>
      </Form>

      {!!questions.length && (
        <>
          <DragAndDropHint>{t('dragAndDropHint')}</DragAndDropHint>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable
              droppableId="droppable"
              isDropDisabled={questions.length === 1}
            >
              {(provided) => {
                return (
                  <Questions
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {questions.map((question, index) => {
                      const handleRemoveItem = () => {
                        handleRemoveQuestion(index)
                      }

                      return (
                        <Draggable
                          isDragDisabled={questions.length === 1}
                          draggableId={`${index}-${question}`}
                          key={`${index}-${question}`}
                          index={index}
                        >
                          {(provided) => {
                            return (
                              <Question
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                style={provided.draggableProps.style}
                              >
                                <span>{index + 1}</span>
                                <span>{question}</span>
                                <RemoveQuestionButton
                                  onClick={handleRemoveItem}
                                >
                                  <FiTrash />
                                </RemoveQuestionButton>
                              </Question>
                            )
                          }}
                        </Draggable>
                      )
                    })}

                    {provided.placeholder}
                  </Questions>
                )
              }}
            </Droppable>
          </DragDropContext>

          <SaveAnamneseButton
            onClick={isEditing ? handleEditQuiz : handleSaveQuiz}
            disabled={isSavingOrUpdating}
          >
            <span>
              {t(isEditing ? 'editAnamneseQuiz' : 'saveAnamneseQuiz')}
            </span>
            {isSavingOrUpdating ? <WhiteSpinner /> : <FiCheckCircle />}
          </SaveAnamneseButton>
        </>
      )}
    </Container>
  )
}

export default AnamneseForm
