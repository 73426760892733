import React from 'react'
import { BsClipboardData, BsFileEarmarkRuled } from 'react-icons/bs'
import {
  FiArrowRightCircle,
  FiCalendar,
  FiCheckCircle,
  FiFileText,
  FiPlusCircle,
  FiXCircle,
} from 'react-icons/fi'

import { Container, Header, Content, Title, Text, StatusText } from './styles'

export interface TrainingItemProps {
  style?: React.CSSProperties
  className?: string
  onClick?: (e: React.MouseEvent) => void
  trainingLevelName?: string
  trainingName?: string
  trainingNumber?: string
  trainingSequence?: string
  trainingDate?: string
  createdAt?: string
  statusText?: string
  isActive?: boolean
}

export const TrainingItem: React.FC<TrainingItemProps> = (props) => {
  const {
    style,
    className,
    onClick,
    trainingLevelName,
    trainingName,
    trainingNumber,
    trainingSequence,
    trainingDate,
    createdAt,
    statusText,
    isActive = true,
  } = props

  return (
    <Container style={style} className={className} onClick={onClick}>
      <Header>
        {!!trainingName && (
          <Title>
            <FiFileText />
            <span>{trainingName}</span>
          </Title>
        )}
      </Header>

      <Content>
        {!!trainingLevelName && (
          <Text>
            <BsClipboardData />
            <span>{trainingLevelName}</span>
          </Text>
        )}

        {!!trainingNumber && (
          <Text>
            <BsFileEarmarkRuled />
            <span>{trainingNumber}</span>
          </Text>
        )}

        {!!trainingSequence && (
          <Text>
            <FiArrowRightCircle />
            <span>{trainingSequence}</span>
          </Text>
        )}

        {!!trainingDate && (
          <Text>
            <FiCalendar />
            <span>{trainingDate}</span>
          </Text>
        )}

        {!!createdAt && (
          <Text>
            <FiPlusCircle />
            <span>{createdAt}</span>
          </Text>
        )}

        {!!statusText && (
          <StatusText isActive={isActive}>
            {isActive ? <FiCheckCircle /> : <FiXCircle />}
            <span>{statusText}</span>
          </StatusText>
        )}
      </Content>
    </Container>
  )
}
