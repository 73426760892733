import {
  ANAMNESE_QUIZ_ANSWER_TYPES,
  RequestFetchAnamneseQuizAnswersAction,
  RequestAnswerAnamneseQuizAction,
  RequestCheckAnamneseAnswerAction,
  SetAnamneseQuizAnswersAction,
  SetHasAnsweredAnamneseAction,
} from './types'

export const requestFetchAnamneseQuizAnswers = (
  userId: number,
): RequestFetchAnamneseQuizAnswersAction => ({
  type: ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_FETCH_ANSWERS,
  payload: {
    userId,
  },
})

export const requestAnswerAnamneseQuiz = (
  payload: RequestAnswerAnamneseQuizAction['payload'],
): RequestAnswerAnamneseQuizAction => ({
  type: ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_ANSWER_QUIZ,
  payload,
})

export const requestCheckAnamneseAnswer = (
  studentId: number,
): RequestCheckAnamneseAnswerAction => ({
  type: ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_CHECK_ANSWER,
  payload: {
    studentId,
  },
})

export const setAnamneseQuizAnswers = (
  answers: Models.AnamneseGroupedTypes.AnamneseFullAnswer[],
): SetAnamneseQuizAnswersAction => ({
  type: ANAMNESE_QUIZ_ANSWER_TYPES.SET_QUIZ_ANSWERS,
  payload: {
    answers,
  },
})

export const setHasAnsweredAnamnese = (
  hasAnsweredAnamnese: boolean,
): SetHasAnsweredAnamneseAction => ({
  type: ANAMNESE_QUIZ_ANSWER_TYPES.SET_ANSWERED,
  payload: {
    hasAnsweredAnamnese,
  },
})
