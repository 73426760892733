import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FiCheckCircle, FiEdit, FiLock, FiMail } from 'react-icons/fi'

import {
  DefaultError,
  DefaultLabel,
  SnowInput,
  WhiteSpinner,
} from 'src/components'
import {
  useIsLoading,
  useSetBrowserTabTitle,
  useTypedSelector,
} from 'src/hooks'
import { requestChangePassword, USER_TYPES } from 'src/store/ducks/user'

import {
  Container,
  ChangePasswordTitle,
  Form,
  PasswordInput,
  SaveButton,
} from './styles'

const ChangePassword: React.FC = () => {
  useSetBrowserTabTitle('changePassword')

  const { t } = useTranslation(['ChangePassword', 'Error', 'Glossary'])
  const dispatch = useDispatch()

  const userData = useTypedSelector(({ Auth }) => Auth.userData)
  const isLoading = useIsLoading(USER_TYPES.REQUEST_CHANGE_PASSWORD)

  const emailRef = useRef<HTMLInputElement | null>(null)
  const currentPasswordRef = useRef<HTMLInputElement | null>(null)
  const newPasswordRef = useRef<HTMLInputElement | null>(null)
  const confirmPasswordRef = useRef<HTMLInputElement | null>(null)

  const [errors, setErrors] = useState({
    isEmailEmpty: false,
    isCurrentPasswordEmpty: false,
    isNewPasswordEmpty: false,
    isConfirmPasswordEmpty: false,
    isPasswordsNotEqual: false,
  })

  const handleClearValues = () => {
    const userEmail = userData?.usuario_email || ''
    if (emailRef.current) emailRef.current.value = userEmail
    if (currentPasswordRef.current) currentPasswordRef.current.value = ''
    if (newPasswordRef.current) newPasswordRef.current.value = ''
    if (confirmPasswordRef.current) confirmPasswordRef.current.value = ''
  }

  const getValues = () => {
    const email = emailRef.current?.value || ''
    const currentPassword = currentPasswordRef.current?.value || ''
    const newPassword = newPasswordRef.current?.value || ''
    const confirmPassword = confirmPasswordRef.current?.value || ''

    return {
      email,
      currentPassword,
      newPassword,
      confirmPassword,
    }
  }

  const handleValidate = (): boolean => {
    const { email, currentPassword, newPassword, confirmPassword } = getValues()

    const errorState: typeof errors = {
      isEmailEmpty: !email.trim(),
      isCurrentPasswordEmpty: !currentPassword.trim(),
      isNewPasswordEmpty: !newPassword.trim(),
      isConfirmPasswordEmpty: !confirmPassword.trim(),
      isPasswordsNotEqual: newPassword !== confirmPassword,
    }

    setErrors(errorState)

    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (handleValidate()) {
      const { email, currentPassword, newPassword } = getValues()

      dispatch(
        requestChangePassword({
          userId: userData?.usuario_id || 0,
          email,
          newPassword,
          currentPassword,
          successCallback() {
            handleClearValues()
          },
        }),
      )
    }
  }

  return (
    <Container>
      <ChangePasswordTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<FiEdit />}
      />

      <Form onSubmit={handleSubmit} noValidate>
        <PasswordInput
          iconComponent={<FiMail size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="email">
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('emailLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="email"
              ref={emailRef}
              type="email"
              autoCapitalize="off"
              placeholder={t('emailPh')}
              disabled={isLoading}
              defaultValue={userData?.usuario_email}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isCurrentPasswordEmpty}
        />

        <PasswordInput
          iconComponent={<FiLock size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="password">
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('currentPasswordLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="password"
              ref={currentPasswordRef}
              type="password"
              autoCapitalize="off"
              placeholder={t('currentPasswordPh')}
              disabled={isLoading}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isCurrentPasswordEmpty}
        />

        <PasswordInput
          iconComponent={<FiLock size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="password">
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('newPasswordLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="password"
              ref={newPasswordRef}
              type="password"
              autoCapitalize="off"
              placeholder={t('newPasswordPh')}
              disabled={isLoading}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isNewPasswordEmpty}
        />

        <PasswordInput
          iconComponent={<FiLock size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="confirmPassword">
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('confirmPasswordLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="confirmPassword"
              ref={confirmPasswordRef}
              type="password"
              autoCapitalize="off"
              placeholder={t('confirmPasswordPh')}
              disabled={isLoading}
            />
          }
          errorComponent={
            <DefaultError>
              {t(
                !errors.isConfirmPasswordEmpty && errors.isPasswordsNotEqual
                  ? 'Error:passwordsNotEqual'
                  : 'Error:emptyFieldError',
              )}
            </DefaultError>
          }
          showError={
            errors.isConfirmPasswordEmpty || errors.isPasswordsNotEqual
          }
        />

        <SaveButton type="submit" disabled={isLoading}>
          <span>{t('saveButton')}</span>
          {isLoading ? <WhiteSpinner /> : <FiCheckCircle />}
        </SaveButton>
      </Form>
    </Container>
  )
}

export default ChangePassword
