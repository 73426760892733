import React, { useCallback, useMemo, useRef, useState } from 'react'
import StateManager, { OptionsType } from 'react-select'
import { useTranslation } from 'react-i18next'

import { ReactSelectOption } from 'src/components'
import { useModalToggleFunctions, useTypedSelector } from 'src/hooks'

import { EditTrainingActivity } from '../types'

import { TrainingActivityFormProps } from './activityFormTypes'

export default ({
  blocks,
  editingActivityIndexes,
  handleAddActivityInBlock,
  handleEditActivity,
}: TrainingActivityFormProps) => {
  const { t } = useTranslation('Units')

  const activityRef = useRef<StateManager<ReactSelectOption> | null>(null)
  const activityRepetitionsRef = useRef<HTMLInputElement | null>(null)
  const unitRef = useRef<StateManager<ReactSelectOption> | null>(null)
  const valueRef = useRef<HTMLInputElement | null>(null)
  const trainingZoneRef = useRef<StateManager<ReactSelectOption> | null>(null)
  const targetBlockRef = useRef<StateManager<ReactSelectOption> | null>(null)

  const [errors, setErrors] = useState({
    isActivityNotSelected: false,
    isActivityRepetitionsEmpty: false,
    isUnitNotSelected: false,
    isValueEmpty: false,
    isTrainingZoneEmpty: false,
    isTargetBlockNotSelected: false,
  })

  const [
    isShowingActivityModal,
    handleShowActivityModal,
    handleHideActivityModal,
  ] = useModalToggleFunctions()

  const activityOptions: OptionsType<ReactSelectOption> = useTypedSelector(
    ({ Activity }) => {
      return Activity.activities.map((activity) => ({
        label: activity.atividade_descricao,
        value: `${activity.atividade_id}`,
      }))
    },
  )

  const trainingZoneOptions: OptionsType<ReactSelectOption> = useTypedSelector(
    ({ TrainingZone }) => {
      return TrainingZone.trainingZones.map((trainingZone) => ({
        label: trainingZone.zonatrein_descricao,
        value: `${trainingZone.zonatrein_id}`,
      }))
    },
  )

  const targetBlockOptions: OptionsType<ReactSelectOption> = useMemo(() => {
    return blocks.map(({ bloco_descricao, treinobloco_repeticao }, index) => ({
      label: `${index + 1} - ${bloco_descricao} (${treinobloco_repeticao}x)`,
      value: String(index),
    }))
  }, [blocks])

  const trainingZones = useTypedSelector(
    ({ TrainingZone }) => TrainingZone.trainingZones,
  )

  const isEditing = useMemo(() => {
    const { activityIndex, blockIndex } = editingActivityIndexes
    return activityIndex !== -1 && blockIndex !== -1
  }, [editingActivityIndexes])

  const getValues = () => {
    const selectedActivity = (activityRef.current?.state.value ||
      {}) as ReactSelectOption

    const selectedUnit = (unitRef.current?.state.value ||
      {}) as ReactSelectOption

    const selectedTrainingZone = (trainingZoneRef.current?.state.value ||
      {}) as ReactSelectOption

    const selectedTargetBlock = (targetBlockRef.current?.state.value ||
      {}) as ReactSelectOption

    const activityId = selectedActivity.value
    const activityName = selectedActivity.label
    const activityVideo = selectedActivity.label
    const unitId = selectedUnit.value
    const trainingZoneId = selectedTrainingZone.value
    const activityRepetitions = activityRepetitionsRef.current?.value || ''
    const value = valueRef.current?.value || ''
    const targetBlockIndex = Number(selectedTargetBlock.value || -1)

    return {
      activityId,
      activityName,
      activityVideo,
      activityRepetitions,
      unitId,
      value,
      trainingZoneId,
      targetBlockIndex,
    }
  }

  const clearValues = useCallback(() => {
    const selectArray = [
      activityRef?.current?.select,
      unitRef?.current?.select,
      trainingZoneRef?.current?.select,
      targetBlockRef?.current?.select,
    ]

    selectArray.forEach((select) => {
      if (select) select.setValue(null, 'set-value')
    })

    if (activityRepetitionsRef.current) {
      activityRepetitionsRef.current.value = '1'
    }

    if (valueRef.current) valueRef.current.value = ''
  }, [])

  const setValuesFromActivity = useCallback(
    (activity: EditTrainingActivity) => {
      const { blockIndex } = editingActivityIndexes

      const activityOption: ReactSelectOption = {
        label: activity.atividade_descricao,
        value: String(activity.atividade_id),
      }

      const unitOption: ReactSelectOption = {
        label: t(activity.treblocativ_unidade),
        value: activity.treblocativ_unidade,
      }

      const trainingZoneOption: ReactSelectOption = {
        label: activity.zonatrein_descricao,
        value: String(activity.zonatrein_id),
      }

      const targetBlockOption = targetBlockOptions[blockIndex]

      if (activityRef.current) {
        activityRef.current.select.setValue(activityOption, 'set-value')
      }

      if (unitRef.current) {
        unitRef.current.select.setValue(unitOption, 'set-value')
      }

      if (trainingZoneRef.current) {
        trainingZoneRef.current.select.setValue(trainingZoneOption, 'set-value')
      }

      if (targetBlockRef.current) {
        targetBlockRef.current.select.setValue(targetBlockOption, 'set-value')
      }

      if (activityRepetitionsRef.current) {
        const repetitions = String(activity.treblocativ_repeticao)
        activityRepetitionsRef.current.value = repetitions
      }

      if (valueRef.current) {
        valueRef.current.value = String(activity.treblocativ_tempo)
      }
    },
    [editingActivityIndexes, t, targetBlockOptions],
  )

  const handleSelectActivityOption = (activity: Models.Activity) => {
    if (activityRef.current) {
      const { atividade_id, atividade_descricao } = activity

      const activityOption: ReactSelectOption = {
        label: atividade_descricao,
        value: String(atividade_id),
      }

      activityRef.current.select.setValue(activityOption, 'set-value')
    }
  }

  const handleValidate = (): boolean => {
    const {
      activityId,
      activityRepetitions,
      unitId,
      value,
      trainingZoneId,
      targetBlockIndex,
    } = getValues()

    const errorState: typeof errors = {
      isActivityRepetitionsEmpty: !activityRepetitions.trim(),
      isActivityNotSelected: !activityId,
      isUnitNotSelected: !unitId,
      isValueEmpty: !value.trim(),
      isTrainingZoneEmpty: !trainingZoneId,
      isTargetBlockNotSelected: targetBlockIndex < 0,
    }

    setErrors(errorState)
    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleSubmitForm = (e: React.FormEvent) => {
    e.preventDefault()
    if (handleValidate()) {
      const {
        activityId,
        activityName,
        activityVideo,
        activityRepetitions,
        trainingZoneId,
        unitId,
        value,
        targetBlockIndex,
      } = getValues()

      const trainingZoneData = trainingZones.find((trainingZone) => {
        return Number(trainingZone.zonatrein_id) === Number(trainingZoneId)
      })

      const activity: EditTrainingActivity = {
        atividade_id: Number(activityId || 0),
        treblocativ_repeticao: Number(activityRepetitions || 1),
        treblocativ_tempo: Number(value || 0),
        atividade_descricao: activityName,
        atividade_linkvideopadrao: activityVideo,
        treblocativ_unidade: unitId,

        zonatrein_id: Number(trainingZoneId || 0),
        zonatrein_descricao: trainingZoneData?.zonatrein_descricao || '',
        zonatrein_bpmminimo: trainingZoneData?.zonatrein_bpmminimo || 0,
        zonatrein_bpmmaximo: trainingZoneData?.zonatrein_bpmmaximo || 0,

        treinobloco_id: 0,
        treblocativ_id: 0,
        atividade_situacao: 'S',
        treblocativ_sequencia: 0,
      }

      if (isEditing) handleEditActivity(activity, targetBlockIndex)
      else handleAddActivityInBlock(activity, targetBlockIndex)

      clearValues()
    }
  }

  return {
    activityRef,
    activityRepetitionsRef,
    unitRef,
    valueRef,
    trainingZoneRef,
    targetBlockRef,

    errors,
    setErrors,
    isShowingActivityModal,

    activityOptions,
    trainingZoneOptions,
    targetBlockOptions,

    trainingZones,
    isEditing,

    getValues,
    setValuesFromActivity,
    clearValues,

    handleSelectActivityOption,
    handleShowActivityModal,
    handleHideActivityModal,
    handleValidate,
    handleSubmitForm,
  }
}
