import { call, put, takeLatest } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  AxiosServerResponse,
  Server,
  STUDENT_TRAINING_ROUTES,
} from 'src/services/Server'

import { setStudentTrainingData } from './actions'
import {
  StudentTrainingData,
  STUDENT_TRAINING_TYPES,
  RequestGetStudentTrainingAction,
} from './types'

export function* requestGetStudentTrainingWatcher() {
  yield takeLatest(STUDENT_TRAINING_TYPES.REQUEST_GET, handleGetStudentTraining)
}

export function* handleGetStudentTraining(
  action: RequestGetStudentTrainingAction,
) {
  try {
    yield put(addLoading(STUDENT_TRAINING_TYPES.REQUEST_GET))
    const { trainingId } = action.payload

    const routeArray = [STUDENT_TRAINING_ROUTES.TREINO_ALUNO, trainingId]

    const { data }: AxiosServerResponse<StudentTrainingData> = yield call(
      Server.get,
      routeArray.join('/'),
    )

    if (data.isSuccessful) {
      const trainingData = data.getFirstData() || undefined
      yield put(setStudentTrainingData(trainingData))
    } else {
      yield put(setStudentTrainingData(undefined))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(STUDENT_TRAINING_TYPES.REQUEST_GET))
  }
}
