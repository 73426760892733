import styled from 'styled-components'

import { Input, SuccessButton } from 'src/components'

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  padding: 3.2rem 2.4rem;
  background: ${(props) => props.theme.snow};
`

export const Form = styled.form`
  background: ${(props) => props.theme.background};
  box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.06);

  width: 60rem;
  max-width: 60rem;
  height: 100%;

  border-radius: 1rem;

  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 2.4rem;
  border-bottom: 1px solid ${(props) => props.theme.snow};
`

export const HeaderTexts = styled.div`
  flex: 1;
  margin-left: 2.4rem;
`

export const Title = styled.h1`
  margin: 0 0 0.4rem 0;
  font-weight: 900;
`

export const Subtitle = styled.div`
  color: ${(props) => props.theme.secondaryText};
`

export const Content = styled.div`
  padding: 2.4rem;
  flex: 1;
`

export const Footer = styled.div`
  padding: 0 2.4rem 2.4rem 2.4rem;
`

export const CreateAccountInput = styled(Input)`
  margin-bottom: 2.4rem;
`

export const SaveButton = styled(SuccessButton)``
