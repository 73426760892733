export enum ANAMNESE_TYPES {
  REQUEST_GET = '@ANAMNESE/REQUEST_GET',
  REQUEST_ADD = '@ANAMNESE/REQUEST_ADD',
  REQUEST_UPDATE = '@ANAMNESE/REQUEST_UPDATE',

  SET_QUIZ = '@ANAMNESE/SET_QUIZ',
}

export interface AnamneseState {
  quiz?: Models.AnamneseGroupedTypes.AnamneseWithQuestions
}

export interface RequestGetAnamneseQuizAction {
  type: ANAMNESE_TYPES.REQUEST_GET
}

export interface RequestAddAnamneseQuizAction {
  type: ANAMNESE_TYPES.REQUEST_ADD
  payload: {
    questions: string[]
    successCallback?: () => void
  }
}

export interface RequestUpdateAnamneseQuizAction {
  type: ANAMNESE_TYPES.REQUEST_UPDATE
  payload: {
    questions: string[]
    successCallback?: () => void
  }
}

export interface SetAnamneseQuizAction {
  type: ANAMNESE_TYPES.SET_QUIZ
  payload: {
    quiz?: Models.AnamneseGroupedTypes.AnamneseWithQuestions
  }
}
