import React, { useLayoutEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import {
  useAuthenticatedUserTypes,
  useIsLoading,
  useSetBrowserTabTitle,
  useTypedSelector,
} from 'src/hooks'
import {
  requestFetchUserData,
  setUserData,
  USER_TYPES,
} from 'src/store/ducks/user'
import {
  FullHeightSpinnerContainer,
  Spinner,
  SpinnerWithText,
} from 'src/components'

import UserGroupList from './UserGroupList'
import ActionsSection from './ActionsSection'
import MainDataSection from './MainDataSection'
import OtherDataSection from './OtherDataSection'
import AnamneseStatusSection from './AnamneseStatusSection'
import { Container, SectionGroup } from './styles'

interface UserPublicProfileParams {
  id?: string
}

const UserPublicProfile: React.FC = () => {
  useSetBrowserTabTitle('userPublicProfile')

  const { t } = useTranslation('UserPublicProfile')
  const { id } = useParams<UserPublicProfileParams>()
  const dispatch = useDispatch()

  const isLoading = useIsLoading(USER_TYPES.REQUEST_FETCH_USER_DATA)
  const userData = useTypedSelector(({ User }) => User.userData)
  const { isAdmin, isTeacher } = useAuthenticatedUserTypes()

  const isTheAuthenticatedUserProfile = useTypedSelector(({ User, Auth }) => {
    return User.userData?.usuario_id === Auth.userData?.usuario_id
  })

  const isThisUserStudent = useMemo(() => {
    return userData?.usuario_aluno === 'S'
  }, [userData])

  useLayoutEffect(() => {
    dispatch(requestFetchUserData(Number(id || 0)))
    return () => {
      dispatch(setUserData(undefined))
    }
  }, [dispatch, id])

  return isLoading ? (
    <FullHeightSpinnerContainer>
      <SpinnerWithText hasSpinnerOnTheLeft>
        <Spinner />
        <span>{t('loadingText')}</span>
      </SpinnerWithText>
    </FullHeightSpinnerContainer>
  ) : (
    <Container>
      <MainDataSection />
      <OtherDataSection />

      <SectionGroup>
        <UserGroupList />

        {(isTheAuthenticatedUserProfile || isAdmin || isTeacher) && (
          <AnamneseStatusSection />
        )}
      </SectionGroup>

      {(isTheAuthenticatedUserProfile || isAdmin || isTeacher) &&
        isThisUserStudent && <ActionsSection />}
    </Container>
  )
}

export default UserPublicProfile
