import { call, put, takeLatest } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  Server,
  USER_ROUTES,
  AxiosServerResponse,
  RUNNING_GROUP_ROUTES,
} from 'src/services/Server'

import { setRunningGroupList } from './actions'
import {
  RUNNING_GROUP_TYPES,
  RunningGroupListItem,
  RequestFetchRunningGroupsAction,
} from './types'

type Filters = {
  usuario_id?: number
  FILTRO_GERAL?: string
}

export function* requestFetchRunningGroupsWatcher() {
  yield takeLatest(
    RUNNING_GROUP_TYPES.REQUEST_FETCH_RUNNING_GROUPS,
    handleFetchRunningGroups,
  )
}

export function* handleFetchRunningGroups(
  action: RequestFetchRunningGroupsAction,
) {
  try {
    yield put(addLoading(RUNNING_GROUP_TYPES.REQUEST_FETCH_RUNNING_GROUPS))
    const {
      userId,
      search,
      isSearchingForStudentGroups,
      isSearchingForTeacherGroups,
    } = action.payload

    const filters: Filters = {
      FILTRO_GERAL: search,
    }

    if (isSearchingForTeacherGroups || isSearchingForStudentGroups) {
      filters.usuario_id = userId
    }

    let urlToSendRequest: string = RUNNING_GROUP_ROUTES.GET_LISTA

    // A ordem do if é importante: professor tem prioridade maior que aluno
    if (isSearchingForTeacherGroups) {
      urlToSendRequest = USER_ROUTES.GET_GRUPO_PROFESSOR
    } else if (isSearchingForStudentGroups) {
      urlToSendRequest = USER_ROUTES.GET_GRUPO_ALUNO
    }

    const { data }: AxiosServerResponse<RunningGroupListItem> = yield call(
      Server.put,
      urlToSendRequest,
      filters,
    )

    if (data.isSuccessful) {
      yield put(setRunningGroupList(data.data))
    } else {
      yield put(setRunningGroupList([]))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(RUNNING_GROUP_TYPES.REQUEST_FETCH_RUNNING_GROUPS))
  }
}
