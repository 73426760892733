import { call, put, takeLatest } from 'redux-saga/effects'

import { showSuccessToast } from 'src/store/ducks/success'
import { updateAuthenticatedUserData } from 'src/store/ducks/auth'
import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, USER_ROUTES, Server } from 'src/services/Server'
import { LOCAL_STORAGE_KEYS } from 'src/config'
import { withServerStructure } from 'src/utils'

import { USER_TYPES, RequestEditUserProfileAction } from './types'

export function* requestEditUserProfileWatcher() {
  yield takeLatest(USER_TYPES.REQUEST_EDIT_USER_PROFILE, handleEditUserProfile)
}

export function* handleUpdateCurrentUserData(newUserData: Models.User) {
  if (newUserData) {
    const userDataJson: string = yield call(
      [localStorage, localStorage.getItem],
      LOCAL_STORAGE_KEYS.USER_DATA,
    )

    const userData = userDataJson ? JSON.parse(userDataJson) : {}
    const userDataUpdated = { ...userData, ...newUserData }

    yield call(
      [localStorage, localStorage.setItem],
      LOCAL_STORAGE_KEYS.USER_DATA,
      JSON.stringify(userDataUpdated),
    )

    yield put(updateAuthenticatedUserData(userDataUpdated))
  }
}

export function* handleEditUserProfile(action: RequestEditUserProfileAction) {
  try {
    yield put(addLoading(USER_TYPES.REQUEST_EDIT_USER_PROFILE))
    const {
      id,
      name,
      email,
      phone,
      birthDate,
      cpf,
      cnpj,
      successCallback,
      isUpdatingCurrentLoggedUser,
    } = action.payload

    const dataToSave: Partial<Models.User> = {
      usuario_id: id,
      usuario_nome: name,
      usuario_email: email,
      usuario_celular: phone,
      usuario_dtnascimento: birthDate,
      usuario_cpf: cpf,
      usuario_cnpj: cnpj,
    }

    const { data }: AxiosServerResponse<Models.User> = yield call(
      Server.post,
      USER_ROUTES.PATCH_USUARIO,
      withServerStructure(dataToSave),
    )

    if (data.isSuccessful) {
      if (isUpdatingCurrentLoggedUser) {
        const userData = data.getFirstData()
        yield call<any>(handleUpdateCurrentUserData, userData)
      }

      if (successCallback) successCallback()
      yield put(showSuccessToast({ messageCode: 'editUserProfile' }))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(USER_TYPES.REQUEST_EDIT_USER_PROFILE))
  }
}
