import { Reducer } from 'redux'

import {
  OBSERVABLE_TYPES,
  ObservableState,
  AddObserverAction,
  RemoveObserverAction,
} from './types'

export const initialState: ObservableState = {
  MainPageContentScrollEndReached: {},
}

const reducer: Reducer<ObservableState> = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case OBSERVABLE_TYPES.ADD_OBSERVER: {
      const {
        topic,
        observerId,
        callback,
      } = payload as AddObserverAction['payload']

      return {
        ...state,
        [topic]: {
          ...state[topic],
          [observerId]: callback,
        },
      }
    }

    case OBSERVABLE_TYPES.REMOVE_OBSERVER: {
      const { observerId, topic } = payload as RemoveObserverAction['payload']

      const stateClone = { ...state }
      delete stateClone[topic][observerId]
      return stateClone
    }

    default:
      return state
  }
}

export default reducer
