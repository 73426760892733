import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  FiEdit,
  FiFileText,
  FiMail,
  FiPower,
  FiSmartphone,
  FiEdit2,
} from 'react-icons/fi'

import { ConfirmModal } from 'src/components'
import { requestLogout } from 'src/store/ducks/auth'
import { useModalToggleFunctions, useTypedSelector } from 'src/hooks'

import {
  Container,
  UserAvatar,
  EditButton,
  LogoutButton,
  ProfileData,
  UserName,
  UserData,
  ProfileActions,
  ChangePasswordButton,
} from './styles'

const MainSection: React.FC = () => {
  const { t } = useTranslation('UserProfile')
  const dispatch = useDispatch()
  const history = useHistory()

  const [
    isShowingLogoutConfirmation,
    handleShowLogoutConfirmation,
    handleHideLogoutConfirmation,
  ] = useModalToggleFunctions()

  const userData = useTypedSelector(({ User }) => User.userData)

  const handleEditProfile = () => {
    history.push('/edit-user-profile')
  }

  const handleLogout = () => {
    dispatch(
      requestLogout(() => {
        history.replace('/')
      }),
    )
  }

  const handleChangePassword = () => {
    history.push('/change-password')
  }

  return (
    <Container>
      <ConfirmModal
        onConfirm={handleLogout}
        isShowing={isShowingLogoutConfirmation}
        handleCloseModal={handleHideLogoutConfirmation}
        title={t('logoutConfirmationTitle')}
        message={t('logoutConfirmationMessage')}
        confirmButtonText={t('logoutConfirmButton')}
        confirmButtonType="danger"
        iconComponent={<FiPower size="3.2rem" />}
      />

      <ProfileActions>
        <ChangePasswordButton onClick={handleChangePassword}>
          <span>{t('changePassword')}</span>
          <FiEdit2 />
        </ChangePasswordButton>
      </ProfileActions>

      <UserAvatar alt={userData?.usuario_nome} />

      <ProfileData>
        {!!userData?.usuario_nome && (
          <UserName>
            <FiFileText />
            <span>{userData?.usuario_nome}</span>
          </UserName>
        )}

        {!!userData?.usuario_email && (
          <UserData>
            <FiMail />
            <span>{userData?.usuario_email}</span>
          </UserData>
        )}

        {!!userData?.usuario_celular && (
          <UserData>
            <FiSmartphone />
            <span>{userData?.usuario_celular}</span>
          </UserData>
        )}
      </ProfileData>

      <EditButton onClick={handleEditProfile}>
        <span>{t('editButton')}</span>
        <FiEdit />
      </EditButton>

      <LogoutButton onClick={handleShowLogoutConfirmation}>
        <span>{t('logoutButton')}</span>
        <FiPower />
      </LogoutButton>
    </Container>
  )
}

export default MainSection
