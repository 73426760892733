import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { FiCheckCircle, FiEdit, FiXCircle } from 'react-icons/fi'

import { ConfirmModal, WhiteSpinner } from 'src/components'
import {
  useIsLoading,
  useModalToggleFunctions,
  useTypedSelector,
} from 'src/hooks'
import {
  requestChangeTrainingSheetStatus,
  TRAINING_SHEET_TYPES,
} from 'src/store/ducks/trainingSheet'

import { TrainingSheetDetailsParams } from '../types'

import {
  Container,
  EditButton,
  ActivateButton,
  DeactivateButton,
} from './styles'

const Actions: React.FC = () => {
  const { id } = useParams<TrainingSheetDetailsParams>()
  const { t } = useTranslation('TrainingSheetDetails')
  const dispatch = useDispatch()
  const history = useHistory()

  const [
    isShowingDeactivateConfirmModal,
    handleShowDeactivateConfirmModal,
    handleHideDeactivateConfirmModal,
  ] = useModalToggleFunctions()

  const trainingSheetData = useTypedSelector(
    ({ TrainingSheet }) => TrainingSheet.trainingSheetData,
  )

  const isChangingStatus = useIsLoading(
    TRAINING_SHEET_TYPES.REQUEST_CHANGE_STATUS,
  )

  const handleGoToEditPage = () => {
    history.push(`/edit-training-sheet/${id || 0}`)
  }

  const handleActivateTraining = () => {
    dispatch(
      requestChangeTrainingSheetStatus({ id: Number(id), isActive: true }),
    )
  }

  const handleDeactivateTraining = () => {
    dispatch(
      requestChangeTrainingSheetStatus({ id: Number(id), isActive: false }),
    )
  }

  return (
    <Container>
      <ConfirmModal
        confirmButtonType="danger"
        confirmButtonText={t('Glossary:deactivate')}
        isShowing={isShowingDeactivateConfirmModal}
        onConfirm={handleDeactivateTraining}
        title={t('actions.deactivateConfirmationModalTitle')}
        iconComponent={<FiXCircle size="3.2rem" />}
        handleCloseModal={handleHideDeactivateConfirmModal}
        message={t('actions.deactivateConfirmationModalMessage', {
          name: trainingSheetData?.treino_descricao,
        })}
      />

      <EditButton onClick={handleGoToEditPage}>
        <span>{t('actions.edit')}</span>
        <FiEdit />
      </EditButton>

      {trainingSheetData?.treino_situacao === 'AT' ? (
        <DeactivateButton onClick={handleShowDeactivateConfirmModal}>
          <span>{t('actions.deactivate')}</span>
          {isChangingStatus ? <WhiteSpinner /> : <FiXCircle />}
        </DeactivateButton>
      ) : (
        <ActivateButton onClick={handleActivateTraining}>
          <span>{t('actions.activate')}</span>
          {isChangingStatus ? <WhiteSpinner /> : <FiCheckCircle />}
        </ActivateButton>
      )}
    </Container>
  )
}

export default Actions
