import React, { forwardRef } from 'react'
import { BsCardText } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

import { DefaultLabel, SnowInputMask } from 'src/components'
import { INPUT_MASKS } from 'src/config'

import { EditProfileInput } from './styles'

interface UserCnpjInputProps {
  defaultCnpj?: string
}

const UserCnpjInput = forwardRef<HTMLInputElement, UserCnpjInputProps>(
  (props, ref) => {
    const { defaultCnpj } = props

    const { t } = useTranslation('EditUserProfile')

    return (
      <EditProfileInput
        iconComponent={<BsCardText size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="cnpj">
            <span>{t('cnpjLabel')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowInputMask
            inputRef={ref}
            id="cnpj"
            mask={INPUT_MASKS.CNPJ}
            maskChar={null}
            alwaysShowMask={false}
            type="text"
            placeholder={t('cnpjPh')}
            defaultValue={defaultCnpj}
          />
        }
      />
    )
  },
)

UserCnpjInput.displayName = 'UserCnpjInput'

export default UserCnpjInput
