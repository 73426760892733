import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  TRAINING_LEVEL_ROUTES,
  Server,
} from 'src/services/Server'

import { TRAINING_LEVEL_TYPES } from './types'
import { setTrainingLevelList } from './actions'

export function* requestFetchTrainingLevelsWatcher() {
  yield takeLatest(
    TRAINING_LEVEL_TYPES.REQUEST_FETCH,
    handleFetchTrainingLevels,
  )
}

export function* handleFetchTrainingLevels() {
  try {
    yield put(addLoading(TRAINING_LEVEL_TYPES.REQUEST_FETCH))

    const { data }: AxiosServerResponse<Models.TrainingLevel> = yield call(
      Server.put,
      TRAINING_LEVEL_ROUTES.GET_POR_CAMPOS,
      {},
    )

    if (data.isSuccessful) {
      yield put(setTrainingLevelList(data.data))
    } else {
      yield put(setTrainingLevelList([]))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(TRAINING_LEVEL_TYPES.REQUEST_FETCH))
  }
}
