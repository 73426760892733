import React, { useEffect, useCallback, useRef, useState, memo } from 'react'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  FiCalendar,
  FiChevronDown,
  FiChevronUp,
  FiPlusCircle,
  FiSearch,
} from 'react-icons/fi'

import { INPUT_MASKS } from 'src/config'
import {
  useIsLoading,
  useModalToggleFunctions,
  usePrevious,
  useSubscribeToTopic,
  InfinitePagination,
} from 'src/hooks'
import {
  CalendarModal,
  DefaultCalendar,
  DefaultLabel,
  DefaultTooltip,
  RoundIconButton,
  SnowInput,
  SnowInputMask,
  WhiteSpinner,
} from 'src/components'
import {
  requestFetchFullStudentTrainings,
  requestFetchStudentTrainings,
  setFullStudentTrainingList,
  setStudentTrainingList,
  STUDENT_TRAINING_TYPES,
} from 'src/store/ducks/studentTraining'

import DEFAULT_FILTERS from '../defaultFilters'
import { ListViewType } from '../types'

import {
  Container,
  FilterInput,
  SearchButton,
  ToggleFiltersButton,
} from './styles'

interface FiltersProps {
  userId: number
  listViewType: ListViewType | undefined
  pagination: InfinitePagination
}

const Filters: React.FC<FiltersProps> = (props) => {
  const { userId, listViewType, pagination } = props

  const { t } = useTranslation('StudentTrainings')
  const dispatch = useDispatch()

  const previousListViewType = usePrevious(listViewType)
  const searchRef = useRef<HTMLInputElement | null>(null)

  const [initialCalendarDate, setInitialCalendarDate] = useState(new Date())
  const [finalCalendarDate, setFinalCalendarDate] = useState(new Date())
  const [initialDate, setInitialDate] = useState(DEFAULT_FILTERS.INITIAL_DATE)
  const [finalDate, setFinalDate] = useState(DEFAULT_FILTERS.FINAL_DATE)
  const [isShowingFilters, setIsShowingFilters] = useState(false)

  const [
    isShowingInitialCalendarModal,
    handleShowInitialCalendarModal,
    handleHideInitialCalendarModal,
  ] = useModalToggleFunctions()

  const [
    isShowingFinalCalendarModal,
    handleShowFinalCalendarModal,
    handleHideFinalCalendarModal,
  ] = useModalToggleFunctions()

  const isSearching = useIsLoading(
    STUDENT_TRAINING_TYPES.REQUEST_FETCH,
    STUDENT_TRAINING_TYPES.REQUEST_FETCH_FULL,
  )

  const getSearchText = () => {
    return searchRef.current?.value.trim() || ''
  }

  const getFormattedDateToSearch = (date: string) => {
    if (!date) return ''
    return DateTime.fromFormat(date, 'dd/MM/yyyy').toFormat('yyyy-MM-dd')
  }

  const handleConfirmInitialDate = () => {
    setInitialDate(
      DateTime.fromJSDate(initialCalendarDate).toFormat('dd/MM/yyyy'),
    )
  }

  const handleConfirmFinalDate = () => {
    setFinalDate(DateTime.fromJSDate(finalCalendarDate).toFormat('dd/MM/yyyy'))
  }

  const handleToggleFilters = () => {
    setIsShowingFilters((isShowing) => !isShowing)
  }

  const handleFetchFullTrainings = useCallback(
    (limit: number, offset: number) => {
      const search = getSearchText()
      const formattedInitialDate = getFormattedDateToSearch(initialDate)
      const formattedFinalDate = getFormattedDateToSearch(finalDate)

      dispatch(setStudentTrainingList([]))

      dispatch(
        requestFetchFullStudentTrainings({
          search,
          userId,
          limit: limit,
          offset: offset,
          initialDate: formattedInitialDate,
          finalDate: formattedFinalDate,
        }),
      )
    },
    [dispatch, finalDate, initialDate, userId],
  )

  const handleFetchCondensedTrainings = useCallback(
    (limit: number, offset: number) => {
      const search = getSearchText()
      const formattedInitialDate = getFormattedDateToSearch(initialDate)
      const formattedFinalDate = getFormattedDateToSearch(finalDate)

      dispatch(setFullStudentTrainingList([]))

      dispatch(
        requestFetchStudentTrainings({
          search,
          userId,
          limit: limit,
          offset: offset,
          initialDate: formattedInitialDate,
          finalDate: formattedFinalDate,
        }),
      )
    },
    [dispatch, finalDate, initialDate, userId],
  )

  const handleFetchTrainings = useCallback(
    (limit: number, offset: number) => {
      if (listViewType === 'condensed') {
        handleFetchCondensedTrainings(limit, offset)
      } else if (listViewType === 'full') {
        handleFetchFullTrainings(limit, offset)
      }
    },
    [handleFetchCondensedTrainings, handleFetchFullTrainings, listViewType],
  )

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      pagination.handleResetPageNumber()
      handleFetchTrainings(pagination.limit, 0)
    },
    [handleFetchTrainings, pagination],
  )

  const handleEndReachedCallback = useCallback(() => {
    if (isSearching) return
    pagination.handleIncrementPageNumber()
    handleFetchTrainings(pagination.limit, pagination.nextOffset)
  }, [handleFetchTrainings, isSearching, pagination])

  useSubscribeToTopic({
    topic: 'MainPageContentScrollEndReached',
    observerId: 'StudentTrainings',
    callback: handleEndReachedCallback,
  })

  useEffect(() => {
    if (initialDate.length === INPUT_MASKS.DDMMYYYY_DATE.length) {
      const luxonDate = DateTime.fromFormat(initialDate, 'dd/MM/yyyy')
      if (luxonDate.isValid) setInitialCalendarDate(luxonDate.toJSDate())
    }
  }, [initialDate])

  useEffect(() => {
    if (finalDate.length === INPUT_MASKS.DDMMYYYY_DATE.length) {
      const luxonDate = DateTime.fromFormat(finalDate, 'dd/MM/yyyy')
      if (luxonDate.isValid) setFinalCalendarDate(luxonDate.toJSDate())
    }
  }, [finalDate])

  useEffect(() => {
    if (previousListViewType !== listViewType) {
      pagination.handleResetPageNumber()
      handleFetchTrainings(pagination.limit, 0)
    }
  }, [handleFetchTrainings, listViewType, pagination, previousListViewType])

  return (
    <Container>
      <DefaultTooltip id="student-trainings-filters" />

      <CalendarModal
        isShowing={isShowingInitialCalendarModal}
        handleHideModal={handleHideInitialCalendarModal}
        title={t('initialCalendarModalTitle')}
        onConfirm={handleConfirmInitialDate}
      >
        <DefaultCalendar
          value={initialCalendarDate}
          onChange={(date: any) => {
            if (date) setInitialCalendarDate(date as Date)
          }}
        />
      </CalendarModal>

      <CalendarModal
        isShowing={isShowingFinalCalendarModal}
        handleHideModal={handleHideFinalCalendarModal}
        title={t('finalCalendarModalTitle')}
        onConfirm={handleConfirmFinalDate}
      >
        <DefaultCalendar
          value={finalCalendarDate}
          onChange={(date: any) => {
            if (date) setFinalCalendarDate(date as Date)
          }}
        />
      </CalendarModal>

      <ToggleFiltersButton onClick={handleToggleFilters}>
        <span>{t(isShowingFilters ? 'hideFilters' : 'showFilters')}</span>
        {isShowingFilters ? <FiChevronUp /> : <FiChevronDown />}
      </ToggleFiltersButton>

      <form
        style={{ display: isShowingFilters ? 'block' : 'none' }}
        onSubmit={handleSubmit}
        noValidate
      >
        <FilterInput
          iconComponent={<FiSearch size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="searchText">
              <span>{t('searchLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="searchText"
              ref={searchRef}
              type="text"
              placeholder={t('searchPh')}
            />
          }
        />

        <FilterInput
          iconComponent={<FiCalendar size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="initialDate">
              <span>{t('initialDateLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInputMask
              id="initialDate"
              mask={INPUT_MASKS.DDMMYYYY_DATE}
              maskChar={null}
              alwaysShowMask={false}
              type="text"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
              placeholder={t('initialDatePh')}
            />
          }
          actionComponent={
            <RoundIconButton
              onClick={handleShowInitialCalendarModal}
              data-tip={t('selectDateTooltip')}
              data-for="student-trainings-filters"
              size={5.6}
            >
              <FiPlusCircle size="3.2rem" />
            </RoundIconButton>
          }
          showAction
        />

        <FilterInput
          iconComponent={<FiCalendar size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="finalDate">
              <span>{t('finalDateLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInputMask
              id="finalDate"
              mask={INPUT_MASKS.DDMMYYYY_DATE}
              maskChar={null}
              alwaysShowMask={false}
              type="text"
              value={finalDate}
              onChange={(e) => setFinalDate(e.target.value)}
              placeholder={t('finalDatePh')}
            />
          }
          actionComponent={
            <RoundIconButton
              onClick={handleShowFinalCalendarModal}
              data-tip={t('selectDateTooltip')}
              data-for="student-trainings-filters"
              size={5.6}
            >
              <FiPlusCircle size="3.2rem" />
            </RoundIconButton>
          }
          showAction
        />

        <SearchButton type="submit">
          <span>{t('searchButton')}</span>
          {isSearching ? <WhiteSpinner /> : <FiSearch />}
        </SearchButton>
      </form>
    </Container>
  )
}

export default memo(Filters)

