import { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import {
  requestGetTrainingSheet,
  setTrainingSheetData,
} from 'src/store/ducks/trainingSheet'

type EditTrainingParams = {
  id: string
}

export default () => {
  const { id } = useParams<EditTrainingParams>()
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(requestGetTrainingSheet(Number(id)))
    return () => {
      dispatch(setTrainingSheetData(undefined))
    }
  }, [dispatch, id])
}
