import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  TRAINING_ZONE_ROUTES,
  Server,
} from 'src/services/Server'

import { TRAINING_ZONE_TYPES } from './types'
import { setTrainingZoneList } from './actions'

export function* requestFetchTrainingZonesWatcher() {
  yield takeLatest(TRAINING_ZONE_TYPES.REQUEST_FETCH, handleFetchTrainingZones)
}

export function* handleFetchTrainingZones() {
  try {
    yield put(addLoading(TRAINING_ZONE_TYPES.REQUEST_FETCH))

    const { data }: AxiosServerResponse<Models.TrainingZone> = yield call(
      Server.put,
      TRAINING_ZONE_ROUTES.GET_POR_CAMPOS,
      {},
    )

    if (data.isSuccessful) {
      yield put(setTrainingZoneList(data.data))
    } else {
      yield put(setTrainingZoneList([]))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(TRAINING_ZONE_TYPES.REQUEST_FETCH))
  }
}
