import styled from 'styled-components'
import Calendar from 'react-calendar'

export const DefaultCalendar = styled(Calendar)`
  background: white;

  &.react-calendar--doubleView {
    width: 700px;

    .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;

      > * {
        width: 50%;
        margin: 0.5em;
      }
    }
  }

  button {
    margin: 0;
    border: 0;
    outline: none;

    &:enabled {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;

    button {
      min-width: 44px;
      background: none;

      &:enabled {
        &:hover,
        &:focus {
          background-color: ${(props) => props.theme.hover};
        }
      }

      &:disabled {
        background-color: ${(props) => props.theme.disabled};
      }
    }
  }

  .react-calendar__month-view {
    .react-calendar__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75em;

      .react-calendar__month-view__weekdays__weekday {
        padding: 0.5em;
      }
    }

    .react-calendar__month-view__weekNumbers {
      font-weight: bold;

      .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
      }
    }

    .react-calendar__month-view__days {
      .react-calendar__month-view__days__day {
        &.react-calendar__month-view__days__day--weekend {
          color: ${(props) => props.theme.danger};
        }

        &.react-calendar__month-view__days__day--neighboringMonth {
          color: ${(props) => props.theme.placeholder};
          font-size: 0.8em;
        }
      }
    }
  }

  .react-calendar__year-view,
  .react-calendar__decade-view,
  .react-calendar__century-view {
    .react-calendar__tile {
      padding: 2em 0.5em;
    }
  }

  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;

    &:disabled {
      background-color: ${(props) => props.theme.disabled};
    }

    &:enabled {
      &:hover,
      &:focus {
        background-color: ${(props) => props.theme.hover};
      }
    }

    &.react-calendar__tile--now {
      font-weight: 900;

      &:enabled {
        &:hover,
        &:focus {
          font-weight: 900;
        }
      }
    }

    &.react-calendar__tile--hasActive {
      background: ${(props) => props.theme.success};
      color: white !important;

      &:enabled {
        &:hover,
        &:focus {
          background: ${(props) => props.theme.success};
        }
      }
    }

    &.react-calendar__tile--active {
      background: ${(props) => props.theme.success};
      color: white !important;

      &:enabled {
        &:hover,
        &:focus {
          background: ${(props) => props.theme.success};
        }
      }
    }
  }

  &.react-calendar--selectRange {
    .react-calendar__tile {
      &.react-calendar__tile--hover:not(.react-calendar__tile--active):not(.react-calendar__tile--now) {
        background-color: ${(props) => props.theme.hover};
      }
    }
  }
`
