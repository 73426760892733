import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FiBarChart, FiEdit, FiTrash } from 'react-icons/fi'

import { useIsLoading, useTypedSelector } from 'src/hooks'
import {
  ListItemText,
  ListItemTitle,
  DangerRoundIconButton,
  Spinner,
  SpinnerWithText,
  ConfirmModal,
} from 'src/components'
import {
  requestDeleteTrainingLevel,
  TRAINING_LEVEL_TYPES,
} from 'src/store/ducks/trainingLevel'

import { List, ListEmpty, TrainingLevelItem } from './styles'

interface TrainingLevelListProps {
  editingId: number
  setEditingId: (editingId: number) => void
  nameRef: React.MutableRefObject<HTMLInputElement | null>
  handleFinishEditing: () => void
  setValues: (values: { name?: string; sequence?: string }) => void
}

const TrainingLevelList: React.FC<TrainingLevelListProps> = (props) => {
  const {
    editingId,
    setEditingId,
    nameRef,
    handleFinishEditing,
    setValues,
  } = props

  const dispatch = useDispatch()
  const { t } = useTranslation([
    'TrainingLevelConfig',
    'TrainingLevel',
    'Glossary',
  ])

  const isLoading = useIsLoading(TRAINING_LEVEL_TYPES.REQUEST_FETCH)

  const trainingLevels = useTypedSelector(({ TrainingLevel }) =>
    TrainingLevel.trainingLevels.sort(
      (trainingLevel1, trainingLevel2) =>
        trainingLevel1.treinonivel_sequencia -
        trainingLevel2.treinonivel_sequencia,
    ),
  )

  const [
    trainingLevelToDelete,
    setTrainingLevelToDelete,
  ] = useState<Models.TrainingLevel | null>(null)

  const handleEdit = (trainingLevel: Models.TrainingLevel) => {
    const {
      treinonivel_id,
      treinonivel_descricao,
      treinonivel_sequencia,
    } = trainingLevel

    if (editingId === treinonivel_id) {
      handleFinishEditing()
    } else {
      setEditingId(treinonivel_id)

      setValues({
        name: treinonivel_descricao,
        sequence: String(treinonivel_sequencia),
      })

      if (nameRef.current) nameRef.current.focus()
    }
  }

  const handleDelete = (trainingLevel: Models.TrainingLevel) => {
    const { treinonivel_id } = trainingLevel
    if (editingId === treinonivel_id) handleFinishEditing()
    dispatch(requestDeleteTrainingLevel(treinonivel_id))
  }

  const handleShowDeleteConfirmationModal = (
    trainingLevel: Models.TrainingLevel,
  ) => {
    setTrainingLevelToDelete(trainingLevel)
  }

  const handleCloseDeleteConfirmationModal = () => {
    setTrainingLevelToDelete(null)
  }

  const handleConfirmDeletion = () => {
    if (trainingLevelToDelete) handleDelete(trainingLevelToDelete)
  }

  return (
    <>
      {isLoading ? (
        <SpinnerWithText hasSpinnerOnTheLeft>
          <Spinner />
          <span>{t('loadingText')}</span>
        </SpinnerWithText>
      ) : (
        <>
          <ConfirmModal
            confirmButtonType="danger"
            confirmButtonText={t('Glossary:delete')}
            isShowing={!!trainingLevelToDelete}
            onConfirm={handleConfirmDeletion}
            title={t('deleteConfirmationTitle')}
            iconComponent={<FiTrash size="3.2rem" />}
            handleCloseModal={handleCloseDeleteConfirmationModal}
            message={t('deleteConfirmationMessage', {
              name: trainingLevelToDelete?.treinonivel_descricao,
            })}
          />

          <List>
            {trainingLevels.map((trainingLevel) => {
              const {
                treinonivel_id,
                treinonivel_descricao,
                treinonivel_sequencia,
                treinonivel_tipo,
              } = trainingLevel

              const isEditingThisItem = editingId === treinonivel_id

              const handleEditItem = () => {
                handleEdit(trainingLevel)
              }

              const handleDeleteItem = (
                e: React.MouseEvent<HTMLDivElement, MouseEvent>,
              ) => {
                e.stopPropagation()
                handleShowDeleteConfirmationModal(trainingLevel)
              }

              return (
                <TrainingLevelItem
                  key={treinonivel_id}
                  onClick={handleEditItem}
                  isEditing={isEditingThisItem}
                  leftComponent={
                    isEditingThisItem ? (
                      <FiEdit size="2.4rem" />
                    ) : (
                      <FiBarChart size="2.4rem" />
                    )
                  }
                  rightComponent={
                    <DangerRoundIconButton
                      className="deleteTrainingLevel"
                      onClick={handleDeleteItem}
                    >
                      <FiTrash />
                    </DangerRoundIconButton>
                  }
                >
                  <ListItemTitle>{treinonivel_descricao}</ListItemTitle>

                  <ListItemText>
                    {t('TrainingLevel:sequenceValue', {
                      sequence: treinonivel_sequencia,
                    })}
                  </ListItemText>

                  {!!treinonivel_tipo && (
                    <ListItemText>
                      {t('TrainingLevel:typeValue', {
                        type: treinonivel_tipo,
                      })}
                    </ListItemText>
                  )}
                </TrainingLevelItem>
              )
            })}
          </List>
        </>
      )}

      {!isLoading && !trainingLevels.length && (
        <ListEmpty
          iconComponent={<FiBarChart />}
          message={t('emptyListMessage')}
        />
      )}
    </>
  )
}

export default TrainingLevelList
