import { Reducer } from 'redux'

import {
  STUDENT_TRAINING_CONFIG_TYPES,
  StudentTrainingConfigState,
} from './types'

export const initialState: StudentTrainingConfigState = {}

const reducer: Reducer<StudentTrainingConfigState> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action

  switch (type) {
    case STUDENT_TRAINING_CONFIG_TYPES.SET_TRAINING_CONFIG_DATA: {
      return {
        ...state,
        trainingConfig: payload.trainingConfig,
      }
    }

    default:
      return state
  }
}

export default reducer
