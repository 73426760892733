import { Reducer } from 'redux'

import { ANAMNESE_TYPES, AnamneseState } from './types'

export const initialState: AnamneseState = {}

const reducer: Reducer<AnamneseState> = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ANAMNESE_TYPES.SET_QUIZ: {
      return {
        ...state,
        quiz: payload.quiz,
      }
    }

    default:
      return state
  }
}

export default reducer
