import styled from 'styled-components'

export const AnamneseQuestion = styled.div`
  display: flex;
  align-items: center;

  padding: 0.8rem 1.6rem;

  transition: background 0.2s ease-in-out;

  span:nth-child(1) {
    color: ${(props) => props.theme.teacher};
    font-weight: 900;
    font-size: 1.8rem;

    margin-right: 1.6rem;
  }

  span:nth-child(2) {
    flex: 1;
  }

  &:hover {
    background: ${(props) => props.theme.snow};
  }
`
