import React from 'react'
import { FiUser } from 'react-icons/fi'
import { FaChalkboardTeacher } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import {
  Container,
  Label,
  ButtonsContainer,
  TeacherButton,
  StudentButton,
} from './styles'

export interface AccountTypeProps {
  setIsTeacher: (isTeacher: boolean) => void
  isTeacher: boolean
  isLoading: boolean
}

const AccountType: React.FC<AccountTypeProps> = (props) => {
  const { isTeacher, setIsTeacher, isLoading } = props

  const { t } = useTranslation('CreateAccount')

  const handleSetIsTeacher = () => setIsTeacher(true)
  const handleSetIsStudent = () => setIsTeacher(false)

  return (
    <Container>
      <Label>{t('accountTypeLabel')}</Label>

      <ButtonsContainer>
        <TeacherButton
          type="button"
          isSelected={isTeacher}
          onClick={handleSetIsTeacher}
          disabled={isLoading}
        >
          <FaChalkboardTeacher />
          <span>{t('accountTypeTeacher')}</span>
        </TeacherButton>

        <StudentButton
          type="button"
          isSelected={!isTeacher}
          onClick={handleSetIsStudent}
          disabled={isLoading}
        >
          <FiUser />
          <span>{t('accountTypeStudent')}</span>
        </StudentButton>
      </ButtonsContainer>
    </Container>
  )
}

export default AccountType
