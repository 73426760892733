import React, { useLayoutEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsFileEarmarkRuled } from 'react-icons/bs'
import { FiSearch, FiUsers, FiX } from 'react-icons/fi'

import {
  useIsLoading,
  useSetBrowserTabTitle,
  useTypedSelector,
  useTypingCallback,
} from 'src/hooks'
import {
  DefaultLabel,
  Avatar,
  StudentSpinner,
  SpinnerWithText,
  Input,
  RoundIconButton,
  DefaultTooltip,
} from 'src/components'
import {
  setStudentsList,
  requestFetchStudents,
  STUDENT_TYPES,
} from 'src/store/ducks/student'

import {
  Container,
  MyStudentsTitle,
  FilterContainer,
  SearchInput,
  StudentList,
  Student,
  NoStudentsMessage,
  ClearSearchInputButton,
  FilterInputContainer,
} from './styles'

const MyStudents: React.FC = () => {
  useSetBrowserTabTitle('myStudents')

  const { t } = useTranslation('MyStudents')
  const dispatch = useDispatch()
  const history = useHistory()

  const isLoading = useIsLoading(STUDENT_TYPES.REQUEST_FETCH_STUDENTS)
  const students = useTypedSelector(({ Student }) => Student.students)
  const userData = useTypedSelector(({ Auth }) => Auth.userData)

  const searchRef = useRef<HTMLInputElement | null>(null)

  const handleInput = useTypingCallback({
    stopTypingCallback() {
      const userId = userData?.usuario_id || 0
      const search = searchRef.current?.value.trim()
      dispatch(requestFetchStudents(userId, search))
    },
  })

  const handleClearSearchText = () => {
    if (!searchRef.current) return
    if (searchRef.current.value) {
      searchRef.current.value = ''
      searchRef.current.focus()
      handleInput()
    } else {
      searchRef.current.focus()
    }
  }

  useLayoutEffect(() => {
    const userId = userData?.usuario_id || 0
    dispatch(requestFetchStudents(userId))
    return () => {
      dispatch(setStudentsList([]))
    }
  }, [dispatch, userData])

  return (
    <Container>
      <MyStudentsTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<FiUsers />}
      />

      <FilterContainer>
        <Input
          iconComponent={<FiSearch size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="searchStudent">
              <span>{t('searchLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <FilterInputContainer>
              <SearchInput
                id="searchStudent"
                ref={searchRef}
                type="text"
                placeholder={t('searchPh')}
                onInput={handleInput}
                disabled={isLoading}
              />

              <ClearSearchInputButton onClick={handleClearSearchText}>
                <FiX />
              </ClearSearchInputButton>
            </FilterInputContainer>
          }
        />
      </FilterContainer>

      {isLoading ? (
        <SpinnerWithText hasSpinnerOnTheLeft>
          <StudentSpinner />
          <span>{t('loadingText')}</span>
        </SpinnerWithText>
      ) : (
        <StudentList>
          <DefaultTooltip id="my-students" />

          {students.map((item) => {
            const {
              usuario_id,
              usuario_nome,
              usuario_email,
              usuario_celular,
            } = item

            const handleStudentItemClick = () => {
              history.push(`/user-public-profile/${usuario_id}`)
            }

            const handleOpenStudentTrainingsPage = (e: React.MouseEvent) => {
              e.preventDefault()
              e.stopPropagation()
              history.push(`/student-trainings/${usuario_id}`)
            }

            return (
              <Student
                key={usuario_id}
                name={usuario_nome}
                email={usuario_email}
                phone={usuario_celular}
                onClick={handleStudentItemClick}
                leftSideComponent={
                  <Avatar src={undefined} alt={usuario_nome} />
                }
                rightSideComponent={
                  <RoundIconButton
                    onClick={handleOpenStudentTrainingsPage}
                    data-tip={t('seeStudentTrainingsTooltip')}
                    data-for="my-students"
                  >
                    <BsFileEarmarkRuled />
                  </RoundIconButton>
                }
              />
            )
          })}
        </StudentList>
      )}

      {!isLoading && !students.length && (
        <NoStudentsMessage
          message={t('noStudentFound')}
          iconComponent={<FiUsers />}
        />
      )}
    </Container>
  )
}

export default MyStudents
