import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  min-height: 100%;

  background: ${(props) => props.theme.student};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Option = styled(Link)`
  color: white;
  font-weight: 900;
  font-size: 4.8rem;
  text-align: center;
  text-decoration: none;

  outline: none;

  width: 100%;
  min-height: 15rem;

  padding: 2.4rem;

  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  cursor: pointer;
  transition: background 0.2s ease-in-out;

  svg {
    font-size: 10rem;
  }

  &:hover {
    background: ${(props) => props.theme.hover};
  }

  @media only screen and (max-width: 768px) {
    font-size: 2.4rem;

    svg {
      font-size: 5rem;
    }
  }
`

export const MainOption = styled(Option)`
  background: white;
  color: ${(props) => props.theme.primaryText};

  &:hover {
    background: ${(props) => props.theme.snow};
  }
`
