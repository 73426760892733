import styled from 'styled-components'

import {
  Button,
  SmallButton,
  SmallSuccessButton,
  SmallStudentButton,
} from 'src/components'

export const Container = styled.div`
  min-width: 30rem;

  padding: 0 1.6rem 1.6rem 1.6rem;

  background: ${(props) => props.theme.snow};

  @media only screen and (max-width: 1200px) {
    min-width: unset;
    margin-top: 2.4rem;
    border-radius: 1rem;
  }
`

export const Title = styled.h2`
  display: flex;
  align-items: center;

  padding: 2.4rem 0;
  margin: 0;

  span {
    margin-left: 2.4rem;
  }
`

export const ActionGroup = styled.div`
  margin-bottom: 1.6rem;
`

export const AnswerAnamneseQuizButton = styled(SmallSuccessButton)`
  margin-bottom: 0.8rem;
`

export const AddGroupMemberButton = styled(SmallStudentButton)`
  margin-bottom: 0.8rem;
`

export const EditGroupButton = styled(SmallButton)`
  margin-bottom: 0.8rem;

  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.secondaryText};

  &:hover,
  &:focus {
    background: ${(props) => props.theme.background};
    color: ${(props) => props.theme.primaryText};
  }
`

export const DeleteGroupButton = styled(SmallButton)`
  margin-bottom: 0.8rem;

  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.danger};

  &:hover,
  &:focus {
    background: ${(props) => props.theme.background};
    color: ${(props) => props.theme.dangerDark};
  }
`

export const ModalContent = styled.div`
  padding: 1.6rem;
`

export const OptionButtonToAddMember = styled(Button)`
  margin-bottom: 0.8rem;

  &:hover,
  &:focus {
    background: ${(props) => props.theme.student};
    color: white;
  }
`
