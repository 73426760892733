import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { STUDENT_TEST_TYPES } from 'src/config'

export default () => {
  const { t } = useTranslation('StudentTestTypes')

  const studentTestTypesObject = useMemo(
    () => ({
      [STUDENT_TEST_TYPES.CARDIAC_TEST]: {
        type: STUDENT_TEST_TYPES.CARDIAC_TEST,
        text: t(STUDENT_TEST_TYPES.CARDIAC_TEST),
      },
      [STUDENT_TEST_TYPES.RUN_TEST]: {
        type: STUDENT_TEST_TYPES.RUN_TEST,
        text: t(STUDENT_TEST_TYPES.RUN_TEST),
      },
    }),
    [t],
  )

  const studentTestTypesArray = useMemo(
    () => [
      {
        type: STUDENT_TEST_TYPES.CARDIAC_TEST,
        text: t(STUDENT_TEST_TYPES.CARDIAC_TEST),
      },
      {
        type: STUDENT_TEST_TYPES.RUN_TEST,
        text: t(STUDENT_TEST_TYPES.RUN_TEST),
      },
    ],
    [t],
  )

  const studentTestTypesLabelsArray = useMemo(
    () => [t(STUDENT_TEST_TYPES.CARDIAC_TEST), t(STUDENT_TEST_TYPES.RUN_TEST)],
    [t],
  )

  return {
    studentTestTypesObject,
    studentTestTypesArray,
    studentTestTypesLabelsArray,
  }
}
