import { useEffect } from 'react'
import StateManager from 'react-select'

import { ReactSelectOption } from 'src/components'

import { EditStudentTrainingBlock } from '../types'

type Params = {
  blocks: EditStudentTrainingBlock[]
  targetBlockRef: React.MutableRefObject<StateManager<ReactSelectOption> | null>
}

export default ({ targetBlockRef, blocks }: Params) => {
  useEffect(() => {
    if (targetBlockRef.current) {
      targetBlockRef.current.select.setValue(null, 'set-value')
    }
  }, [blocks, targetBlockRef])
}
