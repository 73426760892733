import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FiBox, FiCheckCircle, FiFileText } from 'react-icons/fi'

import {
  DefaultError,
  DefaultLabel,
  SimpleModal,
  SnowInput,
  WhiteSpinner,
} from 'src/components'
import { useIsLoading } from 'src/hooks'
import { BLOCK_TYPES, requestAddBlock } from 'src/store/ducks/block'

import { Form, BlockInput, SaveBlockButton } from './styles'

export interface AddNewBlockModalProps {
  isShowing: boolean
  handleHideModal: () => void
  onBlockAdded?: (block: Models.Block) => void
}

export const AddNewBlockModal: React.FC<AddNewBlockModalProps> = (props) => {
  const { isShowing, handleHideModal, onBlockAdded } = props

  const dispatch = useDispatch()

  const { t } = useTranslation([
    'TrainingManagement',
    'TrainingBlock',
    'Glossary',
    'Error',
  ])

  const nameRef = useRef<HTMLInputElement | null>(null)
  const isSaving = useIsLoading(BLOCK_TYPES.REQUEST_ADD)
  const [errors, setErrors] = useState({
    isNameEmpty: false,
  })

  const handleValidate = (): boolean => {
    const name = nameRef.current?.value || ''

    const errorState: typeof errors = {
      isNameEmpty: !name.trim(),
    }

    setErrors(errorState)
    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleSaveNewBlock = (e: React.FormEvent) => {
    e.preventDefault()
    if (handleValidate()) {
      const name = nameRef.current?.value || ''

      dispatch(
        requestAddBlock(
          {
            bloco_descricao: name,
            bloco_situacao: 'S',
          },
          (block) => {
            if (onBlockAdded) onBlockAdded(block)
            handleHideModal()
          },
        ),
      )
    }
  }

  return (
    <SimpleModal
      isShowing={isShowing}
      handleHideModal={handleHideModal}
      title={t('addBlockModalTitle')}
      subtitle={t('addBlockModalSubtitle')}
      iconComponent={<FiBox size="3.2rem" />}
    >
      <Form onSubmit={handleSaveNewBlock} noValidate>
        <BlockInput
          iconComponent={<FiFileText size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="blockName">
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('TrainingBlock:name')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="blockName"
              name="blockName"
              ref={nameRef}
              type="text"
              disabled={isSaving}
              autoCapitalize="words"
              placeholder={t('TrainingBlock:namePh')}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isNameEmpty}
        />

        <SaveBlockButton
          type="submit"
          disabled={isSaving}
          onClick={handleSaveNewBlock}
        >
          <span>{t('saveNewBlockButton')}</span>
          {isSaving ? <WhiteSpinner /> : <FiCheckCircle />}
        </SaveBlockButton>
      </Form>
    </SimpleModal>
  )
}
