import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiCheckCircle, FiEdit, FiFileText, FiLink } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import {
  DefaultError,
  DefaultLabel,
  SnowInput,
  WhiteSpinner,
} from 'src/components'
import { useIsLoading, useTypedSelector } from 'src/hooks'
import {
  ACTIVITY_TYPES,
  requestAddActivity,
  requestEditActivity,
} from 'src/store/ducks/activity'

import { Form, ActivityInput, SaveButton } from './styles'

interface ActivityFormProps {
  nameRef: React.MutableRefObject<HTMLInputElement | null>
  videoRef: React.MutableRefObject<HTMLInputElement | null>
  editingId: number
  setValues: (values: { name?: string; video?: string }) => void
  handleFinishEditing: () => void
}

const ActivityForm: React.FC<ActivityFormProps> = (props) => {
  const { editingId, nameRef, videoRef, setValues, handleFinishEditing } = props

  const dispatch = useDispatch()

  const { t } = useTranslation([
    'ActivityConfig',
    'Activity',
    'Glossary',
    'Error',
  ])

  const isEditing = useMemo(() => {
    return editingId !== 0
  }, [editingId])

  const [errors, setErrors] = useState({
    nameEmpty: false,
  })

  const activities = useTypedSelector(({ Activity }) => Activity.activities)

  const isSaving = useIsLoading(
    ACTIVITY_TYPES.REQUEST_ADD,
    ACTIVITY_TYPES.REQUEST_EDIT,
  )

  const getValues = () => {
    const name = nameRef.current?.value || ''
    const video = videoRef.current?.value || ''
    return { name, video }
  }

  const handleValidate = (): boolean => {
    const { name } = getValues()

    const errorState = {
      nameEmpty: !name.trim(),
    }

    setErrors(errorState)
    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleValidate()) {
      const { name, video } = getValues()

      if (isEditing) {
        const { atividade_situacao = 'S' } =
          activities.find((activity) => activity.atividade_id === editingId) ||
          {}

        dispatch(
          requestEditActivity({
            situation: atividade_situacao,
            id: editingId,
            name,
            video,
          }),
        )

        handleFinishEditing()
      } else {
        dispatch(
          requestAddActivity({
            name,
            video,
          }),
        )

        setValues({})
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <ActivityInput
        iconComponent={<FiFileText size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="name">
            <span className="required">{t('Glossary:required')}</span>
            <span>{t('Activity:name')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowInput
            id="name"
            name="name"
            ref={nameRef}
            type="text"
            disabled={isSaving}
            autoCapitalize="words"
            placeholder={t('Activity:namePh')}
          />
        }
        errorComponent={
          <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
        }
        showError={errors.nameEmpty}
      />

      <ActivityInput
        iconComponent={<FiLink size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="video">
            <span>{t('Activity:videoUrl')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowInput
            id="video"
            name="video"
            ref={videoRef}
            type="text"
            disabled={isSaving}
            autoCapitalize="words"
            placeholder={t('Activity:videoUrlPh')}
          />
        }
      />

      <SaveButton type="submit" disabled={isSaving}>
        <span>{t(isEditing ? 'editButton' : 'saveButton')}</span>
        {isSaving ? (
          <WhiteSpinner />
        ) : (
          <>{isEditing ? <FiEdit /> : <FiCheckCircle />}</>
        )}
      </SaveButton>
    </Form>
  )
}

export default ActivityForm
