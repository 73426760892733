import React from 'react'
import { FiFile } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import {
  usePersistentState,
  useSetBrowserTabTitle,
  useInfinitePagination,
} from 'src/hooks'
import { LOCAL_STORAGE_KEYS } from 'src/config'

import Filters from './Filters'
import { ListViewType } from './types'
import StudentData from './StudentData'
import TrainingList from './TrainingList'
import useUserToFetchTrainings from './useUserToFetchTrainings'
import useClearStateWhenUnmount from './useClearStateWhenUnmount'
import { Container, StyledPageTitle, Divider } from './styles'

const StudentTrainings: React.FC = () => {
  const { t } = useTranslation(['StudentTrainings', 'Training'])
  const { isCurrentUser, userId } = useUserToFetchTrainings()
  const pagination = useInfinitePagination({ limit: 10 })

  const [listViewType, setListViewType] = usePersistentState<ListViewType>({
    key: LOCAL_STORAGE_KEYS.STUDENT_TRAININGS_LIST_VIEW_TYPE,
    defaultValue: 'condensed',
  })

  useSetBrowserTabTitle(isCurrentUser ? 'myTrainings' : 'studentTrainings')
  useClearStateWhenUnmount()

  return (
    <Container>
      <StyledPageTitle
        title={t(isCurrentUser ? 'currentUserTitle' : 'studentTitle')}
        subtitle={t(isCurrentUser ? 'currentUserSubtitle' : 'studentSubtitle')}
        iconComponent={<FiFile />}
      />

      {!isCurrentUser && <StudentData userId={userId} />}

      <Filters
        userId={userId}
        listViewType={listViewType}
        pagination={pagination}
      />

      <Divider />

      <TrainingList
        pagination={pagination}
        listViewType={listViewType}
        setListViewType={setListViewType}
      />
    </Container>
  )
}

export default StudentTrainings
