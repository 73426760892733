import styled from 'styled-components'

import { NoWrapEllipsis } from 'src/styles'

type HeaderTextsProps = {
  hasLeftComponent: boolean
  hasRightComponent: boolean
}

type StatusProps = {
  isActive: boolean
}

export const Container = styled.div`
  border: 2px solid ${(props) => props.theme.snow};
  border-radius: 1rem;
  overflow: hidden;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.snow};

  padding: 1.6rem;
`

export const HeaderTexts = styled.div<HeaderTextsProps>`
  ${NoWrapEllipsis};

  margin-left: ${(props) => (props.hasLeftComponent ? 1.6 : 0)}rem;
  margin-right: ${(props) => (props.hasRightComponent ? 1.6 : 0)}rem;

  flex: 1;
`

export const Title = styled.div`
  font-weight: 900;
  font-size: 1.8rem;
`

export const Subtitle = styled.div``

export const Status = styled.div<StatusProps>`
  font-weight: bold;
  color: ${(props) => {
    return props.isActive ? props.theme.successDark : props.theme.dangerDark
  }};
`

export const Body = styled.div``
