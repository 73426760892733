import { Reducer } from 'redux'

import { ANAMNESE_QUIZ_ANSWER_TYPES, AnamneseQuizAnswerState } from './types'

export const initialState: AnamneseQuizAnswerState = {
  hasAnsweredAnamnese: false,
  answers: [],
}

const reducer: Reducer<AnamneseQuizAnswerState> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action

  switch (type) {
    case ANAMNESE_QUIZ_ANSWER_TYPES.SET_QUIZ_ANSWERS: {
      return {
        ...state,
        answers: payload.answers,
      }
    }

    case ANAMNESE_QUIZ_ANSWER_TYPES.SET_ANSWERED: {
      return {
        ...state,
        hasAnsweredAnamnese: payload.hasAnsweredAnamnese,
      }
    }

    default:
      return state
  }
}

export default reducer
