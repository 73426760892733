import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  TRAINING_SHEET_ROUTES,
  Server,
} from 'src/services/Server'

import { updateTrainingSheetData } from './actions'
import {
  RequestChangeTrainingSheetStatusAction,
  TRAINING_SHEET_TYPES,
} from './types'

type DataToEdit = Pick<Models.Training, 'treino_id' | 'treino_situacao'>

export function* requestChangeTrainingSheetStatusWatcher() {
  yield takeLatest(
    TRAINING_SHEET_TYPES.REQUEST_CHANGE_STATUS,
    handleChangeTrainingSheetStatus,
  )
}

export function* handleChangeTrainingSheetStatus(
  action: RequestChangeTrainingSheetStatusAction,
) {
  try {
    yield put(addLoading(TRAINING_SHEET_TYPES.REQUEST_CHANGE_STATUS))
    const { id, isActive } = action.payload

    const dataToEdit: DataToEdit = {
      treino_id: id,
      treino_situacao: isActive ? 'AT' : 'NT',
    }

    const {
      data,
    }: AxiosServerResponse<Models.TrainingGroupedTypes.TrainingSheet> = yield call(
      Server.post,
      TRAINING_SHEET_ROUTES.PATCH,
      dataToEdit,
    )

    if (data.isSuccessful) {
      yield put(
        updateTrainingSheetData({
          treino_situacao: dataToEdit.treino_situacao,
        }),
      )
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(TRAINING_SHEET_TYPES.REQUEST_CHANGE_STATUS))
  }
}
