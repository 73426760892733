import React from 'react'
import { BsPeople } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { FiFile, FiHome, FiUser } from 'react-icons/fi'

import { DrawerItem } from 'src/components'

import useCheckIfSelected from './useCheckIfSelected'
import { Group, GroupLabel } from './styles'

const StudentItems: React.FC = () => {
  const { t } = useTranslation('Main')
  const handleCheckIfSelected = useCheckIfSelected()

  return (
    <>
      <Group>
        <GroupLabel>{t('labels.general')}</GroupLabel>

        <DrawerItem
          to="/"
          text={t('drawer.home')}
          iconComponent={<FiHome />}
          selected={handleCheckIfSelected('/', true)}
        />

        <DrawerItem
          to="/user-profile"
          text={t('drawer.userProfile')}
          iconComponent={<FiUser />}
          selected={handleCheckIfSelected('/user-profile')}
        />
      </Group>

      <Group>
        <GroupLabel>{t('labels.training')}</GroupLabel>

        <DrawerItem
          to="/my-trainings"
          text={t('drawer.myTrainings')}
          iconComponent={<FiFile />}
          selected={handleCheckIfSelected('my-trainings')}
        />
      </Group>

      <Group>
        <GroupLabel>{t('labels.runningGroups')}</GroupLabel>

        <DrawerItem
          to="/my-running-groups"
          text={t('drawer.myRunningGroups')}
          iconComponent={<BsPeople />}
          selected={handleCheckIfSelected('my-running-groups')}
        />
      </Group>
    </>
  )
}

export default StudentItems
