import styled from 'styled-components'

import { StudentButton } from 'src/components'

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.student};
  border-radius: 1rem;
  overflow: hidden;

  padding: 1.6rem;
  margin-bottom: 2.4rem;
`

export const Title = styled.div`
  color: ${(props) => props.theme.student};
  font-size: 2.4rem;
  font-weight: 900;

  display: flex;
  align-items: center;

  margin-bottom: 1.6rem;

  span {
    margin-left: 1.6rem;
  }
`

export const AnswerAnamneseButton = styled(StudentButton)`
  width: auto;

  padding: 0 2.4rem;
`
