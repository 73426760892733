import styled from 'styled-components'

export const Content = styled.div`
  display: none;

  position: absolute;
  z-index: 1;

  background: ${(props) => props.theme.background};

  box-shadow: 0 0 3px 1px ${(props) => props.theme.hover};
  border-radius: 1rem;
  overflow: hidden;
`

export const Container = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    ${Content} {
      display: block;
    }
  }
`
