import styled from 'styled-components'

import { Input, PageContainer, PageTitle, SuccessButton } from 'src/components'

export const Container = styled(PageContainer)``

export const ChangePasswordTitle = styled(PageTitle)`
  margin-bottom: 4.8rem;
`

export const Form = styled.form`
  width: auto;
  max-width: 60rem;

  margin: auto;
`

export const PasswordInput = styled(Input)`
  margin-bottom: 2.4rem;
`

export const SaveButton = styled(SuccessButton)`
  margin-bottom: 2.4rem;
`
