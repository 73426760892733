import React from 'react'

import { useAuthenticatedUserTypes } from 'src/hooks'

import AdminItems from './AdminItems'
import TeacherItems from './TeacherItems'
import StudentItems from './StudentItems'

const DrawerItems: React.FC = () => {
  const { isAdmin, isStudent, isTeacher } = useAuthenticatedUserTypes()

  if (isAdmin) return <AdminItems />
  else if (isTeacher) return <TeacherItems />
  else if (isStudent) return <StudentItems />
  else return null
}

export default DrawerItems
