import styled, { css } from 'styled-components'

import { OutlinedButton, Input, SmallButton } from 'src/components'

const Spacing = css`
  padding: 6.4rem 2.4rem;

  @media only screen and (max-width: 768px) {
    padding: 2.4rem;
  }
`

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.background};
`

export const Form = styled.form`
  max-width: 50rem;
  ${Spacing};
`

export const Title = styled.h1`
  margin: 0 0 0.8rem 0;
  font-weight: 900;
`

export const Subtitle = styled.div`
  color: ${(props) => props.theme.secondaryText};
  margin-bottom: 3.2rem;
`

export const EmailInput = styled(Input)`
  margin-bottom: 2.4rem;
`

export const SendButton = styled(OutlinedButton)``

export const SuccessContainer = styled.div`
  ${Spacing};
`

export const SuccessTitle = styled.h1`
  color: ${(props) => props.theme.success};
  margin-bottom: 0.8rem;
  padding: 0.8rem 1.6rem;
  display: flex;
  align-items: center;
  background: rgba(0, 255, 0, 0.1);
  border-radius: 1rem;
  border: 1px solid ${(props) => props.theme.success};

  span {
    margin-left: 1.6rem;
  }
`

export const SuccessMessage = styled.div`
  color: ${(props) => props.theme.secondaryText};
  margin-bottom: 2.4rem;
`

export const BackButton = styled(SmallButton)`
  width: auto;

  span {
    margin-right: 0;
    margin-left: 1.6rem;
  }
`
