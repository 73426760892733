import React from 'react'
import { createPortal } from 'react-dom'

import { usePortal } from 'src/hooks'

export interface PortalProps {
  id: string
  children: any
}

export const Portal: React.FC<PortalProps> = (props) => {
  const { id, children } = props
  const target = usePortal(id)
  return createPortal(children, target)
}

