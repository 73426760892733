import React from 'react'

import { Container, Content } from './styles'

export interface ListItemProps {
  className?: string
  contentClassName?: string
  style?: React.CSSProperties
  contentStyle?: React.CSSProperties
  leftComponent?: React.ReactNode
  rightComponent?: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void
  tabIndex?: number
  children?: any
}

export const ListItem: React.FC<ListItemProps> = (props) => {
  const {
    style,
    contentStyle,
    className,
    contentClassName,
    onClick,
    onFocus,
    leftComponent,
    rightComponent,
    children,
    tabIndex,
  } = props

  return (
    <Container
      style={style}
      className={className}
      onClick={onClick}
      onFocus={onFocus}
      tabIndex={tabIndex}
    >
      {leftComponent}

      <Content
        className={contentClassName}
        style={contentStyle}
        hasLeftComponent={!!leftComponent}
        hasRightComponent={!!rightComponent}
      >
        {children}
      </Content>

      {rightComponent}
    </Container>
  )
}

