import { useEffect } from 'react'
import StateManager from 'react-select'

import { ReactSelectOption } from 'src/components'
import { useTypedSelector } from 'src/hooks'

import { EditStudentTrainingBlock } from '../types'

type Params = {
  setBlocks: (blocks: EditStudentTrainingBlock[]) => void
  trainingLevelRef: React.MutableRefObject<StateManager<ReactSelectOption> | null>
}

export default ({ setBlocks, trainingLevelRef }: Params) => {
  const studentTrainingData = useTypedSelector(
    ({ StudentTraining }) => StudentTraining.trainingData,
  )

  const trainingLevels = useTypedSelector(
    ({ TrainingLevel }) => TrainingLevel.trainingLevels,
  )

  useEffect(() => {
    if (studentTrainingData) {
      setBlocks(studentTrainingData.blocos || [])

      const trainingLevelId = Number(studentTrainingData.treinonivel_id)
      const trainingLevel = trainingLevels.find(
        ({ treinonivel_id }) => treinonivel_id === trainingLevelId,
      )

      if (trainingLevel && trainingLevelRef.current) {
        const trainingSelectOption: ReactSelectOption = {
          label: trainingLevel.treinonivel_descricao,
          value: String(trainingLevel.treinonivel_id),
        }

        trainingLevelRef.current.select.setValue(
          trainingSelectOption,
          'set-value',
        )
      }
    }
  }, [setBlocks, trainingLevelRef, trainingLevels, studentTrainingData])
}
