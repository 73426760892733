import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-areas: 'dataSection mainSection';
  grid-template-rows: 1fr;
  grid-template-columns: 6fr 4fr;

  min-height: 100%;

  @media only screen and (max-width: 1200px) {
    grid-template-areas: 'mainSection' 'dataSection';
    grid-template-rows: auto auto;
    grid-template-columns: auto;
  }
`
