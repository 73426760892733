import React from 'react'

import {
  Container,
  Header,
  Body,
  Repeat,
  Content,
  EditingText,
  HeaderTitle,
} from './styles'

export interface TrainingBlockProps {
  className?: string
  style?: React.CSSProperties
  name: string
  repeat?: number
  isEditing?: boolean
  editingText?: string
  headerLeftComponent?: React.ReactNode
  headerRightComponent?: React.ReactNode
  children?: any
}

export const TrainingBlock: React.FC<TrainingBlockProps> = (props) => {
  const {
    className,
    style,
    children,
    name,
    repeat = 1,
    isEditing = false,
    editingText,
    headerLeftComponent,
    headerRightComponent,
  } = props

  return (
    <Container className={className} style={style} isEditing={isEditing}>
      {isEditing && !!editingText && <EditingText>{editingText}</EditingText>}

      <Header>
        {headerLeftComponent}

        <HeaderTitle
          hasLeftComponent={!!headerLeftComponent}
          hasRightComponent={!!headerRightComponent}
        >
          {name}
        </HeaderTitle>

        {headerRightComponent}
      </Header>

      <Body>
        <Repeat>{repeat}x</Repeat>
        <Content>{children}</Content>
      </Body>
    </Container>
  )
}

