import styled from 'styled-components'

import { PageContainer } from 'src/components'

export const Container = styled(PageContainer)``

export const SectionGroup = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  > * {
    flex: 1;
    min-width: 30rem;
  }
`
