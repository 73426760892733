import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { usePrevious } from 'src/hooks'
import {
  setFullStudentTrainingList,
  setStudentTrainingList,
} from 'src/store/ducks/studentTraining'

export default () => {
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const previousPath = usePrevious(path)

  useEffect(() => {
    const handleClearState = () => {
      dispatch(setStudentTrainingList([]))
      dispatch(setFullStudentTrainingList([]))
    }

    // Quando se está vendo os treinos de um aluno e clica na tela "Meus Treinos"
    // A tela muda mas ela não é desmontada, então é preciso limpar as listas
    if (previousPath !== path) {
      handleClearState()
    }

    return handleClearState
  }, [dispatch, path, previousPath])
}
