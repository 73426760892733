import React from 'react'
import { useTranslation } from 'react-i18next'

import { StudentBadge, TeacherBadge, AdminBadge } from 'src/components'
import { useTypedSelector } from 'src/hooks'

import {
  Container,
  UserAvatar,
  UserData,
  UserName,
  UserEmail,
  Badges,
  BadgeContainer,
} from './styles'

export interface DrawerHeaderProps {
  style?: React.CSSProperties
  className?: string
}

const DrawerHeader: React.FC<DrawerHeaderProps> = (props) => {
  const { className, style } = props

  const { t } = useTranslation('Glossary')

  const userData = useTypedSelector(({ Auth }) => Auth.userData)

  return (
    <Container style={style} className={className}>
      <UserAvatar alt={userData?.usuario_nome || ''} />

      <UserData>
        <UserName>{userData?.usuario_nome}</UserName>
        <UserEmail>{userData?.usuario_email}</UserEmail>

        <Badges>
          {userData?.usuario_professor === 'S' && (
            <BadgeContainer>
              <TeacherBadge size="small">{t('teacher')}</TeacherBadge>
            </BadgeContainer>
          )}

          {userData?.usuario_aluno === 'S' && (
            <BadgeContainer>
              <StudentBadge size="small">{t('student')}</StudentBadge>
            </BadgeContainer>
          )}

          {userData?.usuario_administrador === 'S' && (
            <BadgeContainer>
              <AdminBadge size="small">{t('admin')}</AdminBadge>
            </BadgeContainer>
          )}
        </Badges>
      </UserData>
    </Container>
  )
}

export default DrawerHeader
