import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  addObserver,
  ObservableTopics,
  removeObserver,
} from 'src/store/ducks/observable'

type Params = {
  topic: ObservableTopics
  observerId: string
  callback: () => void
}

export default ({ topic, observerId, callback }: Params) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(addObserver({ topic, observerId, callback }))

    return () => {
      dispatch(removeObserver({ topic, observerId }))
    }
  }, [callback, dispatch, observerId, topic])
}
