import React, { useCallback } from 'react'
import { FiHeart, FiPlayCircle } from 'react-icons/fi'

import { Container, ActivityName, BpmValue, Repeat, Value } from './styles'

export interface TrainingActivityProps {
  name: string
  url?: string
  repeat?: number
  minBpm?: number
  maxBpm?: number
  className?: string
  pace?: number | string
  value?: string | number
  isCalculatedBpm?: boolean
  style?: React.CSSProperties
  onClick?: (e: React.MouseEvent) => void
}

export const TrainingActivity: React.FC<TrainingActivityProps> = (props) => {
  const {
    className,
    style,
    name,
    url,
    repeat = 1,
    maxBpm,
    onClick,
    minBpm,
    value,
    pace,
    isCalculatedBpm = false,
  } = props

  const getBpmValue = useCallback((): string => {
    if (isCalculatedBpm) return `${minBpm} - ${maxBpm}`
    return `${minBpm}% - ${maxBpm}%`
  }, [isCalculatedBpm, maxBpm, minBpm])

  return (
    <Container className={className} style={style}>
      <Repeat>{repeat}x</Repeat>
      <ActivityName>{name}</ActivityName>
      {!!url && (
        <Value>
          <FiPlayCircle onClick={onClick} />
        </Value>
      )}
      {!!value && <Value>{value}</Value>}
      {!!pace && <Value>{`Pace: ${pace}`}</Value>}

      {!!minBpm && !!maxBpm && (
        <BpmValue>
          <FiHeart />
          <span>{getBpmValue()}</span>
        </BpmValue>
      )}
    </Container>
  )
}
