import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Home from 'src/pages/Home'
import UserProfile from 'src/pages/UserProfile'
import PageNotFound from 'src/pages/PageNotFound'
import StudentTrainings from 'src/pages/StudentTrainings'
import MyRunningGroups from 'src/pages/MyRunningGroups'
import StudentTrainingDetails from 'src/pages/StudentTrainingDetails'
import RunningGroupDetails from 'src/pages/RunningGroupDetails'
import EditUserProfile from 'src/pages/EditUserProfile'
import ChangePassword from 'src/pages/ChangePassword'
import UserPublicProfile from 'src/pages/UserPublicProfile'
import AnswerAnamneseQuiz from 'src/pages/AnswerAnamneseQuiz'
import AnamneseAnswers from 'src/pages/AnamneseAnswers'

const StudentRoutes: React.FC = () => {
  return (
    <Switch>
      <Route component={Home} path="/" exact />

      <Route component={UserProfile} path="/user-profile" />
      <Route component={UserPublicProfile} path="/user-public-profile/:id" />
      <Route component={EditUserProfile} path="/edit-user-profile" />
      <Route component={ChangePassword} path="/change-password" />

      <Route component={StudentTrainings} path="/my-trainings" />
      <Route component={StudentTrainingDetails} path="/student-training/:id" />

      <Route component={MyRunningGroups} path="/my-running-groups" />
      <Route component={RunningGroupDetails} path="/running-group/:id" />

      <Route component={AnswerAnamneseQuiz} path="/answer-anamnese" />
      <Route component={AnamneseAnswers} path="/anamnese-answers/:id" />

      <Route component={PageNotFound} />
    </Switch>
  )
}

export default StudentRoutes
