import styled from 'styled-components'

import {
  SuccessButton,
  Input,
  PageContainer,
  PageTitle,
  SmallButton,
} from 'src/components'

export const Container = styled(PageContainer)``

export const CreateRunningGroupTitle = styled(PageTitle)`
  margin-bottom: 4.8rem;
`

export const Form = styled.form`
  margin: auto;

  max-width: 60rem;
`

export const ImageContainer = styled.div`
  display: flex;
`

export const Image = styled.img`
  width: 15rem;
  height: 15rem;

  border-radius: 1rem;
  border: 1px solid transparent;

  margin-bottom: 2.4rem;

  cursor: pointer;
  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-color: ${(props) => props.theme.border};
  }
`

export const ImageInfo = styled.div`
  flex: 1;

  padding: 0 2.4rem;

  div {
    margin: 0 0 0.8rem 0;
  }
`

export const AddImageButton = styled(SmallButton)`
  width: auto;

  margin-bottom: 0.8rem;
`

export const RemoveImageButton = styled(SmallButton)`
  width: auto;

  background: ${(props) => props.theme.background};
  border: 2px solid ${(props) => props.theme.danger};
  color: ${(props) => props.theme.danger};

  &:hover,
  &:focus {
    color: white;
    background: ${(props) => props.theme.danger};
  }
`

export const FormInput = styled(Input)`
  margin-bottom: 2.4rem;
`

export const SaveButton = styled(SuccessButton)`
  margin-bottom: 2.4rem;
`
