export enum ANAMNESE_QUIZ_ANSWER_TYPES {
  REQUEST_FETCH_ANSWERS = '@ANAMNESE_QUIZ_ANSWER/REQUEST_FETCH_ANSWERS',
  REQUEST_ANSWER_QUIZ = '@ANAMNESE_QUIZ_ANSWER/REQUEST_ANSWER_QUIZ',
  REQUEST_CHECK_ANSWER = '@ANAMNESE_QUIZ_ANSWER/REQUEST_CHECK_ANSWER',

  SET_QUIZ_ANSWERS = '@ANAMNESE_QUIZ_ANSWER/SET_QUIZ_ANSWERS',
  SET_ANSWERED = '@ANAMNESE_QUIZ_ANSWER/SET_ANSWERED',
}

export type Answer = Pick<
  Models.GroupStudentAnamneseAnswer,
  'anamipergunta_id' | 'anamineseresp_resposta'
>

export interface AnamneseQuizAnswerState {
  hasAnsweredAnamnese: boolean
  answers: Models.AnamneseGroupedTypes.AnamneseFullAnswer[]
}

export interface RequestFetchAnamneseQuizAnswersAction {
  type: ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_FETCH_ANSWERS
  payload: {
    userId: number
  }
}

export interface RequestAnswerAnamneseQuizAction {
  type: ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_ANSWER_QUIZ
  payload: {
    answers: Answer[]
    anamneseId: number
    groupAndStudentId: number
  }
}

export interface RequestCheckAnamneseAnswerAction {
  type: ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_CHECK_ANSWER
  payload: {
    studentId: number
  }
}

export interface SetAnamneseQuizAnswersAction {
  type: ANAMNESE_QUIZ_ANSWER_TYPES.SET_QUIZ_ANSWERS
  payload: {
    answers: Models.AnamneseGroupedTypes.AnamneseFullAnswer[]
  }
}

export interface SetHasAnsweredAnamneseAction {
  type: ANAMNESE_QUIZ_ANSWER_TYPES.SET_ANSWERED
  payload: {
    hasAnsweredAnamnese: boolean
  }
}
