import { Reducer } from 'redux'

import { StudentTrainingState, STUDENT_TRAINING_TYPES } from './types'

export const initialState: StudentTrainingState = {
  trainings: [],
  fullTrainings: [],
}

const reducer: Reducer<StudentTrainingState> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action

  switch (type) {
    case STUDENT_TRAINING_TYPES.SET_LIST: {
      if (payload.shouldAppendInTheList) {
        return {
          ...state,
          trainings: [...state.trainings, ...payload.trainings],
        }
      }

      return {
        ...state,
        trainings: payload.trainings,
      }
    }

    case STUDENT_TRAINING_TYPES.SET_LIST_FULL: {
      if (payload.shouldAppendInTheList) {
        return {
          ...state,
          fullTrainings: [...state.fullTrainings, ...payload.fullTrainings],
        }
      }

      return {
        ...state,
        fullTrainings: payload.fullTrainings,
      }
    }

    case STUDENT_TRAINING_TYPES.SET_DATA: {
      return {
        ...state,
        trainingData: payload.trainingData,
      }
    }

    default:
      return state
  }
}

export default reducer
