import { useCallback, useEffect, useState } from 'react'

export default <T extends string | object>({
  key,
  defaultValue,
  isJson = false,
}: {
  key: string
  defaultValue?: T
  isJson?: boolean
}): [
  T | undefined,
  React.Dispatch<React.SetStateAction<T | undefined>>,
  () => void,
] => {
  const [value, setValue] = useState<T | undefined>((): T | undefined => {
    const data = localStorage.getItem(key)
    if (!data) return defaultValue

    if (isJson) {
      try {
        return JSON.parse(data)
      } catch (e) {
        return defaultValue
      }
    }

    return (data as T) || defaultValue
  })

  const handleClearValue = useCallback(() => {
    localStorage.removeItem(key)
  }, [key])

  useEffect(() => {
    if (value) {
      if (isJson) {
        const json = JSON.stringify(value)
        localStorage.setItem(key, json)
      } else if (typeof value === 'string') {
        localStorage.setItem(key, value)
      }
    } else {
      localStorage.removeItem(key)
    }
  }, [isJson, key, value])

  return [value, setValue, handleClearValue]
}
