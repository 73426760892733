import { DateTime } from 'luxon'

const DEFAULT_FILTERS = {
  INITIAL_DATE: DateTime.local().toFormat('dd/MM/yyyy'),
  INITIAL_DATE_FORMATTED: DateTime.local().toFormat('yyyy-MM-dd'),

  FINAL_DATE: DateTime.local().plus({ days: 6 }).toFormat('dd/MM/yyyy'),
  FINAL_DATE_FORMATTED: DateTime.local()
    .plus({ days: 6 })
    .toFormat('yyyy-MM-dd'),
}

export default DEFAULT_FILTERS
