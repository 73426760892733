import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  TRAINING_ZONE_ROUTES,
  Server,
} from 'src/services/Server'
import { withServerStructure } from 'src/utils'

import { RequestEditTrainingZoneAction, TRAINING_ZONE_TYPES } from './types'
import { editTrainingZoneInList } from './actions'

export function* requestEditTrainingZoneWatcher() {
  yield takeLatest(TRAINING_ZONE_TYPES.REQUEST_EDIT, handleEditTrainingZone)
}

export function* handleEditTrainingZone(action: RequestEditTrainingZoneAction) {
  try {
    yield put(addLoading(TRAINING_ZONE_TYPES.REQUEST_EDIT))

    const { data }: AxiosServerResponse<Models.TrainingZone> = yield call(
      Server.post,
      TRAINING_ZONE_ROUTES.ZONA_TREINAMENTO,
      withServerStructure(action.payload),
    )

    if (data.isSuccessful) {
      const trainingZone = data.getFirstData()
      if (trainingZone) yield put(editTrainingZoneInList(trainingZone))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(TRAINING_ZONE_TYPES.REQUEST_EDIT))
  }
}
