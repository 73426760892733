import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  TRAINING_LEVEL_ROUTES,
  Server,
} from 'src/services/Server'
import { withServerStructure } from 'src/utils'

import { RequestAddTrainingLevelAction, TRAINING_LEVEL_TYPES } from './types'
import { addInTrainingLevelList } from './actions'

export function* requestAddTrainingLevelWatcher() {
  yield takeLatest(TRAINING_LEVEL_TYPES.REQUEST_ADD, handleAddTrainingLevel)
}

export function* handleAddTrainingLevel(action: RequestAddTrainingLevelAction) {
  try {
    yield put(addLoading(TRAINING_LEVEL_TYPES.REQUEST_ADD))
    const { name, sequence, type } = action.payload

    const dataToAdd: Omit<
      Models.TrainingLevel,
      'treinonivel_id' | 'atividade_linkvideopadrao'
    > = {
      treinonivel_descricao: name,
      treinonivel_sequencia: sequence,
      treinonivel_tipo: type,
    }

    const { data }: AxiosServerResponse<Models.TrainingLevel> = yield call(
      Server.put,
      TRAINING_LEVEL_ROUTES.TREINO_NIVEL,
      withServerStructure(dataToAdd),
    )

    if (data.isSuccessful) {
      const trainingLevel = data.getFirstData()
      if (trainingLevel) yield put(addInTrainingLevelList(trainingLevel))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(TRAINING_LEVEL_TYPES.REQUEST_ADD))
  }
}
