import React, { useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FiCheckCircle, FiEdit } from 'react-icons/fi'

import { WhiteSpinner } from 'src/components'
import { requestEditUserProfile, USER_TYPES } from 'src/store/ducks/user'
import {
  useIsLoading,
  useSetBrowserTabTitle,
  useTypedSelector,
} from 'src/hooks'

import UserNameInput from './UserNameInput'
import UserEmailInput from './UserEmailInput'
import UserPhoneInput from './UserPhoneInput'
import UserProfileImage from './UserProfileImage'
import UserBirthDateInput from './UserBirthDate'
import UserCpfInput from './UserCpfInput'
import UserCnpjInput from './UserCnpjInput'
import { Container, EditProfileTitle, Form, EditButton } from './styles'

const EditUserProfile: React.FC = () => {
  useSetBrowserTabTitle('editUserProfile')

  const { t } = useTranslation(['EditUserProfile', 'Glossary', 'Error', 'User'])
  const dispatch = useDispatch()
  const history = useHistory()

  const imageRef = useRef<HTMLInputElement | null>(null)
  const nameRef = useRef<HTMLInputElement | null>(null)
  const emailRef = useRef<HTMLInputElement | null>(null)
  const phoneRef = useRef<HTMLInputElement | null>(null)
  const birthDateRef = useRef<HTMLInputElement | null>(null)
  const cpfRef = useRef<HTMLInputElement | null>(null)
  const cnpjRef = useRef<HTMLInputElement | null>(null)
  const [imageFile, setImageFile] = useState<File | null>(null)

  const isLoading = useIsLoading(USER_TYPES.REQUEST_EDIT_USER_PROFILE)
  const userData = useTypedSelector(({ Auth }) => Auth.userData)

  const [errors, setErrors] = useState({
    isNameEmpty: false,
    isEmailEmpty: false,
  })

  const getFormattedDate = (date: string | undefined) => {
    if (!date) return ''
    return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('dd/MM/yyyy')
  }

  const handleFormatDate = (date: string | undefined) => {
    if (!date) return date
    return DateTime.fromFormat(date, 'dd/MM/yyyy').toSQLDate()
  }

  const getValues = () => {
    const name = nameRef.current?.value || ''
    const email = emailRef.current?.value || ''
    const phone = phoneRef.current?.value
    const birthDate = birthDateRef.current?.value
    const cpf = cpfRef.current?.value
    const cnpj = cnpjRef.current?.value

    return {
      name,
      email,
      phone,
      birthDate,
      cpf,
      cnpj,
    }
  }

  const handleValidate = (): boolean => {
    const { name, email } = getValues()

    const errorState: typeof errors = {
      isNameEmpty: !name.trim(),
      isEmailEmpty: !email.trim(),
    }

    setErrors(errorState)
    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (handleValidate()) {
      const { name, email, phone, birthDate, cpf, cnpj } = getValues()

      const formattedBirthDate = handleFormatDate(birthDate)

      dispatch(
        requestEditUserProfile({
          isUpdatingCurrentLoggedUser: true,
          id: userData?.usuario_id || 0,
          birthDate: formattedBirthDate,
          image: imageFile,
          name,
          email,
          phone,
          cpf,
          cnpj,

          successCallback() {
            history.push('/user-profile')
          },
        }),
      )
    }
  }

  const handleSelectImage = () => {
    imageRef.current?.click()
  }

  const handleImageSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [selectedImage] = e.target.files || []
    if (selectedImage) setImageFile(selectedImage)
  }

  const handleRemoveImage = () => {
    if (imageRef.current) imageRef.current.value = ''
    setImageFile(null)
  }

  return (
    <Container>
      <EditProfileTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<FiEdit />}
      />

      <Form onSubmit={handleSubmit} noValidate>
        <UserProfileImage
          handleImageSelected={handleImageSelected}
          handleRemoveImage={handleRemoveImage}
          handleSelectImage={handleSelectImage}
          userName={userData?.usuario_nome}
          imageFile={imageFile}
          ref={imageRef}
        />

        <UserNameInput
          ref={nameRef}
          isNameEmpty={errors.isNameEmpty}
          defaultName={userData?.usuario_nome}
        />

        <UserEmailInput
          ref={emailRef}
          isEmailEmpty={errors.isEmailEmpty}
          defaultEmail={userData?.usuario_email}
        />

        <UserPhoneInput
          ref={(ref) => (phoneRef.current = ref)}
          defaultPhone={userData?.usuario_celular}
        />

        <UserBirthDateInput
          ref={(ref) => (birthDateRef.current = ref)}
          defaultBirthDate={getFormattedDate(userData?.usuario_dtnascimento)}
        />

        <UserCpfInput
          ref={(ref) => (cpfRef.current = ref)}
          defaultCpf={userData?.usuario_cpf}
        />

        <UserCnpjInput
          ref={(ref) => (cnpjRef.current = ref)}
          defaultCnpj={userData?.usuario_cnpj}
        />

        <EditButton type="submit" disabled={isLoading}>
          <span>{t('editButton')}</span>
          {isLoading ? <WhiteSpinner /> : <FiCheckCircle />}
        </EditButton>
      </Form>
    </Container>
  )
}

export default EditUserProfile
