import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  FiCheckCircle,
  FiEdit,
  FiFileText,
  FiMinusCircle,
  FiPlusCircle,
} from 'react-icons/fi'

import {
  DefaultError,
  DefaultLabel,
  SnowInput,
  WhiteSpinner,
} from 'src/components'
import {
  requestAddTrainingZone,
  requestEditTrainingZone,
  TRAINING_ZONE_TYPES,
} from 'src/store/ducks/trainingZone'
import { useIsLoading } from 'src/hooks'

import { Form, TrainingZoneInput, SaveButton } from './styles'

interface TrainingZoneFormProps {
  nameRef: React.MutableRefObject<HTMLInputElement | null>
  minBpmRef: React.MutableRefObject<HTMLInputElement | null>
  maxBpmRef: React.MutableRefObject<HTMLInputElement | null>
  editingId: number
  setValues: (values: {
    name?: string
    minBpm?: string
    maxBpm?: string
  }) => void
  handleFinishEditing: () => void
}

const TrainingZoneForm: React.FC<TrainingZoneFormProps> = (props) => {
  const {
    nameRef,
    minBpmRef,
    maxBpmRef,
    editingId,
    setValues,
    handleFinishEditing,
  } = props

  const dispatch = useDispatch()

  const { t } = useTranslation([
    'TrainingZoneConfig',
    'TrainingZone',
    'Glossary',
    'Error',
  ])

  const isSaving = useIsLoading(
    TRAINING_ZONE_TYPES.REQUEST_ADD,
    TRAINING_ZONE_TYPES.REQUEST_EDIT,
  )

  const isEditing = useMemo(() => {
    return editingId !== 0
  }, [editingId])

  const [errors, setErrors] = useState({
    nameEmpty: false,
    minBpmEmpty: false,
    maxBpmEmpty: false,
  })

  const getValues = () => {
    const name = nameRef.current?.value || ''
    const minBpm = minBpmRef.current?.value || ''
    const maxBpm = maxBpmRef.current?.value || ''
    return { name, minBpm, maxBpm }
  }

  const handleValidate = (): boolean => {
    const { name, minBpm, maxBpm } = getValues()

    const errorState = {
      nameEmpty: !name.trim(),
      minBpmEmpty: !minBpm.trim(),
      maxBpmEmpty: !maxBpm.trim(),
    }

    setErrors(errorState)
    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleValidate()) {
      const { name, minBpm, maxBpm } = getValues()

      if (isEditing) {
        dispatch(
          requestEditTrainingZone({
            zonatrein_id: editingId,
            zonatrein_descricao: name,
            zonatrein_bpmminimo: Number(minBpm),
            zonatrein_bpmmaximo: Number(maxBpm),
          }),
        )

        handleFinishEditing()
      } else {
        dispatch(
          requestAddTrainingZone({
            zonatrein_descricao: name,
            zonatrein_bpmminimo: Number(minBpm),
            zonatrein_bpmmaximo: Number(maxBpm),
          }),
        )

        setValues({})
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <TrainingZoneInput
        iconComponent={<FiFileText size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="name">
            <span className="required">{t('Glossary:required')}</span>
            <span>{t('TrainingZone:name')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowInput
            id="name"
            name="name"
            ref={nameRef}
            type="text"
            disabled={isSaving}
            autoCapitalize="words"
            placeholder={t('TrainingZone:namePh')}
          />
        }
        errorComponent={
          <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
        }
        showError={errors.nameEmpty}
      />

      <TrainingZoneInput
        iconComponent={<FiMinusCircle size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="minBpm">
            <span className="required">{t('Glossary:required')}</span>
            <span>{t('TrainingZone:minBpm')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowInput
            id="minBpm"
            name="minBpm"
            ref={minBpmRef}
            type="number"
            min={0}
            max={100}
            disabled={isSaving}
            placeholder={t('TrainingZone:minBpmPh')}
          />
        }
        errorComponent={
          <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
        }
        showError={errors.minBpmEmpty}
      />

      <TrainingZoneInput
        iconComponent={<FiPlusCircle size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="maxBpm">
            <span className="required">{t('Glossary:required')}</span>
            <span>{t('TrainingZone:maxBpm')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowInput
            id="maxBpm"
            name="maxBpm"
            ref={maxBpmRef}
            type="number"
            min={0}
            max={100}
            disabled={isSaving}
            placeholder={t('TrainingZone:maxBpmPh')}
          />
        }
        errorComponent={
          <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
        }
        showError={errors.maxBpmEmpty}
      />

      <SaveButton type="submit" disabled={isSaving}>
        <span>{t(isEditing ? 'editButton' : 'saveButton')}</span>
        {isSaving ? (
          <WhiteSpinner />
        ) : (
          <>{isEditing ? <FiEdit /> : <FiCheckCircle />}</>
        )}
      </SaveButton>
    </Form>
  )
}

export default TrainingZoneForm
