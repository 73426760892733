import Glossary from './Glossary.json'
import Common from './Common.json'
import Error from './Error.json'
import Success from './Success.json'
import Login from './Login.json'
import ForgotPassword from './ForgotPassword.json'
import PageNotFound from './PageNotFound.json'
import CreateAccount from './CreateAccount.json'
import Main from './Main.json'
import UserProfile from './UserProfile.json'
import User from './User.json'
import MyStudents from './MyStudents.json'
import Home from './Home.json'
import NewStudent from './NewStudent.json'
import CreateRunningGroup from './CreateRunningGroup.json'
import RunningGroup from './RunningGroup.json'
import MyRunningGroups from './MyRunningGroups.json'
import StudentTrainings from './StudentTrainings.json'
import Training from './Training.json'
import EditUserProfile from './EditUserProfile.json'
import ChangePassword from './ChangePassword.json'
import RunningGroupDetails from './RunningGroupDetails.json'
import TrainingLevelConfig from './TrainingLevelConfig.json'
import TrainingLevel from './TrainingLevel.json'
import Activity from './Activity.json'
import ActivityConfig from './ActivityConfig.json'
import TrainingZone from './TrainingZone.json'
import TrainingZoneConfig from './TrainingZoneConfig.json'
import AnamneseConfig from './AnamneseConfig.json'
import Anamnese from './Anamnese.json'
import TrainingConfig from './TrainingConfig.json'
import Units from './Units.json'
import TrainingBlock from './TrainingBlock.json'
import TrainingBlockConfig from './TrainingBlockConfig.json'
import AnswerAnamneseQuiz from './AnswerAnamneseQuiz.json'
import UserPublicProfile from './UserPublicProfile.json'
import RegisterUser from './RegisterUser.json'
import SearchUser from './SearchUser.json'
import EditRunningGroup from './EditRunningGroup.json'
import PageTitles from './PageTitles.json'
import StudentTrainingConfig from './StudentTrainingConfig.json'
import StudentTestTypes from './StudentTestTypes.json'
import CardiacProtocols from './CardiacProtocols.json'
import StudentTrainingDetails from './StudentTrainingDetails.json'
import TrainingSheetDetails from './TrainingSheetDetails.json'
import TrainingManagement from './TrainingManagement.json'
import NoBirthDateModal from './NoBirthDateModal.json'
import ComingSoon from './ComingSoon.json'
import VideoModal from './VideoModal.json'
import AnamneseAnswers from './AnamneseAnswers.json'
import AnamneseStatus from './AnamneseStatus.json'

export default {
  Glossary,
  Common,
  Error,
  Success,
  Login,
  ForgotPassword,
  PageNotFound,
  CreateAccount,
  Main,
  UserProfile,
  User,
  MyStudents,
  Home,
  NewStudent,
  CreateRunningGroup,
  RunningGroup,
  MyRunningGroups,
  StudentTrainings,
  Training,
  EditUserProfile,
  ChangePassword,
  RunningGroupDetails,
  TrainingLevelConfig,
  TrainingLevel,
  Activity,
  ActivityConfig,
  TrainingZone,
  TrainingZoneConfig,
  AnamneseConfig,
  Anamnese,
  TrainingConfig,
  Units,
  TrainingBlock,
  TrainingBlockConfig,
  AnswerAnamneseQuiz,
  UserPublicProfile,
  RegisterUser,
  SearchUser,
  EditRunningGroup,
  PageTitles,
  StudentTrainingConfig,
  StudentTestTypes,
  CardiacProtocols,
  StudentTrainingDetails,
  TrainingSheetDetails,
  TrainingManagement,
  NoBirthDateModal,
  ComingSoon,
  VideoModal,
  AnamneseAnswers,
  AnamneseStatus,
}
