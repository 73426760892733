import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  Server,
  STUDENT_TRAINING_CONFIG_ROUTES,
} from 'src/services/Server'

import { setTrainingConfigData } from './actions'
import {
  STUDENT_TRAINING_CONFIG_TYPES as STCT,
  RequestGetTrainingConfigAction,
} from './types'

type Filters = Pick<
  Models.StudentTrainingConfig,
  'configtreinoaluno_id' | 'configtreinoalu_situacao'
>

export function* requestGetTrainingConfigWatcher() {
  yield takeLatest(
    STCT.REQUEST_GET_TRAINING_CONFIG,
    handleGetStudentTrainingConfig,
  )
}

export function* handleGetStudentTrainingConfig(
  action: RequestGetTrainingConfigAction,
) {
  try {
    yield put(addLoading(STCT.REQUEST_GET_TRAINING_CONFIG))
    const { trainingConfigId, isActive } = action.payload

    const filters: Filters = {
      configtreinoaluno_id: trainingConfigId,
      configtreinoalu_situacao: isActive ? 'AT' : 'NT',
    }

    const {
      data,
    }: AxiosServerResponse<Models.StudentTrainingConfig> = yield call(
      Server.put,
      STUDENT_TRAINING_CONFIG_ROUTES.GET_POR_CAMPOS,
      filters,
    )

    if (data.isSuccessful) {
      const trainingConfig = data.getFirstData()
      if (trainingConfig) yield put(setTrainingConfigData(trainingConfig))
    } else {
      yield put(setTrainingConfigData(undefined))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(STCT.REQUEST_GET_TRAINING_CONFIG))
  }
}
