import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FiZap, FiCheckCircle, FiFileText } from 'react-icons/fi'

import {
  DefaultError,
  DefaultLabel,
  SimpleModal,
  SnowInput,
  WhiteSpinner,
} from 'src/components'
import { useIsLoading } from 'src/hooks'
import { ACTIVITY_TYPES, requestAddActivity } from 'src/store/ducks/activity'

import { Form, ActivityInput, SaveActivityButton } from './styles'

export interface AddNewActivityModalProps {
  isShowing: boolean
  handleHideModal: () => void
  onActivityAdded?: (activity: Models.Activity) => void
}

export const AddNewActivityModal: React.FC<AddNewActivityModalProps> = (
  props,
) => {
  const { isShowing, handleHideModal, onActivityAdded } = props

  const dispatch = useDispatch()

  const { t } = useTranslation([
    'TrainingManagement',
    'Activity',
    'Glossary',
    'Error',
  ])

  const nameRef = useRef<HTMLInputElement | null>(null)
  const videoRef = useRef<HTMLInputElement | null>(null)
  const isSaving = useIsLoading(ACTIVITY_TYPES.REQUEST_ADD)
  const [errors, setErrors] = useState({
    isNameEmpty: false,
    isVideoEmpty: false,
  })

  const handleValidate = (): boolean => {
    const name = nameRef.current?.value || ''
    const video = videoRef.current?.value || ''

    const errorState: typeof errors = {
      isNameEmpty: !name.trim(),
      isVideoEmpty: !video.trim(),
    }

    setErrors(errorState)
    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleSaveNewActivity = (e: React.FormEvent) => {
    e.preventDefault()
    if (handleValidate()) {
      const name = nameRef.current?.value || ''
      const video = videoRef.current?.value || ''

      dispatch(
        requestAddActivity({
          name,
          video,
          successCallback(activity) {
            if (onActivityAdded) onActivityAdded(activity)
            handleHideModal()
          },
        }),
      )
    }
  }

  return (
    <SimpleModal
      isShowing={isShowing}
      handleHideModal={handleHideModal}
      title={t('addActivityModalTitle')}
      subtitle={t('addActivityModalSubtitle')}
      iconComponent={<FiZap size="3.2rem" />}
    >
      <Form onSubmit={handleSaveNewActivity} noValidate>
        <ActivityInput
          iconComponent={<FiFileText size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="activityName">
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('Activity:name')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="activityName"
              name="activityName"
              ref={nameRef}
              type="text"
              disabled={isSaving}
              autoCapitalize="words"
              placeholder={t('Activity:namePh')}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isNameEmpty}
        />
        <ActivityInput
          iconComponent={<FiFileText size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="activityName">
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('Activity:name')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="activityName"
              name="activityName"
              ref={videoRef}
              type="text"
              disabled={isSaving}
              autoCapitalize="words"
              placeholder={t('Activity:namePh')}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isNameEmpty}
        />

        <SaveActivityButton
          type="submit"
          disabled={isSaving}
          onClick={handleSaveNewActivity}
        >
          <span>{t('saveNewActivityButton')}</span>
          {isSaving ? <WhiteSpinner /> : <FiCheckCircle />}
        </SaveActivityButton>
      </Form>
    </SimpleModal>
  )
}
