import { Reducer } from 'redux'

import { AuthState, AUTH_TYPES } from './types'

export const initialState: AuthState = {
  isAuthenticated: false,
}

const reducer: Reducer<AuthState> = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case AUTH_TYPES.SET_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: true,
        userData: payload.userData,
      }
    }

    case AUTH_TYPES.SET_NOT_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: false,
        userData: undefined,
      }
    }

    case AUTH_TYPES.UPDATE_AUTHENTICATED_USER_DATA: {
      return {
        ...state,
        userData: { ...(state.userData || {}), ...payload.userData },
      }
    }

    default:
      return state
  }
}

export default reducer
