import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import { useIsLoading, useSetBrowserTabTitle } from 'src/hooks'
import { TRAINING_SHEET_TYPES } from 'src/store/ducks/trainingSheet'
import {
  FullHeightSpinnerContainer,
  Spinner,
  SpinnerWithText,
} from 'src/components'

import useFetchTraining from './useFetchTraining'
import BuildTrainingSheet from './BuildTrainingSheet'
import { Container, StyledPageTitle } from './styles'

const EditTrainingSheet: React.FC = () => {
  const { t } = useTranslation('TrainingManagement')

  useSetBrowserTabTitle('editTraining')
  useFetchTraining()

  const isLoading = useIsLoading(TRAINING_SHEET_TYPES.REQUEST_GET_DATA)

  return isLoading ? (
    <FullHeightSpinnerContainer>
      <SpinnerWithText hasSpinnerOnTheLeft>
        <Spinner />
        <span>{t('loadingText')}</span>
      </SpinnerWithText>
    </FullHeightSpinnerContainer>
  ) : (
    <Container>
      <StyledPageTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<FiEdit />}
      />

      <BuildTrainingSheet />
    </Container>
  )
}

export default EditTrainingSheet
