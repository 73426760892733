import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BsFileCheck } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import {
  FiEdit,
  FiPlay,
  FiPlusCircle,
  FiSearch,
  FiTrash,
  FiUserPlus,
} from 'react-icons/fi'

import { ConfirmModal, SimpleModal, WhiteSpinner } from 'src/components'
import { requestDeleteRunningGroup } from 'src/store/ducks/runningGroup'
import {
  useAuthenticatedUserTypes,
  useIsLoading,
  useModalToggleFunctions,
  useTypedSelector,
} from 'src/hooks'
import {
  ANAMNESE_QUIZ_ANSWER_TYPES,
  requestCheckAnamneseAnswer,
  setHasAnsweredAnamnese,
} from 'src/store/ducks/anamneseQuizAnswer'

import { RunningGroupDetailsParams } from '../types'
import useIsCurrentUserInGroupMembers from '../useIsCurrentUserInGroupMembers'

import {
  Container,
  Title,
  ActionGroup,
  ModalContent,
  AnswerAnamneseQuizButton,
  AddGroupMemberButton,
  EditGroupButton,
  DeleteGroupButton,
  OptionButtonToAddMember,
} from './styles'

const GroupActions: React.FC = () => {
  const { t } = useTranslation(['RunningGroupDetails', 'Glossary'])
  const { id } = useParams<RunningGroupDetailsParams>()
  const dispatch = useDispatch()
  const history = useHistory()

  const { isAdmin, isTeacher, isStudent } = useAuthenticatedUserTypes()
  const isCurrentUserInGroupMembers = useIsCurrentUserInGroupMembers()

  const isCheckingIfAnsweredAnamnese = useIsLoading(
    ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_CHECK_ANSWER,
  )

  const userId = useTypedSelector(({ Auth }) => {
    return Auth.userData?.usuario_id || 0
  })

  const isAnamneseAnswered = useTypedSelector(({ AnamneseQuizAnswer }) => {
    return AnamneseQuizAnswer.hasAnsweredAnamnese
  })

  const [
    isShowingDeleteConfirmation,
    handleShowDeleteConfirmationModal,
    handleHideDeleteConfirmationModal,
  ] = useModalToggleFunctions()

  const [
    isShowingAddMemberOptionsModal,
    handleShowAddMemberOptionsModal,
    handleHideAddMemberOptionsModal,
  ] = useModalToggleFunctions()

  const handleEditGroup = () => {
    history.push(`/edit-running-group/${id}`)
  }

  const handleSearchUserToAddInGroup = () => {
    history.push(`/search-user/${id}`)
  }

  const handleRegisterUserAndAddInGroup = () => {
    history.push(`/register-user/${id}`)
  }

  const handleAnswerAnamneseQuiz = () => {
    history.push('/answer-anamnese')
  }

  const handleDeleteGroup = () => {
    dispatch(
      requestDeleteRunningGroup(Number(id || 0), () => {
        history.replace('/my-running-groups')
      }),
    )
  }

  useEffect(() => {
    dispatch(requestCheckAnamneseAnswer(userId))
    return () => {
      dispatch(setHasAnsweredAnamnese(false))
    }
  }, [dispatch, userId])

  return (
    <Container>
      {isAdmin && (
        <ConfirmModal
          confirmButtonType="danger"
          onConfirm={handleDeleteGroup}
          title={t('deleteConfirmationTitle')}
          isShowing={isShowingDeleteConfirmation}
          confirmButtonText={t('Glossary:delete')}
          message={t('deleteConfirmationMessage')}
          iconComponent={<FiTrash size="3.2rem" />}
          handleCloseModal={handleHideDeleteConfirmationModal}
        />
      )}

      {(isAdmin || isTeacher) && (
        <SimpleModal
          isShowing={isShowingAddMemberOptionsModal}
          handleHideModal={handleHideAddMemberOptionsModal}
          title={t('addMemberOptionsModalTitle')}
          subtitle={t('addMemberOptionsModalSubtitle')}
          iconComponent={<FiUserPlus size="3.2rem" />}
        >
          <ModalContent>
            <OptionButtonToAddMember onClick={handleSearchUserToAddInGroup}>
              <span>{t('searchUserOptionButton')}</span>
              <FiSearch />
            </OptionButtonToAddMember>

            <OptionButtonToAddMember onClick={handleRegisterUserAndAddInGroup}>
              <span>{t('registerUserOptionButton')}</span>
              <FiPlusCircle />
            </OptionButtonToAddMember>
          </ModalContent>
        </SimpleModal>
      )}

      <Title>
        <FiPlay />
        <span>{t('actionsSection')}</span>
      </Title>

      <ActionGroup>
        {isStudent && isCurrentUserInGroupMembers && (
          <AnswerAnamneseQuizButton
            onClick={handleAnswerAnamneseQuiz}
            disabled={isCheckingIfAnsweredAnamnese}
          >
            {!isCheckingIfAnsweredAnamnese && (
              <span>
                {t(
                  isAnamneseAnswered
                    ? 'editAnamneseAnswersButton'
                    : 'answerAnamneseQuizButton',
                )}
              </span>
            )}

            {isCheckingIfAnsweredAnamnese && (
              <span>{t('Glossary:loading')}</span>
            )}

            {isCheckingIfAnsweredAnamnese ? <WhiteSpinner /> : <BsFileCheck />}
          </AnswerAnamneseQuizButton>
        )}

        {(isAdmin || isTeacher) && (
          <AddGroupMemberButton onClick={handleShowAddMemberOptionsModal}>
            <span>{t('addMemberButton')}</span>
            <FiUserPlus />
          </AddGroupMemberButton>
        )}
      </ActionGroup>

      {(isAdmin || isTeacher) && (
        <EditGroupButton onClick={handleEditGroup}>
          <span>{t('editButton')}</span>
          <FiEdit />
        </EditGroupButton>
      )}

      {isAdmin && (
        <DeleteGroupButton onClick={handleShowDeleteConfirmationModal}>
          <span>{t('deleteButton')}</span>
          <FiTrash />
        </DeleteGroupButton>
      )}
    </Container>
  )
}

export default GroupActions
