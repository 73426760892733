import { call, put, takeLatest } from 'redux-saga/effects'

import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, LOGIN_ROUTES, Server } from 'src/services/Server'

import { showSuccessToast } from '../success'

import { USER_TYPES, RequestChangePasswordAction } from './types'

export function* requestChangePasswordWatcher() {
  yield takeLatest(USER_TYPES.REQUEST_CHANGE_PASSWORD, handleChangePassword)
}

export function* handleChangePassword(action: RequestChangePasswordAction) {
  try {
    yield put(addLoading(USER_TYPES.REQUEST_CHANGE_PASSWORD))
    const {
      email,
      userId,
      currentPassword,
      newPassword,
      successCallback,
    } = action.payload

    const urlAndData = [
      LOGIN_ROUTES.UPDATE_SENHA,
      email,
      encodeURIComponent(currentPassword),
      encodeURIComponent(newPassword),
      userId,
    ]

    const { data }: AxiosServerResponse = yield call(
      Server.get,
      urlAndData.join('/'),
    )

    if (data.isSuccessful) {
      if (successCallback) successCallback()
      yield put(showSuccessToast({ messageCode: 'changePassword' }))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(USER_TYPES.REQUEST_CHANGE_PASSWORD))
  }
}
