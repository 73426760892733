import styled from 'styled-components'

import { Avatar } from 'src/components'
import { NoWrapEllipsis } from 'src/styles'

export const Container = styled.div`
  padding: 2.4rem 0 2.4rem 1.6rem;

  display: flex;
  align-items: center;
`

export const UserAvatar = styled(Avatar)`
  background: ${(props) => props.theme.background};

  width: 6rem;
  height: 6rem;
`

export const UserData = styled.div`
  ${NoWrapEllipsis};
  padding-left: 1.6rem;

  flex: 1;
`

export const UserName = styled.h4`
  ${NoWrapEllipsis};
  color: ${(props) => props.theme.contrastPrimaryText};
  margin: 0;
`

export const UserEmail = styled.div`
  ${NoWrapEllipsis};
  color: ${(props) => props.theme.contrastSecondaryText};
  font-size: 1.4rem;
  margin-bottom: 0.4rem;
`

export const Badges = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const BadgeContainer = styled.span`
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
`
