import { useCallback, useRef } from 'react'

import { useTypedSelector } from 'src/hooks'

export default () => {
  const contentRef = useRef<HTMLDivElement | null>(null)

  const subscribers = useTypedSelector(
    ({ Observable }) => Observable.MainPageContentScrollEndReached,
  )

  const handleScroll = useCallback(() => {
    const element = contentRef.current
    if (!element) return

    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      const callbacks = Object.values(subscribers)
      callbacks.forEach((callback) => {
        callback()
      })

      contentRef.current?.scrollTo({ top: element.scrollTop - 1 })
    }
  }, [subscribers])

  return {
    contentRef,
    handleScroll,
  }
}
