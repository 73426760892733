import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'

import { requestFetchBlocks, setBlockList } from 'src/store/ducks/block'
import {
  requestFetchActivities,
  setActivityList,
} from 'src/store/ducks/activity'
import {
  requestFetchTrainingZones,
  setTrainingZoneList,
} from 'src/store/ducks/trainingZone'
import {
  requestFetchTrainingLevels,
  setTrainingLevelList,
} from 'src/store/ducks/trainingLevel'

export default () => {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(requestFetchBlocks())
    dispatch(requestFetchActivities())
    dispatch(requestFetchTrainingZones())
    dispatch(requestFetchTrainingLevels())

    return () => {
      dispatch(setBlockList([]))
      dispatch(setActivityList([]))
      dispatch(setTrainingZoneList([]))
      dispatch(setTrainingLevelList([]))
    }
  }, [dispatch])
}
