import React, { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import {
  useIsLoading,
  useSetBrowserTabTitle,
  useTypedSelector,
} from 'src/hooks'
import {
  FullHeightSpinnerContainer,
  Spinner,
  SpinnerWithText,
} from 'src/components'
import {
  requestFetchUserData,
  USER_TYPES,
  setUserData,
} from 'src/store/ducks/user'

import MainSection from './MainSection'
import DataSection from './DataSection'
import { Container } from './styles'

const UserProfile: React.FC = () => {
  useSetBrowserTabTitle('userProfile')

  const { t } = useTranslation(['UserProfile', 'User'])
  const dispatch = useDispatch()

  const currentUserId = useTypedSelector(
    ({ Auth }) => Auth.userData?.usuario_id,
  )

  const isLoading = useIsLoading(USER_TYPES.REQUEST_FETCH_USER_DATA)

  useLayoutEffect(() => {
    dispatch(requestFetchUserData(currentUserId || 0))
    return () => {
      dispatch(setUserData(undefined))
    }
  }, [currentUserId, dispatch])

  return isLoading ? (
    <FullHeightSpinnerContainer>
      <SpinnerWithText hasSpinnerOnTheLeft>
        <Spinner />
        <span>{t('loadingText')}</span>
      </SpinnerWithText>
    </FullHeightSpinnerContainer>
  ) : (
    <Container>
      <DataSection />
      <MainSection />
    </Container>
  )
}

export default UserProfile
