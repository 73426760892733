import styled from 'styled-components'

import { SuccessButton, Input, PageContainer, PageTitle } from 'src/components'

export const Container = styled(PageContainer)``

export const EditRunningGroupTitle = styled(PageTitle)`
  margin-bottom: 4.8rem;
`

export const Form = styled.form`
  margin: auto;

  max-width: 60rem;
`

export const FormInput = styled(Input)`
  margin-bottom: 2.4rem;
`

export const SaveButton = styled(SuccessButton)`
  margin-bottom: 2.4rem;
`
