import styled from 'styled-components'

import { Avatar, DangerButton, SmallButton, Button } from 'src/components'

export const Container = styled.div`
  grid-area: mainSection;

  padding: 2.4rem 4.8rem;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ProfileActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;

  margin-bottom: 2.4rem;
`

export const ProfileData = styled.div`
  padding: 4.8rem 0 2.4rem 0;

  width: 100%;
`

export const ChangePasswordButton = styled(SmallButton)`
  width: auto;
  height: 3.6rem;

  font-size: 1.4rem;
`

export const UserAvatar = styled(Avatar)`
  width: 20rem;
  height: 20rem;

  border-radius: 15rem;

  font-size: 4rem;

  background: ${(props) => props.theme.snow};
`

export const UserName = styled.div`
  font-weight: 900;
  font-size: 2rem;

  margin-bottom: 0.8rem;

  display: flex;
  align-items: center;

  span {
    margin-left: 1.6rem;
  }
`

export const UserData = styled.div`
  color: ${(props) => props.theme.secondaryText};
  font-size: 2rem;

  margin-bottom: 0.8rem;

  display: flex;
  align-items: center;

  span {
    margin-left: 1.6rem;
  }
`

export const EditButton = styled(Button)`
  margin-bottom: 0.8rem;
`

export const LogoutButton = styled(DangerButton)``
