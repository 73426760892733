import { call, put, takeLatest } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, USER_ROUTES, Server } from 'src/services/Server'

import { STUDENT_TYPES, RequestFetchStudentsOutsideGroupAction } from './types'
import { setStudentsList } from './actions'

type Filters = Pick<Models.Group, 'grupo_id'> & {
  FILTRO_GERAL?: string
}

export function* requestFetchStudentsOutsideGroupWatcher() {
  yield takeLatest(
    STUDENT_TYPES.REQUEST_FETCH_STUDENTS_OUTSIDE_GROUP,
    handleFetchStudentsOutsideGroup,
  )
}

export function* handleFetchStudentsOutsideGroup(
  action: RequestFetchStudentsOutsideGroupAction,
) {
  try {
    yield put(addLoading(STUDENT_TYPES.REQUEST_FETCH_STUDENTS_OUTSIDE_GROUP))
    const { groupId, search } = action.payload

    const filters: Filters = {
      grupo_id: groupId,
      FILTRO_GERAL: search,
    }

    const { data }: AxiosServerResponse<Models.User> = yield call(
      Server.put,
      USER_ROUTES.GET_LISTA_ALUNO_FORA_GRUPO,
      filters,
    )

    if (data.isSuccessful) {
      yield put(setStudentsList(data.data, 'studentsOutsideGroup'))
    } else {
      yield put(setStudentsList([], 'studentsOutsideGroup'))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(STUDENT_TYPES.REQUEST_FETCH_STUDENTS_OUTSIDE_GROUP))
  }
}
