import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  FiCheckCircle,
  FiFilePlus,
  FiFileText,
  FiPlusSquare,
  FiUsers,
  FiXSquare,
} from 'react-icons/fi'
import StateManager, { OptionsType } from 'react-select'

import {
  DefaultError,
  DefaultLabel,
  ImageFileInput,
  ReactSelectOption,
  SnowInput,
  SnowSelect,
} from 'src/components'
import { ImagePlaceholder } from 'src/assets'
import {
  useIsLoading,
  useSetBrowserTabTitle,
  useTypedSelector,
} from 'src/hooks'
import { requestCreateRunningGroup } from 'src/store/ducks/runningGroup'
import {
  USER_TYPES,
  requestFetchUsers,
  setUserList,
} from 'src/store/ducks/user'

import {
  Container,
  CreateRunningGroupTitle,
  Form,
  FormInput,
  Image,
  SaveButton,
  ImageContainer,
  ImageInfo,
  AddImageButton,
  RemoveImageButton,
} from './styles'

const CreateRunningGroup: React.FC = () => {
  useSetBrowserTabTitle('createRunningGroup')

  const dispatch = useDispatch()
  const { t } = useTranslation([
    'CreateRunningGroup',
    'RunningGroup',
    'Glossary',
  ])

  const [imageFile, setImageFile] = useState<File | null>(null)
  const [errors, setErrors] = useState({
    isNameEmpty: false,
  })

  const imagePreviewSrc = useMemo((): string => {
    if (imageFile) return URL.createObjectURL(imageFile)
    return ImagePlaceholder
  }, [imageFile])

  const userData = useTypedSelector(({ Auth }) => Auth.userData)
  const isLoadingMembers = useIsLoading(USER_TYPES.REQUEST_FETCH_USERS)

  const userOptions: OptionsType<ReactSelectOption> = useTypedSelector(
    ({ User, Auth }) => {
      const userId = Auth.userData?.usuario_id || 0

      return User.userList
        .filter(({ usuario_id }) => Number(usuario_id) !== Number(userId))
        .map((user) => {
          const label = `${user.usuario_nome} - ${user.usuario_email}`

          return {
            label,
            value: String(user.usuario_id),
          }
        })
    },
  )

  const imageRef = useRef<HTMLInputElement | null>(null)
  const nameRef = useRef<HTMLInputElement | null>(null)
  const membersRef = useRef<StateManager<ReactSelectOption[]> | null>(null)

  const handleSelectImage = () => {
    imageRef.current?.click()
  }

  const handleImageSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [selectedImage] = e.target.files || []
    if (selectedImage) setImageFile(selectedImage)
  }

  const handleRemoveImage = () => {
    if (imageRef.current) imageRef.current.value = ''
    setImageFile(null)
  }

  const getValues = () => {
    const name = nameRef.current?.value || ''
    const members = membersRef.current?.state.value as ReactSelectOption[]

    return {
      name,
      members,
    }
  }

  const handleClearValues = () => {
    if (nameRef.current) nameRef.current.value = ''

    if (membersRef.current) {
      membersRef.current.select.setValue([], 'set-value')
    }

    handleRemoveImage()
  }

  const handleValidation = (): boolean => {
    const { name } = getValues()

    const errorState: typeof errors = {
      isNameEmpty: !name.trim(),
    }

    setErrors(errorState)
    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleSaveGroup = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleValidation()) {
      const { name, members } = getValues()

      const memberIds = members
        ? members.map((member) => Number(member.value))
        : []

      dispatch(
        requestCreateRunningGroup({
          userId: userData?.usuario_id || 0,
          members: memberIds,
          image: imageFile,
          name,
          successCallback() {
            handleClearValues()
          },
        }),
      )
    }
  }

  useLayoutEffect(() => {
    dispatch(requestFetchUsers())
    return () => {
      dispatch(setUserList([]))
    }
  }, [dispatch])

  return (
    <Container>
      <CreateRunningGroupTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<FiFilePlus />}
      />

      <Form onSubmit={handleSaveGroup} noValidate>
        <ImageContainer>
          <Image
            src={imagePreviewSrc}
            alt={t('RunningGroup:image')}
            onClick={handleSelectImage}
          />

          <ImageFileInput onChange={handleImageSelected} ref={imageRef} />

          <ImageInfo>
            <div>{t('RunningGroup:image')}</div>

            <AddImageButton onClick={handleSelectImage}>
              <span>{t('addImage')}</span>
              <FiPlusSquare />
            </AddImageButton>

            {!!imageFile && (
              <RemoveImageButton onClick={handleRemoveImage}>
                <span>{t('removeImage')}</span>
                <FiXSquare />
              </RemoveImageButton>
            )}
          </ImageInfo>
        </ImageContainer>

        <FormInput
          iconComponent={<FiFileText size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="name">
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('RunningGroup:name')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="name"
              name="name"
              ref={nameRef}
              type="text"
              autoCapitalize="words"
              placeholder={t('RunningGroup:namePh')}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isNameEmpty}
        />

        <FormInput
          iconComponent={<FiUsers size="3.2rem" />}
          labelComponent={
            <DefaultLabel>
              <span>{t('membersLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowSelect
              id="members"
              name="members"
              ref={membersRef}
              options={userOptions}
              placeholder={t('membersPh')}
              noOptionsMessage={() => t('noMembersMessage')}
              loadingMessage={() => t('Glossary:loading')}
              isLoading={isLoadingMembers}
              isMulti
            />
          }
        />

        <SaveButton type="submit">
          <span>{t('saveButton')}</span>
          <FiCheckCircle />
        </SaveButton>
      </Form>
    </Container>
  )
}

export default CreateRunningGroup
