import React from 'react'
import { useTranslation } from 'react-i18next'
import { FiSearch, FiUserPlus } from 'react-icons/fi'

import { useSetBrowserTabTitle } from 'src/hooks'

import { Container, Option, MainOption } from './styles'

const NewStudent: React.FC = () => {
  useSetBrowserTabTitle('newStudent')

  const { t } = useTranslation('NewStudent')

  return (
    <Container>
      <MainOption to="/search-user">
        <FiSearch />
        <span>{t('searchOption')}</span>
      </MainOption>

      <Option to="/register-user">
        <FiUserPlus />
        <span>{t('registerOption')}</span>
      </Option>
    </Container>
  )
}

export default NewStudent
