import styled, { css } from 'styled-components'

type DataProps = {
  isTitle?: boolean
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;

  padding: 0.8rem 1.6rem;

  border: 1px solid ${(props) => props.theme.border};
  border-bottom: none;
  border-radius: 1rem 1rem 0 0;
  overflow: hidden;
`

export const DataContainer = styled.div`
  flex: 1;

  min-width: 30rem;
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  padding: 0.4rem 0;

  > *:not(:first-of-type) {
    margin-left: 0.4rem;
  }
`

export const Data = styled.div<DataProps>`
  display: flex;
  align-items: center;

  ${(props) => {
    if (props.isTitle) {
      return css`
        font-weight: 900;
      `
    }

    return css`
      span {
        color: ${props.theme.secondaryText};
      }
    `
  }}

  span {
    margin-left: 1.6rem;
  }
`
