import React from 'react'
import { useTranslation } from 'react-i18next'
import { BsFileEarmarkRuled } from 'react-icons/bs'
import { FiChevronDown, FiChevronUp, FiEdit, FiTrash } from 'react-icons/fi'

import { useUnits } from 'src/hooks'
import { RoundIconButton } from 'src/components'

import {
  NoTrainingSheet,
  TrainingSheet,
  Block,
  Activity,
  ActivityContainer,
  ActivityActionButton,
  ActivityActions,
  ActivityEditButton,
} from './styles'

type EditingActivityIndexes = {
  activityIndex: number
  blockIndex: number
}

type BlockType = Models.TrainingGroupedTypes.TrainingSheetBlock

export interface TrainingSheetPreviewProps {
  blocks: BlockType[]
  editingBlockIndex: number
  setEditingBlockIndex: (editingBlockIndex: number) => void
  editingActivityIndexes: EditingActivityIndexes
  setEditingActivityIndexes: (indexes: EditingActivityIndexes) => void
  handleRemoveTrainingBlock: (index: number) => void
  handleCancelEditingBlock: () => void
  handleCancelEditingActivity: () => void
  handleRemoveActivityFromBlock: (
    blockIndex: number,
    activityIndex: number,
  ) => void
  handleMoveActivityInBlock: (
    blockIndex: number,
    activityIndex: number,
    direction: 'down' | 'up',
  ) => void
}

export const TrainingSheetPreview: React.FC<TrainingSheetPreviewProps> = (
  props,
) => {
  const {
    blocks,
    editingBlockIndex,
    setEditingBlockIndex,
    editingActivityIndexes,
    setEditingActivityIndexes,
    handleCancelEditingBlock,
    handleCancelEditingActivity,
    handleRemoveTrainingBlock,
    handleRemoveActivityFromBlock,
    handleMoveActivityInBlock,
  } = props

  const { t } = useTranslation('TrainingManagement')
  const { handleFormatValueWithUnit } = useUnits()

  return blocks.length > 0 ? (
    <TrainingSheet>
      {blocks.map((block, blockIndex) => {
        const { bloco_descricao, treinobloco_repeticao, atividades } = block

        const isEditingThisBlock = editingBlockIndex === blockIndex

        const isEditingActivity =
          editingActivityIndexes.activityIndex !== -1 &&
          editingActivityIndexes.blockIndex !== -1

        const handleEditThisBlock = () => {
          if (isEditingThisBlock) handleCancelEditingBlock()
          else setEditingBlockIndex(blockIndex)

          if (isEditingActivity) handleCancelEditingActivity()
        }

        const handleRemoveThisBlock = () => {
          handleRemoveTrainingBlock(blockIndex)
        }

        return (
          <Block
            key={blockIndex}
            name={bloco_descricao}
            repeat={treinobloco_repeticao}
            isEditing={isEditingThisBlock}
            editingText={t('editingBlockText')}
            headerLeftComponent={
              <RoundIconButton onClick={handleRemoveThisBlock}>
                <FiTrash />
              </RoundIconButton>
            }
            headerRightComponent={
              <RoundIconButton onClick={handleEditThisBlock}>
                <FiEdit />
              </RoundIconButton>
            }
          >
            {atividades.map((activity, activityIndex) => {
              const {
                treblocativ_repeticao,
                treblocativ_unidade,
                treblocativ_tempo,
                atividade_descricao,
                zonatrein_bpmminimo,
                zonatrein_bpmmaximo,
              } = activity

              const isEditingThisActivity =
                editingActivityIndexes.blockIndex === blockIndex &&
                editingActivityIndexes.activityIndex === activityIndex

              const handleEditThisActivity = () => {
                if (isEditingThisActivity) {
                  handleCancelEditingActivity()
                } else {
                  setEditingActivityIndexes({
                    blockIndex,
                    activityIndex,
                  })
                }

                if (editingBlockIndex !== -1) handleCancelEditingBlock()
              }

              const handleRemoveThisActivity = () => {
                handleRemoveActivityFromBlock(blockIndex, activityIndex)
              }

              const handleMoveDown = () => {
                handleMoveActivityInBlock(blockIndex, activityIndex, 'down')
              }

              const handleMoveUp = () => {
                handleMoveActivityInBlock(blockIndex, activityIndex, 'up')
              }

              return (
                <ActivityContainer key={`${blockIndex}_${activityIndex}`}>
                  <Activity
                    name={atividade_descricao}
                    repeat={treblocativ_repeticao}
                    minBpm={zonatrein_bpmminimo}
                    maxBpm={zonatrein_bpmmaximo}
                    value={handleFormatValueWithUnit(
                      treblocativ_unidade,
                      treblocativ_tempo,
                    )}
                  />

                  <ActivityActions>
                    <ActivityEditButton
                      onClick={handleEditThisActivity}
                      isEditing={isEditingThisActivity}
                    >
                      <FiEdit />
                    </ActivityEditButton>

                    <ActivityActionButton
                      onClick={handleMoveDown}
                      disabled={activityIndex + 1 === block.atividades.length}
                    >
                      <FiChevronDown />
                    </ActivityActionButton>

                    <ActivityActionButton
                      onClick={handleMoveUp}
                      disabled={activityIndex === 0}
                    >
                      <FiChevronUp />
                    </ActivityActionButton>

                    <ActivityActionButton onClick={handleRemoveThisActivity}>
                      <FiTrash />
                    </ActivityActionButton>
                  </ActivityActions>
                </ActivityContainer>
              )
            })}
          </Block>
        )
      })}
    </TrainingSheet>
  ) : (
    <NoTrainingSheet
      message={t('noTrainingSheetMessage')}
      iconComponent={<BsFileEarmarkRuled />}
    />
  )
}
