import React from 'react'
import { FiMenu } from 'react-icons/fi'

import { MainContentRoutes } from 'src/routes'
import {
  useAuthenticatedUserTypes,
  useProfileCompletionStatus,
} from 'src/hooks'
import { NoBirthDateModal } from 'src/views'

import useResponsiveLogic from './useResponsiveLogic'
import DrawerHeader from './DrawerHeader'
import DrawerItems from './DrawerItems'
import {
  Container,
  Content,
  MainDrawer,
  Navbar,
  DrawerToggler,
  DrawerBackdrop,
} from './styles'
import useNotifyEndReachedSubscribers from './useNotifyEndReachedSubscribers'

const Main: React.FC = () => {
  const {
    handleToggleDrawer,
    isResponsiveMode,
    isShowingDrawer,
  } = useResponsiveLogic()

  const { isAdmin, isStudent, isTeacher } = useAuthenticatedUserTypes()
  const { contentRef, handleScroll } = useNotifyEndReachedSubscribers()
  const { hasBirthDate } = useProfileCompletionStatus()

  const handleRenderRoutes = () => {
    if (isAdmin) return <MainContentRoutes.Admin />
    else if (isTeacher) return <MainContentRoutes.Teacher />
    else if (isStudent) return <MainContentRoutes.Student />
  }

  return (
    <Container>
      {isResponsiveMode && isShowingDrawer && (
        <DrawerBackdrop onClick={handleToggleDrawer} />
      )}

      <MainDrawer
        headerComponent={<DrawerHeader />}
        isShowingDrawer={isShowingDrawer}
      >
        <DrawerItems />
      </MainDrawer>

      <Content ref={contentRef} onScroll={handleScroll}>
        {isResponsiveMode && (
          <Navbar>
            <DrawerToggler onClick={handleToggleDrawer}>
              <FiMenu />
            </DrawerToggler>
          </Navbar>
        )}

        {handleRenderRoutes()}
      </Content>

      {!hasBirthDate && <NoBirthDateModal />}
    </Container>
  )
}

export default Main
