import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CARDIAC_PROTOCOLS } from 'src/config'

export default () => {
  const { t } = useTranslation('CardiacProtocols')

  const cardiacProtocolsObject = useMemo(
    () => ({
      [CARDIAC_PROTOCOLS.GENERAL]: {
        cardiacProtocol: CARDIAC_PROTOCOLS.GENERAL,
        text: t(CARDIAC_PROTOCOLS.GENERAL),
      },
      [CARDIAC_PROTOCOLS.MEN]: {
        cardiacProtocol: CARDIAC_PROTOCOLS.MEN,
        text: t(CARDIAC_PROTOCOLS.MEN),
      },
      [CARDIAC_PROTOCOLS.ACTIVE_MEN]: {
        cardiacProtocol: CARDIAC_PROTOCOLS.ACTIVE_MEN,
        text: t(CARDIAC_PROTOCOLS.ACTIVE_MEN),
      },
      [CARDIAC_PROTOCOLS.SEDENTARY_MEN]: {
        cardiacProtocol: CARDIAC_PROTOCOLS.SEDENTARY_MEN,
        text: t(CARDIAC_PROTOCOLS.SEDENTARY_MEN),
      },
      [CARDIAC_PROTOCOLS.WOMEN]: {
        cardiacProtocol: CARDIAC_PROTOCOLS.WOMEN,
        text: t(CARDIAC_PROTOCOLS.WOMEN),
      },
      [CARDIAC_PROTOCOLS.SEDENTARY_INDIVIDUALS]: {
        cardiacProtocol: CARDIAC_PROTOCOLS.SEDENTARY_INDIVIDUALS,
        text: t(CARDIAC_PROTOCOLS.SEDENTARY_INDIVIDUALS),
      },
    }),
    [t],
  )

  const cardiacProtocolsArray = useMemo(
    () => [
      {
        cardiacProtocol: CARDIAC_PROTOCOLS.GENERAL,
        text: t(CARDIAC_PROTOCOLS.GENERAL),
      },
      {
        cardiacProtocol: CARDIAC_PROTOCOLS.MEN,
        text: t(CARDIAC_PROTOCOLS.MEN),
      },
      {
        cardiacProtocol: CARDIAC_PROTOCOLS.ACTIVE_MEN,
        text: t(CARDIAC_PROTOCOLS.ACTIVE_MEN),
      },
      {
        cardiacProtocol: CARDIAC_PROTOCOLS.SEDENTARY_MEN,
        text: t(CARDIAC_PROTOCOLS.SEDENTARY_MEN),
      },
      {
        cardiacProtocol: CARDIAC_PROTOCOLS.WOMEN,
        text: t(CARDIAC_PROTOCOLS.WOMEN),
      },
      {
        cardiacProtocol: CARDIAC_PROTOCOLS.SEDENTARY_INDIVIDUALS,
        text: t(CARDIAC_PROTOCOLS.SEDENTARY_INDIVIDUALS),
      },
    ],
    [t],
  )

  const cardiacProtocolsLabelsArray = useMemo(
    () => [
      t(CARDIAC_PROTOCOLS.GENERAL),
      t(CARDIAC_PROTOCOLS.MEN),
      t(CARDIAC_PROTOCOLS.ACTIVE_MEN),
      t(CARDIAC_PROTOCOLS.SEDENTARY_MEN),
      t(CARDIAC_PROTOCOLS.WOMEN),
      t(CARDIAC_PROTOCOLS.SEDENTARY_INDIVIDUALS),
    ],
    [t],
  )

  return {
    cardiacProtocolsObject,
    cardiacProtocolsArray,
    cardiacProtocolsLabelsArray,
  }
}
