import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FiPlusCircle } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import { BsFileEarmarkRuled } from 'react-icons/bs'

import { useInfinitePagination, useSetBrowserTabTitle } from 'src/hooks'
import {
  requestFetchTrainingSheets,
  setTrainingSheetList,
} from 'src/store/ducks/trainingSheet'

import Filters from './Filters'
import TrainingList from './TrainingList'
import { TrainingConfigFilters } from './types'
import BuildTrainingSheet from './BuildTrainingSheet'
import {
  Container,
  TrainingConfigTitle,
  Divider,
  BuildSheetButton,
} from './styles'

const INITIAL_FILTERS: TrainingConfigFilters = {
  limit: 10,
  offset: 0,
}

const CreateTraining: React.FC = () => {
  useSetBrowserTabTitle('trainingConfig')

  const { t } = useTranslation('TrainingConfig')
  const dispatch = useDispatch()

  const pagination = useInfinitePagination({ limit: 10 })
  const [isBuildingSheet, setIsBuildingSheet] = useState(false)
  const [filters, setFilters] = useState<TrainingConfigFilters>(INITIAL_FILTERS)

  const handleBuildNewSheet = () => {
    setIsBuildingSheet(true)
  }

  const handleCancelBuildNewSheet = () => {
    setIsBuildingSheet(false)
  }

  useEffect(() => {
    dispatch(requestFetchTrainingSheets(filters))
  }, [dispatch, filters])

  useEffect(() => {
    return () => {
      dispatch(setTrainingSheetList([]))
    }
  }, [dispatch])

  return (
    <Container>
      <TrainingConfigTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<BsFileEarmarkRuled />}
      />

      {isBuildingSheet ? (
        <BuildTrainingSheet
          handleCancelBuildNewSheet={handleCancelBuildNewSheet}
          setFilters={setFilters}
          pagination={pagination}
        />
      ) : (
        <BuildSheetButton onClick={handleBuildNewSheet}>
          <span>{t('buildSheetButton')}</span>
          <FiPlusCircle />
        </BuildSheetButton>
      )}

      <Divider />

      <Filters setFilters={setFilters} pagination={pagination} />
      <TrainingList pagination={pagination} />
    </Container>
  )
}

export default CreateTraining
