import { useEffect } from 'react'

import { ReactSelectOption } from 'src/components'

import { EditTrainingBlock } from '../types'

type Params = {
  blocks: EditTrainingBlock[]
  editingBlockIndex: number
  setValues: (block?: ReactSelectOption, repetitions?: number) => void
}

export default ({ editingBlockIndex, setValues, blocks }: Params) => {
  useEffect(() => {
    if (editingBlockIndex !== -1) {
      const { bloco_descricao, bloco_id, treinobloco_repeticao } = blocks[
        editingBlockIndex
      ]

      const blockOption: ReactSelectOption = {
        label: bloco_descricao,
        value: String(bloco_id),
      }

      setValues(blockOption, Number(treinobloco_repeticao))
    }
  }, [blocks, editingBlockIndex, setValues])
}
