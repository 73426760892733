export enum TRAINING_LEVEL_TYPES {
  REQUEST_FETCH = '@TRAINING_LEVEL/REQUEST_FETCH',
  REQUEST_EDIT = '@TRAINING_LEVEL/REQUEST_EDIT',
  REQUEST_ADD = '@TRAINING_LEVEL/REQUEST_ADD',
  REQUEST_DELETE = '@TRAINING_LEVEL/REQUEST_DELETE',

  SET_LIST = '@TRAINING_LEVEL/SET_LIST',
  EDIT_IN_LIST = '@TRAINING_LEVEL/EDIT_IN_LIST',
  ADD_IN_LIST = '@TRAINING_LEVEL/ADD_IN_LIST',
  REMOVE_FROM_LIST = '@TRAINING_LEVEL/REMOVE_FROM_LIST',
}

export interface TrainingLevelState {
  trainingLevels: Models.TrainingLevel[]
}

export interface RequestFetchTrainingLevelsAction {
  type: TRAINING_LEVEL_TYPES.REQUEST_FETCH
}

export interface RequestEditTrainingLevelAction {
  type: TRAINING_LEVEL_TYPES.REQUEST_EDIT
  payload: {
    id: number
    type: string
    name: string
    sequence: number
  }
}

export interface RequestAddTrainingLevelAction {
  type: TRAINING_LEVEL_TYPES.REQUEST_ADD
  payload: {
    type: string
    name: string
    sequence: number
  }
}

export interface RequestDeleteTrainingLevelAction {
  type: TRAINING_LEVEL_TYPES.REQUEST_DELETE
  payload: {
    id: number
  }
}

export interface SetTrainingLevelListAction {
  type: TRAINING_LEVEL_TYPES.SET_LIST
  payload: {
    trainingLevels: Models.TrainingLevel[]
  }
}

export interface EditTrainingLevelInListAction {
  type: TRAINING_LEVEL_TYPES.EDIT_IN_LIST
  payload: Models.TrainingLevel
}

export interface AddInTrainingLevelListAction {
  type: TRAINING_LEVEL_TYPES.ADD_IN_LIST
  payload: Models.TrainingLevel
}

export interface RemoveFromTrainingLevelListAction {
  type: TRAINING_LEVEL_TYPES.REMOVE_FROM_LIST
  payload: {
    id: number
  }
}
