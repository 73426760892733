import { Reducer } from 'redux'

import { BLOCK_TYPES, BlockState } from './types'

export const initialState: BlockState = {
  blocks: [],
}

const reducer: Reducer<BlockState> = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case BLOCK_TYPES.SET_LIST: {
      return {
        ...state,
        blocks: payload.blocks,
      }
    }

    case BLOCK_TYPES.EDIT_IN_LIST: {
      const indexToEdit = state.blocks.findIndex(
        ({ bloco_id }) => bloco_id === payload.bloco_id,
      )

      const blocksClone = [...state.blocks]
      blocksClone[indexToEdit] = payload

      return {
        ...state,
        blocks: blocksClone,
      }
    }

    case BLOCK_TYPES.REMOVE_FROM_LIST: {
      const indexToRemove = state.blocks.findIndex(
        ({ bloco_id }) => bloco_id === payload.id,
      )

      const blocksClone = [...state.blocks]
      blocksClone.splice(indexToRemove, 1)

      return {
        ...state,
        blocks: blocksClone,
      }
    }

    case BLOCK_TYPES.ADD_IN_LIST: {
      const blocksClone = [...state.blocks]
      blocksClone.push(payload)

      return {
        ...state,
        blocks: blocksClone,
      }
    }

    default:
      return state
  }
}

export default reducer
