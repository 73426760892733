import { useTranslation } from 'react-i18next'

import { UNITS } from 'src/config'

export default () => {
  const { t } = useTranslation('Units')

  const unitsObject = {
    [UNITS.MINUTES]: { unit: UNITS.MINUTES, text: t(UNITS.MINUTES) },
    [UNITS.METERS]: { unit: UNITS.METERS, text: t(UNITS.METERS) },
    [UNITS.REPETITION]: { unit: UNITS.REPETITION, text: t(UNITS.REPETITION) },
    [UNITS.SECONDS]: { unit: UNITS.SECONDS, text: t(UNITS.SECONDS) },
  }

  const unitsArray = [
    { unit: UNITS.MINUTES, text: t(UNITS.MINUTES) },
    { unit: UNITS.METERS, text: t(UNITS.METERS) },
    { unit: UNITS.REPETITION, text: t(UNITS.REPETITION) },
    { unit: UNITS.SECONDS, text: t(UNITS.SECONDS) },
  ]

  const unitLabelsArray = [
    t(UNITS.MINUTES),
    t(UNITS.METERS),
    t(UNITS.REPETITION),
    t(UNITS.SECONDS),
  ]

  const handleFormatValueWithUnit = (
    unit: string,
    value: number | string,
  ): string => {
    if (!value && value !== 0) return ''

    if (Object.values<string>(UNITS).includes(unit)) {
      return t(`valueFormat.${unit}`, { value, defaultValue: value })
    }

    return `${value}`
  }

  return {
    unitsObject,
    unitsArray,
    unitLabelsArray,
    handleFormatValueWithUnit,
  }
}
