import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useTypedSelector } from 'src/hooks'

import { StudentTrainingsParams } from './types'

export default () => {
  const { id } = useParams<StudentTrainingsParams>()

  const userData = useTypedSelector(({ Auth }) => Auth.userData)

  const isCurrentUser = useMemo((): boolean => {
    const idExists = !!id
    const loggedUserIdExists = !!userData?.usuario_id

    return (
      !idExists ||
      (!!userData &&
        idExists &&
        loggedUserIdExists &&
        Number(id) === Number(userData.usuario_id))
    )
  }, [id, userData])

  const userId = useMemo(() => {
    const idToUse = Number(id || userData?.usuario_id || 0)
    return Number.isSafeInteger(idToUse) ? idToUse : 0
  }, [id, userData])

  return {
    isCurrentUser,
    userId,
  }
}
