export enum AUTH_TYPES {
  REQUEST_AUTHENTICATE = '@AUTH/REQUEST_AUTHENTICATE',
  REQUEST_SEND_RESET_PASS_EMAIL = '@AUTH/REQUEST_SEND_RESET_PASS_EMAIL',
  REQUEST_CREATE_ACCOUNT = '@AUTH/REQUEST_CREATE_ACCOUNT',
  REQUEST_CHECK_AUTH = '@AUTH/REQUEST_CHECK_AUTH',
  REQUEST_LOGOUT = '@AUTH/REQUEST_LOGOUT',

  SET_AUTHENTICATED = '@AUTH/SET_AUTHENTICATED',
  SET_NOT_AUTHENTICATED = '@AUTH/SET_NOT_AUTHENTICATED',
  UPDATE_AUTHENTICATED_USER_DATA = '@AUTH/UPDATE_AUTHENTICATED_USER_DATA',
}

export interface AuthState {
  isAuthenticated: boolean
  userData?: Models.User
}

export interface RequestAuthenticateAction {
  type: AUTH_TYPES.REQUEST_AUTHENTICATE
  payload: {
    email: string
    password: string
    onAuthenticated: () => void
  }
}

export interface SetAuthenticatedAction {
  type: AUTH_TYPES.SET_AUTHENTICATED
  payload: {
    userData: Models.User
  }
}

export interface SetNotAuthenticatedAction {
  type: AUTH_TYPES.SET_NOT_AUTHENTICATED
}

export interface RequestSendResetPassEmailAction {
  type: AUTH_TYPES.REQUEST_SEND_RESET_PASS_EMAIL
  payload: {
    email: string
  }
}

export interface RequestCreateAccountAction {
  type: AUTH_TYPES.REQUEST_CREATE_ACCOUNT
  payload: {
    name: string
    email: string
    password: string
    isTeacher: boolean
    successCallback?: () => void
  }
}

export interface RequestCheckAuthAction {
  type: AUTH_TYPES.REQUEST_CHECK_AUTH
}

export interface RequestLogoutAction {
  type: AUTH_TYPES.REQUEST_LOGOUT
  payload: {
    successCallback?: () => void
  }
}

export interface UpdateAuthenticatedUserDataAction {
  type: AUTH_TYPES.UPDATE_AUTHENTICATED_USER_DATA
  payload: {
    userData: Partial<Models.User>
  }
}
