import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import { useIsLoading, useSetBrowserTabTitle } from 'src/hooks'
import { STUDENT_TRAINING_TYPES } from 'src/store/ducks/studentTraining'
import {
  FullHeightSpinnerContainer,
  Spinner,
  SpinnerWithText,
} from 'src/components'

import useFetchTraining from './useFetchTraining'
import BuildTrainingSheet from './BuildTrainingSheet'
import { Container, StyledPageTitle } from './styles'
import TrainingOwnerUser from './TrainingOwnerUser'

const EditStudentTraining: React.FC = () => {
  const { t } = useTranslation('TrainingManagement')

  useSetBrowserTabTitle('editStudentTraining')
  useFetchTraining()

  const isLoading = useIsLoading(STUDENT_TRAINING_TYPES.REQUEST_GET)

  return isLoading ? (
    <FullHeightSpinnerContainer>
      <SpinnerWithText hasSpinnerOnTheLeft>
        <Spinner />
        <span>{t('loadingText')}</span>
      </SpinnerWithText>
    </FullHeightSpinnerContainer>
  ) : (
    <Container>
      <StyledPageTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<FiEdit />}
      />

      <TrainingOwnerUser />
      <BuildTrainingSheet />
    </Container>
  )
}

export default EditStudentTraining
