import React from 'react'
import { useTranslation } from 'react-i18next'

import { LogoRound } from 'src/assets'
import { useAuthenticatedUserTypes, useSetBrowserTabTitle } from 'src/hooks'

import FastActions from './FastActions'
import AnamneseStatus from './AnamneseStatus'
import ProfileCompletionStatus from './ProfileCompletionStatus'
import { Container, StyledPageTitle, AppLogo } from './styles'

const Home: React.FC = () => {
  useSetBrowserTabTitle('home')

  const { t } = useTranslation('Home')
  const { isStudent } = useAuthenticatedUserTypes()

  return (
    <Container>
      <StyledPageTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<AppLogo src={LogoRound} draggable={false} />}
      />

      <ProfileCompletionStatus />
      {isStudent && <AnamneseStatus />}
      <FastActions />
    </Container>
  )
}

export default Home
