import styled from 'styled-components'

import { SmallButton } from 'src/components'

export const Container = styled.div`
  margin-bottom: 4.8rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const EditButton = styled(SmallButton)`
  width: auto;

  padding: 0 2.4rem;
  margin: 0 0.8rem;
`
