import {
  TRAINING_SHEET_TYPES,
  TrainingSheetToCreate,
  RequestFetchTrainingSheetsAction,
  RequestEditTrainingSheetAction,
  RequestAddTrainingSheetAction,
  RequestDeleteTrainingSheetAction,
  RequestGetTrainingSheetAction,
  RequestChangeTrainingSheetStatusAction,
  SetTrainingSheetListAction,
  SetTrainingSheetDataAction,
  UpdateTrainingSheetDataAction,
} from './types'

export const requestFetchTrainingSheets = (
  payload: RequestFetchTrainingSheetsAction['payload'] = {},
): RequestFetchTrainingSheetsAction => ({
  type: TRAINING_SHEET_TYPES.REQUEST_FETCH,
  payload,
})

export const requestEditTrainingSheet = (
  trainingSheet: Models.TrainingGroupedTypes.TrainingSheet,
  successCallback?: () => void,
): RequestEditTrainingSheetAction => ({
  type: TRAINING_SHEET_TYPES.REQUEST_EDIT,
  payload: {
    trainingSheet,
    successCallback,
  },
})

export const requestAddTrainingSheet = (
  trainingSheet: TrainingSheetToCreate,
  successCallback?: () => void,
): RequestAddTrainingSheetAction => ({
  type: TRAINING_SHEET_TYPES.REQUEST_ADD,
  payload: {
    trainingSheet,
    successCallback,
  },
})

export const requestDeleteTrainingSheet = (
  id: number,
  successCallback?: () => void,
): RequestDeleteTrainingSheetAction => ({
  type: TRAINING_SHEET_TYPES.REQUEST_DELETE,
  payload: {
    id,
    successCallback,
  },
})

export const requestGetTrainingSheet = (
  id: number,
): RequestGetTrainingSheetAction => ({
  type: TRAINING_SHEET_TYPES.REQUEST_GET_DATA,
  payload: {
    id,
  },
})

export const requestChangeTrainingSheetStatus = (
  payload: RequestChangeTrainingSheetStatusAction['payload'],
): RequestChangeTrainingSheetStatusAction => ({
  type: TRAINING_SHEET_TYPES.REQUEST_CHANGE_STATUS,
  payload,
})

export const setTrainingSheetList = (
  trainingSheets: Models.TrainingGroupedTypes.TrainingWithTrainingLevel[],
  shouldAppendInTheList: boolean = false,
): SetTrainingSheetListAction => ({
  type: TRAINING_SHEET_TYPES.SET_LIST,
  payload: {
    trainingSheets,
    shouldAppendInTheList,
  },
})

export const setTrainingSheetData = (
  trainingSheetData?: Models.TrainingGroupedTypes.TrainingSheetAndLevel,
): SetTrainingSheetDataAction => ({
  type: TRAINING_SHEET_TYPES.SET_DATA,
  payload: {
    trainingSheetData,
  },
})

export const updateTrainingSheetData = (
  trainingSheetData: Partial<Models.TrainingGroupedTypes.TrainingSheetAndLevel>,
): UpdateTrainingSheetDataAction => ({
  type: TRAINING_SHEET_TYPES.UPDATE_DATA,
  payload: {
    trainingSheetData,
  },
})
