import { useMemo } from 'react'

import useTypedSelector from './useTypedSelector'

export interface ProfileCompletionStatus {
  isProfileCompleted: boolean
  hasPhone: boolean
  hasBirthDate: boolean
  hasCpf: boolean
}

export default () => {
  const userData = useTypedSelector(({ Auth }) => Auth.userData)

  return useMemo((): ProfileCompletionStatus => {
    const profileCompletionStatus: ProfileCompletionStatus = {
      hasPhone: !!userData?.usuario_celular,
      hasBirthDate: !!userData?.usuario_dtnascimento,
      hasCpf: !!userData?.usuario_cpf,

      isProfileCompleted: false,
    }

    profileCompletionStatus.isProfileCompleted =
      profileCompletionStatus.hasPhone &&
      profileCompletionStatus.hasBirthDate &&
      profileCompletionStatus.hasCpf

    return profileCompletionStatus
  }, [userData])
}
