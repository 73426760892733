import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FiCheckCircle,
  FiFileText,
  FiLock,
  FiMail,
  FiUserPlus,
} from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useIsLoading, useSetBrowserTabTitle } from 'src/hooks'
import { AUTH_TYPES, requestCreateAccount } from 'src/store/ducks/auth'
import {
  DefaultLabel,
  SnowInput,
  DefaultError,
  WhiteSpinner,
} from 'src/components'

import AccountType from './AccountType'
import {
  Container,
  Form,
  Header,
  HeaderTexts,
  Title,
  Subtitle,
  Content,
  Footer,
  SaveButton,
  CreateAccountInput,
} from './styles'

const CreateAccount: React.FC = () => {
  useSetBrowserTabTitle('createAccount')

  const { t } = useTranslation(['CreateAccount', 'Error', 'Glossary'])
  const dispatch = useDispatch()
  const history = useHistory()

  const [isTeacher, setIsTeacher] = useState(false)
  const isLoading = useIsLoading(AUTH_TYPES.REQUEST_CREATE_ACCOUNT)

  const [errors, setErrors] = useState({
    isNameEmpty: false,
    isEmailEmpty: false,
    isPasswordEmpty: false,
    isConfirmPasswordEmpty: false,
    isPasswordsNotEqual: false,
  })

  const nameRef = useRef<HTMLInputElement | null>(null)
  const emailRef = useRef<HTMLInputElement | null>(null)
  const passwordRef = useRef<HTMLInputElement | null>(null)
  const confirmPasswordRef = useRef<HTMLInputElement | null>(null)

  const getValues = () => {
    return {
      name: (nameRef.current?.value || '').trim(),
      email: (emailRef.current?.value || '').trim(),
      password: (passwordRef.current?.value || '').trim(),
      confirmPassword: (confirmPasswordRef.current?.value || '').trim(),
    }
  }

  const handleValidation = (): boolean => {
    const values = getValues()

    const errorState: typeof errors = {
      isNameEmpty: !values.name,
      isEmailEmpty: !values.email,
      isPasswordEmpty: !values.password,
      isConfirmPasswordEmpty: !values.confirmPassword,
      isPasswordsNotEqual: values.password !== values.confirmPassword,
    }

    setErrors(errorState)

    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleValidation()) {
      const { name, email, password } = getValues()

      dispatch(
        requestCreateAccount({
          name,
          email,
          password,
          isTeacher,
          successCallback() {
            history.push('/')
          },
        }),
      )
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit} noValidate>
        <Header>
          <FiUserPlus size="4.8rem" />
          <HeaderTexts>
            <Title>{t('title')}</Title>
            <Subtitle>{t('subtitle')}</Subtitle>
          </HeaderTexts>
        </Header>

        <Content>
          <AccountType
            isTeacher={isTeacher}
            setIsTeacher={setIsTeacher}
            isLoading={isLoading}
          />

          <CreateAccountInput
            iconComponent={<FiFileText size="3.2rem" />}
            labelComponent={
              <DefaultLabel htmlFor="name">
                <span className="required">{t('Glossary:required')}</span>
                <span>{t('nameLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowInput
                id="name"
                name="name"
                ref={nameRef}
                type="text"
                autoCapitalize="words"
                placeholder={t('namePh')}
                disabled={isLoading}
              />
            }
            errorComponent={
              <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
            }
            showError={errors.isNameEmpty}
          />

          <CreateAccountInput
            iconComponent={<FiMail size="3.2rem" />}
            labelComponent={
              <DefaultLabel htmlFor="email">
                <span className="required">{t('Glossary:required')}</span>
                <span>{t('emailLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowInput
                id="email"
                ref={emailRef}
                type="email"
                autoCapitalize="off"
                placeholder={t('emailPh')}
                disabled={isLoading}
              />
            }
            errorComponent={
              <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
            }
            showError={errors.isEmailEmpty}
          />

          <CreateAccountInput
            iconComponent={<FiLock size="3.2rem" />}
            labelComponent={
              <DefaultLabel htmlFor="password">
                <span className="required">{t('Glossary:required')}</span>
                <span>{t('passwordLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowInput
                id="password"
                ref={passwordRef}
                type="password"
                autoCapitalize="off"
                placeholder={t('passwordPh')}
                disabled={isLoading}
              />
            }
            errorComponent={
              <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
            }
            showError={errors.isPasswordEmpty}
          />

          <CreateAccountInput
            iconComponent={<FiLock size="3.2rem" />}
            labelComponent={
              <DefaultLabel htmlFor="confirmPassword">
                <span className="required">{t('Glossary:required')}</span>
                <span>{t('confirmPasswordLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowInput
                id="confirmPassword"
                ref={confirmPasswordRef}
                type="password"
                autoCapitalize="off"
                placeholder={t('confirmPasswordPh')}
                disabled={isLoading}
              />
            }
            errorComponent={
              <DefaultError>
                {t(
                  !errors.isConfirmPasswordEmpty && errors.isPasswordsNotEqual
                    ? 'Error:passwordsNotEqual'
                    : 'Error:emptyFieldError',
                )}
              </DefaultError>
            }
            showError={
              errors.isConfirmPasswordEmpty || errors.isPasswordsNotEqual
            }
          />
        </Content>

        <Footer>
          <SaveButton type="submit" disabled={isLoading}>
            <span>{t('saveButton')}</span>
            {isLoading ? <WhiteSpinner /> : <FiCheckCircle />}
          </SaveButton>
        </Footer>
      </Form>
    </Container>
  )
}

export default CreateAccount
