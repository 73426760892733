import React, { forwardRef } from 'react'
import { FiFileText } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import { DefaultError, DefaultLabel, SnowInput } from 'src/components'

import { EditProfileInput } from './styles'

interface UserNameInputProps {
  defaultName?: string
  isNameEmpty: boolean
}

const UserNameInput = forwardRef<HTMLInputElement, UserNameInputProps>(
  (props, ref) => {
    const { defaultName, isNameEmpty } = props

    const { t } = useTranslation(['EditUserProfile', 'Error', 'Glossary'])

    return (
      <EditProfileInput
        iconComponent={<FiFileText size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="name">
            <span className="required">{t('Glossary:required')}</span>
            <span>{t('nameLabel')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowInput
            ref={ref}
            id="name"
            name="name"
            type="text"
            autoCapitalize="words"
            placeholder={t('namePh')}
            defaultValue={defaultName}
          />
        }
        errorComponent={
          <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
        }
        showError={isNameEmpty}
      />
    )
  },
)

UserNameInput.displayName = 'UserNameInput'

export default UserNameInput
