import React, { forwardRef } from 'react'
import { BsCardHeading } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

import { DefaultLabel, SnowInputMask } from 'src/components'
import { INPUT_MASKS } from 'src/config'

import { EditProfileInput } from './styles'

interface UserCpfInputProps {
  defaultCpf?: string
}

const UserCpfInput = forwardRef<HTMLInputElement, UserCpfInputProps>(
  (props, ref) => {
    const { defaultCpf } = props

    const { t } = useTranslation('EditUserProfile')

    return (
      <EditProfileInput
        iconComponent={<BsCardHeading size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="cpf">
            <span>{t('cpfLabel')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowInputMask
            inputRef={ref}
            id="cpf"
            mask={INPUT_MASKS.CPF}
            maskChar={null}
            alwaysShowMask={false}
            type="text"
            placeholder={t('cpfPh')}
            defaultValue={defaultCpf}
          />
        }
      />
    )
  },
)

UserCpfInput.displayName = 'UserCpfInput'

export default UserCpfInput
