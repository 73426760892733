export enum RUNNING_GROUP_TYPES {
  REQUEST_FETCH_RUNNING_GROUPS = '@RUNNING_GROUP/REQUEST_FETCH_RUNNING_GROUPS',
  REQUEST_CREATE_RUNNING_GROUP = '@RUNNING_GROUP/REQUEST_CREATE_RUNNING_GROUP',
  REQUEST_EDIT_RUNNING_GROUP = '@RUNNING_GROUP/REQUEST_EDIT_RUNNING_GROUP',
  REQUEST_GET_RUNNING_GROUP = '@RUNNING_GROUP/REQUEST_GET_RUNNING_GROUP',
  REQUEST_DELETE_RUNNING_GROUP = '@RUNNING_GROUP/REQUEST_DELETE_RUNNING_GROUP',

  SET_RUNNING_GROUP_LIST = '@RUNNING_GROUP/SET_RUNNING_GROUP_LIST',
  SET_RUNNING_GROUP_DATA = '@RUNNING_GROUP/SET_RUNNING_GROUP_DATA',
}

export type RunningGroupListItem = Models.Group &
  Partial<Models.StudentGroup> &
  Partial<Models.TeacherGroup> & {
    grupo_numeromembros: number
  }

export interface RunningGroupState {
  runningGroups: RunningGroupListItem[]
  runningGroupData?: Models.Group
}

export interface RequestFetchRunningGroupsAction {
  type: RUNNING_GROUP_TYPES.REQUEST_FETCH_RUNNING_GROUPS
  payload: {
    userId?: number
    search?: string
    isSearchingForStudentGroups?: boolean
    isSearchingForTeacherGroups?: boolean
  }
}

export interface SetRunningGroupListAction {
  type: RUNNING_GROUP_TYPES.SET_RUNNING_GROUP_LIST
  payload: {
    runningGroups: RunningGroupListItem[]
  }
}

export interface RequestCreateRunningGroupAction {
  type: RUNNING_GROUP_TYPES.REQUEST_CREATE_RUNNING_GROUP
  payload: {
    userId: number
    name: string
    image?: File | null
    members: number[]
    successCallback?: () => void
  }
}

export interface RequestEditRunningGroupAction {
  type: RUNNING_GROUP_TYPES.REQUEST_EDIT_RUNNING_GROUP
  payload: {
    id: number
    name: string
    image?: File | null
    successCallback?: () => void
  }
}

export interface RequestGetRunningGroupAction {
  type: RUNNING_GROUP_TYPES.REQUEST_GET_RUNNING_GROUP
  payload: {
    runningGroupId: number
  }
}

export interface RequestDeleteRunningGroupAction {
  type: RUNNING_GROUP_TYPES.REQUEST_DELETE_RUNNING_GROUP
  payload: {
    runningGroupId: number
    successCallback?: () => void
  }
}

export interface SetRunningGroupDataAction {
  type: RUNNING_GROUP_TYPES.SET_RUNNING_GROUP_DATA
  payload: {
    runningGroupData?: Models.Group
  }
}
