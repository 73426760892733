import React, { useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FiCalendar, FiCheckCircle } from 'react-icons/fi'

import { INPUT_MASKS } from 'src/config'
import { useIsLoading, useTypedSelector } from 'src/hooks'
import { requestEditUserProfile, USER_TYPES } from 'src/store/ducks/user'
import {
  DefaultError,
  DefaultLabel,
  Portal,
  SnowInputMask,
  WhiteSpinner,
} from 'src/components'

import {
  Form,
  Backdrop,
  Dialog,
  Title,
  Subtitle,
  IconContainer,
  BirthDateInput,
  SaveBirthDateButton,
} from './styles'

export const NoBirthDateModal: React.FC = () => {
  const { t } = useTranslation(['NoBirthDateModal', 'Glossary', 'Error'])
  const dispatch = useDispatch()

  const formRef = useRef<HTMLFormElement | null>(null)
  const birthDateRef = useRef<HTMLInputElement | null>(null)
  const [isShowingError, setIsShowingError] = useState(false)
  const isSaving = useIsLoading(USER_TYPES.REQUEST_EDIT_USER_PROFILE)

  const userId = useTypedSelector(({ Auth }) =>
    Number(Auth.userData?.usuario_id || 0),
  )

  const handleFormatDate = (date: string) => {
    return DateTime.fromFormat(date, 'dd/MM/yyyy').toSQLDate()
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    const birthDate = birthDateRef.current?.value.trim() || ''

    setIsShowingError(!birthDate)
    if (!birthDate && userId > 0) return

    dispatch(
      requestEditUserProfile({
        birthDate: handleFormatDate(birthDate),
        isUpdatingCurrentLoggedUser: true,
        id: userId,
      }),
    )
  }

  // Esta lógica no useEffect se chama "Focus Trap"
  useEffect(() => {
    if (birthDateRef.current) birthDateRef.current.focus()

    function handleKeyUp(e: KeyboardEvent) {
      const isTabKey = e && e.key && e.key.toLowerCase() === 'tab'

      if (
        isTabKey &&
        formRef.current &&
        !formRef.current.contains(document.activeElement)
      ) {
        if (birthDateRef.current) birthDateRef.current.focus()
      }
    }

    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return (
    <Portal id="no-birth-date-modal">
      <Backdrop>
        <Dialog>
          <Form ref={formRef} onSubmit={handleSubmit} noValidate>
            <IconContainer>
              <FiCalendar />
            </IconContainer>

            <Title>{t('title')}</Title>
            <Subtitle>{t('subtitle')}</Subtitle>

            <BirthDateInput
              labelComponent={
                <DefaultLabel htmlFor="birthDate">
                  <FiCalendar />
                  <span className="required">{t('Glossary:required')}</span>
                  <span>{t('birthDateLabel')}</span>
                </DefaultLabel>
              }
              inputComponent={
                <SnowInputMask
                  inputRef={(ref) => (birthDateRef.current = ref)}
                  mask={INPUT_MASKS.DDMMYYYY_DATE}
                  alwaysShowMask={false}
                  maskChar={null}
                  id="birthDate"
                  type="text"
                  placeholder={t('birthDatePh')}
                />
              }
              errorComponent={
                <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
              }
              showError={isShowingError}
            />

            <SaveBirthDateButton type="submit" disabled={isSaving}>
              <span>{t('saveBirthDateButton')}</span>
              {isSaving ? <WhiteSpinner /> : <FiCheckCircle />}
            </SaveBirthDateButton>
          </Form>
        </Dialog>
      </Backdrop>
    </Portal>
  )
}
