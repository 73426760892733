import { call, put, takeLatest } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  GROUP_STUDENT_ANAMINESE_ROUTES,
  AxiosServerResponse,
  Server,
} from 'src/services/Server'

import { setHasAnsweredAnamnese } from './actions'
import {
  ANAMNESE_QUIZ_ANSWER_TYPES,
  RequestCheckAnamneseAnswerAction,
} from './types'

export function* requestCheckAnamneseAnswerWatcher() {
  yield takeLatest(
    ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_CHECK_ANSWER,
    handleCheckAnamneseAnswer,
  )
}

export function* handleCheckAnamneseAnswer(
  action: RequestCheckAnamneseAnswerAction,
) {
  try {
    yield put(addLoading(ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_CHECK_ANSWER))
    const { studentId } = action.payload

    const routeArray = [
      GROUP_STUDENT_ANAMINESE_ROUTES.GET_RESPONDEU_ANAMNESE,
      studentId,
    ]

    const { data }: AxiosServerResponse = yield call(
      Server.get,
      routeArray.join('/'),
    )

    yield put(setHasAnsweredAnamnese(data.isSuccessful))
  } catch (e) {
    yield put(setHasAnsweredAnamnese(false))
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_CHECK_ANSWER))
  }
}
