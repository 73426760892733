import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  RUNNING_GROUP_ROUTES,
  Server,
} from 'src/services/Server'

import { RUNNING_GROUP_TYPES, RequestDeleteRunningGroupAction } from './types'

export function* requestDeleteRunningGroupWatcher() {
  yield takeLatest(
    RUNNING_GROUP_TYPES.REQUEST_DELETE_RUNNING_GROUP,
    handleDeleteRunningGroup,
  )
}

export function* handleDeleteRunningGroup(
  action: RequestDeleteRunningGroupAction,
) {
  try {
    yield put(addLoading(RUNNING_GROUP_TYPES.REQUEST_DELETE_RUNNING_GROUP))
    const { runningGroupId, successCallback } = action.payload

    const routeArray = [RUNNING_GROUP_ROUTES.GRUPO, runningGroupId]

    const { data }: AxiosServerResponse = yield call(
      Server.delete,
      routeArray.join('/'),
    )

    if (data.isSuccessful) {
      if (successCallback) successCallback()
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(RUNNING_GROUP_TYPES.REQUEST_DELETE_RUNNING_GROUP))
  }
}
