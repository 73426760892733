import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import { showSuccessToast } from 'src/store/ducks/success'
import {
  AxiosServerResponse,
  RUNNING_GROUP_ROUTES,
  Server,
} from 'src/services/Server'
import { withServerStructure } from 'src/utils'

import { RUNNING_GROUP_TYPES, RequestCreateRunningGroupAction } from './types'

type DataToSave = {
  grupo_nome: string
  usuario_id: number
  lista_alunos: { usuario_id: number }[]
}

export function* requestCreateRunningGroupWatcher() {
  yield takeLatest(
    RUNNING_GROUP_TYPES.REQUEST_CREATE_RUNNING_GROUP,
    handleCreateRunningGroup,
  )
}

export function* handleCreateRunningGroup(
  action: RequestCreateRunningGroupAction,
) {
  try {
    yield put(addLoading(RUNNING_GROUP_TYPES.REQUEST_CREATE_RUNNING_GROUP))
    const { userId, name, members, successCallback } = action.payload

    const memberArrayToSave = members.map((memberId) => ({
      usuario_id: memberId,
    }))

    const dataToSave: DataToSave = {
      grupo_nome: name,
      usuario_id: userId,
      lista_alunos: memberArrayToSave,
    }

    const { data }: AxiosServerResponse<Models.Group> = yield call(
      Server.put,
      RUNNING_GROUP_ROUTES.GRUPO,
      withServerStructure(dataToSave),
    )

    if (data.isSuccessful) {
      yield put(showSuccessToast({ messageCode: 'createGroup' }))
      if (successCallback) successCallback()
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(RUNNING_GROUP_TYPES.REQUEST_CREATE_RUNNING_GROUP))
  }
}
