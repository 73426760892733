import styled, { css } from 'styled-components'

import { EmptyMessage, SmallButton } from 'src/components'

type ListViewTypeButtonProps = {
  isSelected?: boolean
}

export const Container = styled.div``

export const NoTrainingsMessage = styled(EmptyMessage)`
  margin: auto;
`

export const ListViewTypes = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin-bottom: 1.6rem;
`

export const ListViewTypeButton = styled(SmallButton)<ListViewTypeButtonProps>`
  width: auto;

  padding: 0 1.6rem;
  margin: 0.8rem;

  ${(props) => {
    if (props.isSelected) {
      return css`
        background: ${props.theme.success} !important;
        color: white !important;

        pointer-events: none;
      `
    }
  }}
`

export const BottomSpinnerContainer = styled.div`
  margin: 0.8rem 0.8rem 1.6rem 0.8rem;
`

export const TopSpinnerContainer = styled.div`
  margin: 0.8rem 0.8rem 1.6rem 0.8rem;
`
