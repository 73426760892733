import {
  AUTH_TYPES,
  RequestAuthenticateAction,
  SetAuthenticatedAction,
  RequestCreateAccountAction,
  RequestSendResetPassEmailAction,
  SetNotAuthenticatedAction,
  RequestLogoutAction,
  RequestCheckAuthAction,
  UpdateAuthenticatedUserDataAction,
} from './types'

export const requestAuthenticate = (
  email: string,
  password: string,
  onAuthenticated: () => void,
): RequestAuthenticateAction => ({
  type: AUTH_TYPES.REQUEST_AUTHENTICATE,
  payload: {
    email,
    password,
    onAuthenticated,
  },
})

export const setAuthenticated = (
  userData: Models.User,
): SetAuthenticatedAction => ({
  type: AUTH_TYPES.SET_AUTHENTICATED,
  payload: {
    userData,
  },
})

export const setNotAuthenticated = (): SetNotAuthenticatedAction => ({
  type: AUTH_TYPES.SET_NOT_AUTHENTICATED,
})

export const requestSendResetPassEmail = (
  email: string,
): RequestSendResetPassEmailAction => ({
  type: AUTH_TYPES.REQUEST_SEND_RESET_PASS_EMAIL,
  payload: {
    email,
  },
})

export const requestCreateAccount = (
  payload: RequestCreateAccountAction['payload'],
): RequestCreateAccountAction => ({
  type: AUTH_TYPES.REQUEST_CREATE_ACCOUNT,
  payload,
})

export const requestCheckAuth = (): RequestCheckAuthAction => ({
  type: AUTH_TYPES.REQUEST_CHECK_AUTH,
})

export const requestLogout = (
  successCallback?: () => void,
): RequestLogoutAction => ({
  type: AUTH_TYPES.REQUEST_LOGOUT,
  payload: {
    successCallback,
  },
})

export const updateAuthenticatedUserData = (
  userData: Partial<Models.User>,
): UpdateAuthenticatedUserDataAction => ({
  type: AUTH_TYPES.UPDATE_AUTHENTICATED_USER_DATA,
  payload: {
    userData,
  },
})
