import { DateTime } from 'luxon'
import React, { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FiXCircle } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import {
  EmptyMessage,
  RunningGroupItem,
  Spinner,
  SpinnerWithText,
} from 'src/components'
import { useIsLoading, useTypedSelector } from 'src/hooks'
import {
  requestGetRunningGroup,
  RUNNING_GROUP_TYPES,
  setRunningGroupData,
} from 'src/store/ducks/runningGroup'

interface GroupDataProps {
  style?: React.CSSProperties
  className?: string
  groupId: number
}

const GroupData: React.FC<GroupDataProps> = (props) => {
  const { className, style, groupId } = props

  const { t } = useTranslation(['RegisterUser', 'RunningGroup'])
  const dispatch = useDispatch()

  const groupData = useTypedSelector(
    ({ RunningGroup }) => RunningGroup.runningGroupData,
  )

  const isLoadingGroupData = useIsLoading(
    RUNNING_GROUP_TYPES.REQUEST_GET_RUNNING_GROUP,
  )

  const getFormattedCreationDate = (): string | undefined => {
    if (!groupData?.grupo_dtinclusao) return

    const luxonDate = DateTime.fromSQL(groupData.grupo_dtinclusao)

    return t('RunningGroup:createdAtWithTime', {
      date: luxonDate.toFormat('dd/MM/yyyy'),
      time: luxonDate.toFormat('hh:mm:ss'),
      interpolation: {
        escapeValue: false,
      },
    })
  }

  useLayoutEffect(() => {
    dispatch(requestGetRunningGroup(groupId))
    return () => {
      dispatch(setRunningGroupData(undefined))
    }
  }, [dispatch, groupId])

  if (isLoadingGroupData) {
    return (
      <SpinnerWithText className={className} style={style} hasSpinnerOnTheLeft>
        <Spinner />
        <span>{t('loadingGroupDataText')}</span>
      </SpinnerWithText>
    )
  } else if (groupData) {
    return (
      <RunningGroupItem
        className={className}
        style={style}
        imageSrc={undefined}
        imageAlt={groupData.grupo_nome}
        name={groupData.grupo_nome}
        createdAt={getFormattedCreationDate()}
      />
    )
  } else {
    return (
      <EmptyMessage
        style={style}
        className={className}
        iconComponent={<FiXCircle />}
        message={t('noGroupDataToShow')}
      />
    )
  }
}

export default GroupData
