import styled from 'styled-components'

import {
  DefaultDivider,
  EmptyMessage,
  Input,
  PageContainer,
  PageTitle,
  RoundIconButton,
  SpinnerWithText,
  UserItem,
  SuccessButton,
} from 'src/components'

import GroupData from './GroupData'
import GroupSelect from './GroupSelect'

export const Container = styled(PageContainer)``

export const SearchUserTitle = styled(PageTitle)`
  margin-bottom: 4.8rem;
`

export const Content = styled.div`
  max-width: 60rem;
  margin: auto;
`

export const UserInput = styled(Input)`
  margin-bottom: 2.4rem;
`

export const SearchButton = styled(SuccessButton)`
  margin-bottom: 2.4rem;
`

export const Divider = styled(DefaultDivider)`
  margin-bottom: 2.4rem;
`

export const UserList = styled.div``

export const UserItemContainer = styled.div`
  display: flex;
  align-items: center;
`

export const User = styled(UserItem)`
  margin: 0 1.6rem 0.8rem 0;

  flex: 1;

  pointer-events: none;

  border: 2px solid ${(props) => props.theme.snow};
`

export const SpinnerContainer = styled(SpinnerWithText)`
  margin-bottom: 1.6rem;
`

export const StyledGroupData = styled(GroupData)`
  margin-bottom: 2.4rem;
  max-width: unset;

  pointer-events: none;
`

export const StyledGroupSelect = styled(GroupSelect)``

export const EmptyUserList = styled(EmptyMessage)`
  margin: auto;
`

export const AddUserInGroupButton = styled(RoundIconButton)`
  width: 6rem;
  height: 6rem;

  font-size: 3rem;
  color: ${(props) => props.theme.student};

  transition: color 0.2s ease-in-out, background 0.2s ease-in-out;

  &:hover {
    color: white;
    background: ${(props) => props.theme.student};
  }
`
