import styled from 'styled-components'

export const ListItemTitle = styled.div`
  font-weight: 900;
  font-size: 1.8rem;
  color: ${(props) => props.theme.primaryText};

  display: flex;
  align-items: center;

  span {
    margin-left: 1.6rem;
  }
`
export const ListItemSubTitle = styled.div`
  font-weight: 800;
  font-size: 1.4rem;
  color: ${(props) => props.theme.secondaryText};

  display: flex;
  align-items: center;

  margin: 0.4rem 0;

  span {
    margin-left: 1.6rem;
  }
`

export const ListItemText = styled.div`
  color: ${(props) => props.theme.secondaryText};

  display: flex;
  align-items: center;

  span {
    margin-left: 1.6rem;
  }
`
