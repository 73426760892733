import React, { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Avatar, Spinner, SpinnerWithText } from 'src/components'
import { useIsLoading, useTypedSelector } from 'src/hooks'
import {
  requestFetchUserData,
  setUserData,
  USER_TYPES,
} from 'src/store/ducks/user'

import { Container, User } from './styles'

interface StudentDataProps {
  userId: number
}

const StudentData: React.FC<StudentDataProps> = (props) => {
  const { userId } = props

  const { t } = useTranslation('StudentTrainings')
  const dispatch = useDispatch()

  const userData = useTypedSelector(({ User }) => User.userData)
  const isLoading = useIsLoading(USER_TYPES.REQUEST_FETCH_USER_DATA)

  useLayoutEffect(() => {
    dispatch(requestFetchUserData(userId))
    return () => {
      dispatch(setUserData(undefined))
    }
  }, [dispatch, userId])

  if (!userData) return null

  return (
    <Container>
      {isLoading ? (
        <SpinnerWithText>
          <Spinner />
          <span>{t('loadingStudentDataText')}</span>
        </SpinnerWithText>
      ) : (
        <User
          name={userData.usuario_nome}
          email={userData.usuario_email}
          phone={userData.usuario_celular}
          leftSideComponent={
            <Avatar src={undefined} alt={userData.usuario_nome} />
          }
        />
      )}
    </Container>
  )
}

export default StudentData
