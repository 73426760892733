import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError } from 'src/store/ducks/error'
import { AxiosServerResponse, USER_ROUTES, Server } from 'src/services/Server'

import { STUDENT_TYPES, RequestFetchStudentsAction } from './types'
import { setStudentsList } from './actions'

type Filters = {
  usuario_id: number
  FILTRO_GERAL?: string
}

export function* requestFetchStudentsWatcher() {
  yield takeLatest(STUDENT_TYPES.REQUEST_FETCH_STUDENTS, handleFetchStudents)
}

export function* handleFetchStudents(action: RequestFetchStudentsAction) {
  try {
    yield put(addLoading(STUDENT_TYPES.REQUEST_FETCH_STUDENTS))
    const { teacherId, search } = action.payload

    const filters: Filters = {
      usuario_id: teacherId,
      FILTRO_GERAL: search,
    }

    const { data }: AxiosServerResponse<Models.User> = yield call(
      Server.put,
      USER_ROUTES.GET_LISTA_ALUNO,
      filters,
    )

    if (data.isSuccessful) {
      yield put(setStudentsList(data.data))
    } else {
      yield put(setStudentsList([]))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(STUDENT_TYPES.REQUEST_FETCH_STUDENTS))
  }
}
