import {
  STUDENT_TRAINING_TYPES,
  StudentTrainingData,
  RequestFetchStudentTrainingsAction,
  RequestFetchFullStudentTrainingsAction,
  RequestGetStudentTrainingAction,
  RequestEditStudentTrainingAction,
  SetStudentTrainingDataAction,
  SetStudentTrainingListAction,
  SetFullStudentTrainingListAction,
} from './types'

export const requestFetchStudentTrainings = (
  payload: RequestFetchStudentTrainingsAction['payload'],
): RequestFetchStudentTrainingsAction => ({
  type: STUDENT_TRAINING_TYPES.REQUEST_FETCH,
  payload,
})

export const requestFetchFullStudentTrainings = (
  payload: RequestFetchFullStudentTrainingsAction['payload'],
): RequestFetchFullStudentTrainingsAction => ({
  type: STUDENT_TRAINING_TYPES.REQUEST_FETCH_FULL,
  payload,
})

export const requestGetStudentTraining = (
  trainingId: number,
): RequestGetStudentTrainingAction => ({
  type: STUDENT_TRAINING_TYPES.REQUEST_GET,
  payload: {
    trainingId,
  },
})

export const requestEditStudentTraining = (
  trainingSheet: Partial<Models.StudentTrainingGroupedTypes.TrainingSheet>,
  successCallback?: () => void,
): RequestEditStudentTrainingAction => ({
  type: STUDENT_TRAINING_TYPES.REQUEST_EDIT,
  payload: {
    trainingSheet,
    successCallback,
  },
})

export const setStudentTrainingList = (
  trainings: Models.StudentTrainingGroupedTypes.TrainingWithTrainingLevel[],
  shouldAppendInTheList: boolean = false,
): SetStudentTrainingListAction => ({
  type: STUDENT_TRAINING_TYPES.SET_LIST,
  payload: {
    trainings,
    shouldAppendInTheList,
  },
})

export const setFullStudentTrainingList = (
  fullTrainings: Models.StudentTrainingGroupedTypes.TrainingSheetAndLevel[],
  shouldAppendInTheList: boolean = false,
): SetFullStudentTrainingListAction => ({
  type: STUDENT_TRAINING_TYPES.SET_LIST_FULL,
  payload: {
    fullTrainings,
    shouldAppendInTheList,
  },
})

export const setStudentTrainingData = (
  trainingData?: StudentTrainingData,
): SetStudentTrainingDataAction => ({
  type: STUDENT_TRAINING_TYPES.SET_DATA,
  payload: {
    trainingData,
  },
})
