import React, { useMemo } from 'react'
import { BsFileCheck } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

import { ANAMNESE_STATUS } from 'src/config'
import { useTypedSelector } from 'src/hooks'

import { Container, AnamneseStatus } from './styles'

const AnamneseStatusSection: React.FC = () => {
  const { t } = useTranslation(['UserPublicProfile', 'AnamneseStatus'])
  const userData = useTypedSelector(({ User }) => User.userData)

  const {
    answersContainOneYes,
    isEverythingAllRight,
    notAnswered,
  } = useMemo(() => {
    const anamneseStatus = userData?.anaminese_status
    return {
      answersContainOneYes: anamneseStatus === ANAMNESE_STATUS.ANSWERED_YES,
      isEverythingAllRight: anamneseStatus === ANAMNESE_STATUS.ALL_RIGHT,
      notAnswered: anamneseStatus === ANAMNESE_STATUS.NOT_ANSWERED,
    }
  }, [userData?.anaminese_status])

  if (!userData?.anaminese_status) {
    return null
  }

  return (
    <Container
      label={t('anamneseStatusSection')}
      iconComponent={<BsFileCheck />}
      isAdmin={userData?.usuario_administrador === 'S'}
      isTeacher={userData?.usuario_professor === 'S'}
      isStudent={userData?.usuario_aluno === 'S'}
    >
      <AnamneseStatus
        answersContainOneYes={answersContainOneYes}
        isEverythingAllRight={isEverythingAllRight}
        notAnswered={notAnswered}
      >
        {t(`AnamneseStatus:long.${userData.anaminese_status}`)}
      </AnamneseStatus>
    </Container>
  )
}

export default AnamneseStatusSection
