import React, { useLayoutEffect, useRef, useState } from 'react'
import StateManager from 'react-select'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { BsClipboardData } from 'react-icons/bs'

import { useSetBrowserTabTitle } from 'src/hooks'
import {
  requestFetchTrainingLevels,
  setTrainingLevelList,
} from 'src/store/ducks/trainingLevel'
import { ReactSelectOption } from 'src/components'

import TrainingLevelList from './TrainingLevelList'
import TrainingLevelForm from './TrainingLevelForm'
import { Container, TrainingLevelConfigTitle, Divider } from './styles'

const TrainingLevelConfig: React.FC = () => {
  useSetBrowserTabTitle('trainingLevelConfig')

  const { t } = useTranslation(['TrainingLevelConfig'])
  const dispatch = useDispatch()

  const nameRef = useRef<HTMLInputElement | null>(null)
  const sequenceRef = useRef<HTMLInputElement | null>(null)
  const typeRef = useRef<StateManager<ReactSelectOption> | null>(null)
  const [editingId, setEditingId] = useState(0)

  const handleFinishEditing = () => {
    setEditingId(0)
    setValues({})
  }

  const setValues = ({
    name = '',
    sequence = '',
    type = null,
  }: {
    name?: string
    sequence?: string
    type?: ReactSelectOption | null
  }) => {
    if (nameRef.current) nameRef.current.value = name
    if (sequenceRef.current) sequenceRef.current.value = sequence
    if (typeRef.current) typeRef.current.select.setValue(type, 'set-value')
  }

  useLayoutEffect(() => {
    dispatch(requestFetchTrainingLevels())
    return () => {
      dispatch(setTrainingLevelList([]))
    }
  }, [dispatch])

  return (
    <Container>
      <TrainingLevelConfigTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<BsClipboardData />}
      />

      <TrainingLevelForm
        nameRef={nameRef}
        sequenceRef={sequenceRef}
        typeRef={typeRef}
        editingId={editingId}
        setValues={setValues}
        handleFinishEditing={handleFinishEditing}
      />

      <Divider />

      <TrainingLevelList
        nameRef={nameRef}
        setValues={setValues}
        editingId={editingId}
        setEditingId={setEditingId}
        handleFinishEditing={handleFinishEditing}
      />
    </Container>
  )
}

export default TrainingLevelConfig
