import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  RUNNING_GROUP_ROUTES,
  Server,
} from 'src/services/Server'

import { setGroupMemberList } from './actions'
import { GROUP_MEMBER_TYPES, RequestFetchGroupMembersAction } from './types'

export function* requestFetchGroupMembersWatcher() {
  yield takeLatest(
    GROUP_MEMBER_TYPES.REQUEST_FETCH_MEMBERS,
    handleFetchGroupMembers,
  )
}

export function* handleFetchGroupMembers(
  action: RequestFetchGroupMembersAction,
) {
  try {
    yield put(addLoading(GROUP_MEMBER_TYPES.REQUEST_FETCH_MEMBERS))
    const { id } = action.payload

    const filters: Pick<Models.Group, 'grupo_id'> = {
      grupo_id: id,
    }

    const {
      data,
    }: AxiosServerResponse<Models.GroupedTypes.GroupMember> = yield call(
      Server.put,
      RUNNING_GROUP_ROUTES.GET_LISTA_ALUNOS,
      filters,
    )

    if (data.isSuccessful) {
      yield put(setGroupMemberList(data.data))
    } else {
      yield put(setGroupMemberList([]))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(GROUP_MEMBER_TYPES.REQUEST_FETCH_MEMBERS))
  }
}
