import { Reducer } from 'redux'

import { TRAINING_SHEET_TYPES, TrainingSheetState } from './types'

export const initialState: TrainingSheetState = {
  trainingSheets: [],
}

const reducer: Reducer<TrainingSheetState> = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case TRAINING_SHEET_TYPES.SET_LIST: {
      if (payload.shouldAppendInTheList) {
        return {
          ...state,
          trainingSheets: [...state.trainingSheets, ...payload.trainingSheets],
        }
      }

      return {
        ...state,
        trainingSheets: payload.trainingSheets,
      }
    }

    case TRAINING_SHEET_TYPES.SET_DATA: {
      return {
        ...state,
        trainingSheetData: payload.trainingSheetData,
      }
    }

    case TRAINING_SHEET_TYPES.UPDATE_DATA: {
      if (state.trainingSheetData) {
        return {
          ...state,
          trainingSheetData: {
            ...state.trainingSheetData,
            ...payload.trainingSheetData,
          },
        }
      }

      return {
        ...state,
        trainingSheetData: payload.trainingSheetData,
      }
    }

    default:
      return state
  }
}

export default reducer
