import styled from 'styled-components'

type TitleProps = {
  hasIcon: boolean
}

export const Container = styled.div`
  overflow: hidden;

  border-radius: 1rem;

  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.primaryText};

  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  padding: 0.8rem 1.6rem;
`

export const Title = styled.div<TitleProps>`
  flex: 1;

  margin-left: ${(props) => (props.hasIcon ? 1.6 : 0)}rem;

  font-weight: bold;
`

export const Data = styled.div`
  text-align: center;
  font-weight: 900;
  font-size: 2.4rem;

  padding: 1.6rem;
`
