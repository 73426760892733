import styled, { css } from 'styled-components'

export interface BadgeProps {
  size?: 'small' | 'normal'
}

export const Badge = styled.div<BadgeProps>`
  border-radius: 10rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.primaryText};
  color: ${(props) => props.theme.contrastPrimaryText};

  ${(props) => {
    if (props.size === 'small') {
      return css`
        height: 2.2rem;
        font-size: 1.2rem;
        padding: 0 1.2rem;
      `
    }

    return css`
      height: 3rem;
      padding: 0 1.6rem;
    `
  }};
`

export const SuccessBadge = styled(Badge)`
  background: ${(props) => props.theme.success};
  color: white;
`

export const DangerBadge = styled(Badge)`
  background: ${(props) => props.theme.danger};
  color: white;
`

export const AccentBadge = styled(Badge)`
  background: ${(props) => props.theme.accent};
  color: white;
`

export const TeacherBadge = styled(Badge)`
  background: ${(props) => props.theme.teacher};
  color: white;
`

export const StudentBadge = styled(Badge)`
  background: ${(props) => props.theme.student};
  color: white;
`

export const AdminBadge = styled(Badge)`
  background: ${(props) => props.theme.admin};
  color: white;
`
