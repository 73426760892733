import styled from 'styled-components'

import {
  PageContainer,
  PageTitle,
  SuccessButton,
  Input,
  CheckBox,
  DefaultLabel,
  DefaultError,
} from 'src/components'

export const Container = styled(PageContainer)``

export const StyledPageTitle = styled(PageTitle)`
  margin-bottom: 4.8rem;
`

export const Form = styled.form`
  max-width: 60rem;

  margin: auto;
`

export const StudentInput = styled(Input)`
  margin-bottom: 2.4rem;
`

export const WeekDayContainer = styled.div`
  border-radius: 1rem;
  border: 1px solid ${(props) => props.theme.border};

  padding: 0.8rem;
  margin-bottom: 2.4rem;
`

export const WeekDaysLabel = styled(DefaultLabel)`
  margin: 0.8rem 0.8rem 1.6rem 0.8rem;
`

export const WeekDaysError = styled(DefaultError)`
  margin: 1.6rem 0.8rem 0.8rem 0.8rem;
`

export const WeekDays = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

export const WeekDayCheckbox = styled(CheckBox)`
  flex: 1 40%;
  min-width: 18rem;

  margin: 0.8rem;

  .label {
    text-transform: capitalize;
    color: ${(props) => props.theme.secondaryText};

    transition: color 0.2s ease-in-out;

    margin-left: 1.6rem;
  }

  &:hover {
    .label {
      color: ${(props) => props.theme.primaryText};
    }
  }
`

export const SaveButton = styled(SuccessButton)``
