import styled from 'styled-components'

import { TrainingItem } from 'src/components'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Training = styled(TrainingItem)`
  flex: 1 40%;
  min-width: 30rem;

  margin: 0.8rem;
`
