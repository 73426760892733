import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { BsFileCheck } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

import { useTypedSelector } from 'src/hooks'
import { requestCheckAnamneseAnswer } from 'src/store/ducks/anamneseQuizAnswer'

import { Container, Title, AnswerAnamneseButton } from './styles'

const AnamneseStatus: React.FC = () => {
  const { t } = useTranslation('Home')
  const dispatch = useDispatch()
  const history = useHistory()

  const currentUserId = useTypedSelector(({ Auth }) => {
    return Auth.userData?.usuario_id || 0
  })

  const isAnamneseAnswered = useTypedSelector(({ AnamneseQuizAnswer }) => {
    return AnamneseQuizAnswer.hasAnsweredAnamnese
  })

  const handleAnswerAnamnese = () => {
    history.push('/answer-anamnese')
  }

  useEffect(() => {
    dispatch(requestCheckAnamneseAnswer(currentUserId))
  }, [dispatch, currentUserId])

  return isAnamneseAnswered ? null : (
    <Container>
      <Title>
        <BsFileCheck />
        <span>{t('anamneseStatus.title')}</span>
      </Title>

      <AnswerAnamneseButton onClick={handleAnswerAnamnese}>
        <span>{t('anamneseStatus.answerAnamneseButton')}</span>
        <BsFileCheck />
      </AnswerAnamneseButton>
    </Container>
  )
}

export default AnamneseStatus
