import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  ACTIVITY_ROUTES,
  Server,
} from 'src/services/Server'
import { withServerStructure } from 'src/utils'

import { addInActivityList } from './actions'
import { RequestAddActivityAction, ACTIVITY_TYPES } from './types'

export function* requestAddActivityWatcher() {
  yield takeLatest(ACTIVITY_TYPES.REQUEST_ADD, handleAddActivity)
}

export function* handleAddActivity(action: RequestAddActivityAction) {
  try {
    yield put(addLoading(ACTIVITY_TYPES.REQUEST_ADD))
    const { name, video, successCallback } = action.payload

    const dataToAdd: Omit<Models.Activity, 'atividade_id'> = {
      atividade_descricao: name,
      atividade_linkvideopadrao: video,
      atividade_situacao: 'S',
    }

    const { data }: AxiosServerResponse<Models.Activity> = yield call(
      Server.put,
      ACTIVITY_ROUTES.ATIVIDADE,
      withServerStructure(dataToAdd),
    )

    if (data.isSuccessful) {
      const activity = data.getFirstData()
      if (successCallback && activity) successCallback(activity)
      if (activity) yield put(addInActivityList(activity))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(ACTIVITY_TYPES.REQUEST_ADD))
  }
}
