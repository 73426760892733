import styled from 'styled-components'

import { LabeledEdgeContainer } from 'src/components'

export const Container = styled.div`
  margin-bottom: 2.4rem;
`

export const Content = styled(LabeledEdgeContainer)`
  min-width: 36rem;
  max-width: 90rem;

  margin: auto;
  padding: 1.6rem;
`

export const TrainingDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const TrainingData = styled.div`
  flex: 0.5;
  min-width: 25rem;

  margin: 0.8rem;

  > div:nth-child(1) {
    font-weight: bold;
  }
`
