import React, { useLayoutEffect } from 'react'
import { DateTime } from 'luxon'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ImagePlaceholder } from 'src/assets'
import {
  FullHeightSpinnerContainer,
  Spinner,
  SpinnerWithText,
} from 'src/components'
import {
  useIsLoading,
  useSetBrowserTabTitle,
  useTypedSelector,
} from 'src/hooks'
import {
  requestGetRunningGroup,
  RUNNING_GROUP_TYPES,
  setRunningGroupData,
} from 'src/store/ducks/runningGroup'

import GroupActions from './GroupActions'
import GroupMemberList from './GroupMemberList'
import { RunningGroupDetailsParams } from './types'
import { Container, RunningGroupTitle, GroupImage, Content } from './styles'

const RunningGroupDetails: React.FC = () => {
  useSetBrowserTabTitle('runningGroupDetails')

  const { t } = useTranslation(['RunningGroupDetails', 'RunningGroup'])
  const { id } = useParams<RunningGroupDetailsParams>()
  const dispatch = useDispatch()

  const isLoading = useIsLoading(RUNNING_GROUP_TYPES.REQUEST_GET_RUNNING_GROUP)
  const groupData = useTypedSelector(
    ({ RunningGroup }) => RunningGroup.runningGroupData,
  )

  const getFormattedCreationDate = (): string | undefined => {
    if (!groupData?.grupo_dtinclusao) return

    const luxonDate = DateTime.fromSQL(groupData.grupo_dtinclusao)

    return t('RunningGroup:createdAtWithTime', {
      date: luxonDate.toFormat('dd/MM/yyyy'),
      time: luxonDate.toFormat('hh:mm:ss'),
      interpolation: {
        escapeValue: false,
      },
    })
  }

  useLayoutEffect(() => {
    dispatch(requestGetRunningGroup(Number(id || 0)))
    return () => {
      dispatch(setRunningGroupData(undefined))
    }
  }, [dispatch, id])

  return isLoading ? (
    <FullHeightSpinnerContainer>
      <SpinnerWithText hasSpinnerOnTheLeft>
        <Spinner />
        <span>{t('loadingText')}</span>
      </SpinnerWithText>
    </FullHeightSpinnerContainer>
  ) : (
    <Container>
      <RunningGroupTitle
        title={groupData?.grupo_nome || t('title')}
        subtitle={getFormattedCreationDate() || t('subtitle')}
        iconComponent={<GroupImage src={null || ImagePlaceholder} />}
      />

      <Content>
        <GroupMemberList />
        <GroupActions />
      </Content>
    </Container>
  )
}

export default RunningGroupDetails
