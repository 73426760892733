export enum STUDENT_TRAINING_CONFIG_TYPES {
  REQUEST_GET_TRAINING_CONFIG = '@STUDENT_TRAINING_CONFIG/REQUEST_GET_TRAINING_CONFIG',

  REQUEST_EDIT_TRAINING_CONFIG = '@STUDENT_TRAINING_CONFIG/REQUEST_EDIT_TRAINING_CONFIG',

  SET_TRAINING_CONFIG_DATA = '@STUDENT_TRAINING_CONFIG/SET_TRAINING_CONFIG_DATA',
}

export interface StudentTrainingConfigState {
  trainingConfig?: Models.StudentTrainingConfig
}

export interface RequestGetTrainingConfigAction {
  type: STUDENT_TRAINING_CONFIG_TYPES.REQUEST_GET_TRAINING_CONFIG
  payload: {
    trainingConfigId: number
    isActive: boolean
  }
}

export interface RequestEditTrainingConfigAction {
  type: STUDENT_TRAINING_CONFIG_TYPES.REQUEST_EDIT_TRAINING_CONFIG
  payload: {
    studentGroupId: number
    trainingConfigId: number
    testType: Models.Common.TestTypesUnion
    cardiacProtocol?: Models.Common.CardiacProtocolsUnion
    trainingDaysStartingFromMonday: boolean[]
    testDuration?: string
  }
}

export interface SetTrainingConfigDataAction {
  type: STUDENT_TRAINING_CONFIG_TYPES.SET_TRAINING_CONFIG_DATA
  payload: {
    trainingConfig?: Models.StudentTrainingConfig
  }
}
