import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  ACTIVITY_ROUTES,
  Server,
} from 'src/services/Server'

import { RequestDeleteActivityAction, ACTIVITY_TYPES } from './types'
import { removeFromActivityList } from './actions'

export function* requestDeleteActivityWatcher() {
  yield takeLatest(ACTIVITY_TYPES.REQUEST_DELETE, handleDeleteActivity)
}

export function* handleDeleteActivity(action: RequestDeleteActivityAction) {
  try {
    yield put(addLoading(ACTIVITY_TYPES.REQUEST_DELETE))
    const { id } = action.payload

    const routeArray = [ACTIVITY_ROUTES.ATIVIDADE, id]

    const { data }: AxiosServerResponse = yield call(
      Server.delete,
      routeArray.join('/'),
    )

    if (data.isSuccessful) {
      yield put(removeFromActivityList(id))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(ACTIVITY_TYPES.REQUEST_DELETE))
  }
}
