import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useIsLoading, useTypedSelector } from 'src/hooks'
import {
  setAnamneseQuiz,
  requestGetAnamneseQuiz,
} from 'src/store/ducks/anamnese'
import {
  ANAMNESE_QUIZ_ANSWER_TYPES,
  setAnamneseQuizAnswers,
  requestFetchAnamneseQuizAnswers,
} from 'src/store/ducks/anamneseQuizAnswer'

type AnamneseAnswersObject = {
  [key: number]: Models.AnamneseGroupedTypes.AnamneseFullAnswer
}

export default () => {
  const dispatch = useDispatch()

  const userId = useTypedSelector(({ Auth }) => {
    return Auth.userData?.usuario_id || 0
  })

  const anamneseQuiz = useTypedSelector(({ Anamnese }) => {
    return Anamnese.quiz
  })

  const anamneseQuizAnswers = useTypedSelector(({ AnamneseQuizAnswer }) => {
    const anamneseAnswersObject: AnamneseAnswersObject = {}
    AnamneseQuizAnswer.answers.forEach((answer) => {
      anamneseAnswersObject[answer.grupoaluanami_id] = answer
    })

    return anamneseAnswersObject
  })

  const hasAnamneseQuizAnswers = useTypedSelector(({ AnamneseQuizAnswer }) => {
    return AnamneseQuizAnswer.answers.length > 0
  })

  const isLoadingAnswers = useIsLoading(
    ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_FETCH_ANSWERS,
  )

  const isSavingAnswers = useIsLoading(
    ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_ANSWER_QUIZ,
  )

  useLayoutEffect(() => {
    dispatch(requestGetAnamneseQuiz())
    dispatch(requestFetchAnamneseQuizAnswers(userId))

    return () => {
      dispatch(setAnamneseQuiz(undefined))
      dispatch(setAnamneseQuizAnswers([]))
    }
  }, [dispatch, userId])

  return {
    anamneseId: anamneseQuiz?.anaminese_id || 0,
    questions: anamneseQuiz?.questions || [],
    savedAnswers: anamneseQuizAnswers,
    hasAnamneseQuizAnswers,
    isLoadingAnswers,
    isSavingAnswers,
  }
}
