import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  TRAINING_LEVEL_ROUTES,
  Server,
} from 'src/services/Server'

import { RequestDeleteTrainingLevelAction, TRAINING_LEVEL_TYPES } from './types'
import { removeFromTrainingLevelList } from './actions'

export function* requestDeleteTrainingLevelWatcher() {
  yield takeLatest(
    TRAINING_LEVEL_TYPES.REQUEST_DELETE,
    handleDeleteTrainingLevel,
  )
}

export function* handleDeleteTrainingLevel(
  action: RequestDeleteTrainingLevelAction,
) {
  try {
    yield put(addLoading(TRAINING_LEVEL_TYPES.REQUEST_DELETE))
    const { id } = action.payload

    const routeArray = [TRAINING_LEVEL_ROUTES.TREINO_NIVEL, id]

    const { data }: AxiosServerResponse = yield call(
      Server.delete,
      routeArray.join('/'),
    )

    if (data.isSuccessful) {
      yield put(removeFromTrainingLevelList(id))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(TRAINING_LEVEL_TYPES.REQUEST_DELETE))
  }
}
