import React from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { FiFile, FiMessageCircle } from 'react-icons/fi'

import { useTypedSelector } from 'src/hooks'

import { Container, Section, UserData } from './styles'

const OtherInfoSection: React.FC = () => {
  const { t } = useTranslation(['UserPublicProfile', 'User'])
  const userData = useTypedSelector(({ User }) => User.userData)

  const getFormattedDate = (date?: string) => {
    if (!date) return '-'
    return DateTime.fromSQL(date).toFormat('dd/MM/yyyy')
  }

  return (
    <Container
      isAdmin={userData?.usuario_administrador === 'S'}
      isTeacher={userData?.usuario_professor === 'S'}
      isStudent={userData?.usuario_aluno === 'S'}
    >
      <Section label={t('contactSection')} iconComponent={<FiMessageCircle />}>
        <>
          <UserData>
            <div>{t('User:email')}</div>
            <div>{userData?.usuario_email || '-'}</div>
          </UserData>

          <UserData>
            <div>{t('User:phone')}</div>
            <div>{userData?.usuario_celular || '-'}</div>
          </UserData>
        </>
      </Section>

      <Section label={t('otherInfoSection')} iconComponent={<FiFile />}>
        <>
          <UserData>
            <div>{t('User:birthDate')}</div>
            <div>{getFormattedDate(userData?.usuario_dtnascimento)}</div>
          </UserData>

          <UserData>
            <div>{t('User:accountCreationDate')}</div>
            <div>{getFormattedDate(userData?.usuario_dtinclusao)}</div>
          </UserData>
        </>
      </Section>
    </Container>
  )
}

export default OtherInfoSection
