import styled from 'styled-components'

import { DangerButton } from 'src/components'

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.danger};
  border-radius: 1rem;
  overflow: hidden;

  padding: 1.6rem;
  margin-bottom: 2.4rem;
`

export const Title = styled.div`
  color: ${(props) => props.theme.danger};
  font-size: 2.4rem;
  font-weight: 900;

  display: flex;
  align-items: center;

  margin-bottom: 0.4rem;

  span {
    margin-left: 1.6rem;
  }
`

export const MissingData = styled.div`
  margin-bottom: 2.4rem;

  span:first-child {
    font-weight: bold;

    margin-right: 0.8rem;
  }
`

export const NavigateToEditProfileButton = styled(DangerButton)`
  width: auto;

  padding: 0 2.4rem;
`
