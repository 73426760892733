import styled, { css } from 'styled-components'

import { DrawerMenu, RoundIconButton } from 'src/components'
import { DefaultScrollBar } from 'src/styles'

type DrawerProps = {
  isShowingDrawer: boolean
}

const ContainerResponsiveStyles = css`
  @media only screen and (max-width: 900px) {
    grid-template-areas: 'content';
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }
`

const DrawerResponsiveStyles = css<DrawerProps>`
  @media only screen and (max-width: 900px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;

    z-index: 2;

    width: 30rem;

    transition: transform 0.3s ease-in-out;
    transform: translateX(${(props) => (props.isShowingDrawer ? 0 : -32)}rem);
  }
`

export const Container = styled.div`
  display: grid;
  grid-template-areas: 'drawer content';
  grid-template-rows: 1fr;
  grid-template-columns: 30rem 1fr;

  height: 100%;

  ${ContainerResponsiveStyles};
`

export const Content = styled.main`
  ${DefaultScrollBar};

  grid-area: content;

  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`

export const MainDrawer = styled(DrawerMenu)<DrawerProps>`
  ${DefaultScrollBar};
  ${DrawerResponsiveStyles};

  grid-area: drawer;

  max-height: 100vh;
  overflow-y: auto;
`

export const Navbar = styled.div`
  height: 5.6rem;
  width: 100%;

  padding: 0 1.6rem;

  display: flex;
  align-items: center;
`

export const DrawerToggler = styled(RoundIconButton)``

export const DrawerBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background: rgba(0, 0, 0, 0.7);

  z-index: 1;
`
