import styled from 'styled-components'

type ContentProps = {
  hasLeftSideComponent: boolean
  hasRightSideComponent: boolean
}

type ContainerProps = {
  isTeacher?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;

  overflow: hidden;
  border-radius: 1rem;
  border: 2px solid transparent;

  background: ${(props) => props.theme.background};

  padding: 1.6rem;

  cursor: pointer;
  transition: border-color 0.2s ease-in-out;

  &:hover,
  &:focus {
    border-color: ${(props) =>
      props.isTeacher ? props.theme.teacher : props.theme.student};
  }
`

export const Content = styled.div<ContentProps>`
  flex: 1;

  margin-left: ${(props) => (props.hasLeftSideComponent ? 1.6 : 0)}rem;
  margin-right: ${(props) => (props.hasRightSideComponent ? 1.6 : 0)}rem;
`

export const Title = styled.div`
  font-weight: 900;
  font-size: 1.8rem;
  color: ${(props) => props.theme.primaryText};

  display: flex;
  align-items: center;

  span {
    margin-left: 1.6rem;
  }
`

export const Text = styled.div`
  color: ${(props) => props.theme.secondaryText};

  display: flex;
  align-items: center;

  span {
    margin-left: 1.6rem;
    word-break: break-word;
  }
`
