import React, { useRef, useState } from 'react'
import StateManager from 'react-select'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FiCheckCircle, FiFileText, FiMail, FiUserPlus } from 'react-icons/fi'

import { useIsLoading, useSetBrowserTabTitle } from 'src/hooks'
import { requestRegisterStudent, STUDENT_TYPES } from 'src/store/ducks/student'
import {
  DefaultError,
  DefaultLabel,
  ReactSelectOption,
  SnowInput,
  WhiteSpinner,
} from 'src/components'

import {
  Container,
  RegisterUserTitle,
  Form,
  UserInput,
  SaveButton,
  StyledGroupData,
  StyledGroupSelect,
} from './styles'

interface RegisterUserParams {
  groupId?: string
}

const RegisterUser: React.FC = () => {
  useSetBrowserTabTitle('registerUser')

  const { t } = useTranslation(['RegisterUser', 'Error'])
  const { groupId } = useParams<RegisterUserParams>()
  const dispatch = useDispatch()

  const isSaving = useIsLoading(STUDENT_TYPES.REQUEST_REGISTER_STUDENT)

  const [errors, setErrors] = useState({
    nameEmpty: false,
    emailEmpty: false,
    groupNotSelected: false,
  })

  const groupRef = useRef<StateManager<ReactSelectOption> | null>(null)
  const emailRef = useRef<HTMLInputElement | null>(null)
  const nameRef = useRef<HTMLInputElement | null>(null)

  const clearAllValues = () => {
    if (groupRef.current) groupRef.current.select.setValue(null, 'set-value')
    if (nameRef.current) nameRef.current.value = ''
    if (emailRef.current) emailRef.current.value = ''
  }

  const getValues = () => {
    const selectedGroup = groupRef.current?.state.value as
      | ReactSelectOption
      | null
      | undefined

    const name = nameRef.current?.value.trim() || ''
    const email = emailRef.current?.value.trim() || ''

    return {
      selectedGroup,
      name,
      email,
    }
  }

  const handleValidation = (): boolean => {
    const { name, email, selectedGroup } = getValues()

    const errorState = {
      nameEmpty: !name.trim(),
      emailEmpty: !email.trim(),
      groupNotSelected: !groupId && !selectedGroup,
    }

    setErrors(errorState)
    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleSaveStudent = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!isSaving && handleValidation()) {
      const { name, email, selectedGroup } = getValues()

      let groupIdToSave = 0
      if (groupId) groupIdToSave = Number(groupId)
      else groupIdToSave = Number(selectedGroup?.value || 0)

      dispatch(
        requestRegisterStudent({
          name,
          email,
          groupId: groupIdToSave,
          successCallback() {
            clearAllValues()
          },
        }),
      )
    }
  }

  return (
    <Container>
      <RegisterUserTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<FiUserPlus />}
      />

      <Form onSubmit={handleSaveStudent} noValidate>
        <UserInput
          iconComponent={<FiFileText size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="name">
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('nameLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="name"
              name="name"
              ref={nameRef}
              type="text"
              autoCapitalize="words"
              placeholder={t('namePh')}
              disabled={isSaving}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.nameEmpty}
        />

        <UserInput
          iconComponent={<FiMail size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="email">
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('emailLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="email"
              ref={emailRef}
              type="email"
              autoCapitalize="off"
              placeholder={t('emailPh')}
              disabled={isSaving}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.emailEmpty}
        />

        {groupId ? (
          <StyledGroupData groupId={Number(groupId)} />
        ) : (
          <StyledGroupSelect
            groupRef={groupRef}
            isGroupNotSelected={errors.groupNotSelected}
          />
        )}

        <SaveButton type="submit" disabled={isSaving}>
          <span>{t('saveButton')}</span>
          {isSaving ? <WhiteSpinner /> : <FiCheckCircle />}
        </SaveButton>
      </Form>
    </Container>
  )
}

export default RegisterUser
