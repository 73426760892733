import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import { AxiosServerResponse, BLOCK_ROUTES, Server } from 'src/services/Server'

import { RequestDeleteBlockAction, BLOCK_TYPES } from './types'
import { removeFromBlockList } from './actions'

export function* requestDeleteBlockWatcher() {
  yield takeLatest(BLOCK_TYPES.REQUEST_DELETE, handleDeleteBlock)
}

export function* handleDeleteBlock(action: RequestDeleteBlockAction) {
  try {
    yield put(addLoading(BLOCK_TYPES.REQUEST_DELETE))
    const { id } = action.payload

    const routeArray = [BLOCK_ROUTES.BLOCO, id]

    const { data }: AxiosServerResponse = yield call(
      Server.delete,
      routeArray.join('/'),
    )

    if (data.isSuccessful) {
      yield put(removeFromBlockList(id))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(BLOCK_TYPES.REQUEST_DELETE))
  }
}
