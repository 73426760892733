import styled from 'styled-components'

import { Input, SuccessButton } from 'src/components'

export const Form = styled.form`
  max-width: 60rem;
  margin: auto;
`

export const TrainingZoneInput = styled(Input)`
  margin-bottom: 2.4rem;
`

export const SaveButton = styled(SuccessButton)``
