import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FiCheckCircle, FiChevronLeft, FiMail, FiSend } from 'react-icons/fi'

import { hideSuccess } from 'src/store/ducks/success'
import { useIsLoading, useSetBrowserTabTitle } from 'src/hooks'
import useIsShowingSuccess from 'src/hooks/useIsShowingSuccess'
import { DefaultLabel, SnowInput, Spinner } from 'src/components'
import { AUTH_TYPES, requestSendResetPassEmail } from 'src/store/ducks/auth'

import {
  Container,
  Form,
  Title,
  Subtitle,
  EmailInput,
  SendButton,
  SuccessContainer,
  SuccessTitle,
  SuccessMessage,
  BackButton,
} from './styles'

const ForgotPassword: React.FC = () => {
  useSetBrowserTabTitle('forgotPassword')

  const { t } = useTranslation('ForgotPassword')
  const dispatch = useDispatch()
  const history = useHistory()

  const emailRef = useRef<HTMLInputElement | null>(null)

  const isLoading = useIsLoading(AUTH_TYPES.REQUEST_SEND_RESET_PASS_EMAIL)

  const isShowingSuccess = useIsShowingSuccess(
    AUTH_TYPES.REQUEST_SEND_RESET_PASS_EMAIL,
  )

  const handleValidation = (email: string): boolean => {
    if (email.trim()) return true
    emailRef.current?.focus()
    return false
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const email = emailRef.current?.value || ''
    if (handleValidation(email)) {
      dispatch(requestSendResetPassEmail(email))
    }
  }

  const handleBackToLogin = () => {
    history.goBack()
  }

  useEffect(() => {
    return () => {
      dispatch(hideSuccess(AUTH_TYPES.REQUEST_SEND_RESET_PASS_EMAIL))
    }
  }, [dispatch])

  return (
    <Container>
      {isShowingSuccess ? (
        <SuccessContainer>
          <SuccessTitle>
            <FiCheckCircle />
            <span>{t('successTitle')}</span>
          </SuccessTitle>

          <SuccessMessage>{t('successMessage')}</SuccessMessage>

          <BackButton onClick={handleBackToLogin}>
            <FiChevronLeft />
            <span>{t('backButton')}</span>
          </BackButton>
        </SuccessContainer>
      ) : (
        <Form onSubmit={handleSubmit} noValidate>
          <Title>{t('title')}</Title>
          <Subtitle>{t('subtitle')}</Subtitle>

          <EmailInput
            iconComponent={<FiMail size="3.2rem" />}
            labelComponent={
              <DefaultLabel htmlFor="email">
                <span>{t('emailLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowInput
                id="email"
                ref={emailRef}
                type="email"
                disabled={isLoading}
                autoCapitalize="off"
                placeholder={t('emailPh')}
              />
            }
          />

          <SendButton type="submit" disabled={isLoading}>
            <span>{t('sendButton')}</span>
            {isLoading ? <Spinner /> : <FiSend />}
          </SendButton>
        </Form>
      )}
    </Container>
  )
}

export default ForgotPassword
