import React, { useMemo } from 'react'
import { FiInfo } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import { useTypedSelector } from 'src/hooks'
import { DangerBadge, SuccessBadge } from 'src/components'

import {
  Container,
  Content,
  TrainingDataContainer,
  TrainingData,
} from './styles'

const SheetInfo: React.FC = () => {
  const { t } = useTranslation(['TrainingSheetDetails', 'Glossary'])

  const trainingSheetData = useTypedSelector(
    ({ TrainingSheet }) => TrainingSheet.trainingSheetData,
  )

  const isTrainingActive = useMemo(() => {
    return trainingSheetData?.treino_situacao === 'AT'
  }, [trainingSheetData?.treino_situacao])

  return (
    <Container>
      <Content label={t('sheetInfo.label')} iconComponent={<FiInfo />}>
        <TrainingDataContainer>
          {!!trainingSheetData?.treino_descricao && (
            <TrainingData>
              <div>{t('sheetInfo.trainingNameLabel')}</div>
              <div>{trainingSheetData?.treino_descricao}</div>
            </TrainingData>
          )}

          {!!trainingSheetData?.treinonivel_descricao && (
            <TrainingData>
              <div>{t('sheetInfo.trainingLevelLabel')}</div>
              <div>{trainingSheetData?.treinonivel_descricao}</div>
            </TrainingData>
          )}

          <TrainingData>
            <div>{t('sheetInfo.trainingStatusLabel')}</div>
            <div>
              {isTrainingActive ? (
                <SuccessBadge style={{ display: 'inline' }}>
                  {t('Glossary:active', {
                    context: 'male',
                  })}
                </SuccessBadge>
              ) : (
                <DangerBadge style={{ display: 'inline' }}>
                  {t('Glossary:inactive', {
                    context: 'male',
                  })}
                </DangerBadge>
              )}
            </div>
          </TrainingData>
        </TrainingDataContainer>
      </Content>
    </Container>
  )
}

export default SheetInfo
