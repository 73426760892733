import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  color: ${(props) => props.theme.secondaryText};
  font-size: 1.8rem;

  flex: 1;
`

export const Title = styled.div`
  color: ${(props) => props.theme.primaryText};
  font-weight: 900;
  font-size: 4.8rem;

  @media only screen and (max-width: 900px) {
    font-size: 3.2rem;
  }
`

export const Subtitle = styled.div``

export const IconContainer = styled.div`
  font-size: 6rem;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.primaryText};

  margin-right: 2.4rem;
`
