import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FiPlusCircle,
  FiZap,
  FiRepeat,
  FiSettings,
  FiHash,
  FiActivity,
  FiPlus,
  FiBox,
  FiEdit,
} from 'react-icons/fi'

import { AddNewActivityModal } from 'src/views'
import { ACTIVITY_TYPES } from 'src/store/ducks/activity'
import { TRAINING_ZONE_TYPES } from 'src/store/ducks/trainingZone'
import { useAuthenticatedUserTypes, useIsLoading, useUnits } from 'src/hooks'
import {
  DefaultError,
  DefaultLabel,
  SnowInput,
  SnowSelect,
} from 'src/components'

import useActivityFormState from './useActivityFormState'
import { TrainingActivityFormProps } from './activityFormTypes'
import useFeedStateToEditActivity from './useFeedStateToEditActivity'
import useClearValuesWhenCancelEditing from './useClearValuesWhenCancelEditing'
import useClearTargetBlockWhenChangeBlocks from './useClearTargetBlockWhenChangeBlocks'
import {
  Form,
  TrainingActivityInput,
  AddTrainingActivity,
  CreateNewActivity,
  EditingIndicatorText,
  Content,
} from './styles'

const TrainingActivityForm: React.FC<TrainingActivityFormProps> = (props) => {
  const { blocks, editingActivityIndexes } = props

  const { t } = useTranslation(['TrainingManagement', 'Glossary', 'Error'])
  const isLoadingActivities = useIsLoading(ACTIVITY_TYPES.REQUEST_FETCH)
  const isLoadingZones = useIsLoading(TRAINING_ZONE_TYPES.REQUEST_FETCH)
  const { isAdmin } = useAuthenticatedUserTypes()
  const { unitsArray } = useUnits()

  const {
    activityRef,
    activityRepetitionsRef,
    unitRef,
    valueRef,
    trainingZoneRef,
    targetBlockRef,

    isEditing,
    errors,
    isShowingActivityModal,

    activityOptions,
    trainingZoneOptions,
    targetBlockOptions,

    clearValues,
    setValuesFromActivity,

    handleSelectActivityOption,
    handleShowActivityModal,
    handleHideActivityModal,
    handleSubmitForm,
  } = useActivityFormState(props)

  useFeedStateToEditActivity({
    blocks,
    editingActivityIndexes,
    setValuesFromActivity,
  })

  useClearTargetBlockWhenChangeBlocks({ blocks, targetBlockRef })
  useClearValuesWhenCancelEditing({ editingActivityIndexes, clearValues })

  return (
    <>
      {isAdmin && (
        <AddNewActivityModal
          isShowing={isShowingActivityModal}
          handleHideModal={handleHideActivityModal}
          onActivityAdded={handleSelectActivityOption}
        />
      )}

      <Form onSubmit={handleSubmitForm} isEditing={isEditing} noValidate>
        {isEditing && (
          <EditingIndicatorText>
            {t('editingActivityIndicatorText')}
          </EditingIndicatorText>
        )}

        <Content>
          {isAdmin && (
            <CreateNewActivity onClick={handleShowActivityModal} tabIndex={-1}>
              <span>{t('createNewActivity')}</span>
              <FiPlus />
            </CreateNewActivity>
          )}

          <TrainingActivityInput
            iconComponent={<FiZap size="3.2rem" />}
            labelComponent={
              <DefaultLabel htmlFor="activity">
                <span className="required">{t('Glossary:required')}</span>
                <span>{t('trainingActivityLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowSelect
                id="activity"
                name="activity"
                ref={activityRef}
                options={activityOptions}
                placeholder={t('trainingActivityPh')}
                noOptionsMessage={() => t('noActivitiesMessage')}
                loadingMessage={() => t('Glossary:loading')}
                isLoading={isLoadingActivities}
              />
            }
            errorComponent={
              <DefaultError>{t('Error:noOptionSelectedError')}</DefaultError>
            }
            showError={errors.isActivityNotSelected}
          />

          <TrainingActivityInput
            iconComponent={<FiRepeat size="3.2rem" />}
            labelComponent={
              <DefaultLabel htmlFor="activityRepetitions">
                <span className="required">{t('Glossary:required')}</span>
                <span>{t('activityRepetitionsLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowInput
                min={1}
                type="number"
                defaultValue={1}
                id="activityRepetitions"
                name="activityRepetitions"
                ref={activityRepetitionsRef}
                placeholder={t('activityRepetitionsPh')}
              />
            }
            errorComponent={
              <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
            }
            showError={errors.isActivityRepetitionsEmpty}
          />

          <TrainingActivityInput
            iconComponent={<FiSettings size="3.2rem" />}
            labelComponent={
              <DefaultLabel htmlFor="unit">
                <span className="required">{t('Glossary:required')}</span>
                <span>{t('unitLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowSelect
                id="unit"
                name="unit"
                ref={unitRef}
                placeholder={t('unitPh')}
                noOptionsMessage={() => t('noUnitMessage')}
                loadingMessage={() => t('Glossary:loading')}
                options={unitsArray.map((unit) => ({
                  label: unit.text,
                  value: unit.unit,
                }))}
              />
            }
            errorComponent={
              <DefaultError>{t('Error:noOptionSelectedError')}</DefaultError>
            }
            showError={errors.isActivityNotSelected}
          />

          <TrainingActivityInput
            iconComponent={<FiHash size="3.2rem" />}
            labelComponent={
              <DefaultLabel htmlFor="value">
                <span className="required">{t('Glossary:required')}</span>
                <span>{t('activityValueLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowInput
                ref={valueRef}
                type="number"
                id="value"
                name="value"
                min={0}
                placeholder={t('activityValuePh')}
              />
            }
            errorComponent={
              <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
            }
            showError={errors.isValueEmpty}
          />

          <TrainingActivityInput
            iconComponent={<FiActivity size="3.2rem" />}
            labelComponent={
              <DefaultLabel htmlFor="trainingZone">
                <span className="required">{t('Glossary:required')}</span>
                <span>{t('trainingZoneLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowSelect
                id="trainingZone"
                name="trainingZone"
                ref={trainingZoneRef}
                placeholder={t('trainingZonePh')}
                noOptionsMessage={() => t('noTrainingZoneMessage')}
                loadingMessage={() => t('Glossary:loading')}
                options={trainingZoneOptions}
                isLoading={isLoadingZones}
              />
            }
            errorComponent={
              <DefaultError>{t('Error:noOptionSelectedError')}</DefaultError>
            }
            showError={errors.isTrainingZoneEmpty}
          />

          <TrainingActivityInput
            iconComponent={<FiBox size="3.2rem" />}
            labelComponent={
              <DefaultLabel htmlFor="targetBlock">
                <span className="required">{t('Glossary:required')}</span>
                <span>{t('targetBlockLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowSelect
                id="targetBlock"
                name="targetBlock"
                ref={targetBlockRef}
                placeholder={t('targetBlockPh')}
                noOptionsMessage={() => t('noTargetBlockMessage')}
                options={targetBlockOptions}
              />
            }
            errorComponent={
              <DefaultError>{t('Error:noOptionSelectedError')}</DefaultError>
            }
            showError={errors.isTargetBlockNotSelected}
          />

          <AddTrainingActivity type="submit">
            <span>
              {t(isEditing ? 'editTrainingActivity' : 'addTrainingActivity')}
            </span>

            {isEditing ? <FiEdit /> : <FiPlusCircle />}
          </AddTrainingActivity>
        </Content>
      </Form>
    </>
  )
}

export default TrainingActivityForm
