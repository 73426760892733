import { all } from 'redux-saga/effects'

import {
  requestFetchStudentTrainingsWatcher,
  requestGetStudentTrainingWatcher,
  requestEditStudentTrainingWatcher,
  requestFetchFullStudentTrainingsWatcher,
} from './ducks/studentTraining'
import {
  requestAuthenticateWatcher,
  requestCreateAccountWatcher,
  requestSendResetPassEmailWatcher,
  requestCheckAuthWatcher,
  requestLogoutWatcher,
} from './ducks/auth'
import {
  requestFetchStudentsWatcher,
  requestRegisterStudentWatcher,
  requestFetchStudentsOutsideGroupWatcher,
} from './ducks/student'
import {
  requestCreateRunningGroupWatcher,
  requestEditRunningGroupWatcher,
  requestFetchRunningGroupsWatcher,
  requestGetRunningGroupWatcher,
  requestDeleteRunningGroupWatcher,
} from './ducks/runningGroup'
import {
  requestFetchUserDataWatcher,
  requestChangePasswordWatcher,
  requestEditUserProfileWatcher,
  requestFetchUsersWatcher,
} from './ducks/user'
import {
  requestAddTrainingLevelWatcher,
  requestDeleteTrainingLevelWatcher,
  requestEditTrainingLevelWatcher,
  requestFetchTrainingLevelsWatcher,
} from './ducks/trainingLevel'
import {
  requestAddActivityWatcher,
  requestDeleteActivityWatcher,
  requestEditActivityWatcher,
  requestFetchActivitiesWatcher,
} from './ducks/activity'
import {
  requestAddTrainingZoneWatcher,
  requestDeleteTrainingZoneWatcher,
  requestEditTrainingZoneWatcher,
  requestFetchTrainingZonesWatcher,
} from './ducks/trainingZone'
import {
  requestAddAnamneseQuizWatcher,
  requestGetAnamneseQuizWatcher,
  requestUpdateAnamneseQuizWatcher,
} from './ducks/anamnese'
import {
  requestAddBlockWatcher,
  requestDeleteBlockWatcher,
  requestEditBlockWatcher,
  requestFetchBlocksWatcher,
} from './ducks/block'
import {
  requestAddTrainingSheetWatcher,
  requestDeleteTrainingSheetWatcher,
  requestEditTrainingSheetWatcher,
  requestFetchTrainingSheetsWatcher,
  requestGetTrainingSheetWatcher,
  requestChangeTrainingSheetStatusWatcher,
} from './ducks/trainingSheet'
import {
  requestAnswerAnamneseQuizWatcher,
  requestFetchAnamneseQuizAnswersWatcher,
  requestCheckAnamneseAnswerWatcher,
} from './ducks/anamneseQuizAnswer'
import {
  requestFetchGroupMembersWatcher,
  requestRemoveGroupMemberWatcher,
  requestAddGroupMemberWatcher,
} from './ducks/groupMember'
import {
  requestGetTrainingConfigWatcher,
  requestEditTrainingConfigWatcher,
} from './ducks/studentTrainingConfig'

export default function* rootSaga() {
  yield all([
    requestAuthenticateWatcher(),
    requestSendResetPassEmailWatcher(),
    requestCreateAccountWatcher(),
    requestCheckAuthWatcher(),
    requestLogoutWatcher(),

    requestFetchStudentsWatcher(),
    requestRegisterStudentWatcher(),
    requestFetchStudentsOutsideGroupWatcher(),

    requestFetchStudentTrainingsWatcher(),
    requestGetStudentTrainingWatcher(),
    requestEditStudentTrainingWatcher(),
    requestFetchFullStudentTrainingsWatcher(),

    requestFetchRunningGroupsWatcher(),
    requestCreateRunningGroupWatcher(),
    requestEditRunningGroupWatcher(),
    requestGetRunningGroupWatcher(),
    requestDeleteRunningGroupWatcher(),

    requestFetchUserDataWatcher(),
    requestChangePasswordWatcher(),
    requestEditUserProfileWatcher(),
    requestFetchUsersWatcher(),

    requestAddTrainingLevelWatcher(),
    requestDeleteTrainingLevelWatcher(),
    requestEditTrainingLevelWatcher(),
    requestFetchTrainingLevelsWatcher(),

    requestAddActivityWatcher(),
    requestDeleteActivityWatcher(),
    requestEditActivityWatcher(),
    requestFetchActivitiesWatcher(),

    requestAddTrainingZoneWatcher(),
    requestDeleteTrainingZoneWatcher(),
    requestEditTrainingZoneWatcher(),
    requestFetchTrainingZonesWatcher(),

    requestAddAnamneseQuizWatcher(),
    requestGetAnamneseQuizWatcher(),
    requestUpdateAnamneseQuizWatcher(),

    requestAddBlockWatcher(),
    requestDeleteBlockWatcher(),
    requestEditBlockWatcher(),
    requestFetchBlocksWatcher(),

    requestAddTrainingSheetWatcher(),
    requestDeleteTrainingSheetWatcher(),
    requestEditTrainingSheetWatcher(),
    requestFetchTrainingSheetsWatcher(),
    requestGetTrainingSheetWatcher(),
    requestChangeTrainingSheetStatusWatcher(),

    requestAnswerAnamneseQuizWatcher(),
    requestFetchAnamneseQuizAnswersWatcher(),
    requestCheckAnamneseAnswerWatcher(),

    requestFetchGroupMembersWatcher(),
    requestRemoveGroupMemberWatcher(),
    requestAddGroupMemberWatcher(),

    requestGetTrainingConfigWatcher(),
    requestEditTrainingConfigWatcher(),
  ])
}
