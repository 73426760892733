import React from 'react'
import { FiCalendar, FiFileText, FiUser } from 'react-icons/fi'

import { ImagePlaceholder } from 'src/assets'

import { Container, Content, Title, Image, Text } from './styles'

export interface RunningGroupItemProps {
  className?: string
  style?: React.CSSProperties
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  imageSrc?: string | null
  imageAlt?: string
  name?: string
  createdAt?: string
  numberOfMembers?: string
  contentHeaderComponent?: React.ReactNode
  contentFooterComponent?: React.ReactNode
}

export const RunningGroupItem: React.FC<RunningGroupItemProps> = (props) => {
  const {
    style,
    className,
    onClick,
    imageSrc,
    imageAlt,
    name,
    createdAt,
    numberOfMembers,
    contentHeaderComponent,
    contentFooterComponent,
  } = props

  return (
    <Container style={style} className={className} onClick={onClick}>
      <Image src={imageSrc || ImagePlaceholder} alt={imageAlt} />

      <Content>
        {contentHeaderComponent}

        {!!name && (
          <Title>
            <FiFileText />
            <span>{name}</span>
          </Title>
        )}

        {!!createdAt && (
          <Text>
            <FiCalendar />
            <span>{createdAt}</span>
          </Text>
        )}

        {!!numberOfMembers && (
          <Text>
            <FiUser />
            <span>{numberOfMembers}</span>
          </Text>
        )}

        {contentFooterComponent}
      </Content>
    </Container>
  )
}
