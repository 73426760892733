export enum GROUP_MEMBER_TYPES {
  REQUEST_FETCH_MEMBERS = '@GROUP_MEMBER/REQUEST_FETCH_MEMBERS',
  REQUEST_REMOVE_MEMBER = '@GROUP_MEMBER/REQUEST_REMOVE_MEMBER',
  REQUEST_ADD_MEMBER = '@GROUP_MEMBER/REQUEST_ADD_MEMBER',

  SET_MEMBER_LIST = '@GROUP_MEMBER/SET_MEMBER_LIST',
  REMOVE_FROM_MEMBER_LIST = '@GROUP_MEMBER/REMOVE_FROM_MEMBER_LIST',
}

export interface GroupMemberState {
  groupMembers: Models.GroupedTypes.GroupMember[]
}

export interface RequestFetchGroupMembersAction {
  type: GROUP_MEMBER_TYPES.REQUEST_FETCH_MEMBERS
  payload: {
    id: number
  }
}

export interface RequestRemoveGroupMemberAction {
  type: GROUP_MEMBER_TYPES.REQUEST_REMOVE_MEMBER
  payload: {
    id: number
    isStudent: boolean
    shouldRemoveFromMemberList?: boolean
  }
}

export interface RequestAddGroupMemberAction {
  type: GROUP_MEMBER_TYPES.REQUEST_ADD_MEMBER
  payload: {
    userId: number
    groupId: number
    isStudent: boolean
    successCallback?: () => void
  }
}

export interface SetGroupMemberListAction {
  type: GROUP_MEMBER_TYPES.SET_MEMBER_LIST
  payload: {
    groupMembers: Models.GroupedTypes.GroupMember[]
  }
}

export interface RemoveFromGroupMemberListAction {
  type: GROUP_MEMBER_TYPES.REMOVE_FROM_MEMBER_LIST
  payload: {
    id: number
  }
}
