import { call, put, takeLatest } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  AxiosServerResponse,
  Server,
  STUDENT_TRAINING_ROUTES,
} from 'src/services/Server'

import { setStudentTrainingList } from './actions'
import {
  STUDENT_TRAINING_TYPES,
  RequestFetchStudentTrainingsAction,
} from './types'

type Filters = {
  usuario_id: number
  FILTRO_GERAL?: string
  DATA_INICIAL?: string
  DATA_FINAL?: string
  SQL_LIMIT?: number
  SQL_OFFSET?: number
}

type ResponseData = Models.StudentTrainingGroupedTypes.TrainingWithTrainingLevel

export function* requestFetchStudentTrainingsWatcher() {
  yield takeLatest(
    STUDENT_TRAINING_TYPES.REQUEST_FETCH,
    handleFetchStudentTrainings,
  )
}

export function* handleFetchStudentTrainings(
  action: RequestFetchStudentTrainingsAction,
) {
  try {
    yield put(addLoading(STUDENT_TRAINING_TYPES.REQUEST_FETCH))

    const {
      userId,
      search,
      initialDate,
      finalDate,
      limit,
      offset,
    } = action.payload

    const hasOffset = !!offset && offset > 0

    const filters: Filters = {
      usuario_id: userId,
      FILTRO_GERAL: search,
      DATA_INICIAL: initialDate || undefined,
      DATA_FINAL: finalDate || undefined,
      SQL_LIMIT: limit,
      SQL_OFFSET: offset,
    }

    const { data }: AxiosServerResponse<ResponseData> = yield call(
      Server.put,
      STUDENT_TRAINING_ROUTES.GET_LISTA,
      filters,
    )

    if (data.isSuccessful) {
      yield put(setStudentTrainingList(data.data, hasOffset))
    } else {
      yield put(setStudentTrainingList([], hasOffset))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(STUDENT_TRAINING_TYPES.REQUEST_FETCH))
  }
}
