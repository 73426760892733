import { useEffect } from 'react'

import {
  EditStudentTrainingBlock,
  EditStudentTrainingActivity,
  EditingActivityIndexes,
} from '../types'

type Params = {
  blocks: EditStudentTrainingBlock[]
  editingActivityIndexes: EditingActivityIndexes
  setValuesFromActivity: (activity: EditStudentTrainingActivity) => void
}

export default ({
  blocks,
  editingActivityIndexes,
  setValuesFromActivity,
}: Params) => {
  useEffect(() => {
    if (
      editingActivityIndexes.activityIndex !== -1 &&
      editingActivityIndexes.blockIndex !== -1
    ) {
      const { blockIndex, activityIndex } = editingActivityIndexes
      const block = blocks[blockIndex]
      const activities = block.atividades
      const activityToEdit = activities[activityIndex]
      setValuesFromActivity(activityToEdit)
    }
  }, [blocks, editingActivityIndexes, setValuesFromActivity])
}
