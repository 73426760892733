import React from 'react'
import { useTranslation } from 'react-i18next'
import { BsFileEarmarkRuled, BsPeople } from 'react-icons/bs'
import {
  FiFile,
  FiFilePlus,
  FiPieChart,
  FiUser,
  FiUserPlus,
  FiUsers,
} from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

import { useAuthenticatedUserTypes } from 'src/hooks'

import {
  Container,
  Title,
  Subtitle,
  Action,
  AdminActions,
  StudentActions,
  TeacherActions,
} from './styles'

const FastActions: React.FC = () => {
  const { isAdmin, isStudent, isTeacher } = useAuthenticatedUserTypes()
  const { t } = useTranslation('Home')
  const history = useHistory()

  const getNavigationAction = (page: string) => () => {
    history.push(page)
  }

  const renderActions = () => {
    if (isAdmin) {
      return (
        <AdminActions>
          <Action
            text={t('fastActions.trainingConfig')}
            iconComponent={<BsFileEarmarkRuled />}
            onClick={getNavigationAction('/training-config')}
          />

          <Action
            text={t('fastActions.newStudent')}
            iconComponent={<FiUserPlus />}
            onClick={getNavigationAction('/new-student')}
          />

          <Action
            text={t('fastActions.newRunningGroup')}
            iconComponent={<BsPeople />}
            onClick={getNavigationAction('/create-running-group')}
          />

          <Action
            text={t('fastActions.dashboard')}
            iconComponent={<FiPieChart />}
            onClick={getNavigationAction('/dashboard')}
          />
        </AdminActions>
      )
    } else if (isTeacher) {
      return (
        <TeacherActions>
          <Action
            text={t('fastActions.newStudent')}
            iconComponent={<FiUserPlus />}
            onClick={getNavigationAction('/new-student')}
          />

          <Action
            text={t('fastActions.myStudents')}
            iconComponent={<FiUsers />}
            onClick={getNavigationAction('/my-students')}
          />

          <Action
            text={t('fastActions.newRunningGroup')}
            iconComponent={<FiFilePlus />}
            onClick={getNavigationAction('/create-running-group')}
          />

          <Action
            text={t('fastActions.myRunningGroups')}
            iconComponent={<BsPeople />}
            onClick={getNavigationAction('/my-running-groups')}
          />
        </TeacherActions>
      )
    } else if (isStudent) {
      return (
        <StudentActions>
          <Action
            text={t('fastActions.myTrainings')}
            iconComponent={<FiFile />}
            onClick={getNavigationAction('/my-trainings')}
          />

          <Action
            text={t('fastActions.myProfile')}
            iconComponent={<FiUser />}
            onClick={getNavigationAction('/user-profile')}
          />

          <Action
            text={t('fastActions.myRunningGroups')}
            iconComponent={<BsPeople />}
            onClick={getNavigationAction('/my-running-groups')}
          />
        </StudentActions>
      )
    } else {
      return null
    }
  }

  return (
    <Container>
      <Title>{t('fastActionsTitle')}</Title>
      <Subtitle>{t('fastActionsSubtitle')}</Subtitle>
      {renderActions()}
    </Container>
  )
}

export default FastActions
