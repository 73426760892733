import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import { showSuccessToast } from 'src/store/ducks/success'
import { withServerStructure } from 'src/utils'
import {
  AxiosServerResponse,
  Server,
  STUDENT_TRAINING_ROUTES,
} from 'src/services/Server'

import {
  STUDENT_TRAINING_TYPES,
  RequestEditStudentTrainingAction,
} from './types'

export function* requestEditStudentTrainingWatcher() {
  yield takeLatest(
    STUDENT_TRAINING_TYPES.REQUEST_EDIT,
    handleEditStudentTraining,
  )
}

export function* handleEditStudentTraining(
  action: RequestEditStudentTrainingAction,
) {
  try {
    yield put(addLoading(STUDENT_TRAINING_TYPES.REQUEST_EDIT))
    const { trainingSheet, successCallback } = action.payload

    const {
      data,
    }: AxiosServerResponse<Models.StudentTrainingGroupedTypes.TrainingSheet> = yield call(
      Server.post,
      STUDENT_TRAINING_ROUTES.TREINO_ALUNO,
      withServerStructure(trainingSheet),
    )

    if (data.isSuccessful) {
      if (successCallback) successCallback()
      yield put(showSuccessToast({ messageCode: 'editStudentTraining' }))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(STUDENT_TRAINING_TYPES.REQUEST_EDIT))
  }
}
