import styled, { css } from 'styled-components'

import {
  EmptyMessage,
  TrainingSheetContainer,
  TrainingBlock,
  TrainingActivity,
  RoundIconButton,
} from 'src/components'

type ActivityEditButtonProps = {
  isEditing?: boolean
}

export const NoTrainingSheet = styled(EmptyMessage)`
  width: 100%;
  max-width: unset;

  margin: 4.8rem auto;
`

export const TrainingSheet = styled(TrainingSheetContainer)`
  min-width: 36rem;
  max-width: 90rem;

  margin: 4.8rem auto;
`

export const Block = styled(TrainingBlock)``

export const Activity = styled(TrainingActivity)`
  flex: 1;

  border-right: 1px solid ${(props) => props.theme.border};
`

export const ActivityContainer = styled.div`
  display: flex;
  align-items: center;

  height: 100%;
`

export const ActivityActions = styled.div`
  display: flex;
  align-items: center;

  padding: 0 0.8rem;
  margin: 0.4rem 0;
`

export const ActivityActionButton = styled(RoundIconButton)`
  width: 3.6rem;
  height: 3.6rem;

  margin: 0 0.2rem;

  font-size: 1.6rem;

  background: ${(props) =>
    props.disabled ? props.theme.snow : props.theme.background};
`

export const ActivityEditButton = styled(
  ActivityActionButton,
)<ActivityEditButtonProps>`
  ${(props) => {
    if (props.isEditing) {
      return css`
        background: ${props.theme.danger} !important;
        color: white !important;
      `
    }
  }}
`
