import { useEffect } from 'react'
import StateManager from 'react-select'

import { ReactSelectOption } from 'src/components'
import { useTypedSelector } from 'src/hooks'

import { EditTrainingBlock } from '../types'

type Params = {
  setBlocks: (blocks: EditTrainingBlock[]) => void
  trainingLevelRef: React.MutableRefObject<StateManager<ReactSelectOption> | null>
}

export default ({ setBlocks, trainingLevelRef }: Params) => {
  const trainingSheetData = useTypedSelector(
    ({ TrainingSheet }) => TrainingSheet.trainingSheetData,
  )

  const trainingLevels = useTypedSelector(
    ({ TrainingLevel }) => TrainingLevel.trainingLevels,
  )

  useEffect(() => {
    if (trainingSheetData) {
      setBlocks(trainingSheetData.blocos || [])

      const trainingLevelId = Number(trainingSheetData.treinonivel_id)
      const trainingLevel = trainingLevels.find(
        ({ treinonivel_id }) => treinonivel_id === trainingLevelId,
      )

      if (trainingLevel && trainingLevelRef.current) {
        const trainingSelectOption: ReactSelectOption = {
          label: trainingLevel.treinonivel_descricao,
          value: String(trainingLevel.treinonivel_id),
        }

        trainingLevelRef.current.select.setValue(
          trainingSelectOption,
          'set-value',
        )
      }
    }
  }, [setBlocks, trainingLevelRef, trainingLevels, trainingSheetData])
}
