import styled from 'styled-components'

import {
  AnamneseItem,
  AnamneseQuestion,
  EmptyMessage,
  RoundIconButton,
} from 'src/components'

type EditingProps = {
  isEditing: boolean
}

export const Container = styled.div``

export const Anamnese = styled(AnamneseItem)<EditingProps>`
  border-color: ${(props) => {
    return props.isEditing ? props.theme.success : props.theme.snow
  }};
`

export const Question = styled(AnamneseQuestion)`
  pointer-events: none;
`

export const ListEmpty = styled(EmptyMessage)`
  margin: auto;
`

export const EditIconButton = styled(RoundIconButton)<EditingProps>`
  background: ${(props) => {
    return props.isEditing ? props.theme.success : 'transparent'
  }} !important;

  color: ${(props) => {
    return props.isEditing ? 'white' : undefined
  }} !important;
`
