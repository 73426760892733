import styled from 'styled-components'

import { UserItem } from 'src/components'

export const Container = styled.div`
  margin-bottom: 2.4rem;
`

export const User = styled(UserItem)`
  pointer-events: none;

  background: ${(props) => props.theme.snow};

  border: 1px solid ${(props) => props.theme.snowDark};
`
