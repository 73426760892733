import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  ANAMNESE_ROUTES,
  Server,
} from 'src/services/Server'

import { setAnamneseQuiz } from './actions'
import { ANAMNESE_TYPES } from './types'

interface AnamneseAndQuestion
  extends Models.Anamnese,
    Models.AnamneseQuestion {}

type AxiosResponse = AxiosServerResponse<AnamneseAndQuestion>

export const getAnamneseQuiz = (
  response: AxiosResponse['data'],
): Models.AnamneseGroupedTypes.AnamneseWithQuestions | undefined => {
  if (!response.data?.length) return undefined

  const questions = response.data.map(
    ({
      anaminese_id,
      anamipergunta_id,
      anamipergunta_sequencia,
      anamipergunta_descricao,
    }) => ({
      anaminese_id,
      anamipergunta_id,
      anamipergunta_sequencia,
      anamipergunta_descricao,
    }),
  )

  const firstQuestion = response.getFirstData() as AnamneseAndQuestion

  const quiz: Models.AnamneseGroupedTypes.AnamneseWithQuestions = {
    anaminese_id: firstQuestion?.anaminese_id,
    anaminese_ativo: firstQuestion?.anaminese_ativo,
    anaminese_dtinclusao: firstQuestion?.anaminese_dtinclusao,
    questions,
  }

  return quiz
}

export function* requestGetAnamneseQuizWatcher() {
  yield takeLatest(ANAMNESE_TYPES.REQUEST_GET, handleGetAnamneseQuiz)
}

export function* handleGetAnamneseQuiz() {
  try {
    yield put(addLoading(ANAMNESE_TYPES.REQUEST_GET))

    const { data }: AxiosResponse = yield call(
      Server.get,
      ANAMNESE_ROUTES.GET_ANAMNESE_ATIVO,
    )

    if (data.isSuccessful && data.hasData) {
      const quiz = getAnamneseQuiz(data)
      yield put(setAnamneseQuiz(quiz))
    } else {
      yield put(setAnamneseQuiz(undefined))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(ANAMNESE_TYPES.REQUEST_GET))
  }
}
