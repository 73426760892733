import {
  GROUP_MEMBER_TYPES,
  RequestFetchGroupMembersAction,
  RequestRemoveGroupMemberAction,
  RequestAddGroupMemberAction,
  SetGroupMemberListAction,
  RemoveFromGroupMemberListAction,
} from './types'

export const requestFetchGroupMembers = (
  id: number,
): RequestFetchGroupMembersAction => ({
  type: GROUP_MEMBER_TYPES.REQUEST_FETCH_MEMBERS,
  payload: {
    id,
  },
})

export const requestRemoveGroupMember = (
  id: number,
  isStudent: boolean = true,
  shouldRemoveFromMemberList: boolean = false,
): RequestRemoveGroupMemberAction => ({
  type: GROUP_MEMBER_TYPES.REQUEST_REMOVE_MEMBER,
  payload: {
    id,
    isStudent,
    shouldRemoveFromMemberList,
  },
})

export const requestAddGroupMember = ({
  userId,
  groupId,
  isStudent = true,
  successCallback,
}: {
  userId: number
  groupId: number
  isStudent: boolean
  successCallback?: () => void
}): RequestAddGroupMemberAction => ({
  type: GROUP_MEMBER_TYPES.REQUEST_ADD_MEMBER,
  payload: {
    userId,
    groupId,
    isStudent,
    successCallback,
  },
})

export const setGroupMemberList = (
  groupMembers: Models.GroupedTypes.GroupMember[],
): SetGroupMemberListAction => ({
  type: GROUP_MEMBER_TYPES.SET_MEMBER_LIST,
  payload: {
    groupMembers,
  },
})

export const removeFromGroupMemberList = (
  id: number,
): RemoveFromGroupMemberListAction => ({
  type: GROUP_MEMBER_TYPES.REMOVE_FROM_MEMBER_LIST,
  payload: {
    id,
  },
})
