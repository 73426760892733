import { call, put, takeLatest } from 'redux-saga/effects'

import { showSuccessToast } from 'src/store/ducks/success'
import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  GROUP_STUDENT_ANAMINESE_ROUTES,
  AxiosServerResponse,
  Server,
} from 'src/services/Server'
import { withServerStructure } from 'src/utils'

import {
  Answer,
  ANAMNESE_QUIZ_ANSWER_TYPES,
  RequestAnswerAnamneseQuizAction,
} from './types'

type RequestData = Pick<Models.Anamnese, 'anaminese_id'> &
  Pick<Models.StudentGroup, 'grupoaluno_id'> & {
    perguntas: Answer[]
  }

export function* requestAnswerAnamneseQuizWatcher() {
  yield takeLatest(
    ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_ANSWER_QUIZ,
    handleAnswerAnamneseQuiz,
  )
}

export function* handleAnswerAnamneseQuiz(
  action: RequestAnswerAnamneseQuizAction,
) {
  try {
    yield put(addLoading(ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_ANSWER_QUIZ))
    const { groupAndStudentId, anamneseId, answers } = action.payload

    const requestData: RequestData = {
      anaminese_id: anamneseId,
      grupoaluno_id: groupAndStudentId,
      perguntas: answers,
    }

    const { data }: AxiosServerResponse<Models.AnamneseQuestion> = yield call(
      Server.put,
      GROUP_STUDENT_ANAMINESE_ROUTES.GRUPO_ALUNO_ANAMNESE,
      withServerStructure(requestData),
    )

    if (data.isSuccessful) {
      yield put(showSuccessToast({ messageCode: 'answerAnamneseQuiz' }))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_ANSWER_QUIZ))
  }
}
