import React from 'react'
import { useTranslation } from 'react-i18next'
import { FiCheckCircle, FiXCircle } from 'react-icons/fi'

import { WhiteSpinner } from 'src/components'
import { InfinitePagination, useIsLoading } from 'src/hooks'
import { TRAINING_SHEET_TYPES } from 'src/store/ducks/trainingSheet'
import {
  TrainingNameInput,
  TrainingLevelSelect,
  TrainingSheetPreview,
} from 'src/views'

import TrainingActivityForm from '../TrainingActivityForm'
import TrainingBlockForm from '../TrainingBlockForm'
import { TrainingConfigFilters } from '../types'

import useFetchNecessaryConfigs from './useFetchNecessaryConfigs'
import useTrainingSheetState from './useTrainingSheetState'
import {
  Container,
  Content,
  FormsContainer,
  SaveTrainingSheetButton,
  Divider,
  CancelBuildNewSheetButton,
} from './styles'

interface BuildTrainingSheetProps {
  handleCancelBuildNewSheet: () => void
  setFilters: React.Dispatch<React.SetStateAction<TrainingConfigFilters>>
  pagination: InfinitePagination
}

const BuildTrainingSheet: React.FC<BuildTrainingSheetProps> = (props) => {
  const { handleCancelBuildNewSheet, pagination, setFilters } = props

  const { t } = useTranslation('TrainingManagement')
  const isSaving = useIsLoading(TRAINING_SHEET_TYPES.REQUEST_ADD)

  const {
    trainingLevelRef,
    trainingNameRef,

    blocks,
    editingBlockIndex,
    setEditingBlockIndex,
    editingActivityIndexes,
    setEditingActivityIndexes,
    errors,

    handleCancelEditingBlock,
    handleCancelEditingActivity,
    handleAddTrainingBlock,
    handleRemoveTrainingBlock,
    handleAddActivityInBlock,
    handleRemoveActivityFromBlock,
    handleMoveActivityInBlock,
    handleSaveTrainingSheet,
    handleEditBlock,
    handleEditActivity,
  } = useTrainingSheetState({ pagination, setFilters })

  useFetchNecessaryConfigs()

  return (
    <Container>
      <CancelBuildNewSheetButton onClick={handleCancelBuildNewSheet}>
        <span>{t('cancelBuildNewSheetButton')}</span>
        <FiXCircle />
      </CancelBuildNewSheetButton>

      <TrainingLevelSelect
        ref={trainingLevelRef}
        isShowingError={errors.isTrainingLevelNotSelected}
      />

      <TrainingNameInput
        ref={trainingNameRef}
        isShowingError={errors.isTrainingNameEmpty}
      />

      <Content>
        <FormsContainer>
          <TrainingBlockForm
            blocks={blocks}
            editingBlockIndex={editingBlockIndex}
            handleAddTrainingBlock={handleAddTrainingBlock}
            handleEditBlock={handleEditBlock}
          />

          <Divider />

          <TrainingActivityForm
            blocks={blocks}
            handleEditActivity={handleEditActivity}
            handleAddActivityInBlock={handleAddActivityInBlock}
            editingActivityIndexes={editingActivityIndexes}
          />
        </FormsContainer>

        <TrainingSheetPreview
          blocks={blocks}
          editingBlockIndex={editingBlockIndex}
          setEditingBlockIndex={setEditingBlockIndex}
          editingActivityIndexes={editingActivityIndexes}
          setEditingActivityIndexes={setEditingActivityIndexes}
          handleCancelEditingActivity={handleCancelEditingActivity}
          handleCancelEditingBlock={handleCancelEditingBlock}
          handleRemoveTrainingBlock={handleRemoveTrainingBlock}
          handleRemoveActivityFromBlock={handleRemoveActivityFromBlock}
          handleMoveActivityInBlock={handleMoveActivityInBlock}
        />
      </Content>

      <SaveTrainingSheetButton onClick={handleSaveTrainingSheet}>
        <span>{t('saveTrainingSheetButton')}</span>
        {isSaving ? <WhiteSpinner /> : <FiCheckCircle />}
      </SaveTrainingSheetButton>
    </Container>
  )
}

export default BuildTrainingSheet
