import styled from 'styled-components'

import {
  DefaultDivider,
  PageContainer,
  PageTitle,
  SuccessButton,
} from 'src/components'

export const Container = styled(PageContainer)``

export const AnamneseConfigTitle = styled(PageTitle)`
  margin-bottom: 4.8rem;
`

export const CreateAnamneseButton = styled(SuccessButton)`
  width: auto;
  padding: 0 2.4rem;
`

export const Divider = styled(DefaultDivider)`
  margin: 2.4rem 0;
`
