import { ServerTransformedMessage } from 'src/services/Server'

import { ERROR_TYPES } from './types'

export const showError = (messages: string[], title?: string) => ({
  type: ERROR_TYPES.SHOW_ERROR,
  payload: {
    title,
    messages,
  },
})

export const showServerError = (
  serverMessages: ServerTransformedMessage[],
  title?: string,
) => ({
  type: ERROR_TYPES.SHOW_ERROR,
  payload: {
    title,
    messages: serverMessages.map((message) => message.fullMessage),
  },
})

export const hideError = () => ({
  type: ERROR_TYPES.HIDE_ERROR,
})

