import React, { forwardRef } from 'react'
import { FiMail } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import { DefaultError, DefaultLabel, SnowInput } from 'src/components'

import { EditProfileInput } from './styles'

interface UserEmailInputProps {
  defaultEmail?: string
  isEmailEmpty: boolean
}

const UserEmailInput = forwardRef<HTMLInputElement, UserEmailInputProps>(
  (props, ref) => {
    const { defaultEmail, isEmailEmpty } = props

    const { t } = useTranslation(['EditUserProfile', 'Error', 'Glossary'])

    return (
      <EditProfileInput
        iconComponent={<FiMail size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="email">
            <span className="required">{t('Glossary:required')}</span>
            <span>{t('emailLabel')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowInput
            ref={ref}
            id="email"
            type="email"
            autoCapitalize="off"
            placeholder={t('emailPh')}
            defaultValue={defaultEmail}
          />
        }
        errorComponent={
          <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
        }
        showError={isEmailEmpty}
      />
    )
  },
)

UserEmailInput.displayName = 'UserEmailInput'

export default UserEmailInput
