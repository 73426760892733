import styled, { css } from 'styled-components'

import { Button } from 'src/components'

type AccountTypeButtonProps = {
  isSelected: boolean
}

export const Container = styled.div`
  margin-bottom: 3.2rem;
`

export const Label = styled.div`
  text-align: center;
  margin-bottom: 1.6rem;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const PillButton = styled(Button)<AccountTypeButtonProps>`
  border-radius: 10rem;

  height: 4rem;
  width: auto;
  min-width: 15rem;

  font-size: 1.6rem;
  color: white;

  padding: 0 1.6rem;
  margin: 0 0.8rem;

  span {
    margin: 0 0 0 1.6rem;
  }
`

export const TeacherButton = styled(PillButton)`
  border: 1px solid ${(props) => props.theme.secondaryText};
  color: ${(props) => props.theme.primaryText};
  background: ${(props) => props.theme.background};

  ${(props) => {
    if (props.isSelected) {
      return css`
        pointer-events: none;
        border: 1px solid ${(props) => props.theme.teacher};
        background: ${props.theme.teacher};
        color: white;

        &:hover,
        &:focus {
          background: ${(props) => props.theme.teacherDark};
        }
      `
    }
  }};
`

export const StudentButton = styled(PillButton)`
  border: 1px solid ${(props) => props.theme.secondaryText};
  color: ${(props) => props.theme.primaryText};
  background: ${(props) => props.theme.background};

  ${(props) => {
    if (props.isSelected) {
      return css`
        pointer-events: none;
        border: 1px solid ${(props) => props.theme.student};
        background: ${props.theme.student};
        color: white;

        &:hover,
        &:focus {
          background: ${(props) => props.theme.studentDark};
        }
      `
    }
  }};
`
