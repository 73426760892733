import styled from 'styled-components'

import { DrawerLabel } from 'src/components'

export const Group = styled.div`
  padding-top: 1.2rem;
  margin-bottom: 1.6rem;

  border-top: 1px solid ${(props) => props.theme.contrastHover};
`

export const GroupLabel = styled(DrawerLabel)`
  margin-bottom: 1.2rem;
`
