import styled from 'styled-components'

import {
  TrainingSheetContainer,
  TrainingBlock,
  TrainingActivity,
} from 'src/components'

export const Container = styled.div``

export const TrainingSheet = styled(TrainingSheetContainer)`
  min-width: 36rem;
  max-width: 90rem;

  margin: auto;
`

export const Block = styled(TrainingBlock)``

export const Activity = styled(TrainingActivity)``
