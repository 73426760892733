import { call, put, takeLatest } from 'redux-saga/effects'

import { withServerStructure } from 'src/utils'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  Server,
  ANAMNESE_ROUTES,
} from 'src/services/Server'

import { requestGetAnamneseQuiz } from './actions'
import { RequestAddAnamneseQuizAction, ANAMNESE_TYPES } from './types'

type QuestionsToSave = Pick<Models.AnamneseQuestion, 'anamipergunta_descricao'>

type RequestData = Pick<Models.Anamnese, 'anaminese_ativo'> & {
  perguntas: QuestionsToSave[]
}

export function* requestAddAnamneseQuizWatcher() {
  yield takeLatest(ANAMNESE_TYPES.REQUEST_ADD, handleAddAnamneseQuiz)
}

export function* handleAddAnamneseQuiz(action: RequestAddAnamneseQuizAction) {
  try {
    yield put(addLoading(ANAMNESE_TYPES.REQUEST_ADD))
    const { questions, successCallback } = action.payload

    const questionsToSave: QuestionsToSave[] = questions.map((question) => ({
      anamipergunta_descricao: question,
    }))

    const requestData: RequestData = {
      anaminese_ativo: 'S',
      perguntas: questionsToSave,
    }

    const { data }: AxiosServerResponse<Models.Anamnese> = yield call(
      Server.put,
      ANAMNESE_ROUTES.ANAMNESE,
      withServerStructure(requestData),
    )

    if (data.isSuccessful) {
      if (successCallback) yield call(successCallback)
      yield put(requestGetAnamneseQuiz())
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(ANAMNESE_TYPES.REQUEST_ADD))
  }
}
