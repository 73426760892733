import { useEffect } from 'react'

type Params = {
  editingBlockIndex: number
  clearValues: () => void
}

export default ({ editingBlockIndex, clearValues }: Params) => {
  useEffect(() => {
    if (editingBlockIndex === -1) clearValues()
  }, [editingBlockIndex, clearValues])
}
