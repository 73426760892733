import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import { AxiosServerResponse, USER_ROUTES, Server } from 'src/services/Server'

import { setUserList } from './actions'
import { USER_TYPES } from './types'

export function* requestFetchUsersWatcher() {
  yield takeLatest(USER_TYPES.REQUEST_FETCH_USERS, handleFetchUsers)
}

export function* handleFetchUsers() {
  try {
    yield put(addLoading(USER_TYPES.REQUEST_FETCH_USERS))

    const { data }: AxiosServerResponse<Models.User> = yield call(
      Server.put,
      USER_ROUTES.GET_POR_CAMPOS,
      {},
    )

    if (data.isSuccessful) {
      yield put(setUserList(data.data))
    } else {
      yield put(setUserList([]))
      if (data.messages.length) {
        yield put(showServerError(data.messages))
      }
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(USER_TYPES.REQUEST_FETCH_USERS))
  }
}
