import styled from 'styled-components'

import { BigClickableCard } from 'src/components'

const Content = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`

export const Container = styled.div``

export const Title = styled.div`
  font-size: 2.4rem;
  font-weight: 900;

  margin-bottom: 0.4rem;
  margin-left: 0.8rem;
`

export const Subtitle = styled.div`
  color: ${(props) => props.theme.secondaryText};

  margin-bottom: 0.8rem;
  margin-left: 0.8rem;
`

export const Action = styled(BigClickableCard)`
  flex: 1 40%;
  min-width: 30rem;

  margin: 0.8rem;
`

export const AdminActions = styled(Content)`
  ${Action} {
    &:hover {
      color: ${(props) => props.theme.adminDark};
    }
  }
`

export const TeacherActions = styled(Content)`
  ${Action} {
    &:hover {
      color: ${(props) => props.theme.teacherDark};
    }
  }
`

export const StudentActions = styled(Content)`
  ${Action} {
    &:hover {
      color: ${(props) => props.theme.studentDark};
    }
  }
`
