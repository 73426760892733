import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FiAlertOctagon, FiEdit } from 'react-icons/fi'

import { useProfileCompletionStatus } from 'src/hooks'

import {
  Container,
  Title,
  MissingData,
  NavigateToEditProfileButton,
} from './styles'

const ProfileCompletionStatus: React.FC = () => {
  const { t } = useTranslation(['Home', 'User'])
  const history = useHistory()

  const completionStatus = useProfileCompletionStatus()

  const handleGoToEditProfile = () => {
    history.push('/edit-user-profile')
  }

  const getMissingData = (): string => {
    const dataLabels = [t('User:phone'), t('User:birthDate'), t('User:cpf')]

    const dataExists = [
      completionStatus.hasPhone,
      completionStatus.hasBirthDate,
      completionStatus.hasCpf,
    ]

    const missingDataArray = dataExists
      .map((exists, index) => (!exists ? dataLabels[index] : null))
      .filter((data) => !!data)

    return missingDataArray.join(', ')
  }

  return completionStatus.isProfileCompleted ? null : (
    <Container>
      <Title>
        <FiAlertOctagon />
        <span>{t('profileCompletion.title')}</span>
      </Title>

      <MissingData>
        <span>{t('profileCompletion.missingData')}</span>
        <span>{getMissingData()}</span>
      </MissingData>

      <NavigateToEditProfileButton onClick={handleGoToEditProfile}>
        <span>{t('profileCompletion.goToEditProfileButton')}</span>
        <FiEdit />
      </NavigateToEditProfileButton>
    </Container>
  )
}

export default ProfileCompletionStatus
