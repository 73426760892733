import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { BsClipboardData } from 'react-icons/bs'
import StateManager, { OptionsType } from 'react-select'
import {
  FiCheck,
  FiCheckCircle,
  FiChevronDown,
  FiChevronUp,
  FiSearch,
} from 'react-icons/fi'

import { TRAINING_SHEET_TYPES } from 'src/store/ducks/trainingSheet'
import {
  InfinitePagination,
  useIsLoading,
  useSubscribeToTopic,
  useTypedSelector,
} from 'src/hooks'
import {
  DefaultLabel,
  ReactSelectOption,
  SnowInput,
  SnowSelect,
  WhiteSpinner,
} from 'src/components'
import {
  requestFetchTrainingLevels,
  setTrainingLevelList,
} from 'src/store/ducks/trainingLevel'

import { TrainingConfigFilters } from '../types'

import {
  Container,
  FilterGroup,
  FilterInput,
  SearchButton,
  Content,
  ToggleFiltersButton,
  FilterCheckbox,
  CheckBoxesContainer,
} from './styles'

type TrainingStatus = Models.Common.ActiveOrNotActive | undefined

interface FiltersProps {
  setFilters: React.Dispatch<React.SetStateAction<TrainingConfigFilters>>
  pagination: InfinitePagination
}

const Filters: React.FC<FiltersProps> = ({ setFilters, pagination }) => {
  const { t } = useTranslation(['TrainingConfig', 'Glossary'])
  const dispatch = useDispatch()

  const [isShowingFilters, setIsShowingFilters] = useState(false)
  const [trainingStatus, setTrainingStatus] = useState<TrainingStatus>()

  const isSearching = useIsLoading(TRAINING_SHEET_TYPES.REQUEST_FETCH)

  const trainingLevelOptions: OptionsType<ReactSelectOption> = useTypedSelector(
    ({ TrainingLevel }) => {
      return TrainingLevel.trainingLevels.map((trainingLevel) => ({
        label: trainingLevel.treinonivel_descricao,
        value: `${trainingLevel.treinonivel_id}`,
      }))
    },
  )

  const searchRef = useRef<HTMLInputElement | null>(null)
  const trainingLevelRef = useRef<StateManager<ReactSelectOption> | null>(null)

  const handleToggleFilters = () => {
    setIsShowingFilters((isShowing) => !isShowing)
  }

  const handleSelectStatus = (status: TrainingStatus) => () => {
    setTrainingStatus(status)
  }

  const getValues = () => {
    const search = searchRef.current?.value.trim() || ''

    const trainingLevel = trainingLevelRef.current?.state.value
    const trainingLevelId = Number(trainingLevel?.value || 0)

    return {
      search,
      trainingLevelId,
      trainingStatus,
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    const { search, trainingLevelId, trainingStatus } = getValues()
    pagination.handleResetPageNumber()

    setFilters((currentFilters) => ({
      ...currentFilters,
      search,
      trainingLevelId,
      trainingStatus,
      offset: 0,
    }))
  }

  const handleLoadMore = useCallback(() => {
    if (isSearching) return

    pagination.handleIncrementPageNumber()

    setFilters((currentFilters) => ({
      ...currentFilters,
      offset: pagination.nextOffset,
    }))
  }, [isSearching, pagination, setFilters])

  useSubscribeToTopic({
    topic: 'MainPageContentScrollEndReached',
    observerId: 'TrainingConfig',
    callback: handleLoadMore,
  })

  useEffect(() => {
    dispatch(requestFetchTrainingLevels())
    return () => {
      dispatch(setTrainingLevelList([]))
    }
  }, [dispatch])

  return (
    <Container>
      <ToggleFiltersButton onClick={handleToggleFilters}>
        <span>
          {t(
            isShowingFilters
              ? 'filters.hideFiltersButton'
              : 'filters.showFiltersButton',
          )}
        </span>

        {isShowingFilters ? <FiChevronUp /> : <FiChevronDown />}
      </ToggleFiltersButton>

      <form
        style={{ display: isShowingFilters ? 'block' : 'none' }}
        onSubmit={handleSubmit}
        noValidate
      >
        <Content>
          <FilterGroup>
            <FilterInput
              iconComponent={<FiSearch size="3.2rem" />}
              labelComponent={
                <DefaultLabel htmlFor="searchText">
                  <span>{t('filters.searchLabel')}</span>
                </DefaultLabel>
              }
              inputComponent={
                <SnowInput
                  id="searchText"
                  ref={searchRef}
                  type="text"
                  placeholder={t('filters.searchPh')}
                />
              }
            />

            <FilterInput
              iconComponent={<BsClipboardData size="3.2rem" />}
              labelComponent={
                <DefaultLabel htmlFor="trainingLevel">
                  <span>{t('filters.trainingLevelLabel')}</span>
                </DefaultLabel>
              }
              inputComponent={
                <SnowSelect
                  id="trainingLevel"
                  name="trainingLevel"
                  ref={trainingLevelRef}
                  placeholder={t('filters.trainingLevelPh')}
                  noOptionsMessage={() => t('filters.noTrainingLevelMessage')}
                  options={trainingLevelOptions}
                  isClearable
                />
              }
            />
          </FilterGroup>

          <FilterGroup>
            <CheckBoxesContainer>
              <DefaultLabel>
                <FiCheckCircle />
                <span>{t('filters.statusFilterTitle')}</span>
              </DefaultLabel>

              <FilterCheckbox
                checkMarkIcon={<FiCheck />}
                checked={trainingStatus === 'AT'}
                onChange={handleSelectStatus('AT')}
                isCheckMarkRounded
              >
                <span>{t('filters.onlyActiveTrainings')}</span>
              </FilterCheckbox>

              <FilterCheckbox
                checkMarkIcon={<FiCheck />}
                checked={trainingStatus === 'NT'}
                onChange={handleSelectStatus('NT')}
                isCheckMarkRounded
              >
                <span>{t('filters.onlyInactiveTrainings')}</span>
              </FilterCheckbox>

              <FilterCheckbox
                checkMarkIcon={<FiCheck />}
                checked={trainingStatus === undefined}
                onChange={handleSelectStatus(undefined)}
                isCheckMarkRounded
              >
                <span>{t('filters.allTrainings')}</span>
              </FilterCheckbox>
            </CheckBoxesContainer>
          </FilterGroup>
        </Content>

        <SearchButton type="submit">
          <span>{t('filters.searchButton')}</span>
          {isSearching ? <WhiteSpinner /> : <FiSearch />}
        </SearchButton>
      </form>
    </Container>
  )
}

export default Filters
