import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  ACTIVITY_ROUTES,
  Server,
} from 'src/services/Server'

import { ACTIVITY_TYPES } from './types'
import { setActivityList } from './actions'

export function* requestFetchActivitiesWatcher() {
  yield takeLatest(ACTIVITY_TYPES.REQUEST_FETCH, handleFetchActivities)
}

export function* handleFetchActivities() {
  try {
    yield put(addLoading(ACTIVITY_TYPES.REQUEST_FETCH))

    const { data }: AxiosServerResponse<Models.Activity> = yield call(
      Server.put,
      ACTIVITY_ROUTES.GET_POR_CAMPOS,
      {},
    )

    if (data.isSuccessful) {
      yield put(setActivityList(data.data))
    } else {
      yield put(setActivityList([]))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(ACTIVITY_TYPES.REQUEST_FETCH))
  }
}
