import { Reducer } from 'redux'

import { RunningGroupState, RUNNING_GROUP_TYPES } from './types'

export const initialState: RunningGroupState = {
  runningGroups: [],
}

const reducer: Reducer<RunningGroupState> = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case RUNNING_GROUP_TYPES.SET_RUNNING_GROUP_LIST: {
      return {
        ...state,
        runningGroups: payload.runningGroups,
      }
    }

    case RUNNING_GROUP_TYPES.SET_RUNNING_GROUP_DATA: {
      return {
        ...state,
        runningGroupData: payload.runningGroupData,
      }
    }

    default:
      return state
  }
}

export default reducer
