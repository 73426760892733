import {
  RequestFetchTrainingLevelsAction,
  RequestEditTrainingLevelAction,
  RequestAddTrainingLevelAction,
  RequestDeleteTrainingLevelAction,
  SetTrainingLevelListAction,
  EditTrainingLevelInListAction,
  TRAINING_LEVEL_TYPES,
  AddInTrainingLevelListAction,
  RemoveFromTrainingLevelListAction,
} from './types'

export const requestFetchTrainingLevels = (): RequestFetchTrainingLevelsAction => ({
  type: TRAINING_LEVEL_TYPES.REQUEST_FETCH,
})

export const requestEditTrainingLevel = (
  payload: RequestEditTrainingLevelAction['payload'],
): RequestEditTrainingLevelAction => ({
  type: TRAINING_LEVEL_TYPES.REQUEST_EDIT,
  payload,
})

export const requestAddTrainingLevel = (
  payload: RequestAddTrainingLevelAction['payload'],
): RequestAddTrainingLevelAction => ({
  type: TRAINING_LEVEL_TYPES.REQUEST_ADD,
  payload,
})

export const requestDeleteTrainingLevel = (
  id: number,
): RequestDeleteTrainingLevelAction => ({
  type: TRAINING_LEVEL_TYPES.REQUEST_DELETE,
  payload: {
    id,
  },
})

export const setTrainingLevelList = (
  trainingLevels: Models.TrainingLevel[],
): SetTrainingLevelListAction => ({
  type: TRAINING_LEVEL_TYPES.SET_LIST,
  payload: {
    trainingLevels,
  },
})

export const editTrainingLevelInList = (
  trainingLevel: Models.TrainingLevel,
): EditTrainingLevelInListAction => ({
  type: TRAINING_LEVEL_TYPES.EDIT_IN_LIST,
  payload: trainingLevel,
})

export const addInTrainingLevelList = (
  trainingLevel: Models.TrainingLevel,
): AddInTrainingLevelListAction => ({
  type: TRAINING_LEVEL_TYPES.ADD_IN_LIST,
  payload: trainingLevel,
})

export const removeFromTrainingLevelList = (
  id: number,
): RemoveFromTrainingLevelListAction => ({
  type: TRAINING_LEVEL_TYPES.REMOVE_FROM_LIST,
  payload: {
    id,
  },
})
