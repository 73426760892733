import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  TRAINING_SHEET_ROUTES,
  Server,
} from 'src/services/Server'

import { setTrainingSheetList } from './actions'
import { TRAINING_SHEET_TYPES, RequestFetchTrainingSheetsAction } from './types'

type Filters = Partial<
  Pick<Models.Training, 'treinonivel_id' | 'treino_situacao'>
> & {
  SQL_LIMIT?: number
  SQL_OFFSET?: number
  FILTRO_GERAL?: string
}

export function* requestFetchTrainingSheetsWatcher() {
  yield takeLatest(
    TRAINING_SHEET_TYPES.REQUEST_FETCH,
    handleFetchTrainingSheets,
  )
}

export function* handleFetchTrainingSheets(
  action: RequestFetchTrainingSheetsAction,
) {
  try {
    yield put(addLoading(TRAINING_SHEET_TYPES.REQUEST_FETCH))
    const {
      trainingLevelId,
      limit,
      offset,
      trainingStatus,
      search,
    } = action.payload

    const hasOffset = !!offset && offset > 0

    const filters: Filters = {
      treinonivel_id: trainingLevelId,
      SQL_LIMIT: limit,
      SQL_OFFSET: offset,
      FILTRO_GERAL: search,
      treino_situacao: trainingStatus,
    }

    const {
      data,
    }: AxiosServerResponse<Models.TrainingGroupedTypes.TrainingWithTrainingLevel> = yield call(
      Server.put,
      TRAINING_SHEET_ROUTES.GET_LISTA,
      filters,
    )

    if (data.isSuccessful) {
      yield put(setTrainingSheetList(data.data, hasOffset))
    } else {
      yield put(setTrainingSheetList([], hasOffset))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(TRAINING_SHEET_TYPES.REQUEST_FETCH))
  }
}
