import { call, put, takeLatest } from 'redux-saga/effects'

import { showSuccess } from 'src/store/ducks/success'
import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, LOGIN_ROUTES, Server } from 'src/services/Server'

import { AUTH_TYPES, RequestSendResetPassEmailAction } from './types'

export function* requestSendResetPassEmailWatcher() {
  yield takeLatest(
    AUTH_TYPES.REQUEST_SEND_RESET_PASS_EMAIL,
    handleSendResetPassEmail,
  )
}

export function* handleSendResetPassEmail(
  action: RequestSendResetPassEmailAction,
) {
  try {
    yield put(addLoading(AUTH_TYPES.REQUEST_SEND_RESET_PASS_EMAIL))
    const { email } = action.payload

    const urlAndEmail = [LOGIN_ROUTES.RESET_SENHA, email]

    const { data }: AxiosServerResponse = yield call(
      Server.get,
      urlAndEmail.join('/'),
    )

    if (data.isSuccessful) {
      yield put(showSuccess(AUTH_TYPES.REQUEST_SEND_RESET_PASS_EMAIL))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(AUTH_TYPES.REQUEST_SEND_RESET_PASS_EMAIL))
  }
}
