import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ForgotPassword from 'src/pages/ForgotPassword'
import CreateAccount from 'src/pages/CreateAccount'
import PageNotFound from 'src/pages/PageNotFound'
import Login from 'src/pages/Login'

const AuthRoutes: React.FC = () => {
  return (
    <Switch>
      <Route component={Login} path="/" exact />
      <Route component={ForgotPassword} path="/forgot-password" exact />
      <Route component={CreateAccount} path="/create-account" exact />
      <Route component={PageNotFound} />
    </Switch>
  )
}

export default AuthRoutes
