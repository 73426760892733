export enum TRAINING_ZONE_TYPES {
  REQUEST_FETCH = '@TRAINING_ZONE/REQUEST_FETCH',
  REQUEST_EDIT = '@TRAINING_ZONE/REQUEST_EDIT',
  REQUEST_ADD = '@TRAINING_ZONE/REQUEST_ADD',
  REQUEST_DELETE = '@TRAINING_ZONE/REQUEST_DELETE',

  SET_LIST = '@TRAINING_ZONE/SET_LIST',
  EDIT_IN_LIST = '@TRAINING_ZONE/EDIT_IN_LIST',
  ADD_IN_LIST = '@TRAINING_ZONE/ADD_IN_LIST',
  REMOVE_FROM_LIST = '@TRAINING_ZONE/REMOVE_FROM_LIST',
}

export interface TrainingZoneState {
  trainingZones: Models.TrainingZone[]
}

export interface RequestFetchTrainingZonesAction {
  type: TRAINING_ZONE_TYPES.REQUEST_FETCH
}

export interface RequestEditTrainingZoneAction {
  type: TRAINING_ZONE_TYPES.REQUEST_EDIT
  payload: Models.TrainingZone
}

export interface RequestAddTrainingZoneAction {
  type: TRAINING_ZONE_TYPES.REQUEST_ADD
  payload: Omit<Models.TrainingZone, 'zonatrein_id'>
}

export interface RequestDeleteTrainingZoneAction {
  type: TRAINING_ZONE_TYPES.REQUEST_DELETE
  payload: {
    id: number
  }
}

export interface SetTrainingZoneListAction {
  type: TRAINING_ZONE_TYPES.SET_LIST
  payload: {
    trainingZones: Models.TrainingZone[]
  }
}

export interface EditTrainingZoneInListAction {
  type: TRAINING_ZONE_TYPES.EDIT_IN_LIST
  payload: Models.TrainingZone
}

export interface AddInTrainingZoneListAction {
  type: TRAINING_ZONE_TYPES.ADD_IN_LIST
  payload: Models.TrainingZone
}

export interface RemoveFromTrainingZoneListAction {
  type: TRAINING_ZONE_TYPES.REMOVE_FROM_LIST
  payload: {
    id: number
  }
}
