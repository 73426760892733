import styled from 'styled-components'

import { Input, SmallButton, SuccessButton } from 'src/components'

export const Container = styled.div`
  margin: 0 auto;

  max-width: 60rem;
`

export const FilterInput = styled(Input)`
  margin-bottom: 1.6rem;
`

export const SearchButton = styled(SuccessButton)`
  margin-left: auto;
  margin-bottom: 1.6rem;

  width: 50%;
  min-width: 30rem;
`

export const ToggleFiltersButton = styled(SmallButton)`
  margin: auto;
  width: auto;

  padding: 0 2.4rem;
  margin-bottom: 1.6rem;
`
