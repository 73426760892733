export enum STUDENT_TYPES {
  REQUEST_FETCH_STUDENTS = '@STUDENT/REQUEST_FETCH_STUDENTS',
  REQUEST_REGISTER_STUDENT = '@STUDENT/REQUEST_REGISTER_STUDENT',
  REQUEST_FETCH_STUDENTS_OUTSIDE_GROUP = '@STUDENT/REQUEST_FETCH_STUDENTS_OUTSIDE_GROUP',

  SET_STUDENT_LIST = '@STUDENT/SET_STUDENT_LIST',
}

export interface StudentState {
  students: Models.User[]
  studentsOutsideGroup: Models.User[]
}

export interface RequestFetchStudentsAction {
  type: STUDENT_TYPES.REQUEST_FETCH_STUDENTS
  payload: {
    teacherId: number
    search?: string
  }
}

export interface SetStudentListAction {
  type: STUDENT_TYPES.SET_STUDENT_LIST
  payload: {
    students: Models.User[]
    listName: 'students' | 'studentsOutsideGroup'
  }
}

export interface RequestRegisterStudentAction {
  type: STUDENT_TYPES.REQUEST_REGISTER_STUDENT
  payload: {
    name: string
    email: string
    groupId: number
    successCallback: () => void
  }
}

export interface RequestFetchStudentsOutsideGroupAction {
  type: STUDENT_TYPES.REQUEST_FETCH_STUDENTS_OUTSIDE_GROUP
  payload: {
    groupId: number
    search?: string
  }
}
