import {
  RUNNING_GROUP_TYPES,
  RunningGroupListItem,
  RequestFetchRunningGroupsAction,
  SetRunningGroupListAction,
  RequestCreateRunningGroupAction,
  RequestEditRunningGroupAction,
  RequestGetRunningGroupAction,
  SetRunningGroupDataAction,
  RequestDeleteRunningGroupAction,
} from './types'

export const requestFetchRunningGroups = (
  payload: RequestFetchRunningGroupsAction['payload'],
): RequestFetchRunningGroupsAction => ({
  type: RUNNING_GROUP_TYPES.REQUEST_FETCH_RUNNING_GROUPS,
  payload,
})

export const setRunningGroupList = (
  runningGroups: RunningGroupListItem[],
): SetRunningGroupListAction => ({
  type: RUNNING_GROUP_TYPES.SET_RUNNING_GROUP_LIST,
  payload: {
    runningGroups,
  },
})

export const requestCreateRunningGroup = (
  payload: RequestCreateRunningGroupAction['payload'],
): RequestCreateRunningGroupAction => ({
  type: RUNNING_GROUP_TYPES.REQUEST_CREATE_RUNNING_GROUP,
  payload,
})

export const requestEditRunningGroup = (
  payload: RequestEditRunningGroupAction['payload'],
): RequestEditRunningGroupAction => ({
  type: RUNNING_GROUP_TYPES.REQUEST_EDIT_RUNNING_GROUP,
  payload,
})

export const requestGetRunningGroup = (
  runningGroupId: number,
): RequestGetRunningGroupAction => ({
  type: RUNNING_GROUP_TYPES.REQUEST_GET_RUNNING_GROUP,
  payload: {
    runningGroupId,
  },
})

export const requestDeleteRunningGroup = (
  runningGroupId: number,
  successCallback?: () => void,
): RequestDeleteRunningGroupAction => ({
  type: RUNNING_GROUP_TYPES.REQUEST_DELETE_RUNNING_GROUP,
  payload: {
    runningGroupId,
    successCallback,
  },
})

export const setRunningGroupData = (
  runningGroupData?: Models.Group,
): SetRunningGroupDataAction => ({
  type: RUNNING_GROUP_TYPES.SET_RUNNING_GROUP_DATA,
  payload: {
    runningGroupData,
  },
})
