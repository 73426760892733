import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  TRAINING_SHEET_ROUTES,
  Server,
} from 'src/services/Server'

import { setTrainingSheetData } from './actions'
import { TRAINING_SHEET_TYPES, RequestGetTrainingSheetAction } from './types'

export function* requestGetTrainingSheetWatcher() {
  yield takeLatest(
    TRAINING_SHEET_TYPES.REQUEST_GET_DATA,
    handleGetTrainingSheet,
  )
}

export function* handleGetTrainingSheet(action: RequestGetTrainingSheetAction) {
  try {
    yield put(addLoading(TRAINING_SHEET_TYPES.REQUEST_GET_DATA))
    const { id } = action.payload

    const routeArray = [TRAINING_SHEET_ROUTES.TREINO, id]

    const {
      data,
    }: AxiosServerResponse<Models.TrainingGroupedTypes.TrainingSheetAndLevel> = yield call(
      Server.get,
      routeArray.join('/'),
    )

    if (data.isSuccessful) {
      const trainingSheetData = data.getFirstData() || undefined
      yield put(setTrainingSheetData(trainingSheetData))
    } else {
      yield put(setTrainingSheetData(undefined))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(TRAINING_SHEET_TYPES.REQUEST_GET_DATA))
  }
}
