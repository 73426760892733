import React from 'react'
import { useTranslation } from 'react-i18next'
import { FiCheckCircle } from 'react-icons/fi'

import { useIsLoading, useTypedSelector } from 'src/hooks'
import { WhiteSpinner } from 'src/components'
import { TRAINING_SHEET_TYPES } from 'src/store/ducks/trainingSheet'
import {
  TrainingNameInput,
  TrainingLevelSelect,
  TrainingSheetPreview,
} from 'src/views'

import TrainingBlockForm from '../TrainingBlockForm'
import TrainingActivityForm from '../TrainingActivityForm'

import useFeedStateWithData from './useFeedStateWithData'
import useTrainingSheetState from './useTrainingSheetState'
import useFetchNecessaryConfigs from './useFetchNecessaryConfigs'
import {
  Container,
  Content,
  FormsContainer,
  EditTrainingSheetButton,
  Divider,
} from './styles'

const BuildTrainingSheet: React.FC = () => {
  const { t } = useTranslation('TrainingManagement')
  const isEditing = useIsLoading(TRAINING_SHEET_TYPES.REQUEST_EDIT)

  const trainingSheetData = useTypedSelector(
    ({ TrainingSheet }) => TrainingSheet.trainingSheetData,
  )

  const {
    trainingLevelRef,
    trainingNameRef,

    blocks,
    setBlocks,
    editingBlockIndex,
    setEditingBlockIndex,
    editingActivityIndexes,
    setEditingActivityIndexes,
    errors,

    handleCancelEditingBlock,
    handleCancelEditingActivity,
    handleAddTrainingBlock,
    handleRemoveTrainingBlock,
    handleAddActivityInBlock,
    handleRemoveActivityFromBlock,
    handleMoveActivityInBlock,
    handleSaveTrainingSheet,
    handleEditBlock,
    handleEditActivity,
  } = useTrainingSheetState()

  useFetchNecessaryConfigs()
  useFeedStateWithData({ setBlocks, trainingLevelRef })

  return (
    <Container>
      <TrainingLevelSelect
        ref={trainingLevelRef}
        isShowingError={errors.isTrainingLevelNotSelected}
      />

      <TrainingNameInput
        ref={trainingNameRef}
        isShowingError={errors.isTrainingNameEmpty}
        defaultTrainingName={trainingSheetData?.treino_descricao}
      />

      <Content>
        <FormsContainer>
          <TrainingBlockForm
            blocks={blocks}
            editingBlockIndex={editingBlockIndex}
            handleAddTrainingBlock={handleAddTrainingBlock}
            handleEditBlock={handleEditBlock}
          />

          <Divider />

          <TrainingActivityForm
            blocks={blocks}
            handleEditActivity={handleEditActivity}
            handleAddActivityInBlock={handleAddActivityInBlock}
            editingActivityIndexes={editingActivityIndexes}
          />
        </FormsContainer>

        <TrainingSheetPreview
          blocks={blocks}
          editingBlockIndex={editingBlockIndex}
          setEditingBlockIndex={setEditingBlockIndex}
          editingActivityIndexes={editingActivityIndexes}
          setEditingActivityIndexes={setEditingActivityIndexes}
          handleCancelEditingActivity={handleCancelEditingActivity}
          handleCancelEditingBlock={handleCancelEditingBlock}
          handleRemoveTrainingBlock={handleRemoveTrainingBlock}
          handleRemoveActivityFromBlock={handleRemoveActivityFromBlock}
          handleMoveActivityInBlock={handleMoveActivityInBlock}
        />
      </Content>

      <EditTrainingSheetButton onClick={handleSaveTrainingSheet}>
        <span>{t('editTrainingSheetButton')}</span>
        {isEditing ? <WhiteSpinner /> : <FiCheckCircle />}
      </EditTrainingSheetButton>
    </Container>
  )
}

export default BuildTrainingSheet
