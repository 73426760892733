import {
  STUDENT_TRAINING_CONFIG_TYPES,
  RequestGetTrainingConfigAction,
  RequestEditTrainingConfigAction,
  SetTrainingConfigDataAction,
} from './types'

export const requestGetTrainingConfig = (
  trainingConfigId: number,
  isActive: boolean = true,
): RequestGetTrainingConfigAction => ({
  type: STUDENT_TRAINING_CONFIG_TYPES.REQUEST_GET_TRAINING_CONFIG,
  payload: {
    trainingConfigId,
    isActive,
  },
})

export const requestEditTrainingConfig = (
  payload: RequestEditTrainingConfigAction['payload'],
): RequestEditTrainingConfigAction => ({
  type: STUDENT_TRAINING_CONFIG_TYPES.REQUEST_EDIT_TRAINING_CONFIG,
  payload,
})

export const setTrainingConfigData = (
  trainingConfig?: Models.StudentTrainingConfig,
): SetTrainingConfigDataAction => ({
  type: STUDENT_TRAINING_CONFIG_TYPES.SET_TRAINING_CONFIG_DATA,
  payload: {
    trainingConfig,
  },
})
