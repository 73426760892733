import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BsFileCheck } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { FiCheck, FiCheckCircle, FiEdit } from 'react-icons/fi'

import { useSetBrowserTabTitle } from 'src/hooks'
import { Spinner, SpinnerWithText, WhiteSpinner } from 'src/components'
import { requestAnswerAnamneseQuiz } from 'src/store/ducks/anamneseQuizAnswer'

import useAnamneseQuiz from './useAnamneseQuiz'
import useGroupAndStudentId from './useGroupAndStudentId'
import {
  Container,
  AnswerAnamneseTitle,
  QuestionsList,
  AnswerableQuestion,
  SaveAnswersButton,
  EmptyList,
  WarningText,
  TermTitle,
  TermText,
  TermCheckbox,
  EditingText,
} from './styles'

type Answer = Pick<
  Models.GroupStudentAnamneseAnswer,
  'anamipergunta_id' | 'anamineseresp_resposta'
>

const AnswerAnamneseQuiz: React.FC = () => {
  useSetBrowserTabTitle('answerAnamneseQuiz')

  const { t } = useTranslation('AnswerAnamneseQuiz')
  const dispatch = useDispatch()

  const {
    questions,
    anamneseId,
    savedAnswers,
    isSavingAnswers,
    isLoadingAnswers,
    hasAnamneseQuizAnswers,
  } = useAnamneseQuiz()

  const {
    groupAndStudentId,
    isLoadingGroupAndStudentId,
  } = useGroupAndStudentId()

  const [answers, setAnswers] = useState<{ [key: number]: Answer }>({})
  const [isTermAccepted, setIsTermAccepted] = useState(false)

  const canSaveAnswers = useMemo(() => {
    const hasQuestions = questions.length > 0
    const answerKeys = Object.keys(answers)
    const answeredAllQuestions = answerKeys.length === questions.length
    return hasQuestions && answeredAllQuestions
  }, [questions.length, answers])

  const handleAnswerQuestion = (questionId: number, answer: 'S' | 'N') => {
    setAnswers((currentAnswers) => {
      return {
        ...currentAnswers,
        [questionId]: {
          anamipergunta_id: questionId,
          anamineseresp_resposta: answer,
        },
      }
    })
  }

  const handleSaveAnswers = () => {
    dispatch(
      requestAnswerAnamneseQuiz({
        answers: Object.values(answers),
        groupAndStudentId,
        anamneseId,
      }),
    )
  }

  return (
    <Container>
      <AnswerAnamneseTitle title={t('title')} iconComponent={<BsFileCheck />} />
      <WarningText>{t('aboutThisQuiz')}</WarningText>

      {isLoadingAnswers || isLoadingGroupAndStudentId ? (
        <SpinnerWithText hasSpinnerOnTheLeft>
          <Spinner />
          <span>{t('loadingText')}</span>
        </SpinnerWithText>
      ) : (
        <>
          {hasAnamneseQuizAnswers && (
            <EditingText>
              <FiEdit />
              <span>{t('editingAnswersText')}</span>
            </EditingText>
          )}

          <QuestionsList
            style={{ pointerEvents: isSavingAnswers ? 'none' : 'auto' }}
          >
            {questions.map((question, index) => {
              const { anamipergunta_id, anamipergunta_descricao } = question
              const savedAnswer = savedAnswers[anamipergunta_id]
              const currentAnswer = answers[anamipergunta_id]

              const isNoButtonSelected =
                currentAnswer?.anamineseresp_resposta === 'N' ||
                savedAnswer?.anamineseresp_resposta === 'N'

              const isYesButtonSelected =
                currentAnswer?.anamineseresp_resposta === 'S' ||
                savedAnswer?.anamineseresp_resposta === 'S'

              const onYesButtonClicked = () => {
                handleAnswerQuestion(anamipergunta_id, 'S')
              }

              const onNoButtonClicked = () => {
                handleAnswerQuestion(anamipergunta_id, 'N')
              }

              return (
                <AnswerableQuestion
                  key={anamipergunta_id}
                  questionNumber={index + 1}
                  question={anamipergunta_descricao}
                  totalOfQuestions={questions.length}
                  onNoButtonClicked={onNoButtonClicked}
                  onYesButtonClicked={onYesButtonClicked}
                  isNoButtonSelected={isNoButtonSelected}
                  isYesButtonSelected={isYesButtonSelected}
                />
              )
            })}
          </QuestionsList>

          {questions.length === 0 && (
            <EmptyList
              message={t('emptyMessage')}
              iconComponent={<BsFileCheck />}
            />
          )}

          <TermTitle>{t('termTitle')}</TermTitle>
          <TermText>{t('termText')}</TermText>

          <TermCheckbox
            onChange={(e) => setIsTermAccepted(e.target.checked)}
            checkMarkIcon={<FiCheck />}
            checked={isTermAccepted}
            isCheckMarkRounded
          >
            <span>{t('acceptTermLabel')}</span>
          </TermCheckbox>

          <SaveAnswersButton
            onClick={handleSaveAnswers}
            disabled={!canSaveAnswers || !isTermAccepted}
          >
            <span>{t('saveAnswersButton')}</span>
            {isSavingAnswers ? <WhiteSpinner /> : <FiCheckCircle />}
          </SaveAnswersButton>
        </>
      )}
    </Container>
  )
}

export default AnswerAnamneseQuiz
