import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiPlay } from 'react-icons/fi'

import { IframeVideo } from 'src/components'
import { STUDENT_TEST_TYPES } from 'src/config'
import { useTypedSelector, useUnits } from 'src/hooks'

import {
  Container,
  TrainingSheet,
  Block,
  Activity,
  StyledModal,
} from './styles'

const SheetView: React.FC = () => {
  const { handleFormatValueWithUnit } = useUnits()

  const { t } = useTranslation(['VideoModal'])

  const [isShowingVideo, setIsShowingVideo] = useState<{
    describe: string
    link: string
  } | null>()

  const studentTrainingData = useTypedSelector(
    ({ StudentTraining }) => StudentTraining.trainingData,
  )

  const handleHideVideoModal = () => {
    setIsShowingVideo(null)
  }
  if (!studentTrainingData) return null
  return (
    <Container>
      <TrainingSheet>
        {studentTrainingData.blocos.map((block) => {
          const {
            treinoblocoaluno_id,
            treinoblocoalu_repeticao,
            bloco_descricao,
            atividades,
          } = block

          const testType = studentTrainingData.configtreinoalu_tipoteste
          const canShowBpm = testType === STUDENT_TEST_TYPES.CARDIAC_TEST

          return (
            <Block
              key={treinoblocoaluno_id}
              name={bloco_descricao}
              repeat={treinoblocoalu_repeticao}
            >
              {atividades.map((activity) => {
                const {
                  trebloativaluno_id,
                  trebloativaluno_repeticao,
                  trebloativaluno_tempo,
                  trebloativaluno_unidade,
                  atividade_descricao,
                  zonatrein_bpmminimo,
                  zonatrein_bpmmaximo,
                  valor_convertido,
                  atividade_linkvideopadrao,
                } = activity
                console.log(atividade_linkvideopadrao?.substr(16))
                return (
                  <>
                    <StyledModal
                      isShowing={!!isShowingVideo}
                      handleHideModal={handleHideVideoModal}
                      title={t('title')}
                      iconComponent={<FiPlay size="3.2rem" />}
                    >
                      <IframeVideo
                        tittle={isShowingVideo?.describe}
                        url={isShowingVideo?.link}
                      />
                    </StyledModal>

                    <Activity
                      key={trebloativaluno_id}
                      name={atividade_descricao}
                      repeat={trebloativaluno_repeticao}
                      value={handleFormatValueWithUnit(
                        trebloativaluno_unidade,
                        trebloativaluno_tempo,
                      )}
                      minBpm={canShowBpm ? zonatrein_bpmminimo : undefined}
                      maxBpm={canShowBpm ? zonatrein_bpmmaximo : undefined}
                      pace={
                        valor_convertido
                          ? Number(valor_convertido).toFixed(2)
                          : undefined
                      }
                      isCalculatedBpm
                      url={atividade_linkvideopadrao}
                      onClick={() =>
                        setIsShowingVideo({
                          describe: atividade_descricao,
                          link: atividade_linkvideopadrao,
                        })
                      }
                    />
                  </>
                )
              })}
            </Block>
          )
        })}
      </TrainingSheet>
    </Container>
  )
}

export default SheetView
