import React, { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BsPeople } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import StateManager, { OptionsType } from 'react-select'

import {
  DefaultError,
  DefaultLabel,
  ReactSelectOption,
  SnowSelect,
} from 'src/components'
import {
  useAuthenticatedUserTypes,
  useIsLoading,
  useTypedSelector,
} from 'src/hooks'
import {
  requestFetchRunningGroups,
  RUNNING_GROUP_TYPES,
  setRunningGroupList,
} from 'src/store/ducks/runningGroup'

import { UserInput } from './styles'

interface GroupSelectProps {
  style?: React.CSSProperties
  className?: string
  isGroupNotSelected: boolean
  groupRef: React.MutableRefObject<StateManager<ReactSelectOption> | null>
}

const GroupSelect: React.FC<GroupSelectProps> = (props) => {
  const { className, style, groupRef, isGroupNotSelected } = props

  const { t } = useTranslation(['RegisterUser', 'Error', 'Glossary'])
  const dispatch = useDispatch()

  const userId = useTypedSelector(({ Auth }) => Auth.userData?.usuario_id || 0)
  const { isAdmin, isStudent, isTeacher } = useAuthenticatedUserTypes()

  const groupOptions: OptionsType<ReactSelectOption> = useTypedSelector(
    ({ RunningGroup }) => {
      return RunningGroup.runningGroups.map((group) => ({
        label: group.grupo_nome,
        value: String(group.grupo_id),
      }))
    },
  )

  const isLoadingGroups = useIsLoading(
    RUNNING_GROUP_TYPES.REQUEST_FETCH_RUNNING_GROUPS,
  )

  useLayoutEffect(() => {
    dispatch(
      requestFetchRunningGroups({
        userId,
        isSearchingForStudentGroups: !isAdmin && isStudent,
        isSearchingForTeacherGroups: !isAdmin && isTeacher,
      }),
    )

    return () => {
      dispatch(setRunningGroupList([]))
    }
  }, [dispatch, isAdmin, isStudent, isTeacher, userId])

  return (
    <UserInput
      className={className}
      style={style}
      iconComponent={<BsPeople size="3.2rem" />}
      labelComponent={
        <DefaultLabel>
          <span className="required">{t('Glossary:required')}</span>
          <span>{t('groupLabel')}</span>
        </DefaultLabel>
      }
      inputComponent={
        <SnowSelect
          id="group"
          name="group"
          ref={groupRef}
          options={groupOptions}
          placeholder={t('groupPh')}
          noOptionsMessage={() => t('noGroupsMessage')}
          loadingMessage={() => t('Glossary:loading')}
          isLoading={isLoadingGroups}
        />
      }
      errorComponent={
        <DefaultError>{t('Error:noOptionSelectedError')}</DefaultError>
      }
      showError={isGroupNotSelected}
    />
  )
}

export default GroupSelect
