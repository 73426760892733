import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import { showSuccessToast } from 'src/store/ducks/success'
import { withServerStructure } from 'src/utils'
import {
  AxiosServerResponse,
  TRAINING_SHEET_ROUTES,
  Server,
} from 'src/services/Server'

import { RequestEditTrainingSheetAction, TRAINING_SHEET_TYPES } from './types'

export function* requestEditTrainingSheetWatcher() {
  yield takeLatest(TRAINING_SHEET_TYPES.REQUEST_EDIT, handleEditTrainingSheet)
}

export function* handleEditTrainingSheet(
  action: RequestEditTrainingSheetAction,
) {
  try {
    yield put(addLoading(TRAINING_SHEET_TYPES.REQUEST_EDIT))
    const { trainingSheet, successCallback } = action.payload

    const {
      data,
    }: AxiosServerResponse<Models.TrainingGroupedTypes.TrainingSheet> = yield call(
      Server.post,
      TRAINING_SHEET_ROUTES.TREINO,
      withServerStructure(trainingSheet),
    )

    if (data.isSuccessful) {
      if (successCallback) successCallback()
      yield put(showSuccessToast({ messageCode: 'editTrainingSheet' }))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(TRAINING_SHEET_TYPES.REQUEST_EDIT))
  }
}
