import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FiCheckCircle, FiEdit, FiFileText } from 'react-icons/fi'

import {
  DefaultError,
  DefaultLabel,
  SnowInput,
  WhiteSpinner,
} from 'src/components'
import {
  requestAddBlock,
  requestEditBlock,
  BLOCK_TYPES,
} from 'src/store/ducks/block'
import { useIsLoading } from 'src/hooks'

import { Form, TrainingBlockInput, SaveButton } from './styles'

interface TrainingBlockFormProps {
  nameRef: React.MutableRefObject<HTMLInputElement | null>
  editingId: number
  setValues: (values: { name?: string }) => void
  handleFinishEditing: () => void
}

const TrainingBlockForm: React.FC<TrainingBlockFormProps> = (props) => {
  const { nameRef, editingId, setValues, handleFinishEditing } = props

  const dispatch = useDispatch()

  const { t } = useTranslation([
    'TrainingBlockConfig',
    'TrainingBlock',
    'Glossary',
    'Error',
  ])

  const isSaving = useIsLoading(
    BLOCK_TYPES.REQUEST_ADD,
    BLOCK_TYPES.REQUEST_EDIT,
  )

  const isEditing = useMemo(() => {
    return editingId !== 0
  }, [editingId])

  const [errors, setErrors] = useState({
    nameEmpty: false,
  })

  const getValues = () => {
    const name = nameRef.current?.value || ''
    return { name }
  }

  const handleValidate = (): boolean => {
    const { name } = getValues()

    const errorState = {
      nameEmpty: !name.trim(),
    }

    setErrors(errorState)
    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleValidate()) {
      const { name } = getValues()

      if (isEditing) {
        dispatch(
          requestEditBlock({
            bloco_id: editingId,
            bloco_descricao: name,
            bloco_situacao: 'S',
          }),
        )

        handleFinishEditing()
      } else {
        dispatch(
          requestAddBlock({
            bloco_descricao: name,
            bloco_situacao: 'S',
          }),
        )

        setValues({})
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <TrainingBlockInput
        iconComponent={<FiFileText size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="name">
            <span className="required">{t('Glossary:required')}</span>
            <span>{t('TrainingBlock:name')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowInput
            id="name"
            name="name"
            ref={nameRef}
            type="text"
            disabled={isSaving}
            autoCapitalize="words"
            placeholder={t('TrainingBlock:namePh')}
          />
        }
        errorComponent={
          <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
        }
        showError={errors.nameEmpty}
      />

      <SaveButton type="submit" disabled={isSaving}>
        <span>{t(isEditing ? 'editButton' : 'saveButton')}</span>
        {isSaving ? (
          <WhiteSpinner />
        ) : (
          <>{isEditing ? <FiEdit /> : <FiCheckCircle />}</>
        )}
      </SaveButton>
    </Form>
  )
}

export default TrainingBlockForm
