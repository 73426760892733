import { call, put, takeLatest } from 'redux-saga/effects'

import { AxiosServerResponse, BLOCK_ROUTES, Server } from 'src/services/Server'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import { withServerStructure } from 'src/utils'

import { RequestEditBlockAction, BLOCK_TYPES } from './types'
import { editBlockInList } from './actions'

export function* requestEditBlockWatcher() {
  yield takeLatest(BLOCK_TYPES.REQUEST_EDIT, handleEditBlock)
}

export function* handleEditBlock(action: RequestEditBlockAction) {
  try {
    yield put(addLoading(BLOCK_TYPES.REQUEST_EDIT))

    const { data }: AxiosServerResponse<Models.Block> = yield call(
      Server.post,
      BLOCK_ROUTES.BLOCO,
      withServerStructure(action.payload),
    )

    if (data.isSuccessful) {
      const block = data.getFirstData()
      if (block) yield put(editBlockInList(block))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(BLOCK_TYPES.REQUEST_EDIT))
  }
}
