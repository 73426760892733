import styled from 'styled-components'

import {
  TrainingSheetContainer,
  TrainingBlock,
  TrainingActivity,
  SimpleModal,
} from 'src/components'

export const Container = styled.div``

export const TrainingSheet = styled(TrainingSheetContainer)`
  min-width: 36rem;
  max-width: 90rem;

  margin: auto;
`

export const Block = styled(TrainingBlock)``
export const StyledModal = styled(SimpleModal)`
  background: rgba(0, 0, 0, 0.1);
`

export const Activity = styled(TrainingActivity)``
