import { Reducer } from 'redux'

import { GROUP_MEMBER_TYPES, GroupMemberState } from './types'

export const initialState: GroupMemberState = {
  groupMembers: [],
}

const reducer: Reducer<GroupMemberState> = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GROUP_MEMBER_TYPES.SET_MEMBER_LIST: {
      return {
        ...state,
        groupMembers: payload.groupMembers,
      }
    }

    case GROUP_MEMBER_TYPES.REMOVE_FROM_MEMBER_LIST: {
      const indexToRemove = state.groupMembers.findIndex(
        ({ grupoaluno_id }) => grupoaluno_id === payload.id,
      )

      const groupMembersClone = [...state.groupMembers]
      groupMembersClone.splice(indexToRemove, 1)

      return {
        ...state,
        groupMembers: groupMembersClone,
      }
    }

    default:
      return state
  }
}

export default reducer
