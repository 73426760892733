import styled from 'styled-components'

import { NoWrapEllipsis } from 'src/styles'

type LabelProps = {
  hasIcon: boolean
}

export const Container = styled.div`
  padding: 2.4rem;

  background: ${(props) => props.theme.background};

  border: 1px solid ${(props) => props.theme.border};
  border-radius: 1rem;

  position: relative;
`

export const Label = styled.div<LabelProps>`
  ${NoWrapEllipsis};

  font-weight: 900;
  font-size: 2rem;

  position: absolute;
  top: -1.6rem;
  left: 1.6rem;

  background: inherit;

  display: flex;
  align-items: center;

  padding: 0 0.8rem;

  span {
    margin-left: ${(props) => (props.hasIcon ? 0.8 : 0)}rem;
  }
`

export const Content = styled.div`
  // Definir esta cor permite alterar a cor do Label alterando a cor do Container
  // E isso sem alterar a cor do Content
  color: ${(props) => props.theme.primaryText};
`
