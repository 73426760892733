import styled from 'styled-components'

type ContentProps = {
  hasLeftComponent: boolean
  hasRightComponent: boolean
}

export const Container = styled.div`
  display: flex;
  align-items: center;

  outline: none;
  border-radius: 1rem;
  overflow: hidden;

  background: ${(props) => props.theme.background};

  padding: 1.6rem;

  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover,
  &:focus {
    background: ${(props) => props.theme.snow};
  }
`

export const Content = styled.div<ContentProps>`
  flex: 1;

  margin-left: ${(props) => (props.hasLeftComponent ? 1.6 : 0)}rem;
  margin-right: ${(props) => (props.hasRightComponent ? 1.6 : 0)}rem;
`
