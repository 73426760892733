import React, { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { BsFileCheck } from 'react-icons/bs'

import {
  ANAMNESE_QUIZ_ANSWER_TYPES,
  requestFetchAnamneseQuizAnswers,
  setAnamneseQuizAnswers,
} from 'src/store/ducks/anamneseQuizAnswer'
import {
  useIsLoading,
  useSetBrowserTabTitle,
  useTypedSelector,
} from 'src/hooks'
import { EmptyMessage, Spinner, SpinnerWithText } from 'src/components'

import {
  Container,
  StyledPageTitle,
  AnswerList,
  ContentFiller,
  AnswerableQuestion,
} from './styles'

interface AnamneseAnswersParams {
  id: string
}

const AnamneseAnswers: React.FC = () => {
  useSetBrowserTabTitle('anamneseAnswers')

  const { id } = useParams<AnamneseAnswersParams>()
  const { t } = useTranslation('AnamneseAnswers')
  const dispatch = useDispatch()

  const isLoadingAnswers = useIsLoading(
    ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_FETCH_ANSWERS,
  )

  const answers = useTypedSelector(
    ({ AnamneseQuizAnswer }) => AnamneseQuizAnswer.answers,
  )

  useLayoutEffect(() => {
    const userId = Number(id)
    dispatch(requestFetchAnamneseQuizAnswers(userId))
    return () => {
      dispatch(setAnamneseQuizAnswers([]))
    }
  }, [dispatch, id])

  return (
    <Container>
      <StyledPageTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<BsFileCheck />}
      />

      {(() => {
        if (isLoadingAnswers) {
          return (
            <ContentFiller>
              <SpinnerWithText hasSpinnerOnTheLeft>
                <Spinner />
                <span>{t('loadingText')}</span>
              </SpinnerWithText>
            </ContentFiller>
          )
        }

        if (answers.length === 0) {
          return (
            <ContentFiller>
              <EmptyMessage
                iconComponent={<BsFileCheck />}
                message={t('emptyMessage')}
              />
            </ContentFiller>
          )
        }

        return (
          <AnswerList>
            {answers.map((answer, index) => {
              const { anamineseresp_resposta, anamipergunta_descricao } = answer

              return (
                <AnswerableQuestion
                  key={index}
                  questionNumber={index + 1}
                  totalOfQuestions={answers.length}
                  question={anamipergunta_descricao}
                  isNoButtonSelected={anamineseresp_resposta === 'N'}
                  isYesButtonSelected={anamineseresp_resposta === 'S'}
                />
              )
            })}
          </AnswerList>
        )
      })()}
    </Container>
  )
}

export default AnamneseAnswers
