import { useEffect } from 'react'

import { EditingActivityIndexes } from '../types'

type Params = {
  editingActivityIndexes: EditingActivityIndexes
  clearValues: () => void
}

export default ({ editingActivityIndexes, clearValues }: Params) => {
  useEffect(() => {
    if (
      editingActivityIndexes.activityIndex === -1 &&
      editingActivityIndexes.blockIndex === -1
    ) {
      clearValues()
    }
  }, [clearValues, editingActivityIndexes])
}
