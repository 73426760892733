import styled from 'styled-components'

import {
  Input,
  SuccessButton,
  ModalDialog,
  ModalBackdrop,
} from 'src/components'

export const Backdrop = styled(ModalBackdrop)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Dialog = styled(ModalDialog)`
  background: ${(props) => props.theme.background};

  padding: 2.4rem;
`

export const Form = styled.form``

export const IconContainer = styled.div`
  font-size: 6.4rem;
  width: 6.4rem;
  height: 6.4rem;

  margin-bottom: 1.6rem;

  color: ${(props) => props.theme.successDark};
`

export const Title = styled.div`
  font-size: 2.4rem;
  font-weight: 900;

  margin-bottom: 0.8rem;
`

export const Subtitle = styled.div`
  margin-bottom: 2.4rem;
`

export const BirthDateInput = styled(Input)`
  margin-bottom: 2.4rem;
`

export const SaveBirthDateButton = styled(SuccessButton)``
