import React from 'react'
import { useTranslation } from 'react-i18next'
import { FiPlusCircle, FiBox, FiRepeat, FiPlus, FiEdit } from 'react-icons/fi'

import { AddNewBlockModal } from 'src/views'
import { BLOCK_TYPES } from 'src/store/ducks/block'
import { useAuthenticatedUserTypes, useIsLoading } from 'src/hooks'
import {
  DefaultError,
  DefaultLabel,
  SnowInput,
  SnowSelect,
} from 'src/components'

import useBlockFormState from './useBlockFormState'
import { TrainingBlockFormProps } from './blockFormTypes'
import useFeedStateToEditBlock from './useFeedStateToEditBlock'
import useClearValuesWhenCancelEditing from './useClearValuesWhenCancelEditing'
import {
  Form,
  Content,
  TrainingBlockInput,
  AddTrainingBlock,
  CreateNewBlock,
  EditingIndicatorText,
} from './styles'

const TrainingBlockForm: React.FC<TrainingBlockFormProps> = (props) => {
  const { editingBlockIndex, blocks } = props

  const { t } = useTranslation(['TrainingManagement', 'Glossary', 'Error'])
  const isLoadingBlocks = useIsLoading(BLOCK_TYPES.REQUEST_FETCH)
  const { isAdmin } = useAuthenticatedUserTypes()

  const {
    blockRef,
    blockRepetitionsRef,

    isShowingBlockModal,
    errors,

    blockOptions,

    setValues,
    clearValues,

    handleSelectBlockOption,
    handleShowBlockModal,
    handleHideBlockModal,
    handleSubmitForm,
  } = useBlockFormState(props)

  useFeedStateToEditBlock({
    blocks,
    setValues,
    editingBlockIndex,
  })

  useClearValuesWhenCancelEditing({
    editingBlockIndex,
    clearValues,
  })

  return (
    <>
      {isAdmin && (
        <AddNewBlockModal
          isShowing={isShowingBlockModal}
          handleHideModal={handleHideBlockModal}
          onBlockAdded={handleSelectBlockOption}
        />
      )}

      <Form
        onSubmit={handleSubmitForm}
        isEditing={editingBlockIndex !== -1}
        noValidate
      >
        {editingBlockIndex !== -1 && (
          <EditingIndicatorText>
            {t('editingBlockIndicatorText')}
          </EditingIndicatorText>
        )}

        <Content>
          {isAdmin && (
            <CreateNewBlock onClick={handleShowBlockModal} tabIndex={-1}>
              <span>{t('createNewBlock')}</span>
              <FiPlus />
            </CreateNewBlock>
          )}

          <TrainingBlockInput
            iconComponent={<FiBox size="3.2rem" />}
            labelComponent={
              <DefaultLabel htmlFor="block">
                <span className="required">{t('Glossary:required')}</span>
                <span>{t('trainingBlockLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowSelect
                id="block"
                name="block"
                ref={blockRef}
                options={blockOptions}
                placeholder={t('trainingBlockPh')}
                noOptionsMessage={() => t('noBlocksMessage')}
                loadingMessage={() => t('Glossary:loading')}
                isLoading={isLoadingBlocks}
              />
            }
            errorComponent={
              <DefaultError>{t('Error:noOptionSelectedError')}</DefaultError>
            }
            showError={errors.isBlockNotSelected}
          />

          <TrainingBlockInput
            iconComponent={<FiRepeat size="3.2rem" />}
            labelComponent={
              <DefaultLabel htmlFor="blockRepetitions">
                <span className="required">{t('Glossary:required')}</span>
                <span>{t('blockRepetitionsLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowInput
                min={1}
                type="number"
                defaultValue={1}
                id="blockRepetitions"
                name="blockRepetitions"
                ref={blockRepetitionsRef}
                placeholder={t('blockRepetitionsPh')}
              />
            }
            errorComponent={
              <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
            }
            showError={errors.isBlockRepetitionsEmpty}
          />

          <AddTrainingBlock type="submit">
            <span>
              {t(
                editingBlockIndex === -1
                  ? 'addTrainingBlock'
                  : 'editTrainingBlock',
              )}
            </span>

            {editingBlockIndex === -1 ? <FiPlusCircle /> : <FiEdit />}
          </AddTrainingBlock>
        </Content>
      </Form>
    </>
  )
}

export default TrainingBlockForm
