import styled from 'styled-components'

import {
  RunningGroupItem,
  SuccessButton,
  LabeledEdgeContainer,
  SpinnerWithText,
  EmptyMessage,
} from 'src/components'
import { NoWrapEllipsis } from 'src/styles'

type ContainerProps = {
  isAdmin: boolean
  isTeacher: boolean
  isStudent: boolean
}

export const Container = styled(LabeledEdgeContainer)<ContainerProps>`
  padding: 1.6rem;
  margin: 0 0.8rem 2.4rem 0.8rem;

  // Cor do label da Section
  // Ela herda esta cor
  color: ${(props) => {
    if (props.isAdmin) return props.theme.admin
    else if (props.isTeacher) return props.theme.teacher
    else return props.theme.student
  }};
`

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Group = styled(RunningGroupItem)`
  ${NoWrapEllipsis};

  flex: 1 45%;
  min-width: 25rem;

  margin: 0.8rem;

  @media only screen and (max-width: 468px) {
    img {
      height: 5rem;
      width: 5rem;
    }
  }
`

export const ConfigTrainingButton = styled(SuccessButton)`
  max-width: 40rem;
  margin: auto;
`

export const SpinnerContainer = styled(SpinnerWithText)`
  margin: 0.8rem;
`

export const EmptyGroupList = styled(EmptyMessage)`
  margin: 0.8rem auto;
`
