export enum BLOCK_TYPES {
  REQUEST_FETCH = '@BLOCK/REQUEST_FETCH',
  REQUEST_EDIT = '@BLOCK/REQUEST_EDIT',
  REQUEST_ADD = '@BLOCK/REQUEST_ADD',
  REQUEST_DELETE = '@BLOCK/REQUEST_DELETE',

  SET_LIST = '@BLOCK/SET_LIST',
  EDIT_IN_LIST = '@BLOCK/EDIT_IN_LIST',
  ADD_IN_LIST = '@BLOCK/ADD_IN_LIST',
  REMOVE_FROM_LIST = '@BLOCK/REMOVE_FROM_LIST',
}

export interface BlockState {
  blocks: Models.Block[]
}

export interface RequestFetchBlocksAction {
  type: BLOCK_TYPES.REQUEST_FETCH
}

export interface RequestEditBlockAction {
  type: BLOCK_TYPES.REQUEST_EDIT
  payload: Models.Block
}

export interface RequestAddBlockAction {
  type: BLOCK_TYPES.REQUEST_ADD
  payload: {
    block: Omit<Models.Block, 'bloco_id'>
    successCallback?: (block: Models.Block) => void
  }
}

export interface RequestDeleteBlockAction {
  type: BLOCK_TYPES.REQUEST_DELETE
  payload: {
    id: number
  }
}

export interface SetBlockListAction {
  type: BLOCK_TYPES.SET_LIST
  payload: {
    blocks: Models.Block[]
  }
}

export interface EditBlockInListAction {
  type: BLOCK_TYPES.EDIT_IN_LIST
  payload: Models.Block
}

export interface AddInBlockListAction {
  type: BLOCK_TYPES.ADD_IN_LIST
  payload: Models.Block
}

export interface RemoveFromBlockListAction {
  type: BLOCK_TYPES.REMOVE_FROM_LIST
  payload: {
    id: number
  }
}
