import React, { useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { FiSettings, FiTrash, FiUsers } from 'react-icons/fi'

import {
  useAuthenticatedUserTypes,
  useIsLoading,
  useTypedSelector,
} from 'src/hooks'
import {
  Avatar,
  ConfirmModal,
  DangerRoundIconButton,
  Spinner,
  SpinnerWithText,
  StudentBadge,
  TeacherBadge,
} from 'src/components'
import {
  GROUP_MEMBER_TYPES,
  requestFetchGroupMembers,
  requestRemoveGroupMember,
  setGroupMemberList,
} from 'src/store/ducks/groupMember'
import { ANAMNESE_STATUS as AN_ST } from 'src/config'

import { RunningGroupDetailsParams } from '../types'

import {
  Container,
  Title,
  List,
  EmptyList,
  User,
  ItemBadges,
  ConfigStudentTraining,
  AnamneseStatusBadge,
} from './styles'

type MemberToRemove = {
  id: number
  name: string
  isStudent: boolean
} | null

const GroupMemberList: React.FC = () => {
  const { t } = useTranslation([
    'RunningGroupDetails',
    'Glossary',
    'AnamneseStatus',
  ])

  const { id } = useParams<RunningGroupDetailsParams>()
  const dispatch = useDispatch()
  const history = useHistory()

  const [memberToRemove, setMemberToRemove] = useState<MemberToRemove>(null)
  const isLoading = useIsLoading(GROUP_MEMBER_TYPES.REQUEST_FETCH_MEMBERS)
  const { isAdmin, isTeacher } = useAuthenticatedUserTypes()

  const groupMembers = useTypedSelector(
    ({ GroupMember }) => GroupMember.groupMembers,
  )

  const handleRemoveMemberFromGroup = () => {
    if (!memberToRemove) return

    dispatch(
      requestRemoveGroupMember(
        memberToRemove.id,
        memberToRemove.isStudent,
        true,
      ),
    )
  }

  const handleCloseRemoveMemberConfirmationModal = () => {
    setMemberToRemove(null)
  }

  useLayoutEffect(() => {
    if (id) dispatch(requestFetchGroupMembers(Number(id)))
    return () => {
      dispatch(setGroupMemberList([]))
    }
  }, [dispatch, id])

  return (
    <>
      <ConfirmModal
        confirmButtonType="danger"
        confirmButtonText={t('Glossary:remove')}
        isShowing={!!memberToRemove}
        onConfirm={handleRemoveMemberFromGroup}
        title={t('removeMemberConfirmationTitle')}
        iconComponent={<FiTrash size="3.2rem" />}
        handleCloseModal={handleCloseRemoveMemberConfirmationModal}
        message={t('removeMemberConfirmationMessage', {
          name: memberToRemove?.name,
        })}
      />

      <Container>
        <Title>
          <FiUsers />
          <span>{t('membersSection')}</span>
        </Title>

        {isLoading && (
          <SpinnerWithText hasSpinnerOnTheLeft>
            <Spinner />
            <span>{t('loadingMembersText')}</span>
          </SpinnerWithText>
        )}

        {!isLoading && !!groupMembers.length && (
          <List>
            {groupMembers.map((member) => {
              const {
                grupoaluno_id,
                usuario_nome,
                usuario_email,
                usuario_id,
                usuario_aluno,
                anaminese_status: anam_status,
              } = member

              const isThisMemberStudent = usuario_aluno === 'S'
              const answersContainOneYes = anam_status === AN_ST.ANSWERED_YES
              const isEverythingAllRight = anam_status === AN_ST.ALL_RIGHT
              const notAnswered = anam_status === AN_ST.NOT_ANSWERED

              const handleGoToMemberProfile = () => {
                history.push(`/user-public-profile/${usuario_id}`)
              }

              const handleConfigStudentTraining = (e: React.MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()
                history.push(`/student-training-config/${grupoaluno_id}`)
              }

              const handleRemoveThisMember = (e: React.MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()
                setMemberToRemove({
                  id: grupoaluno_id,
                  name: usuario_nome,
                  isStudent: true,
                })
              }

              return (
                <User
                  key={usuario_id}
                  name={usuario_nome}
                  email={usuario_email}
                  isTeacher={!isThisMemberStudent}
                  onClick={handleGoToMemberProfile}
                  leftSideComponent={
                    <Avatar src={undefined} alt={usuario_nome} />
                  }
                  rightSideComponent={
                    (isAdmin || isTeacher) &&
                    isThisMemberStudent && (
                      <DangerRoundIconButton onClick={handleRemoveThisMember}>
                        <FiTrash />
                      </DangerRoundIconButton>
                    )
                  }
                  contentHeaderComponent={
                    <ItemBadges>
                      {isThisMemberStudent ? (
                        <StudentBadge size="small">
                          {t('Glossary:student')}
                        </StudentBadge>
                      ) : (
                        <TeacherBadge size="small">
                          {t('Glossary:teacher')}
                        </TeacherBadge>
                      )}

                      {(isAdmin || isTeacher) && isThisMemberStudent && (
                        <AnamneseStatusBadge
                          answersContainOneYes={answersContainOneYes}
                          isEverythingAllRight={isEverythingAllRight}
                          notAnswered={notAnswered}
                        >
                          {t('AnamneseStatus:statusLabelShort', {
                            status: t(`AnamneseStatus:short.${anam_status}`),
                          })}
                        </AnamneseStatusBadge>
                      )}
                    </ItemBadges>
                  }
                  contentFooterComponent={
                    (isAdmin || isTeacher) &&
                    isThisMemberStudent && (
                      <ConfigStudentTraining
                        onClick={handleConfigStudentTraining}
                      >
                        <span>{t('configStudentTraining')}</span>
                        <FiSettings />
                      </ConfigStudentTraining>
                    )
                  }
                />
              )
            })}
          </List>
        )}

        {!isLoading && !groupMembers.length && (
          <EmptyList
            message={t('emptyListMessage')}
            iconComponent={<FiUsers />}
          />
        )}
      </Container>
    </>
  )
}

export default GroupMemberList
