import React, { useCallback, useLayoutEffect } from 'react'
import { DateTime } from 'luxon'
import { BsPeople } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { FiSettings } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Spinner } from 'src/components'
import {
  useAuthenticatedUserTypes,
  useIsLoading,
  useTypedSelector,
} from 'src/hooks'
import {
  RUNNING_GROUP_TYPES,
  requestFetchRunningGroups,
  setRunningGroupList,
} from 'src/store/ducks/runningGroup'

import {
  Container,
  List,
  Group,
  ConfigTrainingButton,
  SpinnerContainer,
  EmptyGroupList,
} from './styles'

const UserGroupList: React.FC = () => {
  const { t } = useTranslation(['UserPublicProfile', 'RunningGroup'])
  const dispatch = useDispatch()
  const history = useHistory()

  const userData = useTypedSelector(({ User }) => User.userData)

  const { isAdmin } = useAuthenticatedUserTypes()

  const runningGroups = useTypedSelector(
    ({ RunningGroup }) => RunningGroup.runningGroups,
  )

  const isLoadingGroups = useIsLoading(
    RUNNING_GROUP_TYPES.REQUEST_FETCH_RUNNING_GROUPS,
  )

  const getFormattedDate = (date?: string) => {
    if (!date) return ''
    return DateTime.fromSQL(date).toFormat('dd/MM/yyyy')
  }

  const handleFetchGroupList = useCallback(() => {
    if (!userData) return

    const isThisUserStudent = userData.usuario_aluno === 'S'
    const isThisUserTeacher = userData.usuario_professor === 'S'

    if (isThisUserStudent || isThisUserTeacher) {
      dispatch(
        requestFetchRunningGroups({
          userId: userData.usuario_id,
          isSearchingForStudentGroups: isThisUserStudent,
          isSearchingForTeacherGroups: isThisUserTeacher,
        }),
      )
    } else {
      dispatch(setRunningGroupList([]))
    }
  }, [dispatch, userData])

  useLayoutEffect(() => {
    handleFetchGroupList()
    return () => {
      dispatch(setRunningGroupList([]))
    }
  }, [dispatch, handleFetchGroupList])

  return (
    <Container
      isAdmin={userData?.usuario_administrador === 'S'}
      isTeacher={userData?.usuario_professor === 'S'}
      isStudent={userData?.usuario_aluno === 'S'}
      label={t('groupsSection')}
      iconComponent={<BsPeople />}
    >
      {isLoadingGroups && (
        <SpinnerContainer hasSpinnerOnTheLeft>
          <Spinner />
          <span>{t('loadingGroups')}</span>
        </SpinnerContainer>
      )}

      {!isLoadingGroups && !!runningGroups.length && (
        <List>
          {runningGroups.map((runningGroup) => {
            const {
              grupo_id,
              grupo_nome,
              grupo_dtinclusao,
              grupoaluno_id,
              grupo_numeromembros,
            } = runningGroup

            const handleGoToGroupDetails = () => {
              history.push(`/running-group/${grupo_id}`)
            }

            const handleGoToStudentTrainingConfigPage = () => {
              history.push(`/student-training-config/${grupoaluno_id}`)
            }

            return (
              <Group
                key={grupo_id}
                name={grupo_nome}
                imageSrc={null}
                imageAlt={grupo_nome}
                onClick={handleGoToGroupDetails}
                createdAt={getFormattedDate(grupo_dtinclusao)}
                numberOfMembers={t('RunningGroup:numberOfStudents', {
                  count: grupo_numeromembros || 0,
                })}
                contentFooterComponent={
                  !!grupoaluno_id &&
                  isAdmin && (
                    <ConfigTrainingButton
                      onClick={handleGoToStudentTrainingConfigPage}
                    >
                      <span>{t('configTrainingButton')}</span>
                      <FiSettings />
                    </ConfigTrainingButton>
                  )
                }
              />
            )
          })}
        </List>
      )}

      {!isLoadingGroups && !runningGroups.length && (
        <EmptyGroupList
          iconComponent={<BsPeople />}
          message={t('noGroupsFoundMessage')}
        />
      )}
    </Container>
  )
}

export default UserGroupList
