import styled from 'styled-components'

export const PageContainer = styled.div`
  background: ${(props) => props.theme.background};

  padding: 4.8rem;

  @media only screen and (max-width: 900px) {
    padding: 1.6rem;
  }
`

export const FullHeightSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
`
