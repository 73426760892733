import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Login } from 'src/assets'
import { Input, OutlinedButton } from 'src/components'

export const Container = styled.div`
  min-height: 100%;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${Login});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 3.2rem 0;
`

export const LoginForm = styled.form`
  background: ${(props) => props.theme.background};
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;

  margin: 0 10% 1.6rem 10%;
  max-width: 50rem;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    margin: 0 3.2rem 1.6rem 3.2rem;
  }
`

export const Header = styled.div`
  padding: 1.6rem 2.4rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.border};
`

export const Logo = styled.img`
  height: 8rem;
  width: 8rem;
  border-radius: 100%;
  user-select: none;
`

export const HeaderTexts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.6rem;
`

export const Title = styled.div`
  font-weight: 800;
  font-size: 2.4rem;
`

export const Subtitle = styled.div`
  color: ${(props) => props.theme.secondaryText};
`

export const Body = styled.div`
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const LoginInput = styled(Input)`
  margin-bottom: 1.6rem;
`

export const ForgotPassword = styled(Link)`
  outline: none;
  align-self: flex-end;
  color: ${(props) => props.theme.secondaryText};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.primaryText};
  }
`

export const Footer = styled.div`
  padding: 1.6rem 2.4rem;
`

export const LoginButton = styled(OutlinedButton)``

export const CreateAccountContainer = styled.div`
  max-width: 50rem;
  margin: 0 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin: 0 3.2rem;
  }
`

export const CreateAccountText = styled.div`
  color: white;
`

export const CreateAccountButton = styled(Link)`
  background: ${(props) => props.theme.successDark};
  text-decoration: none;
  font-weight: bold;
  color: white;

  padding: 0.6rem 2.4rem;
  margin-left: 1.6rem;

  border-radius: 10rem;

  cursor: pointer;
  transition: background 0.2s ease-in-out;
  user-select: none;

  &:hover {
    background: ${(props) => props.theme.success};
  }
`
