import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import { AxiosServerResponse, LOGIN_ROUTES, Server } from 'src/services/Server'
import { LOCAL_STORAGE_KEYS } from 'src/config'

import { AUTH_TYPES, RequestAuthenticateAction } from './types'
import { setAuthenticated } from './actions'

export function* requestAuthenticateWatcher() {
  yield takeLatest(AUTH_TYPES.REQUEST_AUTHENTICATE, handleAuthentication)
}

export function* handleAuthentication(action: RequestAuthenticateAction) {
  try {
    yield put(addLoading(AUTH_TYPES.REQUEST_AUTHENTICATE))
    const { email, password, onAuthenticated } = action.payload

    const urlAndDataToAuth = [
      LOGIN_ROUTES.LOGIN,
      email,
      encodeURIComponent(password),
    ]

    const { data }: AxiosServerResponse<Models.User> = yield call(
      Server.get,
      urlAndDataToAuth.join('/'),
    )

    if (data.isSuccessful) {
      const userData = data.getFirstData()

      if (userData) {
        yield call(
          [localStorage, localStorage.setItem],
          LOCAL_STORAGE_KEYS.USER_DATA,
          JSON.stringify(userData),
        )

        yield put(setAuthenticated(userData))
        if (onAuthenticated) onAuthenticated()
      }
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(AUTH_TYPES.REQUEST_AUTHENTICATE))
  }
}
