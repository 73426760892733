import styled from 'styled-components'

import { DefaultDivider, SuccessButton } from 'src/components'

export const Container = styled.div``

export const Content = styled.div`
  max-width: 130rem;
  margin: 0 auto 2.4rem auto;
`

export const FormsContainer = styled.div`
  display: flex;
  align-items: flex-start;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
`

export const EditTrainingSheetButton = styled(SuccessButton)`
  width: 100%;
  max-width: 40rem;

  margin: auto;
`

export const Divider = styled(DefaultDivider)`
  margin: 1.6rem 0;
`
