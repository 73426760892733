import { Reducer } from 'redux'

import { TRAINING_ZONE_TYPES, TrainingZoneState } from './types'

export const initialState: TrainingZoneState = {
  trainingZones: [],
}

const reducer: Reducer<TrainingZoneState> = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case TRAINING_ZONE_TYPES.SET_LIST: {
      return {
        ...state,
        trainingZones: payload.trainingZones,
      }
    }

    case TRAINING_ZONE_TYPES.EDIT_IN_LIST: {
      const indexToEdit = state.trainingZones.findIndex(
        ({ zonatrein_id }) => zonatrein_id === payload.zonatrein_id,
      )

      const trainingZonesClone = [...state.trainingZones]
      trainingZonesClone[indexToEdit] = payload

      return {
        ...state,
        trainingZones: trainingZonesClone,
      }
    }

    case TRAINING_ZONE_TYPES.REMOVE_FROM_LIST: {
      const indexToRemove = state.trainingZones.findIndex(
        ({ zonatrein_id }) => zonatrein_id === payload.id,
      )

      const trainingZonesClone = [...state.trainingZones]
      trainingZonesClone.splice(indexToRemove, 1)

      return {
        ...state,
        trainingZones: trainingZonesClone,
      }
    }

    case TRAINING_ZONE_TYPES.ADD_IN_LIST: {
      const trainingZonesClone = [...state.trainingZones]
      trainingZonesClone.push(payload)

      return {
        ...state,
        trainingZones: trainingZonesClone,
      }
    }

    default:
      return state
  }
}

export default reducer
