import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;

  border-radius: 1rem;
  overflow: hidden;

  background: ${(props) => props.theme.background};

  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover,
  &:focus {
    background: ${(props) => props.theme.snow};
  }
`

export const Image = styled.img`
  width: 10rem;
  height: 10rem;

  border-radius: 1rem;

  user-select: none;
`

export const Content = styled.div`
  flex: 1;

  padding: 0 1.6rem;
`

export const Title = styled.div`
  font-weight: 900;
  font-size: 1.8rem;
  color: ${(props) => props.theme.primaryText};

  display: flex;
  align-items: center;

  span {
    margin-left: 1.6rem;
  }
`

export const Text = styled.div`
  color: ${(props) => props.theme.secondaryText};

  display: flex;
  align-items: center;

  span {
    margin-left: 1.6rem;
  }
`
