import {
  OBSERVABLE_TYPES,
  AddObserverAction,
  RemoveObserverAction,
} from './types'

export const addObserver = (
  payload: AddObserverAction['payload'],
): AddObserverAction => ({
  type: OBSERVABLE_TYPES.ADD_OBSERVER,
  payload,
})

export const removeObserver = (
  payload: RemoveObserverAction['payload'],
): RemoveObserverAction => ({
  type: OBSERVABLE_TYPES.REMOVE_OBSERVER,
  payload,
})
