import styled, { css } from 'styled-components'

import { EmptyMessage, ListItem } from 'src/components'

type TrainingZoneItemProps = {
  isEditing: boolean
}

export const List = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const TrainingZoneItem = styled(ListItem)<TrainingZoneItemProps>`
  flex: 1 40%;
  min-width: 30rem;
  margin: 0 0.8rem 1.6rem 0.8rem;

  border: 2px solid transparent;

  ${(props) => {
    if (props.isEditing) {
      return css`
        border-color: ${props.theme.success};

        &:hover {
          background: ${props.theme.background};
        }
      `
    } else {
      return css`
        .deleteTrainingZone {
          transition: opacity 0.2s ease-in-out;
        }

        &:not(:hover) .deleteTrainingZone {
          pointer-events: none;
          opacity: 0;
          z-index: -1;
          width: 0;
          height: 0;
        }
      `
    }
  }}
`

export const ListEmpty = styled(EmptyMessage)`
  margin: auto;
`
