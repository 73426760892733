import React from 'react'

import { Container, Label, Content } from './styles'

export interface LabeledEdgeContainerProps {
  style?: React.CSSProperties
  className?: string
  contentStyle?: React.CSSProperties
  contentClassName?: string
  label: string
  iconComponent?: React.ReactNode
  children?: any
}

export const LabeledEdgeContainer: React.FC<LabeledEdgeContainerProps> = (
  props,
) => {
  const {
    className,
    style,
    label,
    iconComponent,
    children,
    contentStyle,
    contentClassName,
  } = props

  return (
    <Container className={className} style={style}>
      <Label hasIcon={!!iconComponent}>
        {iconComponent}
        <span>{label}</span>
      </Label>

      <Content className={contentClassName} style={contentStyle}>
        {children}
      </Content>
    </Container>
  )
}

