import {
  RequestFetchTrainingZonesAction,
  RequestEditTrainingZoneAction,
  RequestAddTrainingZoneAction,
  RequestDeleteTrainingZoneAction,
  SetTrainingZoneListAction,
  EditTrainingZoneInListAction,
  TRAINING_ZONE_TYPES,
  AddInTrainingZoneListAction,
  RemoveFromTrainingZoneListAction,
} from './types'

export const requestFetchTrainingZones = (): RequestFetchTrainingZonesAction => ({
  type: TRAINING_ZONE_TYPES.REQUEST_FETCH,
})

export const requestEditTrainingZone = (
  payload: Models.TrainingZone,
): RequestEditTrainingZoneAction => ({
  type: TRAINING_ZONE_TYPES.REQUEST_EDIT,
  payload,
})

export const requestAddTrainingZone = (
  payload: Omit<Models.TrainingZone, 'zonatrein_id'>,
): RequestAddTrainingZoneAction => ({
  type: TRAINING_ZONE_TYPES.REQUEST_ADD,
  payload,
})

export const requestDeleteTrainingZone = (
  id: number,
): RequestDeleteTrainingZoneAction => ({
  type: TRAINING_ZONE_TYPES.REQUEST_DELETE,
  payload: {
    id,
  },
})

export const setTrainingZoneList = (
  trainingZones: Models.TrainingZone[],
): SetTrainingZoneListAction => ({
  type: TRAINING_ZONE_TYPES.SET_LIST,
  payload: {
    trainingZones,
  },
})

export const editTrainingZoneInList = (
  trainingZone: Models.TrainingZone,
): EditTrainingZoneInListAction => ({
  type: TRAINING_ZONE_TYPES.EDIT_IN_LIST,
  payload: trainingZone,
})

export const addInTrainingZoneList = (
  trainingZone: Models.TrainingZone,
): AddInTrainingZoneListAction => ({
  type: TRAINING_ZONE_TYPES.ADD_IN_LIST,
  payload: trainingZone,
})

export const removeFromTrainingZoneList = (
  id: number,
): RemoveFromTrainingZoneListAction => ({
  type: TRAINING_ZONE_TYPES.REMOVE_FROM_LIST,
  payload: {
    id,
  },
})
