import {
  RequestFetchBlocksAction,
  RequestEditBlockAction,
  RequestAddBlockAction,
  RequestDeleteBlockAction,
  SetBlockListAction,
  EditBlockInListAction,
  BLOCK_TYPES,
  AddInBlockListAction,
  RemoveFromBlockListAction,
} from './types'

export const requestFetchBlocks = (): RequestFetchBlocksAction => ({
  type: BLOCK_TYPES.REQUEST_FETCH,
})

export const requestEditBlock = (
  payload: Models.Block,
): RequestEditBlockAction => ({
  type: BLOCK_TYPES.REQUEST_EDIT,
  payload,
})

export const requestAddBlock = (
  block: Omit<Models.Block, 'bloco_id'>,
  successCallback?: (block: Models.Block) => void,
): RequestAddBlockAction => ({
  type: BLOCK_TYPES.REQUEST_ADD,
  payload: {
    block,
    successCallback,
  },
})

export const requestDeleteBlock = (id: number): RequestDeleteBlockAction => ({
  type: BLOCK_TYPES.REQUEST_DELETE,
  payload: {
    id,
  },
})

export const setBlockList = (blocks: Models.Block[]): SetBlockListAction => ({
  type: BLOCK_TYPES.SET_LIST,
  payload: {
    blocks,
  },
})

export const editBlockInList = (
  block: Models.Block,
): EditBlockInListAction => ({
  type: BLOCK_TYPES.EDIT_IN_LIST,
  payload: block,
})

export const addInBlockList = (block: Models.Block): AddInBlockListAction => ({
  type: BLOCK_TYPES.ADD_IN_LIST,
  payload: block,
})

export const removeFromBlockList = (id: number): RemoveFromBlockListAction => ({
  type: BLOCK_TYPES.REMOVE_FROM_LIST,
  payload: {
    id,
  },
})
