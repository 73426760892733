import styled, { css } from 'styled-components'

import { SmallButton } from 'src/components'

type ButtonProps = {
  isSelected: boolean
}

export const Container = styled.div`
  border: 2px solid ${(props) => props.theme.snowDark};
  border-radius: 1rem;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  padding: 1.6rem;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const QuestionNumber = styled.div`
  background: ${(props) => props.theme.snow};

  border-radius: 1rem;

  padding: 0.4rem 1.2rem;
  margin-bottom: 1.6rem;

  font-size: 1.4rem;
  color: ${(props) => props.theme.secondaryText};

  display: inline-block;
`

export const Question = styled.div`
  font-weight: 900;
  font-size: 2rem;

  flex: 1;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  border-top: 2px solid ${(props) => props.theme.snowDark};

  background: ${(props) => props.theme.snow};

  padding: 0.8rem 1.6rem;
`

export const YesButton = styled(SmallButton)<ButtonProps>`
  border: 2px solid ${(props) => props.theme.snowDark};
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.primaryText};

  margin-right: 0.8rem;

  &:hover,
  &:focus {
    background: ${(props) => props.theme.background};
    border-color: ${(props) => props.theme.danger};
    color: ${(props) => props.theme.danger};
  }

  ${(props) => {
    if (props.isSelected) {
      return css`
        background: ${(props) => props.theme.danger} !important;
        border-color: ${(props) => props.theme.danger} !important;
        color: white !important;
      `
    }
  }}

  ${(props) => {
    if (props.disabled) {
      return css`
        opacity: 1;
      `
    }
  }}
`

export const NoButton = styled(SmallButton)<ButtonProps>`
  border: 2px solid ${(props) => props.theme.snowDark};
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.primaryText};

  margin-left: 0.8rem;

  &:hover,
  &:focus {
    background: ${(props) => props.theme.background};
    border-color: ${(props) => props.theme.student};
    color: ${(props) => props.theme.student};
  }

  ${(props) => {
    if (props.isSelected) {
      return css`
        background: ${(props) => props.theme.student} !important;
        border-color: ${(props) => props.theme.student} !important;
        color: white !important;
      `
    }
  }}

  ${(props) => {
    if (props.disabled) {
      return css`
        opacity: 1;
      `
    }
  }}
`
