import React from 'react'
import { FiCheck, FiX } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import {
  Container,
  Content,
  QuestionNumber,
  Question,
  ButtonsContainer,
  NoButton,
  YesButton,
} from './styles'

export interface AnamneseAnswerableQuestionProps {
  className?: string
  style?: React.CSSProperties
  question: string
  questionNumber?: number
  totalOfQuestions?: number
  onYesButtonClicked?: () => void
  onNoButtonClicked?: () => void
  isYesButtonSelected?: boolean
  isNoButtonSelected?: boolean
}

export const AnamneseAnswerableQuestion: React.FC<AnamneseAnswerableQuestionProps> = (
  props,
) => {
  const {
    className,
    style,
    question,
    questionNumber = 0,
    totalOfQuestions = 0,
    onYesButtonClicked,
    onNoButtonClicked,
    isYesButtonSelected = false,
    isNoButtonSelected = false,
  } = props

  const { t } = useTranslation(['Glossary', 'Common'])

  return (
    <Container className={className} style={style}>
      <Content>
        <QuestionNumber>
          {t('Common:question', {
            number: questionNumber,
            total: totalOfQuestions,
          })}
        </QuestionNumber>

        <Question>{question}</Question>
      </Content>

      <ButtonsContainer>
        <YesButton
          onClick={onYesButtonClicked}
          disabled={!onYesButtonClicked}
          isSelected={isYesButtonSelected}
        >
          <span>{t('Glossary:yes')}</span>
          <FiX />
        </YesButton>

        <NoButton
          onClick={onNoButtonClicked}
          disabled={!onNoButtonClicked}
          isSelected={isNoButtonSelected}
        >
          <span>{t('Glossary:no')}</span>
          <FiCheck />
        </NoButton>
      </ButtonsContainer>
    </Container>
  )
}
