import React from 'react'
import { useTranslation } from 'react-i18next'
import { FiFile, FiGrid, FiList } from 'react-icons/fi'

import { Spinner, SpinnerWithText } from 'src/components'
import { STUDENT_TRAINING_TYPES } from 'src/store/ducks/studentTraining'
import { InfinitePagination, useIsLoading, useTypedSelector } from 'src/hooks'

import FullList from '../FullList'
import { ListViewType } from '../types'
import CondensedList from '../CondensedList'

import {
  Container,
  NoTrainingsMessage,
  ListViewTypes,
  ListViewTypeButton,
  BottomSpinnerContainer,
  TopSpinnerContainer,
} from './styles'

interface TrainingListProps {
  pagination: InfinitePagination
  listViewType: ListViewType | undefined
  setListViewType: React.Dispatch<
    React.SetStateAction<ListViewType | undefined>
  >
}

const TrainingList: React.FC<TrainingListProps> = (props) => {
  const { listViewType, setListViewType, pagination } = props

  const { t } = useTranslation(['StudentTrainings', 'Training'])

  const isSearching = useIsLoading(
    STUDENT_TRAINING_TYPES.REQUEST_FETCH,
    STUDENT_TRAINING_TYPES.REQUEST_FETCH_FULL,
  )

  const isListEmpty = useTypedSelector(({ StudentTraining }) => {
    if (listViewType === 'condensed') {
      return StudentTraining.trainings.length === 0
    } else if (listViewType === 'full') {
      return StudentTraining.fullTrainings.length === 0
    } else {
      return false
    }
  })

  const handleToggleListViewType = () => {
    setListViewType((currentViewType) =>
      currentViewType === 'condensed' ? 'full' : 'condensed',
    )
  }

  return (
    <Container>
      <ListViewTypes>
        <ListViewTypeButton
          isSelected={listViewType === 'condensed'}
          onClick={handleToggleListViewType}
        >
          <span>{t('listViewTypeCondensed')}</span>
          <FiList />
        </ListViewTypeButton>

        <ListViewTypeButton
          isSelected={listViewType === 'full'}
          onClick={handleToggleListViewType}
        >
          <span>{t('listViewTypeFull')}</span>
          <FiGrid />
        </ListViewTypeButton>
      </ListViewTypes>

      {isSearching && pagination.pageNumber === 0 && (
        <TopSpinnerContainer>
          <SpinnerWithText hasSpinnerOnTheLeft>
            <Spinner />
            <span>{t('loadingText')}</span>
          </SpinnerWithText>
        </TopSpinnerContainer>
      )}

      {listViewType === 'condensed' ? <CondensedList /> : <FullList />}

      {isSearching && pagination.pageNumber > 0 && (
        <BottomSpinnerContainer>
          <SpinnerWithText hasSpinnerOnTheLeft>
            <Spinner />
            <span>{t('loadingMoreText')}</span>
          </SpinnerWithText>
        </BottomSpinnerContainer>
      )}

      {isListEmpty && (
        <NoTrainingsMessage
          message={t('noTrainingFound')}
          iconComponent={<FiFile />}
        />
      )}
    </Container>
  )
}

export default TrainingList
