import { call, put, takeLatest } from 'redux-saga/effects'

import { showSuccessToast } from 'src/store/ducks/success'
import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  AxiosServerResponse,
  GROUP_STUDENT_ROUTES,
  GROUP_TEACHER_ROUTES,
  Server,
} from 'src/services/Server'

import { GROUP_MEMBER_TYPES, RequestRemoveGroupMemberAction } from './types'
import { removeFromGroupMemberList } from './actions'

export function* requestRemoveGroupMemberWatcher() {
  yield takeLatest(
    GROUP_MEMBER_TYPES.REQUEST_REMOVE_MEMBER,
    handleRemoveGroupMember,
  )
}

export function* handleRemoveGroupMember(
  action: RequestRemoveGroupMemberAction,
) {
  try {
    yield put(addLoading(GROUP_MEMBER_TYPES.REQUEST_REMOVE_MEMBER))
    const { id, isStudent, shouldRemoveFromMemberList } = action.payload

    const routeArray = [
      isStudent
        ? GROUP_STUDENT_ROUTES.GRUPO_ALUNO
        : GROUP_TEACHER_ROUTES.GRUPO_PROFESSOR,
      id,
    ]

    const { data }: AxiosServerResponse = yield call(
      Server.delete,
      routeArray.join('/'),
    )

    if (data.isSuccessful) {
      if (shouldRemoveFromMemberList) {
        yield put(removeFromGroupMemberList(id))
      }

      yield put(showSuccessToast({ messageCode: 'removeMemberFromGroup' }))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(GROUP_MEMBER_TYPES.REQUEST_REMOVE_MEMBER))
  }
}
