import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import { AxiosServerResponse, USER_ROUTES, Server } from 'src/services/Server'

import { setUserData } from './actions'
import { USER_TYPES, RequestFetchUserDataAction } from './types'

type AxiosResponse = AxiosServerResponse<Models.UserGroupedTypes.UserWithExtraAttrs>

export function* requestFetchUserDataWatcher() {
  yield takeLatest(USER_TYPES.REQUEST_FETCH_USER_DATA, handleFetchUserData)
}

export function* handleFetchUserData(action: RequestFetchUserDataAction) {
  try {
    yield put(addLoading(USER_TYPES.REQUEST_FETCH_USER_DATA))
    const { userId } = action.payload

    const { data }: AxiosResponse = yield call(
      Server.get,
      [USER_ROUTES.USUARIO, userId].join('/'),
    )

    if (data.isSuccessful) {
      const userData = data.getFirstData() || undefined
      yield put(setUserData(userData))
    } else {
      yield put(setUserData(undefined))
      if (data.messages.length) {
        yield put(showServerError(data.messages))
      }
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(USER_TYPES.REQUEST_FETCH_USER_DATA))
  }
}
