import styled, { css } from 'styled-components'

import { SmallButton, Input } from 'src/components'

type FormProps = {
  isEditing?: boolean
}

export const Form = styled.form<FormProps>`
  width: 100%;

  ${(props) => {
    if (props.isEditing) {
      return css`
        border: 2px solid ${props.theme.danger};
        border-radius: 1rem;
      `
    }
  }}
`

export const EditingIndicatorText = styled.div`
  background: ${(props) => props.theme.danger};
  color: white;

  border-radius: 0.5rem 0.5rem 0 0;

  padding: 0.4rem 1.6rem;
  font-size: 1.2rem;
  font-weight: bold;
`

export const Content = styled.div`
  padding: 1.6rem;
`

export const TrainingActivityInput = styled(Input)`
  margin-bottom: 1.6rem;
`

export const AddTrainingActivity = styled(SmallButton)`
  width: auto;

  margin: 0.8rem 0 0 auto;
`

export const CreateNewActivity = styled(SmallButton)`
  width: auto;

  font-size: 1.4rem;
  color: ${(props) => props.theme.success};

  margin-bottom: 1.6rem;

  background: ${(props) => props.theme.background};

  &:hover,
  &:focus {
    background: ${(props) => props.theme.success};
    color: white;
  }
`
