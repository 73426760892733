import React from 'react'

import { useTypedSelector, useUnits } from 'src/hooks'

import { Container, TrainingSheet, Block, Activity } from './styles'

const SheetView: React.FC = () => {
  const { handleFormatValueWithUnit } = useUnits()

  const trainingSheetData = useTypedSelector(
    ({ TrainingSheet }) => TrainingSheet.trainingSheetData,
  )

  return (
    <Container>
      <TrainingSheet>
        {trainingSheetData?.blocos.map((block) => {
          const {
            treinobloco_id,
            bloco_descricao,
            treinobloco_repeticao,
            atividades,
          } = block

          return (
            <Block
              name={bloco_descricao}
              repeat={treinobloco_repeticao}
              key={treinobloco_id}
            >
              {atividades.map((activity) => {
                const {
                  treblocativ_id,
                  atividade_descricao,
                  treblocativ_repeticao,
                  treblocativ_tempo,
                  treblocativ_unidade,
                  zonatrein_bpmminimo,
                  zonatrein_bpmmaximo,
                } = activity

                return (
                  <Activity
                    key={treblocativ_id}
                    name={atividade_descricao}
                    repeat={treblocativ_repeticao}
                    minBpm={zonatrein_bpmminimo}
                    maxBpm={zonatrein_bpmmaximo}
                    value={handleFormatValueWithUnit(
                      treblocativ_unidade,
                      treblocativ_tempo,
                    )}
                  />
                )
              })}
            </Block>
          )
        })}
      </TrainingSheet>
    </Container>
  )
}

export default SheetView
