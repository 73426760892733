import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  TRAINING_SHEET_ROUTES,
  Server,
} from 'src/services/Server'

import { RequestDeleteTrainingSheetAction, TRAINING_SHEET_TYPES } from './types'

export function* requestDeleteTrainingSheetWatcher() {
  yield takeLatest(
    TRAINING_SHEET_TYPES.REQUEST_DELETE,
    handleDeleteTrainingSheet,
  )
}

export function* handleDeleteTrainingSheet(
  action: RequestDeleteTrainingSheetAction,
) {
  try {
    yield put(addLoading(TRAINING_SHEET_TYPES.REQUEST_DELETE))
    const { id, successCallback } = action.payload

    const routeArray = [TRAINING_SHEET_ROUTES.TREINO, id]

    const { data }: AxiosServerResponse = yield call(
      Server.delete,
      routeArray.join('/'),
    )

    if (data.isSuccessful) {
      if (successCallback) successCallback()
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(TRAINING_SHEET_TYPES.REQUEST_DELETE))
  }
}
