import styled from 'styled-components'

import { AdminBadge, Avatar, StudentBadge, TeacherBadge } from 'src/components'

export const Container = styled.div`
  margin-bottom: 4.8rem;
`

export const UserName = styled.div`
  font-weight: 900;
  font-size: 3.2rem;
  text-align: center;

  margin-bottom: 0.8rem;
`

export const UserAvatar = styled(Avatar)`
  width: 18rem;
  height: 18rem;
  border-radius: 18rem;
  font-size: 4.5rem;

  margin: 0 auto 1.6rem auto;
`

export const UserTypes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const TeacherTypeBadge = styled(TeacherBadge)`
  margin: 0 0.4rem;
`

export const StudentTypeBadge = styled(StudentBadge)`
  margin: 0 0.4rem;
`

export const AdminTypeBadge = styled(AdminBadge)`
  margin: 0 0.4rem;
`
