export enum TRAINING_LEVEL_CATEGORIES {
  A0 = 'A0',
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  B0 = 'B0',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  C0 = 'C0',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
}
