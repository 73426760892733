import styled from 'styled-components'

import { Input, SuccessButton } from 'src/components'

export const Form = styled.form`
  padding: 1.6rem;
`

export const BlockInput = styled(Input)`
  margin-bottom: 1.6rem;
`

export const SaveBlockButton = styled(SuccessButton)``
