import styled from 'styled-components'

import { PageContainer, PageTitle, DefaultDivider } from 'src/components'

export const Container = styled(PageContainer)``

export const TrainingBlockConfigTitle = styled(PageTitle)`
  margin-bottom: 4.8rem;
`

export const Divider = styled(DefaultDivider)`
  margin: 2.4rem 0;
  border-color: ${(props) => props.theme.snowDark};
`
