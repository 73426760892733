import Axios from 'axios'

import TransformResponse from './TransformResponse'

const Server = Axios.create({
  baseURL: 'https://app.toprunning.com.br/API_TP/DataSnap/rest',
  timeout: 0,
  transformResponse: [TransformResponse],
})

Server.defaults.auth = {
  username: process.env.REACT_APP_API_USER || '',
  password: process.env.REACT_APP_API_PASSWORD || '',
}

export default Server

