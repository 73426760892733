import { useCallback, useRef, useState } from 'react'
import StateManager, { OptionsType } from 'react-select'

import { ReactSelectOption } from 'src/components'
import { useModalToggleFunctions, useTypedSelector } from 'src/hooks'

import { TrainingConfigBlock } from '../types'

import { TrainingBlockFormProps } from './blockFormTypes'

export default ({
  editingBlockIndex,
  handleAddTrainingBlock,
  handleEditBlock,
}: TrainingBlockFormProps) => {
  const blockRef = useRef<StateManager<ReactSelectOption> | null>(null)
  const blockRepetitionsRef = useRef<HTMLInputElement | null>(null)

  const [
    isShowingBlockModal,
    handleShowBlockModal,
    handleHideBlockModal,
  ] = useModalToggleFunctions()

  const [errors, setErrors] = useState({
    isBlockNotSelected: false,
    isBlockRepetitionsEmpty: false,
  })

  const blockOptions: OptionsType<ReactSelectOption> = useTypedSelector(
    ({ Block }) => {
      return Block.blocks.map((block) => ({
        label: block.bloco_descricao,
        value: `${block.bloco_id}`,
      }))
    },
  )

  const getValues = () => {
    const selectedBlock = (blockRef.current?.state.value ||
      {}) as ReactSelectOption

    const blockId = selectedBlock.value || ''
    const blockName = selectedBlock.label || ''
    const blockRepetitions = blockRepetitionsRef.current?.value || ''

    return {
      blockId,
      blockName,
      blockRepetitions,
    }
  }

  const setValues = useCallback(
    (block: ReactSelectOption | null = null, repetitions: number = 1) => {
      if (blockRef.current) {
        blockRef.current.select.setValue(block, 'set-value')
      }

      if (blockRepetitionsRef.current && repetitions > 0) {
        blockRepetitionsRef.current.value = String(repetitions)
      }
    },
    [],
  )

  const clearValues = useCallback(() => {
    if (blockRef.current) blockRef.current.select.setValue(null, 'set-value')
    if (blockRepetitionsRef.current) blockRepetitionsRef.current.value = ''
  }, [])

  const handleSelectBlockOption = (block: Models.Block) => {
    if (blockRef.current) {
      const { bloco_id, bloco_descricao } = block

      const blockOption: ReactSelectOption = {
        label: bloco_descricao,
        value: String(bloco_id),
      }

      blockRef.current.select.setValue(blockOption, 'set-value')
    }
  }

  const handleValidate = (): boolean => {
    const { blockId, blockRepetitions } = getValues()

    const errorState: typeof errors = {
      isBlockNotSelected: !blockId,
      isBlockRepetitionsEmpty: !blockRepetitions.trim(),
    }

    setErrors(errorState)
    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleValidate()) {
      const { blockId, blockName, blockRepetitions } = getValues()

      const block: TrainingConfigBlock = {
        atividades: [],
        bloco_id: Number(blockId),
        bloco_descricao: blockName,
        treinobloco_repeticao: Number(blockRepetitions || 1),

        treino_id: 0,
        treinobloco_id: 0,
        treinobloco_sequencia: 0,
        bloco_situacao: 'S',
      }

      if (editingBlockIndex === -1) handleAddTrainingBlock(block)
      else handleEditBlock(block)

      setValues()
    }
  }

  return {
    blockRef,
    blockRepetitionsRef,

    isShowingBlockModal,
    errors,
    setErrors,

    blockOptions,

    getValues,
    setValues,
    clearValues,

    handleSelectBlockOption,
    handleShowBlockModal,
    handleHideBlockModal,
    handleValidate,
    handleSubmitForm,
  }
}
