import styled from 'styled-components'

import { PageContainer, PageTitle } from 'src/components'

export const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;

  min-height: 100%;
`

export const RunningGroupTitle = styled(PageTitle)`
  border-bottom: 1px solid ${(props) => props.theme.snow};

  padding-bottom: 2.4rem;
`

export const GroupImage = styled.img`
  width: 15rem;
  height: 15rem;

  border: none;
  border-radius: 1rem;

  overflow: hidden;

  @media only screen and (max-width: 768px) {
    width: 10rem;
    height: 10rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
`
