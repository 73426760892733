import React, { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FiChevronLeft } from 'react-icons/fi'
import { BsFileEarmarkRuled } from 'react-icons/bs'
import { useHistory, useParams } from 'react-router-dom'

import {
  FullHeightSpinnerContainer,
  Spinner,
  SpinnerWithText,
} from 'src/components'
import {
  useIsLoading,
  useSetBrowserTabTitle,
  useTypedSelector,
} from 'src/hooks'
import {
  requestGetStudentTraining,
  setStudentTrainingData,
  STUDENT_TRAINING_TYPES,
} from 'src/store/ducks/studentTraining'

import Actions from './Actions'
import SheetView from './SheetView'
import SheetInfo from './SheetInfo'
import { StudentTrainingDetailsParams } from './types'
import {
  Container,
  StyledPageTitle,
  NoTrainingSheetMessage,
  BackButton,
} from './styles'

const StudentTrainingDetails: React.FC = () => {
  useSetBrowserTabTitle('trainingDetails')

  const { id } = useParams<StudentTrainingDetailsParams>()
  const { t } = useTranslation('StudentTrainingDetails')
  const dispatch = useDispatch()
  const history = useHistory()

  const isLoading = useIsLoading(STUDENT_TRAINING_TYPES.REQUEST_GET)

  const hasData = useTypedSelector(
    ({ StudentTraining }) => !!StudentTraining.trainingData,
  )

  const handleGoBack = () => {
    history.goBack()
  }

  useLayoutEffect(() => {
    dispatch(requestGetStudentTraining(Number(id)))
    return () => {
      dispatch(setStudentTrainingData(undefined))
    }
  }, [dispatch, id])

  return isLoading ? (
    <FullHeightSpinnerContainer>
      <SpinnerWithText hasSpinnerOnTheLeft>
        <Spinner />
        <span>{t('loadingText')}</span>
      </SpinnerWithText>
    </FullHeightSpinnerContainer>
  ) : (
    <Container>
      <StyledPageTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<BsFileEarmarkRuled />}
      />

      {hasData ? (
        <>
          <Actions />
          <SheetInfo />
          <SheetView />
        </>
      ) : (
        <NoTrainingSheetMessage
          iconComponent={<BsFileEarmarkRuled />}
          message={t('noTrainingSheetMessage')}
        >
          <BackButton onClick={handleGoBack}>
            <FiChevronLeft />
            <span>{t('backButton')}</span>
          </BackButton>
        </NoTrainingSheetMessage>
      )}
    </Container>
  )
}

export default StudentTrainingDetails
