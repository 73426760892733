import styled from 'styled-components'

import { EmptyMessage, SmallButton, UserItem, Badge } from 'src/components'
import { NoWrapEllipsis } from 'src/styles'

type AnamneseStatusBadgeProps = {
  notAnswered: boolean
  answersContainOneYes: boolean
  isEverythingAllRight: boolean
}

export const Container = styled.div`
  flex: 1;

  @media only screen and (max-width: 1200px) {
    flex: unset;
  }
`

export const Title = styled.h2`
  display: flex;
  align-items: center;

  padding: 2.4rem 0;
  margin: 0;

  span {
    margin-left: 2.4rem;
  }
`

export const List = styled.div`
  padding-right: 1.6rem;

  @media only screen and (max-width: 1200px) {
    padding-right: 0;
  }
`

export const EmptyList = styled(EmptyMessage)`
  margin: auto;
`

export const User = styled(UserItem)`
  margin-bottom: 0.8rem;
  padding: 0.8rem 1.6rem;

  border-color: ${(props) => props.theme.snow};

  flex-wrap: wrap-reverse;
`

export const ItemBadges = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 0.4rem;

  > * {
    margin-right: 0.4rem;
  }
`

export const ConfigStudentTraining = styled(SmallButton)`
  width: auto;
  height: 3.2rem;

  padding: 0 2.4rem;
  margin-top: 0.8rem;

  font-size: 1.6rem;
  font-weight: bold;
`

export const AnamneseStatusBadge = styled(Badge).attrs({
  size: 'small',
})<AnamneseStatusBadgeProps>`
  ${NoWrapEllipsis};

  background: ${(props) => props.theme.background};
  font-weight: bold;

  border: 1px dashed
    ${(props) => {
      if (props.notAnswered) return props.theme.border
      else if (props.answersContainOneYes) return props.theme.danger
      else if (props.isEverythingAllRight) return props.theme.success
      return 'transparent'
    }};

  color: ${(props) => {
    if (props.notAnswered) return props.theme.secondaryText
    else if (props.answersContainOneYes) return props.theme.danger
    else if (props.isEverythingAllRight) return props.theme.success
    return 'transparent'
  }};
`
