import React from 'react'
import { useTranslation } from 'react-i18next'
import { BsClipboardData, BsFileEarmarkRuled, BsPeople } from 'react-icons/bs'
import {
  FiActivity,
  FiBox,
  FiFile,
  FiFilePlus,
  FiHeart,
  FiHome,
  FiPieChart,
  FiUser,
  FiUserPlus,
  FiUsers,
  FiZap,
} from 'react-icons/fi'

import { DrawerItem } from 'src/components'

import useCheckIfSelected from './useCheckIfSelected'
import { Group, GroupLabel } from './styles'

const AdminItems: React.FC = () => {
  const { t } = useTranslation('Main')
  const handleCheckIfSelected = useCheckIfSelected()

  return (
    <>
      <Group>
        <GroupLabel>{t('labels.general')}</GroupLabel>

        <DrawerItem
          to="/"
          text={t('drawer.home')}
          iconComponent={<FiHome />}
          selected={handleCheckIfSelected('/', true)}
        />

        <DrawerItem
          to="/dashboard"
          text={t('drawer.dashboard')}
          iconComponent={<FiPieChart />}
          selected={handleCheckIfSelected('/dashboard', true)}
        />

        <DrawerItem
          to="/user-profile"
          text={t('drawer.userProfile')}
          iconComponent={<FiUser />}
          selected={handleCheckIfSelected('/user-profile')}
        />
      </Group>

      <Group>
        <GroupLabel>{t('labels.training')}</GroupLabel>

        <DrawerItem
          to="/my-trainings"
          text={t('drawer.myTrainings')}
          iconComponent={<FiFile />}
          selected={handleCheckIfSelected('my-trainings')}
        />
      </Group>

      <Group>
        <GroupLabel>{t('labels.students')}</GroupLabel>

        <DrawerItem
          to="/my-students"
          text={t('drawer.myStudents')}
          iconComponent={<FiUsers />}
          selected={handleCheckIfSelected('my-students')}
        />

        <DrawerItem
          to="/new-student"
          text={t('drawer.newStudent')}
          iconComponent={<FiUserPlus />}
          selected={handleCheckIfSelected('/new-student')}
        />
      </Group>

      <Group>
        <GroupLabel>{t('labels.runningGroups')}</GroupLabel>

        <DrawerItem
          to="/my-running-groups"
          text={t('drawer.myRunningGroups')}
          iconComponent={<BsPeople />}
          selected={handleCheckIfSelected('my-running-groups')}
        />

        <DrawerItem
          to="/create-running-group"
          text={t('drawer.createRunningGroup')}
          iconComponent={<FiFilePlus />}
          selected={handleCheckIfSelected('/create-running-group')}
        />
      </Group>

      <Group>
        <GroupLabel>{t('labels.configs')}</GroupLabel>

        <DrawerItem
          to="/training-level-config"
          text={t('drawer.trainingLevelConfig')}
          iconComponent={<BsClipboardData />}
          selected={handleCheckIfSelected('training-level-config')}
        />

        <DrawerItem
          to="/training-block-config"
          text={t('drawer.trainingBlockConfig')}
          iconComponent={<FiBox />}
          selected={handleCheckIfSelected('training-block-config')}
        />

        <DrawerItem
          to="/training-config"
          text={t('drawer.trainingConfig')}
          iconComponent={<BsFileEarmarkRuled />}
          selected={handleCheckIfSelected('/training-config')}
        />

        <DrawerItem
          to="/activity-config"
          text={t('drawer.activityConfig')}
          iconComponent={<FiZap />}
          selected={handleCheckIfSelected('/activity-config')}
        />

        <DrawerItem
          to="/training-zone-config"
          text={t('drawer.trainingZoneConfig')}
          iconComponent={<FiActivity />}
          selected={handleCheckIfSelected('/training-zone-config')}
        />

        <DrawerItem
          to="/anamnese-config"
          text={t('drawer.anamneseConfig')}
          iconComponent={<FiHeart />}
          selected={handleCheckIfSelected('/anamnese-config')}
        />
      </Group>
    </>
  )
}

export default AdminItems
