import { call, put, takeLatest } from 'redux-saga/effects'

import { LOCAL_STORAGE_KEYS } from 'src/config'
import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, Server, USER_ROUTES } from 'src/services/Server'

import { AUTH_TYPES } from './types'
import { setAuthenticated, setNotAuthenticated } from './actions'

export function* requestCheckAuthWatcher() {
  yield takeLatest(AUTH_TYPES.REQUEST_CHECK_AUTH, handleCheckAuth)
}

export function* handleCheckAuth() {
  try {
    yield put(addLoading(AUTH_TYPES.REQUEST_CHECK_AUTH))

    const userDataJson: string = yield call(
      [localStorage, localStorage.getItem],
      LOCAL_STORAGE_KEYS.USER_DATA,
    )

    if (userDataJson) {
      const userData: Models.User = JSON.parse(userDataJson)
      let currentUserData: Partial<Models.User> = {}

      if (userData && userData.usuario_id) {
        const routeArray = [USER_ROUTES.USUARIO, userData.usuario_id]

        const { data }: AxiosServerResponse<Models.User> = yield call(
          Server.get,
          routeArray.join('/'),
        )

        currentUserData = data.getFirstData() || {}
      }

      yield put(setAuthenticated({ ...userData, ...currentUserData }))
    } else {
      yield put(setNotAuthenticated())
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(AUTH_TYPES.REQUEST_CHECK_AUTH))
  }
}
