import React, { useEffect, useRef, useState } from 'react'
import StateManager from 'react-select'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FiUserPlus, FiSearch, FiUser, FiUserX } from 'react-icons/fi'

import { requestAddGroupMember } from 'src/store/ducks/groupMember'
import {
  useIsLoading,
  useSetBrowserTabTitle,
  useTypedSelector,
} from 'src/hooks'
import {
  requestFetchStudentsOutsideGroup,
  setStudentsList,
  STUDENT_TYPES,
} from 'src/store/ducks/student'
import {
  Avatar,
  DefaultLabel,
  DefaultTooltip,
  ReactSelectOption,
  SnowInput,
  StudentSpinner,
  WhiteSpinner,
} from 'src/components'

import {
  Container,
  SearchUserTitle,
  Content,
  UserInput,
  UserList,
  User,
  SpinnerContainer,
  StyledGroupData,
  StyledGroupSelect,
  Divider,
  EmptyUserList,
  UserItemContainer,
  SearchButton,
  AddUserInGroupButton,
} from './styles'

interface SearchUserParams {
  groupId?: string
}

const SearchUser: React.FC = () => {
  useSetBrowserTabTitle('searchUser')

  const { groupId } = useParams<SearchUserParams>()
  const { t } = useTranslation('SearchUser')
  const dispatch = useDispatch()

  const isLoadingStudents = useIsLoading(
    STUDENT_TYPES.REQUEST_FETCH_STUDENTS_OUTSIDE_GROUP,
  )

  const students = useTypedSelector(
    ({ Student }) => Student.studentsOutsideGroup,
  )

  const groupRef = useRef<StateManager<ReactSelectOption> | null>(null)
  const searchRef = useRef<HTMLInputElement | null>(null)

  const [alreadyResearched, setAlreadyResearched] = useState(false)

  const [errors, setErrors] = useState({
    groupNotSelected: false,
  })

  const getValues = () => {
    const search = searchRef.current?.value.trim() || ''

    const selectedGroup = groupRef.current?.state.value as
      | ReactSelectOption
      | null
      | undefined

    const selectedGroupId = Number(groupId || selectedGroup?.value || 0)

    return {
      search,
      selectedGroupId,
    }
  }

  const handleValidation = (): boolean => {
    const { selectedGroupId } = getValues()

    const errorState: typeof errors = {
      groupNotSelected: !selectedGroupId,
    }

    setErrors(errorState)
    return errorState.groupNotSelected === false
  }

  const handleSearchStudents = () => {
    if (handleValidation()) {
      const { selectedGroupId, search } = getValues()
      setAlreadyResearched(true)
      dispatch(requestFetchStudentsOutsideGroup(selectedGroupId || 0, search))
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSearchStudents()
  }

  const handleAddUserInGroup = (userId: number) => {
    if (handleValidation()) {
      const { selectedGroupId } = getValues()

      dispatch(
        requestAddGroupMember({
          userId,
          groupId: selectedGroupId,
          isStudent: true,
          successCallback() {
            handleSearchStudents()
          },
        }),
      )
    }
  }

  useEffect(() => {
    return () => {
      dispatch(setStudentsList([], 'studentsOutsideGroup'))
    }
  }, [dispatch])

  return (
    <Container>
      <SearchUserTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<FiSearch />}
      />

      <Content>
        <form onSubmit={handleSubmit} noValidate>
          {groupId ? (
            <StyledGroupData groupId={Number(groupId)} />
          ) : (
            <StyledGroupSelect
              groupRef={groupRef}
              isGroupNotSelected={errors.groupNotSelected}
            />
          )}

          <UserInput
            iconComponent={<FiUser size="3.2rem" />}
            labelComponent={
              <DefaultLabel>
                <span>{t('searchLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowInput
                ref={searchRef}
                type="text"
                placeholder={t('searchPh')}
              />
            }
          />

          <SearchButton type="submit">
            <span>{t('searchButton')}</span>
            {isLoadingStudents ? <WhiteSpinner /> : <FiSearch />}
          </SearchButton>
        </form>
      </Content>

      <Divider />

      <Content>
        {isLoadingStudents ? (
          <SpinnerContainer hasSpinnerOnTheLeft>
            <StudentSpinner />
            <span>{t('searchingText')}</span>
          </SpinnerContainer>
        ) : (
          <UserList>
            {students.map((item: any) => {
              const { usuario_id, usuario_nome, usuario_email } = item

              const handleAddThisStudentInGroup = () => {
                handleAddUserInGroup(Number(usuario_id))
              }

              return (
                <UserItemContainer key={usuario_id}>
                  <User
                    name={usuario_nome}
                    email={usuario_email}
                    leftSideComponent={<Avatar alt={usuario_nome} />}
                  />

                  <AddUserInGroupButton
                    onClick={handleAddThisStudentInGroup}
                    data-tip={t('addInGroupTooltip')}
                    data-for="search-user"
                  >
                    <FiUserPlus />
                  </AddUserInGroupButton>
                </UserItemContainer>
              )
            })}

            {!!students.length && <DefaultTooltip id="search-user" />}
          </UserList>
        )}

        {!isLoadingStudents && !students.length && (
          <EmptyUserList
            iconComponent={alreadyResearched ? <FiUserX /> : <FiSearch />}
            message={t(alreadyResearched ? 'noUserFound' : 'notSearchedYet')}
          />
        )}
      </Content>
    </Container>
  )
}

export default SearchUser

