import {
  ANAMNESE_TYPES,
  RequestGetAnamneseQuizAction,
  RequestAddAnamneseQuizAction,
  RequestUpdateAnamneseQuizAction,
  SetAnamneseQuizAction,
} from './types'

export const requestGetAnamneseQuiz = (): RequestGetAnamneseQuizAction => ({
  type: ANAMNESE_TYPES.REQUEST_GET,
})

export const requestAddAnamneseQuiz = (
  questions: string[],
  successCallback?: () => void,
): RequestAddAnamneseQuizAction => ({
  type: ANAMNESE_TYPES.REQUEST_ADD,
  payload: {
    questions,
    successCallback,
  },
})

export const requestUpdateAnamneseQuiz = (
  questions: string[],
  successCallback?: () => void,
): RequestUpdateAnamneseQuizAction => ({
  type: ANAMNESE_TYPES.REQUEST_UPDATE,
  payload: {
    questions,
    successCallback,
  },
})

export const setAnamneseQuiz = (
  quiz?: Models.AnamneseGroupedTypes.AnamneseWithQuestions,
): SetAnamneseQuizAction => ({
  type: ANAMNESE_TYPES.SET_QUIZ,
  payload: {
    quiz,
  },
})
