import styled from 'styled-components'

import { LabeledEdgeContainer } from 'src/components'

type ContainerProps = {
  isAdmin: boolean
  isTeacher: boolean
  isStudent: boolean
}

type AnamneseStatusProps = {
  notAnswered: boolean
  answersContainOneYes: boolean
  isEverythingAllRight: boolean
}

export const Container = styled(LabeledEdgeContainer).attrs({
  contentStyle: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
})<ContainerProps>`
  padding: 2.4rem 1.6rem 1.6rem 1.6rem;
  margin: 0 0.8rem 2.4rem 0.8rem;

  // Cor do label da Section
  // Ela herda esta cor
  color: ${(props) => {
    if (props.isAdmin) return props.theme.admin
    else if (props.isTeacher) return props.theme.teacher
    else return props.theme.student
  }};
`

export const AnamneseStatus = styled.div<AnamneseStatusProps>`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 1rem;

  font-size: 2rem;
  font-weight: bold;

  padding: 1.6rem;

  border: 2px dashed
    ${(props) => {
      if (props.notAnswered) return props.theme.border
      else if (props.answersContainOneYes) return props.theme.danger
      else if (props.isEverythingAllRight) return props.theme.success
      return 'transparent'
    }};

  color: ${(props) => {
    if (props.notAnswered) return props.theme.secondaryText
    else if (props.answersContainOneYes) return props.theme.danger
    else if (props.isEverythingAllRight) return props.theme.success
    return 'transparent'
  }};
`
