import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  ACTIVITY_ROUTES,
  Server,
} from 'src/services/Server'
import { withServerStructure } from 'src/utils'

import { RequestEditActivityAction, ACTIVITY_TYPES } from './types'
import { editActivityInList } from './actions'

export function* requestEditActivityWatcher() {
  yield takeLatest(ACTIVITY_TYPES.REQUEST_EDIT, handleEditActivity)
}

export function* handleEditActivity(action: RequestEditActivityAction) {
  try {
    yield put(addLoading(ACTIVITY_TYPES.REQUEST_EDIT))
    const { id, name, video, situation } = action.payload

    const dataToEdit: Models.Activity = {
      atividade_id: id,
      atividade_descricao: name,
      atividade_linkvideopadrao: video,
      atividade_situacao: situation,
    }

    const { data }: AxiosServerResponse<Models.Activity> = yield call(
      Server.post,
      ACTIVITY_ROUTES.ATIVIDADE,
      withServerStructure(dataToEdit),
    )

    if (data.isSuccessful) {
      const activity = data.getFirstData()
      if (activity) yield put(editActivityInList(activity))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(ACTIVITY_TYPES.REQUEST_EDIT))
  }
}
