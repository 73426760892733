import { call, put, takeLatest } from 'redux-saga/effects'

import { AxiosServerResponse, BLOCK_ROUTES, Server } from 'src/services/Server'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError } from 'src/store/ducks/error'

import { BLOCK_TYPES } from './types'
import { setBlockList } from './actions'

export function* requestFetchBlocksWatcher() {
  yield takeLatest(BLOCK_TYPES.REQUEST_FETCH, handleFetchBlocks)
}

export function* handleFetchBlocks() {
  try {
    yield put(addLoading(BLOCK_TYPES.REQUEST_FETCH))

    const { data }: AxiosServerResponse<Models.Block> = yield call(
      Server.put,
      BLOCK_ROUTES.GET_POR_CAMPOS,
      {},
    )

    if (data.isSuccessful) {
      yield put(setBlockList(data.data))
    } else {
      yield put(setBlockList([]))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(BLOCK_TYPES.REQUEST_FETCH))
  }
}
