import styled from 'styled-components'

import { Card, LabeledEdgeContainer, StudentButton } from 'src/components'

export const Container = styled.div`
  grid-area: dataSection;

  padding: 4.8rem;

  @media only screen and (min-width: 1200px) {
    border-right: 1px solid ${(props) => props.theme.border};
  }
`

export const DataGroup = styled(LabeledEdgeContainer)`
  padding: 1.6rem 0.8rem 0.8rem 0.8rem;
  margin-bottom: 3.2rem;

  color: ${(props) => props.theme.success};
`

export const DataGroupContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ProfileCard = styled(Card)`
  flex: 1 45%;
  min-width: 21rem;
  margin: 0.8rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  border: 1px solid ${(props) => props.theme.snowDark};
`

export const UserData = styled.div`
  flex: 1 45%;
  min-width: 21rem;

  border-radius: 1rem;

  padding: 0.8rem 1.6rem;

  div:nth-child(1) {
    font-weight: 900;
  }
`

export const SeeAnamneseAnswersButton = styled(StudentButton)`
  width: auto;
`
