import styled from 'styled-components'

import { PageContainer, PageTitle, EmptyMessage, Button } from 'src/components'

export const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;

  min-height: 100%;
`

export const StyledPageTitle = styled(PageTitle)`
  margin-bottom: 4.8rem;
`

export const NoTrainingSheetMessage = styled(EmptyMessage)`
  margin: auto;

  align-self: center;
`

export const BackButton = styled(Button)`
  width: auto;

  margin: auto;

  padding: 0 2.4rem;

  span {
    margin-right: 0;
    margin-left: 1.6rem;
  }
`
