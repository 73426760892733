import styled from 'styled-components'

import { NoWrapEllipsis } from 'src/styles'

const COMPONENT_HEIGHT = '8rem'

export const IconContainer = styled.div`
  ${NoWrapEllipsis};

  background: ${(props) => props.theme.snow};

  display: flex;
  align-items: center;
  justify-content: center;

  width: ${COMPONENT_HEIGHT};
  height: 100%;

  font-size: 3.2rem;
`

export const TextContainer = styled.div`
  ${NoWrapEllipsis};

  flex: 1;
  height: 100%;

  line-height: ${COMPONENT_HEIGHT};
  font-size: 1.8rem;
  font-weight: bold;

  padding: 0 1.6rem;
`

export const Container = styled.div`
  ${NoWrapEllipsis};

  height: ${COMPONENT_HEIGHT};

  border: 1px solid ${(props) => props.theme.snow};
  border-radius: 1rem;
  overflow: hidden;

  color: ${(props) => props.theme.primaryText};

  display: flex;
  align-items: center;

  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease-in-out;
`
