import styled from 'styled-components'

type TextProps = {
  hasLeftComponent: boolean
  hasRightComponent: boolean
}

export const Container = styled.div`
  display: flex;
  align-items: center;

  padding: 0.8rem 1.6rem;
  min-height: 4rem;
  min-width: 20rem;

  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.snow};
  }
`

export const Text = styled.div<TextProps>`
  flex: 1;

  margin-left: ${(props) => (props.hasLeftComponent ? 1.6 : 0)}rem;
  margin-right: ${(props) => (props.hasRightComponent ? 1.6 : 0)}rem;
`
