export enum USER_ROUTES {
  USUARIO = 'TWSTPUsuario/TPUsuario',
  GET_POR_CAMPOS = 'TWSTPUsuario/GetPorCampos',
  GET_LISTA_ALUNO = 'TWSTPUsuario/GetListaAluno',
  RECUPERAR_SENHA = 'TWSTPUsuario/RecuperarSenha',
  GET_LISTA_ALUNO_FORA_GRUPO = 'TWSTPUsuario/GetListaUsuarioForaGrupo',
  PATCH_USUARIO = 'TWSTPUsuario/PatchTPUsuario',
  GET_GRUPO_ALUNO = 'TWSTPUsuario/GetListaGrupoAluno',
  GET_GRUPO_PROFESSOR = 'TWSTPUsuario/GetListaGrupoProfessor',
}

export enum TRAINING_LEVEL_ROUTES {
  TREINO_NIVEL = 'TWSTPTreino_Nivel/TPTreino_Nivel',
  GET_POR_CAMPOS = 'TWSTPTreino_Nivel/GetPorCampos',
}

export enum ACTIVITY_ROUTES {
  ATIVIDADE = 'TWSTPAtividade/TPAtividade',
  GET_POR_CAMPOS = 'TWSTPAtividade/GetPorCampos',
}

export enum TRAINING_ZONE_ROUTES {
  ZONA_TREINAMENTO = 'TWSTPZona_Treinamento/TPZona_Treinamento',
  GET_POR_CAMPOS = 'TWSTPZona_Treinamento/GetPorCampos',
}

export enum ANAMNESE_ROUTES {
  ANAMNESE = 'TWSTPAnaminese/TPAnaminese',
  GET_POR_CAMPOS = 'TWSTPAnaminese/GetPorCampos',
  GET_ANAMNESE_ATIVO = 'TWSTPAnaminese/GetFullPerguntasAtivo',
}

export enum GROUP_STUDENT_ANAMINESE_ROUTES {
  GRUPO_ALUNO_ANAMNESE = 'TWSTPGrupoAluno_Anaminese/TPGrupoAluno_Anaminese',
  GET_RESPONDEU_ANAMNESE = 'TWSTPGrupoAluno_Anaminese/GetRespondeuAnaminese',
  GET_FULL_RESPOSTA_ANAMNESE = 'TWSTPGrupoAluno_Anaminese/GetFullRespostaAnaminese',
}

export enum BLOCK_ROUTES {
  BLOCO = 'TWSTPBloco/TPBloco',
  GET_POR_CAMPOS = 'TWSTPBloco/GetPorCampos',
}

export enum TRAINING_SHEET_ROUTES {
  TREINO = 'TWSTPTreino/TPTreino',
  PATCH = 'TWSTPTreino/PatchTPTreino',
  GET_POR_CAMPOS = 'TWSTPTreino/GetPorCampos',
  GET_LISTA = 'TWSTPTreino/GetLista',
}

export enum RUNNING_GROUP_ROUTES {
  GRUPO = 'TWSTPGrupo/TPGrupo',
  GET_POR_CAMPOS = 'TWSTPGrupo/GetPorCampos',
  GET_LISTA_ALUNOS = 'TWSTPGrupo/GetListaAlunos',
  GET_LISTA = 'TWSTPGrupo/GetLista',
  PATCH = 'TWSTPGrupo/PatchTPGrupo',
}

export enum GROUP_STUDENT_ROUTES {
  GRUPO_ALUNO = 'TWSTPGrupo_Aluno/TPGrupo_Aluno',
}

export enum GROUP_TEACHER_ROUTES {
  GRUPO_PROFESSOR = 'TWSTPGrupo_Professor/TPGrupo_Professor',
}

export enum STUDENT_TRAINING_CONFIG_ROUTES {
  CONFIG_TREINO_ALUNO = 'TWSTPConfig_Treino_Aluno/TPConfig_Treino_Aluno',
  GET_POR_CAMPOS = 'TWSTPConfig_Treino_Aluno/GetPorCampos',
}

export enum LOGIN_ROUTES {
  LOGIN = 'TWSTPLogin/Login',
  RESET_SENHA = 'TWSTPLogin/UpdateResetSenha',
  UPDATE_SENHA = 'TWSTPLogin/UpdateSenha',
}

export enum STUDENT_TRAINING_ROUTES {
  TREINO_ALUNO = 'TWSTPTreino_Aluno/TPTreino_Aluno',
  GET_LISTA = 'TWSTPTreino_Aluno/GetLista',
  GET_LISTA_FULL = 'TWSTPTreino_Aluno/GetListaFULL',
}
