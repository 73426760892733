export enum TRAINING_SHEET_TYPES {
  REQUEST_FETCH = '@TRAINING_SHEET/REQUEST_FETCH',
  REQUEST_EDIT = '@TRAINING_SHEET/REQUEST_EDIT',
  REQUEST_ADD = '@TRAINING_SHEET/REQUEST_ADD',
  REQUEST_DELETE = '@TRAINING_SHEET/REQUEST_DELETE',
  REQUEST_GET_DATA = '@TRAINING_SHEET/REQUEST_GET_DATA',
  REQUEST_CHANGE_STATUS = '@TRAINING_SHEET/REQUEST_CHANGE_STATUS',

  SET_LIST = '@TRAINING_SHEET/SET_LIST',
  SET_DATA = '@TRAINING_SHEET/SET_DATA',
  UPDATE_DATA = '@TRAINING_SHEET/UPDATE_DATA',
}

export type TrainingSheetToCreate = Omit<
  Models.TrainingGroupedTypes.TrainingSheet,
  'treino_id' | 'treino_situacao'
>

export interface TrainingSheetState {
  trainingSheets: Models.TrainingGroupedTypes.TrainingWithTrainingLevel[]
  trainingSheetData?: Models.TrainingGroupedTypes.TrainingSheetAndLevel
}

export interface RequestFetchTrainingSheetsAction {
  type: TRAINING_SHEET_TYPES.REQUEST_FETCH
  payload: {
    trainingLevelId?: number
    limit?: number
    offset?: number
    search?: string
    trainingStatus?: Models.Common.ActiveOrNotActive
  }
}

export interface RequestEditTrainingSheetAction {
  type: TRAINING_SHEET_TYPES.REQUEST_EDIT
  payload: {
    trainingSheet: Models.TrainingGroupedTypes.TrainingSheet
    successCallback?: () => void
  }
}

export interface RequestAddTrainingSheetAction {
  type: TRAINING_SHEET_TYPES.REQUEST_ADD
  payload: {
    trainingSheet: TrainingSheetToCreate
    successCallback?: () => void
  }
}

export interface RequestDeleteTrainingSheetAction {
  type: TRAINING_SHEET_TYPES.REQUEST_DELETE
  payload: {
    id: number
    successCallback?: () => void
  }
}

export interface RequestGetTrainingSheetAction {
  type: TRAINING_SHEET_TYPES.REQUEST_GET_DATA
  payload: {
    id: number
  }
}

export interface RequestChangeTrainingSheetStatusAction {
  type: TRAINING_SHEET_TYPES.REQUEST_CHANGE_STATUS
  payload: {
    id: number
    isActive: boolean
  }
}

export interface SetTrainingSheetListAction {
  type: TRAINING_SHEET_TYPES.SET_LIST
  payload: {
    trainingSheets: Models.TrainingGroupedTypes.TrainingWithTrainingLevel[]
    shouldAppendInTheList: boolean
  }
}

export interface SetTrainingSheetDataAction {
  type: TRAINING_SHEET_TYPES.SET_DATA
  payload: {
    trainingSheetData?: Models.TrainingGroupedTypes.TrainingSheetAndLevel
  }
}

export interface UpdateTrainingSheetDataAction {
  type: TRAINING_SHEET_TYPES.UPDATE_DATA
  payload: {
    trainingSheetData: Partial<Models.TrainingGroupedTypes.TrainingSheetAndLevel>
  }
}
