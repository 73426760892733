import React, { useLayoutEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FiActivity } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import { useSetBrowserTabTitle } from 'src/hooks'
import {
  requestFetchTrainingZones,
  setTrainingZoneList,
} from 'src/store/ducks/trainingZone'

import TrainingZoneForm from './TrainingZoneForm'
import TrainingZoneList from './TrainingZoneList'
import { Container, TrainingZoneConfigTitle, Divider } from './styles'

const TrainingZoneConfig: React.FC = () => {
  useSetBrowserTabTitle('trainingZoneConfig')

  const { t } = useTranslation(['TrainingZoneConfig'])
  const dispatch = useDispatch()

  const nameRef = useRef<HTMLInputElement | null>(null)
  const minBpmRef = useRef<HTMLInputElement | null>(null)
  const maxBpmRef = useRef<HTMLInputElement | null>(null)

  const [editingId, setEditingId] = useState(0)

  const setValues = ({ name = '', minBpm = '', maxBpm = '' }) => {
    if (nameRef.current) nameRef.current.value = name
    if (minBpmRef.current) minBpmRef.current.value = minBpm
    if (maxBpmRef.current) maxBpmRef.current.value = maxBpm
  }

  const handleFinishEditing = () => {
    setEditingId(0)
    setValues({})
  }

  useLayoutEffect(() => {
    dispatch(requestFetchTrainingZones())
    return () => {
      dispatch(setTrainingZoneList([]))
    }
  }, [dispatch])

  return (
    <Container>
      <TrainingZoneConfigTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<FiActivity />}
      />

      <TrainingZoneForm
        nameRef={nameRef}
        minBpmRef={minBpmRef}
        maxBpmRef={maxBpmRef}
        editingId={editingId}
        setValues={setValues}
        handleFinishEditing={handleFinishEditing}
      />

      <Divider />

      <TrainingZoneList
        nameRef={nameRef}
        setValues={setValues}
        editingId={editingId}
        setEditingId={setEditingId}
        handleFinishEditing={handleFinishEditing}
      />
    </Container>
  )
}

export default TrainingZoneConfig
