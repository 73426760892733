import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { BsClipboardData } from 'react-icons/bs'
import StateManager, { OptionsType } from 'react-select'

import {
  DefaultError,
  DefaultLabel,
  ReactSelectOption,
  SnowSelect,
} from 'src/components'
import { useIsLoading, useTypedSelector } from 'src/hooks'
import { TRAINING_LEVEL_TYPES } from 'src/store/ducks/trainingLevel'

import { Container, TrainingLevelInput } from './styles'

export interface TrainingLevelSelectProps {
  isShowingError: boolean
}

export const TrainingLevelSelect = forwardRef<
  StateManager<ReactSelectOption>,
  TrainingLevelSelectProps
>((props, ref) => {
  const { isShowingError } = props

  const { t } = useTranslation(['TrainingManagement', 'Glossary', 'Error'])
  const isLoading = useIsLoading(TRAINING_LEVEL_TYPES.REQUEST_FETCH)

  const trainingLevels: OptionsType<ReactSelectOption> = useTypedSelector(
    ({ TrainingLevel }) => {
      return TrainingLevel.trainingLevels.map((trainingLevel) => ({
        label: trainingLevel.treinonivel_descricao,
        value: `${trainingLevel.treinonivel_id}`,
      }))
    },
  )

  return (
    <Container>
      <TrainingLevelInput
        iconComponent={<BsClipboardData size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="trainingLevel">
            <span className="required">{t('Glossary:required')}</span>
            <span>{t('trainingLevelLabel')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowSelect
            id="trainingLevel"
            name="trainingLevel"
            ref={ref}
            placeholder={t('trainingLevelPh')}
            noOptionsMessage={() => t('noTrainingLevelMessage')}
            loadingMessage={() => t('Glossary:loading')}
            options={trainingLevels}
            isLoading={isLoading}
          />
        }
        errorComponent={
          <DefaultError>{t('Error:noOptionSelectedError')}</DefaultError>
        }
        showError={isShowingError}
      />
    </Container>
  )
})

TrainingLevelSelect.displayName = 'TrainingLevelSelect'
