import React from 'react'

import { Container, IconContainer, TextContainer } from './styles'

export interface BigClickableCardProps {
  className?: string
  style?: React.CSSProperties
  onClick?: (e: React.MouseEvent) => void
  iconComponent: React.ReactNode
  text: string
}

export const BigClickableCard: React.FC<BigClickableCardProps> = (props) => {
  const { className, style, text, iconComponent, onClick } = props

  return (
    <Container className={className} style={style} onClick={onClick}>
      <IconContainer>{iconComponent}</IconContainer>

      <TextContainer>
        <span>{text}</span>
      </TextContainer>
    </Container>
  )
}
