import styled from 'styled-components'

import { Input, SuccessButton, SmallButton, CheckBox } from 'src/components'

export const Container = styled.div`
  margin-bottom: 1.6rem;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  margin-bottom: 0.8rem;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
`

export const FilterGroup = styled.div`
  flex: 1 45%;
  max-width: 45%;

  @media only screen and (max-width: 1200px) {
    max-width: unset;
    padding: 0 0.8rem;
  }
`

export const CheckBoxesContainer = styled.div`
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 1rem;

  padding: 1.6rem;
  margin-bottom: 1.6rem;
`

export const FilterInput = styled(Input)`
  margin-bottom: 1.6rem;
`

export const SearchButton = styled(SuccessButton)`
  max-width: 40rem;

  padding: 0 1.6rem;
  margin: 0 auto;
`

export const ToggleFiltersButton = styled(SmallButton)`
  width: auto;

  padding: 0 1.6rem;
  margin: 0 auto 2.4rem auto;
`

export const FilterCheckbox = styled(CheckBox)`
  margin-top: 0.8rem;
`
