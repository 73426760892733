import React, { forwardRef } from 'react'
import { FiSmartphone } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import { DefaultLabel, SnowInputMask } from 'src/components'
import { INPUT_MASKS } from 'src/config'

import { EditProfileInput } from './styles'

interface UserPhoneInputProps {
  defaultPhone?: string
}

const UserPhoneInput = forwardRef<HTMLInputElement, UserPhoneInputProps>(
  (props, ref) => {
    const { defaultPhone } = props

    const { t } = useTranslation('EditUserProfile')

    return (
      <EditProfileInput
        iconComponent={<FiSmartphone size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="phone">
            <span>{t('phoneLabel')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowInputMask
            inputRef={ref}
            id="phone"
            mask={INPUT_MASKS.SMARTPHONE}
            maskChar={null}
            alwaysShowMask={false}
            type="phone"
            placeholder={t('phonePh')}
            defaultValue={defaultPhone}
          />
        }
      />
    )
  },
)

UserPhoneInput.displayName = 'UserPhoneInput'

export default UserPhoneInput
