import styled from 'styled-components'

import { Input, PageContainer, PageTitle, SuccessButton } from 'src/components'

import GroupData from './GroupData'
import GroupSelect from './GroupSelect'

export const Container = styled(PageContainer)``

export const RegisterUserTitle = styled(PageTitle)`
  margin-bottom: 4.8rem;
`

export const Form = styled.form`
  margin: auto;

  max-width: 60rem;
`

export const UserInput = styled(Input)`
  margin-bottom: 2.4rem;
`

export const StyledGroupData = styled(GroupData)`
  margin-bottom: 2.4rem;
  max-width: unset;

  pointer-events: none;
`

export const StyledGroupSelect = styled(GroupSelect)``

export const SaveButton = styled(SuccessButton)``
