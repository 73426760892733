import React, { useMemo } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { BsBoundingBox, BsFileCheck } from 'react-icons/bs'
import {
  FiActivity,
  FiCalendar,
  FiDatabase,
  FiPieChart,
  FiUsers,
} from 'react-icons/fi'
import { useHistory } from 'react-router'

import { useAuthenticatedUserTypes, useTypedSelector } from 'src/hooks'
import { ANAMNESE_STATUS } from 'src/config'

import {
  Container,
  DataGroupContent,
  ProfileCard,
  DataGroup,
  UserData,
  SeeAnamneseAnswersButton,
} from './styles'

const UserProfile: React.FC = () => {
  const { t } = useTranslation(['UserProfile', 'User'])
  const history = useHistory()

  const userData = useTypedSelector(({ User }) => User.userData)
  const { isAdmin, isTeacher, isStudent } = useAuthenticatedUserTypes()

  const isShowingOtherInformation = useMemo(() => {
    return (
      !!userData?.usuario_cnpj ||
      !!userData?.usuario_cpf ||
      !!userData?.usuario_dtnascimento
    )
  }, [userData])

  const isAnamneseAnswered = useMemo(() => {
    return (
      !!userData?.anaminese_status &&
      userData?.anaminese_status !== ANAMNESE_STATUS.NOT_ANSWERED
    )
  }, [userData?.anaminese_status])

  const getFormattedDate = (date?: string) => {
    if (!date) return ''
    return DateTime.fromSQL(date).toFormat('dd/MM/yyyy')
  }

  const handleSeeAnamneseAnswers = () => {
    const userId = userData?.usuario_id || 0
    history.push(`/anamnese-answers/${userId}`)
  }

  return (
    <Container>
      <DataGroup label={t('cardsSectionTitle')} iconComponent={<FiPieChart />}>
        <DataGroupContent>
          {(isTeacher || isAdmin) && (
            <ProfileCard
              iconComponent={<FiUsers />}
              title={t('numberOfStudents')}
              data={userData?.professor_qtdealunos || 0}
            />
          )}

          {!!userData?.usuario_dtinclusao && (
            <ProfileCard
              iconComponent={<FiCalendar />}
              title={t('userSince')}
              data={getFormattedDate(userData?.usuario_dtinclusao)}
            />
          )}

          <ProfileCard
            iconComponent={<FiActivity />}
            title={t('numberOfTrainings')}
            data={
              (isTeacher || isAdmin
                ? userData?.professor_qtdetreinos
                : userData?.aluno_qtdetreinos) || 0
            }
          />

          <ProfileCard
            iconComponent={<BsBoundingBox />}
            title={t('numberOfGroups')}
            data={
              (isTeacher || isAdmin
                ? userData?.professor_qtdegrupos
                : userData?.aluno_qtdegrupos) || 0
            }
          />
        </DataGroupContent>
      </DataGroup>

      {isShowingOtherInformation && (
        <DataGroup
          label={t('otherDataSectionTitle')}
          iconComponent={<FiDatabase />}
        >
          <DataGroupContent>
            {!!userData?.usuario_cnpj && (
              <UserData>
                <div>{t('User:cnpj')}</div>
                <div>{userData?.usuario_cnpj}</div>
              </UserData>
            )}

            {!!userData?.usuario_cpf && (
              <UserData>
                <div>{t('User:cpf')}</div>
                <div>{userData?.usuario_cpf}</div>
              </UserData>
            )}

            {!!userData?.usuario_dtnascimento && (
              <UserData>
                <div>{t('User:birthDate')}</div>
                <div>{getFormattedDate(userData?.usuario_dtnascimento)}</div>
              </UserData>
            )}
          </DataGroupContent>
        </DataGroup>
      )}

      {isStudent && isAnamneseAnswered && (
        <SeeAnamneseAnswersButton onClick={handleSeeAnamneseAnswers}>
          <span>{t('seeAnamneseAnswersButton')}</span>
          <BsFileCheck />
        </SeeAnamneseAnswersButton>
      )}
    </Container>
  )
}

export default UserProfile
