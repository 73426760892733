import styled from 'styled-components'

import {
  SuccessButton,
  Input,
  SmallButton,
  AnamneseQuestion,
  DangerRoundIconButton,
} from 'src/components'

export const Container = styled.div`
  max-width: 60rem;
  margin: auto;
`

export const Form = styled.form``

export const AnamneseInput = styled(Input)`
  margin-bottom: 1.6rem;
`

export const AddQuestionButton = styled(SmallButton)`
  width: auto;

  padding: 0 2.4rem;
  margin-left: auto;
`

export const DragAndDropHint = styled.div`
  margin: 2.4rem auto 0 auto;

  max-width: 70%;

  color: ${(props) => props.theme.secondaryText};
  font-style: italic;
  text-align: center;
`

export const Questions = styled.div`
  margin: 2.4rem 0;
  overflow: hidden;

  border: 1px solid ${(props) => props.theme.border};
  border-radius: 1rem;
`

export const Question = styled(AnamneseQuestion)`
  outline: none;
`

export const RemoveQuestionButton = styled(DangerRoundIconButton)`
  margin-left: 1.6rem;

  min-width: 4rem;
  min-height: 4rem;
`

export const SaveAnamneseButton = styled(SuccessButton)`
  width: auto;

  padding: 0 2.4rem;
  margin-left: auto;
`

export const DiscardQuiz = styled(SmallButton)`
  width: auto;

  padding: 0 2.4rem;
  margin-bottom: 2.4rem;
`
