export enum STUDENT_TRAINING_TYPES {
  REQUEST_FETCH = '@STUDENT_TRAINING/REQUEST_FETCH',
  REQUEST_GET = '@STUDENT_TRAINING/REQUEST_GET',
  REQUEST_EDIT = '@STUDENT_TRAINING/REQUEST_EDIT',
  REQUEST_FETCH_FULL = '@STUDENT_TRAINING/REQUEST_FETCH_FULL',

  SET_LIST = '@STUDENT_TRAINING/SET_LIST',
  SET_DATA = '@STUDENT_TRAINING/SET_DATA',
  SET_LIST_FULL = '@STUDENT_TRAINING/SET_LIST_FULL',
}

export type StudentTrainingData = Models.StudentTrainingGroupedTypes.TrainingSheetAndLevel &
  Pick<Models.User, 'usuario_id'>

export interface StudentTrainingState {
  trainings: Models.StudentTrainingGroupedTypes.TrainingWithTrainingLevel[]
  fullTrainings: Models.StudentTrainingGroupedTypes.TrainingSheetAndLevel[]
  trainingData?: StudentTrainingData
}

export interface RequestFetchStudentTrainingsAction {
  type: STUDENT_TRAINING_TYPES.REQUEST_FETCH
  payload: {
    userId: number
    search?: string
    initialDate?: string
    finalDate?: string
    limit?: number
    offset?: number
  }
}

export interface RequestFetchFullStudentTrainingsAction {
  type: STUDENT_TRAINING_TYPES.REQUEST_FETCH_FULL
  payload: {
    userId: number
    search?: string
    initialDate?: string
    finalDate?: string
    limit?: number
    offset?: number
  }
}

export interface RequestGetStudentTrainingAction {
  type: STUDENT_TRAINING_TYPES.REQUEST_GET
  payload: {
    trainingId: number
  }
}

export interface RequestEditStudentTrainingAction {
  type: STUDENT_TRAINING_TYPES.REQUEST_EDIT
  payload: {
    trainingSheet: Partial<Models.StudentTrainingGroupedTypes.TrainingSheet>
    successCallback?: () => void
  }
}

export interface SetStudentTrainingListAction {
  type: STUDENT_TRAINING_TYPES.SET_LIST
  payload: {
    trainings: Models.StudentTrainingGroupedTypes.TrainingWithTrainingLevel[]
    shouldAppendInTheList: boolean
  }
}

export interface SetFullStudentTrainingListAction {
  type: STUDENT_TRAINING_TYPES.SET_LIST_FULL
  payload: {
    fullTrainings: Models.StudentTrainingGroupedTypes.TrainingSheetAndLevel[]
    shouldAppendInTheList: boolean
  }
}

export interface SetStudentTrainingDataAction {
  type: STUDENT_TRAINING_TYPES.SET_DATA
  payload: {
    trainingData?: StudentTrainingData
  }
}
