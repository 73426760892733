import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError } from 'src/store/ducks/error'
import {
  GROUP_STUDENT_ANAMINESE_ROUTES,
  AxiosServerResponse,
  Server,
} from 'src/services/Server'

import { setAnamneseQuizAnswers } from './actions'
import {
  ANAMNESE_QUIZ_ANSWER_TYPES,
  RequestFetchAnamneseQuizAnswersAction,
} from './types'

type AxiosResponse = AxiosServerResponse<Models.AnamneseGroupedTypes.AnamneseFullAnswer>

export function* requestFetchAnamneseQuizAnswersWatcher() {
  yield takeLatest(
    ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_FETCH_ANSWERS,
    handleFetchAnamneseQuizAnswers,
  )
}

export function* handleFetchAnamneseQuizAnswers(
  action: RequestFetchAnamneseQuizAnswersAction,
) {
  try {
    yield put(addLoading(ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_FETCH_ANSWERS))
    const { userId } = action.payload

    const routeParamsArray = [
      GROUP_STUDENT_ANAMINESE_ROUTES.GET_FULL_RESPOSTA_ANAMNESE,
      userId,
    ]

    const { data }: AxiosResponse = yield call(
      Server.get,
      routeParamsArray.join('/'),
    )

    if (data.isSuccessful) {
      yield put(setAnamneseQuizAnswers(data.data))
    } else if (data.messages.length) {
      yield put(setAnamneseQuizAnswers([]))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(ANAMNESE_QUIZ_ANSWER_TYPES.REQUEST_FETCH_ANSWERS))
  }
}
