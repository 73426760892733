export enum USER_TYPES {
  REQUEST_FETCH_USER_DATA = '@USER/REQUEST_FETCH_USER_DATA',
  REQUEST_CHANGE_PASSWORD = '@USER/REQUEST_CHANGE_PASSWORD',
  REQUEST_EDIT_USER_PROFILE = '@USER/REQUEST_EDIT_USER_PROFILE',
  REQUEST_FETCH_USERS = '@USER/REQUEST_FETCH_USERS',

  SET_USER_DATA = '@USER/SET_USER_DATA',
  SET_USER_LIST = '@USER/SET_USER_LIST',
}

export interface UserState {
  userList: Models.User[]
  userData?: Models.UserGroupedTypes.UserWithExtraAttrs
}

export interface RequestFetchUserDataAction {
  type: USER_TYPES.REQUEST_FETCH_USER_DATA
  payload: {
    userId: number
  }
}

export interface RequestChangePasswordAction {
  type: USER_TYPES.REQUEST_CHANGE_PASSWORD
  payload: {
    email: string
    userId: number
    currentPassword: string
    newPassword: string
    successCallback?: () => void
  }
}

export interface RequestEditUserProfileAction {
  type: USER_TYPES.REQUEST_EDIT_USER_PROFILE
  payload: {
    id: number
    name?: string
    email?: string
    phone?: string
    birthDate?: string
    cpf?: string
    cnpj?: string
    image?: File | null
    isUpdatingCurrentLoggedUser?: boolean
    successCallback?: () => void
  }
}

export interface RequestFetchUsersAction {
  type: USER_TYPES.REQUEST_FETCH_USERS
}

export interface SetUserDataAction {
  type: USER_TYPES.SET_USER_DATA
  payload: {
    userData?: Models.UserGroupedTypes.UserWithExtraAttrs
  }
}

export interface SetUserListAction {
  type: USER_TYPES.SET_USER_LIST
  payload: {
    userList: Models.User[]
  }
}
