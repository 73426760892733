import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  RUNNING_GROUP_ROUTES,
  Server,
} from 'src/services/Server'

import { setRunningGroupData } from './actions'
import { RUNNING_GROUP_TYPES, RequestGetRunningGroupAction } from './types'

export function* requestGetRunningGroupWatcher() {
  yield takeLatest(
    RUNNING_GROUP_TYPES.REQUEST_GET_RUNNING_GROUP,
    handleGetRunningGroup,
  )
}

export function* handleGetRunningGroup(action: RequestGetRunningGroupAction) {
  try {
    yield put(addLoading(RUNNING_GROUP_TYPES.REQUEST_GET_RUNNING_GROUP))
    const { runningGroupId } = action.payload

    const routeArray = [RUNNING_GROUP_ROUTES.GRUPO, runningGroupId]

    const { data }: AxiosServerResponse<Models.Group> = yield call(
      Server.get,
      routeArray.join('/'),
    )

    if (data.isSuccessful) {
      const runningGroup = data.getFirstData()
      if (runningGroup) yield put(setRunningGroupData(runningGroup))
    } else {
      yield put(setRunningGroupData(undefined))
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(RUNNING_GROUP_TYPES.REQUEST_GET_RUNNING_GROUP))
  }
}
