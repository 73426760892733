import { Reducer } from 'redux'

import { TRAINING_LEVEL_TYPES, TrainingLevelState } from './types'

export const initialState: TrainingLevelState = {
  trainingLevels: [],
}

const reducer: Reducer<TrainingLevelState> = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case TRAINING_LEVEL_TYPES.SET_LIST: {
      return {
        ...state,
        trainingLevels: payload.trainingLevels,
      }
    }

    case TRAINING_LEVEL_TYPES.EDIT_IN_LIST: {
      const indexToEdit = state.trainingLevels.findIndex(
        ({ treinonivel_id }) => treinonivel_id === payload.treinonivel_id,
      )

      const trainingLevelsClone = [...state.trainingLevels]
      trainingLevelsClone[indexToEdit] = payload

      return {
        ...state,
        trainingLevels: trainingLevelsClone,
      }
    }

    case TRAINING_LEVEL_TYPES.ADD_IN_LIST: {
      const trainingLevelsClone = [...state.trainingLevels]
      trainingLevelsClone.push(payload)

      return {
        ...state,
        trainingLevels: trainingLevelsClone,
      }
    }

    case TRAINING_LEVEL_TYPES.REMOVE_FROM_LIST: {
      const indexToRemove = state.trainingLevels.findIndex(
        ({ treinonivel_id }) => treinonivel_id === payload.id,
      )

      const trainingLevelsClone = [...state.trainingLevels]
      trainingLevelsClone.splice(indexToRemove, 1)

      return {
        ...state,
        trainingLevels: trainingLevelsClone,
      }
    }

    default:
      return state
  }
}

export default reducer
