import {
  USER_TYPES,
  RequestChangePasswordAction,
  RequestEditUserProfileAction,
  RequestFetchUserDataAction,
  RequestFetchUsersAction,
  SetUserDataAction,
  SetUserListAction,
} from './types'

export const requestFetchUserData = (
  userId: number,
): RequestFetchUserDataAction => ({
  type: USER_TYPES.REQUEST_FETCH_USER_DATA,
  payload: {
    userId,
  },
})

export const requestChangePassword = (
  payload: RequestChangePasswordAction['payload'],
): RequestChangePasswordAction => ({
  type: USER_TYPES.REQUEST_CHANGE_PASSWORD,
  payload,
})

export const requestEditUserProfile = (
  payload: RequestEditUserProfileAction['payload'],
): RequestEditUserProfileAction => ({
  type: USER_TYPES.REQUEST_EDIT_USER_PROFILE,
  payload,
})

export const requestFetchUsers = (): RequestFetchUsersAction => ({
  type: USER_TYPES.REQUEST_FETCH_USERS,
})

export const setUserData = (
  userData?: Models.UserGroupedTypes.UserWithExtraAttrs,
): SetUserDataAction => ({
  type: USER_TYPES.SET_USER_DATA,
  payload: {
    userData,
  },
})

export const setUserList = (userList: Models.User[]): SetUserListAction => ({
  type: USER_TYPES.SET_USER_LIST,
  payload: {
    userList,
  },
})
