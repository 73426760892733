import styled from 'styled-components'

import {
  AnamneseAnswerableQuestion,
  PageContainer,
  PageTitle,
} from 'src/components'

export const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;

  min-height: 100%;
`

export const StyledPageTitle = styled(PageTitle)`
  margin-bottom: 3.2rem;
`

export const ContentFiller = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AnswerList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const AnswerableQuestion = styled(AnamneseAnswerableQuestion)`
  flex: 1 40%;
  min-width: 30rem;
  margin: 0 0.8rem 1.6rem 0.8rem;
`
