import {
  RequestFetchActivitiesAction,
  RequestEditActivityAction,
  RequestAddActivityAction,
  RequestDeleteActivityAction,
  SetActivityListAction,
  EditActivityInListAction,
  ACTIVITY_TYPES,
  AddInActivityListAction,
  RemoveFromActivityListAction,
} from './types'

export const requestFetchActivities = (): RequestFetchActivitiesAction => ({
  type: ACTIVITY_TYPES.REQUEST_FETCH,
})

export const requestEditActivity = (
  payload: RequestEditActivityAction['payload'],
): RequestEditActivityAction => ({
  type: ACTIVITY_TYPES.REQUEST_EDIT,
  payload,
})

export const requestAddActivity = (
  payload: RequestAddActivityAction['payload'],
): RequestAddActivityAction => ({
  type: ACTIVITY_TYPES.REQUEST_ADD,
  payload,
})

export const requestDeleteActivity = (
  id: number,
): RequestDeleteActivityAction => ({
  type: ACTIVITY_TYPES.REQUEST_DELETE,
  payload: {
    id,
  },
})

export const setActivityList = (
  activities: Models.Activity[],
): SetActivityListAction => ({
  type: ACTIVITY_TYPES.SET_LIST,
  payload: {
    activities,
  },
})

export const editActivityInList = (
  activity: Models.Activity,
): EditActivityInListAction => ({
  type: ACTIVITY_TYPES.EDIT_IN_LIST,
  payload: activity,
})

export const addInActivityList = (
  activity: Models.Activity,
): AddInActivityListAction => ({
  type: ACTIVITY_TYPES.ADD_IN_LIST,
  payload: activity,
})

export const removeFromActivityList = (
  id: number,
): RemoveFromActivityListAction => ({
  type: ACTIVITY_TYPES.REMOVE_FROM_LIST,
  payload: {
    id,
  },
})
