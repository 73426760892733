import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import { AxiosServerResponse, USER_ROUTES, Server } from 'src/services/Server'
import { withServerStructure } from 'src/utils'

import { showSuccessToast } from '../success'

import { STUDENT_TYPES, RequestRegisterStudentAction } from './types'

type DataToRegisterStudent = Pick<
  Models.User,
  'usuario_nome' | 'usuario_email' | 'usuario_aluno'
> &
  Pick<Models.Group, 'grupo_id'>

export function* requestRegisterStudentWatcher() {
  yield takeLatest(
    STUDENT_TYPES.REQUEST_REGISTER_STUDENT,
    handleRegisterStudent,
  )
}

export function* handleRegisterStudent(action: RequestRegisterStudentAction) {
  try {
    yield put(addLoading(STUDENT_TYPES.REQUEST_REGISTER_STUDENT))
    const { email, name, groupId, successCallback } = action.payload

    const dataToRegisterStudent: DataToRegisterStudent = {
      usuario_nome: name,
      usuario_email: email,
      grupo_id: groupId,
      usuario_aluno: 'S',
    }

    const { data }: AxiosServerResponse<Models.User> = yield call(
      Server.put,
      USER_ROUTES.USUARIO,
      withServerStructure(dataToRegisterStudent),
    )

    if (data.isSuccessful) {
      if (successCallback) successCallback()
      yield put(showSuccessToast({ messageCode: 'registerStudent' }))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(STUDENT_TYPES.REQUEST_REGISTER_STUDENT))
  }
}
