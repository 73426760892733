import { call, put, takeLatest } from 'redux-saga/effects'

import { withServerStructure } from 'src/utils'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  Server,
  ANAMNESE_ROUTES,
} from 'src/services/Server'

import { requestGetAnamneseQuiz } from './actions'
import { RequestUpdateAnamneseQuizAction, ANAMNESE_TYPES } from './types'

type QuestionsToUpdate = Pick<
  Models.AnamneseQuestion,
  'anamipergunta_descricao'
>

type RequestData = {
  perguntas: QuestionsToUpdate[]
}

export function* requestUpdateAnamneseQuizWatcher() {
  yield takeLatest(ANAMNESE_TYPES.REQUEST_UPDATE, handleUpdateAnamneseQuiz)
}

export function* handleUpdateAnamneseQuiz(
  action: RequestUpdateAnamneseQuizAction,
) {
  try {
    yield put(addLoading(ANAMNESE_TYPES.REQUEST_UPDATE))
    const { questions, successCallback } = action.payload

    const questionsToSave: QuestionsToUpdate[] = questions.map((question) => ({
      anamipergunta_descricao: question,
    }))

    const requestData: RequestData = {
      perguntas: questionsToSave,
    }

    const { data }: AxiosServerResponse<Models.Anamnese> = yield call(
      Server.post,
      ANAMNESE_ROUTES.ANAMNESE,
      withServerStructure(requestData),
    )

    if (data.isSuccessful) {
      if (successCallback) yield call(successCallback)
      yield put(requestGetAnamneseQuiz())
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(ANAMNESE_TYPES.REQUEST_UPDATE))
  }
}
