import React from 'react'
import { FiInfo } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import { useTypedSelector } from 'src/hooks'

import {
  Container,
  Content,
  TrainingDataContainer,
  TrainingData,
} from './styles'

const SheetInfo: React.FC = () => {
  const { t } = useTranslation(['StudentTrainingDetails', 'Glossary'])

  const studentTrainingData = useTypedSelector(
    ({ StudentTraining }) => StudentTraining.trainingData,
  )

  return (
    <Container>
      <Content label={t('sheetInfo.label')} iconComponent={<FiInfo />}>
        <TrainingDataContainer>
          {!!studentTrainingData?.treino_descricao && (
            <TrainingData>
              <div>{t('sheetInfo.trainingNameLabel')}</div>
              <div>{studentTrainingData?.treino_descricao}</div>
            </TrainingData>
          )}

          {!!studentTrainingData?.treinonivel_descricao && (
            <TrainingData>
              <div>{t('sheetInfo.trainingLevelLabel')}</div>
              <div>{studentTrainingData?.treinonivel_descricao}</div>
            </TrainingData>
          )}
        </TrainingDataContainer>
      </Content>
    </Container>
  )
}

export default SheetInfo
