import useTypedSelector from './useTypedSelector'

export default (key: string) => {
  const isShowingSuccess = useTypedSelector(({ Success }) => {
    const success = Success.success
    return !!success && !!success[key]
  })

  return isShowingSuccess
}
