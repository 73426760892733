import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Home from 'src/pages/Home'
import UserProfile from 'src/pages/UserProfile'
import PageNotFound from 'src/pages/PageNotFound'
import StudentTrainings from 'src/pages/StudentTrainings'
import TrainingConfig from 'src/pages/TrainingConfig'
import MyStudents from 'src/pages/MyStudents'
import NewStudent from 'src/pages/NewStudent'
import MyRunningGroups from 'src/pages/MyRunningGroups'
import CreateRunningGroup from 'src/pages/CreateRunningGroup'
import StudentTrainingDetails from 'src/pages/StudentTrainingDetails'
import RunningGroupDetails from 'src/pages/RunningGroupDetails'
import EditUserProfile from 'src/pages/EditUserProfile'
import ChangePassword from 'src/pages/ChangePassword'
import TrainingLevelConfig from 'src/pages/TrainingLevelConfig'
import ActivityConfig from 'src/pages/ActivityConfig'
import TrainingZoneConfig from 'src/pages/TrainingZoneConfig'
import TrainingBlockConfig from 'src/pages/TrainingBlockConfig'
import UserPublicProfile from 'src/pages/UserPublicProfile'
import EditRunningGroup from 'src/pages/EditRunningGroup'
import RegisterUser from 'src/pages/RegisterUser'
import SearchUser from 'src/pages/SearchUser'
import StudentTrainingConfig from 'src/pages/StudentTrainingConfig'
import TrainingSheetDetails from 'src/pages/TrainingSheetDetails'
import EditTrainingSheet from 'src/pages/EditTrainingSheet'
import EditStudentTraining from 'src/pages/EditStudentTraining'
import ComingSoon from 'src/pages/ComingSoon'
import AnswerAnamneseQuiz from 'src/pages/AnswerAnamneseQuiz'
import AnamneseConfig from 'src/pages/AnamneseConfig'
import AnamneseAnswers from 'src/pages/AnamneseAnswers'

const AdminRoutes: React.FC = () => {
  return (
    <Switch>
      <Route component={Home} path="/" exact />
      <Route component={ComingSoon} path="/dashboard" exact />

      <Route component={UserProfile} path="/user-profile" />
      <Route component={UserPublicProfile} path="/user-public-profile/:id" />
      <Route component={EditUserProfile} path="/edit-user-profile" />
      <Route component={ChangePassword} path="/change-password" />

      <Route component={StudentTrainings} path="/my-trainings" />
      <Route component={StudentTrainings} path="/student-trainings/:id" />
      <Route component={StudentTrainingDetails} path="/student-training/:id" />
      <Route
        component={EditStudentTraining}
        path="/edit-student-training/:id"
      />
      <Route
        component={StudentTrainingConfig}
        path="/student-training-config/:id?"
      />

      <Route component={MyStudents} path="/my-students" />
      <Route component={NewStudent} path="/new-student" />
      <Route component={RegisterUser} path="/register-user/:groupId?" />
      <Route component={SearchUser} path="/search-user/:groupId?" />

      <Route component={MyRunningGroups} path="/my-running-groups" />
      <Route component={CreateRunningGroup} path="/create-running-group" />
      <Route component={RunningGroupDetails} path="/running-group/:id" />
      <Route component={EditRunningGroup} path="/edit-running-group/:id" />
      <Route component={AnswerAnamneseQuiz} path="/answer-anamnese" />
      <Route component={AnamneseAnswers} path="/anamnese-answers/:id" />

      <Route component={TrainingLevelConfig} path="/training-level-config" />
      <Route component={TrainingBlockConfig} path="/training-block-config" />
      <Route component={ActivityConfig} path="/activity-config" />
      <Route component={TrainingZoneConfig} path="/training-zone-config" />
      <Route component={AnamneseConfig} path="/anamnese-config" />

      <Route component={TrainingConfig} path="/training-config" />
      <Route component={TrainingSheetDetails} path="/training-sheet/:id" />
      <Route component={EditTrainingSheet} path="/edit-training-sheet/:id" />

      <Route component={PageNotFound} />
    </Switch>
  )
}

export default AdminRoutes
