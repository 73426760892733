import { call, put, takeLatest } from 'redux-saga/effects'

import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showError, showServerError } from 'src/store/ducks/error'
import {
  AxiosServerResponse,
  TRAINING_ZONE_ROUTES,
  Server,
} from 'src/services/Server'

import { RequestDeleteTrainingZoneAction, TRAINING_ZONE_TYPES } from './types'
import { removeFromTrainingZoneList } from './actions'

export function* requestDeleteTrainingZoneWatcher() {
  yield takeLatest(TRAINING_ZONE_TYPES.REQUEST_DELETE, handleDeleteTrainingZone)
}

export function* handleDeleteTrainingZone(
  action: RequestDeleteTrainingZoneAction,
) {
  try {
    yield put(addLoading(TRAINING_ZONE_TYPES.REQUEST_DELETE))
    const { id } = action.payload

    const routeArray = [TRAINING_ZONE_ROUTES.ZONA_TREINAMENTO, id]

    const { data }: AxiosServerResponse = yield call(
      Server.delete,
      routeArray.join('/'),
    )

    if (data.isSuccessful) {
      yield put(removeFromTrainingZoneList(id))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(showError(e && e.message ? [e.message] : []))
  } finally {
    yield put(removeLoading(TRAINING_ZONE_TYPES.REQUEST_DELETE))
  }
}
