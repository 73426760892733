import { combineReducers } from 'redux'

import { ErrorReducer } from './ducks/error'
import { LoadingReducer } from './ducks/loading'
import { SuccessReducer } from './ducks/success'
import { AuthReducer } from './ducks/auth'
import { StudentReducer } from './ducks/student'
import { StudentTrainingReducer } from './ducks/studentTraining'
import { RunningGroupReducer } from './ducks/runningGroup'
import { UserReducer } from './ducks/user'
import { TrainingLevelReducer } from './ducks/trainingLevel'
import { ActivityReducer } from './ducks/activity'
import { TrainingZoneReducer } from './ducks/trainingZone'
import { AnamneseReducer } from './ducks/anamnese'
import { BlockReducer } from './ducks/block'
import { TrainingSheetReducer } from './ducks/trainingSheet'
import { AnamneseQuizAnswerReducer } from './ducks/anamneseQuizAnswer'
import { GroupMemberReducer } from './ducks/groupMember'
import { StudentTrainingConfigReducer } from './ducks/studentTrainingConfig'
import { ObservableReducer } from './ducks/observable'

const rootReducer = combineReducers({
  Error: ErrorReducer,
  Success: SuccessReducer,
  Loading: LoadingReducer,
  Auth: AuthReducer,
  Student: StudentReducer,
  StudentTraining: StudentTrainingReducer,
  RunningGroup: RunningGroupReducer,
  User: UserReducer,
  TrainingLevel: TrainingLevelReducer,
  Activity: ActivityReducer,
  TrainingZone: TrainingZoneReducer,
  Anamnese: AnamneseReducer,
  Block: BlockReducer,
  TrainingSheet: TrainingSheetReducer,
  AnamneseQuizAnswer: AnamneseQuizAnswerReducer,
  GroupMember: GroupMemberReducer,
  StudentTrainingConfig: StudentTrainingConfigReducer,
  Observable: ObservableReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
