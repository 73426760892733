import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useIsLoading, useTypedSelector } from 'src/hooks'
import {
  requestFetchUserData,
  setUserData,
  USER_TYPES,
} from 'src/store/ducks/user'

export default () => {
  const dispatch = useDispatch()

  const userData = useTypedSelector(({ Auth }) => Auth.userData)

  const groupAndStudentId = useTypedSelector(({ User }) => {
    if (!User.userData) return 0
    return User.userData.grupoaluno_id
  })

  const isLoadingGroupAndStudentId = useIsLoading(
    USER_TYPES.REQUEST_FETCH_USER_DATA,
  )

  useLayoutEffect(() => {
    const currentUserId = userData?.usuario_id || 0
    dispatch(requestFetchUserData(currentUserId))

    return () => {
      dispatch(setUserData(undefined))
    }
  }, [dispatch, userData?.usuario_id])

  return { groupAndStudentId, isLoadingGroupAndStudentId }
}
