import React from 'react'

import { StyledIframe } from './styles'

export interface IFrameProps {
  url: string | undefined
  tittle: string | void
}

export const IframeVideo: React.FC<IFrameProps> = (props) => {
  const { url, tittle } = props
  return (
    <StyledIframe
      width={100}
      height={530}
      src={'https://www.youtube.com/embed/' + url?.substr(16)}
      title={tittle as string | undefined}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></StyledIframe>
  )
}
