import styled from 'styled-components'

import { StudentButton, OutlinedButton } from 'src/components'

export const Container = styled.div`
  display: flex;
  align-items: space-between;
  flex-wrap: wrap;

  > * {
    width: auto;
    flex: 1;

    margin: 0 0.8rem;
    padding: 0 2.4rem;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;

    > * {
      flex: unset;
    }

    > *:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }
`

export const SeeStudentTrainings = styled(StudentButton)``

export const SeeStudentAnamneseAnswers = styled(OutlinedButton)``
