import styled from 'styled-components'

import { PageContainer, PageTitle } from 'src/components'

export const Container = styled(PageContainer)``

export const StyledPageTitle = styled(PageTitle)`
  margin-bottom: 4.8rem;
`

export const AppLogo = styled.img`
  max-height: 12rem;
  max-width: 12rem;

  border-radius: 100%;

  user-select: none;
`
