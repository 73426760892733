import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  FiArrowRightCircle,
  FiCheckCircle,
  FiEdit,
  FiFileText,
  FiTag,
} from 'react-icons/fi'
import StateManager, { OptionsType } from 'react-select'

import { useIsLoading } from 'src/hooks'
import {
  DefaultError,
  DefaultLabel,
  ReactSelectOption,
  SnowInput,
  SnowSelect,
  WhiteSpinner,
} from 'src/components'
import {
  requestAddTrainingLevel,
  requestEditTrainingLevel,
  TRAINING_LEVEL_TYPES,
} from 'src/store/ducks/trainingLevel'
import { TRAINING_LEVEL_CATEGORIES } from 'src/config'

import { Form, TrainingLevelInput, SaveButton } from './styles'

interface TrainingLevelFormProps {
  nameRef: React.MutableRefObject<HTMLInputElement | null>
  sequenceRef: React.MutableRefObject<HTMLInputElement | null>
  typeRef: React.MutableRefObject<StateManager<ReactSelectOption> | null>
  editingId: number
  handleFinishEditing: () => void
  setValues: (values: { name?: string; sequence?: string }) => void
}

const trainingLevelTypesOptions: OptionsType<ReactSelectOption> = Object.values(
  TRAINING_LEVEL_CATEGORIES,
).map((value) => ({
  label: String(value),
  value: String(value),
}))

const TrainingLevelForm: React.FC<TrainingLevelFormProps> = (props) => {
  const {
    nameRef,
    sequenceRef,
    typeRef,
    editingId,
    handleFinishEditing,
    setValues,
  } = props

  const dispatch = useDispatch()

  const { t } = useTranslation([
    'TrainingLevelConfig',
    'TrainingLevel',
    'Glossary',
    'Error',
  ])

  const isEditing = useMemo(() => {
    return editingId !== 0
  }, [editingId])

  const [errors, setErrors] = useState({
    isNameEmpty: false,
    isSequenceEmpty: false,
    isTypeNotSelected: false,
  })

  const isSaving = useIsLoading(
    TRAINING_LEVEL_TYPES.REQUEST_ADD,
    TRAINING_LEVEL_TYPES.REQUEST_EDIT,
  )

  const getValues = () => {
    const name = nameRef.current?.value || ''
    const sequence = sequenceRef.current?.value || ''

    const selectedType = typeRef.current?.state.value as
      | ReactSelectOption
      | undefined

    const type = selectedType?.value || ''

    return { name, sequence, type }
  }

  const handleValidate = (): boolean => {
    const { name, sequence, type } = getValues()

    const errorState: typeof errors = {
      isNameEmpty: !name.trim(),
      isSequenceEmpty: !sequence.trim(),
      isTypeNotSelected: !type,
    }

    setErrors(errorState)
    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (handleValidate()) {
      const { name, sequence, type } = getValues()

      if (isEditing) {
        dispatch(
          requestEditTrainingLevel({
            type,
            name,
            id: editingId,
            sequence: Number(sequence),
          }),
        )

        handleFinishEditing()
      } else {
        dispatch(
          requestAddTrainingLevel({
            type,
            name,
            sequence: Number(sequence),
          }),
        )

        setValues({})
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <TrainingLevelInput
        iconComponent={<FiFileText size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="name">
            <span className="required">{t('Glossary:required')}</span>
            <span>{t('TrainingLevel:name')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowInput
            id="name"
            name="name"
            ref={nameRef}
            type="text"
            disabled={isSaving}
            autoCapitalize="words"
            placeholder={t('namePh')}
          />
        }
        errorComponent={
          <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
        }
        showError={errors.isNameEmpty}
      />

      <TrainingLevelInput
        iconComponent={<FiArrowRightCircle size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="sequence">
            <span className="required">{t('Glossary:required')}</span>
            <span>{t('TrainingLevel:sequence')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowInput
            id="sequence"
            name="sequence"
            ref={sequenceRef}
            type="number"
            disabled={isSaving}
            placeholder={t('sequencePh')}
            min={1}
          />
        }
        errorComponent={
          <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
        }
        showError={errors.isSequenceEmpty}
      />

      <TrainingLevelInput
        iconComponent={<FiTag size="3.2rem" />}
        labelComponent={
          <DefaultLabel htmlFor="trainingLevelType">
            <span className="required">{t('Glossary:required')}</span>
            <span>{t('trainingLevelTypeLabel')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowSelect
            id="trainingLevelType"
            name="trainingLevelType"
            ref={typeRef}
            options={trainingLevelTypesOptions}
            placeholder={t('trainingLevelTypePh')}
            noOptionsMessage={() => t('noTrainingLevelTypes')}
            loadingMessage={() => t('Glossary:loading')}
          />
        }
        errorComponent={
          <DefaultError>{t('Error:noOptionSelectedError')}</DefaultError>
        }
        showError={errors.isTypeNotSelected}
      />

      <SaveButton type="submit" disabled={isSaving}>
        <span>{t(isEditing ? 'editButton' : 'saveButton')}</span>
        {isSaving ? (
          <WhiteSpinner />
        ) : isEditing ? (
          <FiEdit />
        ) : (
          <FiCheckCircle />
        )}
      </SaveButton>
    </Form>
  )
}

export default TrainingLevelForm
