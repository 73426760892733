import { Reducer } from 'redux'

import { ACTIVITY_TYPES, ActivityState } from './types'

export const initialState: ActivityState = {
  activities: [],
}

const reducer: Reducer<ActivityState> = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ACTIVITY_TYPES.SET_LIST: {
      return {
        ...state,
        activities: payload.activities,
      }
    }

    case ACTIVITY_TYPES.EDIT_IN_LIST: {
      const indexToEdit = state.activities.findIndex(
        ({ atividade_id }) => atividade_id === payload.atividade_id,
      )

      const activitiesClone = [...state.activities]
      activitiesClone[indexToEdit] = payload

      return {
        ...state,
        activities: activitiesClone,
      }
    }

    case ACTIVITY_TYPES.REMOVE_FROM_LIST: {
      const indexToRemove = state.activities.findIndex(
        ({ atividade_id }) => atividade_id === payload.id,
      )

      const activitiesClone = [...state.activities]
      activitiesClone.splice(indexToRemove, 1)

      return {
        ...state,
        activities: activitiesClone,
      }
    }

    case ACTIVITY_TYPES.ADD_IN_LIST: {
      const activitiesClone = [...state.activities]
      activitiesClone.push(payload)

      return {
        ...state,
        activities: activitiesClone,
      }
    }

    default:
      return state
  }
}

export default reducer
