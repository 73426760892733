import React, { useLayoutEffect, useRef } from 'react'
import { DateTime } from 'luxon'
import { BsPeople } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FiSearch, FiX } from 'react-icons/fi'

import {
  useAuthenticatedUserTypes,
  useIsLoading,
  useSetBrowserTabTitle,
  useTypedSelector,
  useTypingCallback,
} from 'src/hooks'
import { DefaultLabel, Input, Spinner, SpinnerWithText } from 'src/components'
import {
  requestFetchRunningGroups,
  RUNNING_GROUP_TYPES,
  setRunningGroupList,
} from 'src/store/ducks/runningGroup'

import {
  Container,
  MyGroupsTitle,
  FilterContainer,
  SearchInput,
  GroupList,
  RunningGroup,
  NoRunningGroupsMessage,
  FilterInputContainer,
  ClearSearchInputButton,
} from './styles'

const MyRunningGroups: React.FC = () => {
  useSetBrowserTabTitle('myRunningGroups')

  const { t } = useTranslation(['MyRunningGroups', 'RunningGroup'])
  const dispatch = useDispatch()
  const history = useHistory()

  const userData = useTypedSelector(({ Auth }) => Auth.userData)
  const { isStudent, isTeacher, isAdmin } = useAuthenticatedUserTypes()

  const runningGroups = useTypedSelector(
    ({ RunningGroup }) => RunningGroup.runningGroups,
  )

  const isLoading = useIsLoading(
    RUNNING_GROUP_TYPES.REQUEST_FETCH_RUNNING_GROUPS,
  )

  const searchRef = useRef<HTMLInputElement | null>(null)

  const handleInput = useTypingCallback({
    stopTypingCallback() {
      const userId = userData?.usuario_id || 0
      const search = searchRef.current?.value.trim()

      dispatch(
        requestFetchRunningGroups({
          userId,
          search,
          isSearchingForStudentGroups: !isAdmin && isStudent,
          isSearchingForTeacherGroups: !isAdmin && isTeacher,
        }),
      )
    },
  })

  const handleClearSearchText = () => {
    if (!searchRef.current) return
    if (searchRef.current.value) {
      searchRef.current.value = ''
      searchRef.current.focus()
      handleInput()
    } else {
      searchRef.current.focus()
    }
  }

  useLayoutEffect(() => {
    const userId = userData?.usuario_id || 0

    dispatch(
      requestFetchRunningGroups({
        userId,
        isSearchingForStudentGroups: !isAdmin && isStudent,
        isSearchingForTeacherGroups: !isAdmin && isTeacher,
      }),
    )

    return () => {
      dispatch(setRunningGroupList([]))
    }
  }, [dispatch, isAdmin, isStudent, isTeacher, userData])

  return (
    <Container>
      <MyGroupsTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<BsPeople />}
      />

      <FilterContainer>
        <Input
          iconComponent={<FiSearch size="3.2rem" />}
          labelComponent={
            <DefaultLabel htmlFor="searchGroup">
              <span>{t('searchLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <FilterInputContainer>
              <SearchInput
                id="searchGroup"
                ref={searchRef}
                type="text"
                placeholder={t('searchPh')}
                onInput={handleInput}
                disabled={isLoading}
              />

              <ClearSearchInputButton onClick={handleClearSearchText}>
                <FiX />
              </ClearSearchInputButton>
            </FilterInputContainer>
          }
        />
      </FilterContainer>

      {isLoading ? (
        <SpinnerWithText hasSpinnerOnTheLeft>
          <Spinner />
          <span>{t('loadingText')}</span>
        </SpinnerWithText>
      ) : (
        <GroupList>
          {runningGroups.map((item) => {
            const {
              grupo_id,
              grupo_nome,
              grupo_dtinclusao,
              grupo_numeromembros,
            } = item

            const luxonCreationDate = DateTime.fromSQL(grupo_dtinclusao)

            const handleGroupClick = () => {
              history.push(`/running-group/${grupo_id}`)
            }

            return (
              <RunningGroup
                key={grupo_id}
                name={grupo_nome}
                imageSrc={null}
                onClick={handleGroupClick}
                imageAlt={t('RunningGroup:image')}
                numberOfMembers={t('RunningGroup:numberOfStudents', {
                  count: grupo_numeromembros || 0,
                })}
                createdAt={t('RunningGroup:createdAtWithTime', {
                  date: luxonCreationDate.toFormat('dd/MM/yyyy'),
                  time: luxonCreationDate.toFormat('hh:mm:ss'),
                  interpolation: {
                    escapeValue: false,
                  },
                })}
              />
            )
          })}
        </GroupList>
      )}

      {!isLoading && !runningGroups.length && (
        <NoRunningGroupsMessage
          message={t('noRunningGroupFound')}
          iconComponent={<BsPeople />}
        />
      )}
    </Container>
  )
}

export default MyRunningGroups
