import styled, { css } from 'styled-components'

type StatusTextProps = {
  isActive?: boolean
}

const TextWithIcon = css`
  display: flex;
  align-items: center;

  span {
    margin-left: 1.6rem;
  }
`

export const Header = styled.div`
  padding: 0.8rem 1.6rem;

  border-bottom: 1px solid ${(props) => props.theme.border};
  background: ${(props) => props.theme.snow};

  transition: border-bottom 0.2s ease-in-out, background 0.2s ease-in-out;
`

export const Container = styled.div`
  overflow: hidden;
  border-radius: 1rem;
  border: 1px solid ${(props) => props.theme.border};

  cursor: pointer;
  transition: border 0.2s ease-in-out;

  &:hover,
  &:focus {
    border: 1px solid ${(props) => props.theme.contrastSecondaryText};

    ${Header} {
      border-bottom: 1px solid ${(props) => props.theme.contrastSecondaryText};

      background: ${(props) => props.theme.snowDark};
    }
  }
`

export const Content = styled.div`
  padding: 0.8rem 1.6rem;
`

export const Title = styled.div`
  ${TextWithIcon};

  font-size: 1.8rem;
  font-weight: 900;
`

export const Text = styled.div`
  ${TextWithIcon};

  margin: 0.1rem 0;

  span {
    color: ${(props) => props.theme.secondaryText};
  }
`

export const StatusText = styled(Text)<StatusTextProps>`
  color: ${(props) => {
    return props.isActive ? props.theme.success : props.theme.danger
  }};

  span {
    color: ${(props) => {
      return props.isActive ? props.theme.successDark : props.theme.dangerDark
    }};
  }
`
