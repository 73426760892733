import React, { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FiPlusCircle, FiXCircle } from 'react-icons/fi'

import { ImageFileInput } from 'src/components'

import {
  ImageContainer,
  UserAvatar,
  ImageInfo,
  AddImageButton,
  RemoveImageButton,
} from './styles'

interface UserProfileImageProps {
  userName?: string
  imageFile: File | null
  handleSelectImage: () => void
  handleRemoveImage: () => void
  handleImageSelected: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const UserProfileImage = forwardRef<HTMLInputElement, UserProfileImageProps>(
  (props, ref) => {
    const {
      userName,
      imageFile,
      handleImageSelected,
      handleRemoveImage,
      handleSelectImage,
    } = props

    const { t } = useTranslation('EditUserProfile')

    const imagePreviewSrc = useMemo((): string | undefined => {
      if (imageFile) return URL.createObjectURL(imageFile)
      return undefined
    }, [imageFile])

    return (
      <ImageContainer>
        <UserAvatar
          src={imagePreviewSrc}
          alt={userName}
          onClick={handleSelectImage}
        />

        <ImageFileInput onChange={handleImageSelected} ref={ref} />

        <ImageInfo>
          <div>{t('User:image')}</div>

          <AddImageButton onClick={handleSelectImage}>
            <span>{t('addImage')}</span>
            <FiPlusCircle />
          </AddImageButton>

          {!!imageFile && (
            <RemoveImageButton onClick={handleRemoveImage}>
              <span>{t('removeImage')}</span>
              <FiXCircle />
            </RemoveImageButton>
          )}
        </ImageInfo>
      </ImageContainer>
    )
  },
)

UserProfileImage.displayName = 'UserProfileImage'

export default UserProfileImage
