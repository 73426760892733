import { useTypedSelector } from 'src/hooks'

export default () => {
  const userData = useTypedSelector(({ Auth }) => Auth.userData)

  const isCurrentUserInGroupMembers = useTypedSelector(
    ({ GroupMember }): boolean => {
      if (!userData) return false

      const groupMember = GroupMember.groupMembers.find((member) => {
        return member.usuario_id === userData.usuario_id
      })

      return !!groupMember
    },
  )

  return isCurrentUserInGroupMembers
}
