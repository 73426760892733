import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FiPlusSquare, FiXSquare } from 'react-icons/fi'

import { ImagePlaceholder } from 'src/assets'
import { ImageFileInput } from 'src/components'

import {
  Container,
  Image,
  ImageInfo,
  AddImageButton,
  RemoveImageButton,
} from './styles'

interface RunningGroupImageProps {
  imageRef: React.MutableRefObject<HTMLInputElement | null>
  imageFile: File | null
  setImageFile: (imageFile: File | null) => void
  groupData: Models.Group | undefined | null
}

const RunningGroupImage: React.FC<RunningGroupImageProps> = (props) => {
  const { imageRef, imageFile, setImageFile } = props

  const { t } = useTranslation(['EditRunningGroup', 'RunningGroup'])

  const imagePreviewSrc = useMemo((): string => {
    if (imageFile) return URL.createObjectURL(imageFile)
    return ImagePlaceholder
  }, [imageFile])

  const handleSelectImage = () => {
    imageRef.current?.click()
  }

  const handleImageSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [selectedImage] = e.target.files || []
    if (selectedImage) setImageFile(selectedImage)
  }

  const handleRemoveImage = () => {
    if (imageRef.current) imageRef.current.value = ''
    setImageFile(null)
  }

  return (
    <Container>
      <Image
        src={imagePreviewSrc}
        alt={t('RunningGroup:image')}
        onClick={handleSelectImage}
      />

      <ImageFileInput onChange={handleImageSelected} ref={imageRef} />

      <ImageInfo>
        <div>{t('RunningGroup:image')}</div>

        <AddImageButton onClick={handleSelectImage}>
          <span>{t('addImage')}</span>
          <FiPlusSquare />
        </AddImageButton>

        {!!imageFile && (
          <RemoveImageButton onClick={handleRemoveImage}>
            <span>{t('removeImage')}</span>
            <FiXSquare />
          </RemoveImageButton>
        )}
      </ImageInfo>
    </Container>
  )
}

export default RunningGroupImage
