import React, { useLayoutEffect, useRef, useState } from 'react'
import { FiBox } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useSetBrowserTabTitle } from 'src/hooks'
import { requestFetchBlocks, setBlockList } from 'src/store/ducks/block'

import TrainingBlockForm from './TrainingBlockForm'
import TrainingBlockList from './TrainingBlockList'
import { Container, TrainingBlockConfigTitle, Divider } from './styles'

const TrainingBlockConfig: React.FC = () => {
  useSetBrowserTabTitle('trainingBlockConfig')

  const { t } = useTranslation(['TrainingBlockConfig'])
  const dispatch = useDispatch()

  const nameRef = useRef<HTMLInputElement | null>(null)
  const [editingId, setEditingId] = useState(0)

  const setValues = ({ name = '' }) => {
    if (nameRef.current) nameRef.current.value = name
  }

  const handleFinishEditing = () => {
    setEditingId(0)
    setValues({})
  }

  useLayoutEffect(() => {
    dispatch(requestFetchBlocks())
    return () => {
      dispatch(setBlockList([]))
    }
  }, [dispatch])

  return (
    <Container>
      <TrainingBlockConfigTitle
        title={t('title')}
        subtitle={t('subtitle')}
        iconComponent={<FiBox />}
      />

      <TrainingBlockForm
        nameRef={nameRef}
        editingId={editingId}
        setValues={setValues}
        handleFinishEditing={handleFinishEditing}
      />

      <Divider />

      <TrainingBlockList
        nameRef={nameRef}
        setValues={setValues}
        editingId={editingId}
        setEditingId={setEditingId}
        handleFinishEditing={handleFinishEditing}
      />
    </Container>
  )
}

export default TrainingBlockConfig
